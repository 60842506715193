export class PowerBiEmbeddedService {
  constructor(http) {
    this.http = http;
  }

  /**
   * Get all the reports accessible for the given siteId
   * @param {number} siteId - identifies site to get reports for
   * @returns array of all the reports
   */
  async getSiteReports(siteId) {
    return this.http.get(`/powerbi/site/${siteId}/report`);
  }

  /**
   * Get all the reports enabled for the given site configuration
   * @param {number} configId - identifies site config to get enabled reports for
   * @returns array of all the reports (names and Ids)
   */
  async getSiteConfigReports(configId) {
    return this.http.get(`/powerbi/config/${configId}/report`);
  }

  /**
   * Adds a report with the given report information
   * associated with the given siteId
   * @param {number} siteId - identifies site to add report for
   * @param {Object} reportData - report identification and display data
   * @returns the entryId of the newly added report
   */
  async addReport(siteId, reportData) {
    return this.http.post(`/powerbi/site/${siteId}/report/add`, reportData);
  }

  /**
   * Updates the report with the given id to contain the new data
   * @param {number} siteId - identifies site report is associated with
   * @param {number} entryId - id for entry where report to change is found
   * @param {Object} newReportData - updated report identification and display data
   */
  async updateReport(siteId, entryId, newReportData) {
    return this.http.put(
      `/powerbi/site/${siteId}/report/${entryId}/update`,
      newReportData
    );
  }

  /**
   * Remove the report with the given id
   * @param {number} siteId - identifies site report is associated with
   * @param {number} entryId - id for entry where report to remove is found
   */
  async removeReport(siteId, entryId) {
    return this.http.delete(`/powerbi/site/${siteId}/report/${entryId}`);
  }

  /**
   * Get the embed configuration for the report with the given id
   * @param {number} siteId - identifies site report is associated with
   * @param {number} entryId - where report to embed is found
   * @returns embed configuration
   */
  async getEmbedConfig(siteId, entryId) {
    return this.http.get(`/powerbi/site/${siteId}/report/${entryId}/embed`);
  }
}
