import ReactGA from 'react-ga4';

let hasInit = false;
let currentPage = '';
let state = null;
let globalTracker = null;
let tracker = [];
let allowGADevTracking = true;

const isDevEnv = false;
const gaKey =
  process.env.REACT_APP_GA === undefined
    ? 'G-PG3CWMFZLT'
    : process.env.REACT_APP_GA;

const initGA = () => {
  let trackerData = [];

  // GLOBAL tracker setup
  if (gaKey) {
    globalTracker = 'globalTracker';
    tracker.push(globalTracker);
    trackerData.push({
      trackingId: gaKey,
      gaOptions: { name: globalTracker, siteSpeedSampleRate: 75 },
    });
  }

  // do not track on dev its not set
  if (isDevEnv && !allowGADevTracking) {
    return;
  }

  ReactGA.initialize(trackerData);

  hasInit = true;
};

const trackPage = page => {
  // do not track local dev environments unless bit is set
  if (isDevEnv && !allowGADevTracking) {
    return;
  }

  // init the GA to track into the right places
  if (!hasInit && gaKey) {
    initGA();
  }

  // if a user exists let's track everything to that user
  let payload = { hitType: 'pageview', page };
  if (state.auth.userId) {
    payload = { ...payload, userId: state.auth.userId };
  }

  ReactGA.send(payload);
};

export const googleAnalytics = store => next => action => {
  // track URL changes as page views
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.location.pathname}`;
    if (currentPage !== nextPage) {
      currentPage = nextPage;
      state = store.getState();
      trackPage(nextPage);
    }
  }
  return next(action);
};
