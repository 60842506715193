import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import './errorBoundary.scss';

class UnverifiedEmail extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Unrecognized User</title>
        </Helmet>
        <div className="error-card">
          <h1>Unrecognized User</h1>
          <p>
            The email associated with this account has not been granted access
            to any sites on this platform.
          </p>
          <p>
            If you would like to request access or believe you should already
            have access, please email&nbsp;
            <a href="mailto:reveal@mckinstry.com?subject=Reveal Unrecognized Email">
              reveal@mckinstry.com
            </a>
            .
          </p>
          <Link
            to="/logout"
            style={{ color: '#0a3f54', textDecoration: 'none' }}
          >
            <u>Return to Login</u>
          </Link>
          <p></p>
          <div className="logo">
            <img
              src={'/images/reveal-white.svg'}
              alt="reveal-logo"
              width="100px"
              style={{ marginRight: '15px' }}
              className="img text-center"
            />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(UnverifiedEmail);
