import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { login, logout } from '../../reducers/auth';
import ReactLoading from 'react-loading';
import { tokenService } from '../../utilities/tokenService';
import { bindActionCreators } from 'redux';
import { assetBlob } from '../../utilities/assets';
import { Button } from 'reactstrap';
import { selectSite, fetchAndLaunchSiteConfig } from '../../reducers/auth';
import { useMsal, useIsAuthenticated, useAccount } from '@azure/msal-react';
import { loginRequest, apiConfig } from '../../components/Auth/authConfig';
import { isAdmin } from 'utilities/userRoles';

import './styles/login.scss';

const Login = props => {
  const [loginTimedOut, setLoginTimedOut] = useState(false);
  const idleTimer = useRef(null);
  const { instance, accounts, inProgress, } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});

  const history = useHistory();

  const { failed } = props.auth;

  // RE-DIRECT TO LOGIN PAGE
  useEffect(() => {
    const checkLogin = async () => {
      // await redirect to resolve before attempting to re-direct again
      await instance.handleRedirectPromise();

      // if no account and not authenticated, push back to login
      if (!account && !isAuthenticated) {
        instance.loginRedirect(loginRequest);

        // user is authenticated but we're still sitting on the login page
      } else {
        // set a timeout for 5 seconds before flagging page as stale
        idleTimer.current = setTimeout(() => setLoginTimedOut(true), 5000);
      }
    };

    // if auth framework is loading, login is disabled, or currently grabbing token don't do anything here.
    if (
      inProgress === 'login' ||
      inProgress === 'startup' ||
      inProgress === 'handleRedirect' ||
      props.auth.disableLogin ||
      props.auth.grabbingToken
    )
      return;

    checkLogin();
  }, [
    isAuthenticated,
    inProgress,
    props.auth.grabbingToken,
    instance.loginRedirect,
    props.auth.disableLogin,
  ]);

  // PERFORM LOGIN WITH AUTH0 TOKEN
  useEffect(() => {
    // no action if user not authenticated
    if (!isAuthenticated) return;

    // save get token function for use outside of react component
    tokenService.setAccessTokenSilently(getTokenSilently);

    // no user id means we need to call the the login api
    if (!props.auth.userId) {
      // run the login flow
      props.actions.login();
    } else {
      // we do have a user id so no need to call login api again
      //   route to passthrough if available, root if not. 
      let passthroughRoute = sessionStorage.getItem('passthroughRoute')
      let searchQuery = passthroughRoute?.split('?')[1];
      const searchQueryParams = new URLSearchParams('?' + searchQuery);
      const configParam = (searchQuery && searchQueryParams.has("configId")) ? parseInt(searchQueryParams.get("configId")) : null;
      let siteFromAuth = props.auth.sites?.find(s => s.configId === configParam)?.id;
      if (configParam) {
        //if config is not in a user's list, and they are an admin, we need to fetch this config and
        //set it to active, route, then reload
        if (isAdmin(props.auth.role) && (props.auth.configId !== configParam) && !siteFromAuth) {
          async function getConfigAndReload() {
            await props.actions.fetchAndLaunchSiteConfig(configParam);
            siteFromAuth = props.auth.sites?.find(s => s.configId === configParam)?.id;
            props.actions.selectSite(siteFromAuth, configParam);
            history.push(passthroughRoute);
          }
          getConfigAndReload();
        } else if (props.auth.configId !== configParam && siteFromAuth) {
          props.actions.selectSite(siteFromAuth, configParam);
        }
        history.push(passthroughRoute);
      } else if (!configParam && passthroughRoute && siteFromAuth) {
        history.push(passthroughRoute);
      } else {
        sessionStorage.removeItem('passthroughRoute');
        history.push("/");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, props.auth.userId]);

  // async function to get auth0 token
  const getTokenSilently = async () => {
    if (account) {
      try {
        // attempt to get an access token
        const access_token = await instance.acquireTokenSilent({
          scopes: apiConfig.b2cScopes,
          forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
          account: account,
        });
        return access_token;
      } catch (e) {
        console.error(e);
      }
    }
  };
  const logout = () => {
    props.history.replace('/logout');
  };

  return (
    <div
      className="fallback"
      style={{
        backgroundColor: props.auth.partnerConfig?.primaryColor ?? '#FFFF',
      }}
    >
      <img
        src={new URL('product-logo.svg', assetBlob).toString()}
        alt="App Logo"
        className="logo-center"
      />
      {failed || loginTimedOut ? (
        <>
          <div className="loader-text">
            {failed ? 'Login Failed.' : 'Error initializing the application.'}
          </div>
          <div>
            <Button className="submit" onClick={logout}>
              Try Again
            </Button>
          </div>
        </>
      ) : (
        (inProgress === 'login' ||
          !isAuthenticated ||
          props.auth.grabbingToken) && (
          <>
            <div className="loader-text">
              {inProgress === 'login' ? 'Loading...' : 'Logging in...'}
            </div>
            <ReactLoading
              className="loader"
              height={25}
              type="spin"
              width={25}
            />{' '}
          </>
        )
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      logout,
      login,
      selectSite,
      fetchAndLaunchSiteConfig
    },
    dispatch
  ),
  dispatch,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login));
