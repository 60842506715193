import React, { Component } from 'react';

export default function asyncComponent(getComponent) {
  class AsyncComponent extends Component {
    static Component = null;

    state = {
      Component: AsyncComponent.Component,
    };

    componentWillMount() {
      if (!this.state.Component) {
        // check if location reload already called
        const wasForceReloaded = JSON.parse(
          window.localStorage.getItem('was-force-reloaded') || 'false'
        );

        getComponent()
          .then(Component => {
            AsyncComponent.Component = Component;

            // reset reload flag
            window.localStorage.setItem('was-force-reloaded', 'false');

            this.setState({
              Component,
            });
          })
          .catch(error => {
            // catch component load error and reload if not already attempted
            if (!wasForceReloaded) {
              window.localStorage.setItem('was-force-reloaded', 'true');
              return window.location.reload();
            }

            throw error;
          });
      }
    }

    render() {
      const { Component } = this.state;

      if (Component) {
        return <Component {...this.props} />;
      }

      return null;
    }
  }

  return AsyncComponent;
}
