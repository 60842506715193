var moment = require('moment-timezone');

const emptyAppointmentModel = {
  siteID: null,
  buildingID: null,
  areaID: null,
  equipmentID: null,
  holiday: false,
  holidayCommon: false,
  startDate: null,
  endDate: null,
  title: null,
  allDay: false,
  rRule: null,
  exDate: null,
  priorityID: 0,
  modeID: null,
  deleted: 0,
};

//---- Get range of spatial groups from appointments ----------------
export const buildFullApptModel = (appointment, filters) => {
  try {
    //-- combine appointment model with selected filters --
    return { ...emptyAppointmentModel, ...filters, ...appointment };
  } catch (err) {
    console.error(`Error building full appointments: `, err);
    return emptyAppointmentModel;
  }
};
//-------------------------------------------------------------------

//---- Get range of spatial groups from appointments ----------------
export const getSpatialGroupsFromAppointments = appointments => {
  let out = {
    buildingID: [],
    areaID: [],
    equipmentID: [],
    customID: [],
  };
  const fields = Object.keys(out);
  if (!(appointments?.length > 0)) {
    return out;
  }
  try {
    // loop over appointments
    for (let i = 0; i < appointments.length; i++) {
      // loop over field types
      for (let j = 0; j < fields.length; j++) {
        if (appointments[i][fields[j]] !== null) {
          out[fields[j]].push(appointments[i][fields[j]]);
        }
      }
    }
    return out;
  } catch (err) {
    console.log(`Error processing appointments: `, err);
    return out;
  }
};
//-------------------------------------------------------------------

//------ Adjust for Timezones ---------------------------------------
export const formatInputData = (
  data,
  siteTimezone,
  userSelectedTimezone,
  source = 'database'
) => {
  //--- deep copy ---
  let dataNew = [];
  if (data?.length > 0) {
    dataNew = JSON.parse(JSON.stringify(data));
  }
  let booleanMap = [false, true];
  //--- Loop Over Appointments ---
  for (let i = 0; i < dataNew.length; i++) {
    //--- [1] Adjust Timezones, apply offset to spoof local browser time ---
    if (!(dataNew[i]?.startDate in [null, undefined])) {
      const startTime = adjustTimezone(
        dataNew[i].startDate,
        siteTimezone,
        userSelectedTimezone,
        source
      );
      dataNew[i].startDate = startTime;
    }
    if (!(dataNew[i]?.endDate in [null, undefined])) {
      const endTime = adjustTimezone(
        dataNew[i].endDate,
        siteTimezone,
        userSelectedTimezone,
        source
      );
      dataNew[i].endDate = endTime;
    }

    //--- [2] Add small id for calendar actions to work ---
    dataNew[i]['id'] = dataNew[i].ID;

    //--- [3] Process remaining data ---
    dataNew[i].allDay = booleanMap[dataNew[i].allDay];
    dataNew[i].holiday = booleanMap[dataNew[i].holiday];
    // de-duplicate exDates
    dataNew[i].exDate = deduplicateString(dataNew[i].exDate);
    // add exDate array
    dataNew[i]['exDateArray'] =
      dataNew[i].exDate === '' ? [] : exDateSplit(dataNew[i].exDate);
  }
  return dataNew;
};
//-------------------------------------------------------------------

//------ Remove duplicates from a comma separated string ------------
const deduplicateString = str => {
  if (['', undefined, null].includes(str)) {
    return str;
  }
  return Array.from(new Set(str.split(','))).toString();
};
//-------------------------------------------------------------------

//------ String / Array Manipulation --------------------------------
const exDateSplit = dateString => {
  if (['', undefined, null].includes(dateString)) {
    return [];
  }
  return dateString.split(',').sort();
};
const exDateJoin = dateArray => {
  return dateArray.join(',');
};
//-------------------------------------------------------------------

//------ Adjust One Timezone ----------------------------------------
export const adjustTimezone = (
  time,
  siteTimezone,
  userSelectedTimezone,
  source = 'database' // options = ['database', 'browser']
) => {
  // calculate offset between Browser time and Target time
  const browserTimezone = moment.tz.guess();
  const browserOffset = moment.tz.zone(browserTimezone).parse(time);
  const targetOffset = moment.tz.zone(userSelectedTimezone).parse(time);
  const offsetToSubtract = browserOffset - targetOffset;

  //-- [1] dates initiating from the browser
  //-- convert browser time to site time using offsets (don't just switch TZs)
  if (source === 'browser') {
    // convert local (browser) time to target timezone with an offset: 12pm MT (browswer) => 12pm ET (site timezone)
    const newLocalTime = moment(time).subtract(offsetToSubtract, 'minutes');

    //-- convert site time to UTC & store: 12pm ET (site time) => 5pm UTC (database time)
    const newTime = newLocalTime.tz('UTC').format('YYYYMMDDTHHmm') + 'Z';

    return newTime;
  }

  //-- [2] dates initiating from the database
  if (source === 'database') {
    //-- converts UTC to browser time: 5pm UTC => 10am MT
    const newLocalTime = new Date(
      moment
        .utc(time)
        .tz(userSelectedTimezone)
        .format('YYYY-MM-DD HH:mm:ss')
    );
    return newLocalTime;
  }

  console.error('Time source must be `browser` or `database`.');
  return undefined;
};
