import { createAction, handleActions } from 'redux-actions';

//--- [1] Initial State ---
const initialState = {
  //--- ALERTS ---
  alerts: [],
  alertsLoading: false,
  alertsError: null,

  //--- OPTIONS ---
  typeOptions: [],
  typeOptionsLoading: false,

  buildingOptions: [],
  buildingOptionsLoading: false,

  equipmentOptions: [],
  equipmentOptionsLoading: false,

  //--- INTERACTIONS ---
  alertCreating: false,
  alertDeleting: false,
  alertUpdating: false,
  alertInteractError: null,
};

//--- [2] Action Types ---
const prefix = 'app/alert/';
export const types = {
  //--- ALERTS ---
  ALERTS_REQUEST: prefix + 'ALERTS_REQUEST',
  ALERTS_RESPONSE: prefix + 'ALERTS_RESPONSE',

  //--- OPTIONS ---
  TYPE_OPTIONS_REQUEST: prefix + 'TYPE_OPTIONS_REQUEST',
  TYPE_OPTIONS_RESPONSE: prefix + 'TYPE_OPTIONS_RESPONSE',
  BUILDING_OPTIONS_REQUEST: prefix + 'BUILDING_OPTIONS_REQUEST',
  BUILDING_OPTIONS_RESPONSE: prefix + 'BUILDING_OPTIONS_RESPONSE',
  EQUIPMENT_OPTIONS_REQUEST: prefix + 'EQUIPMENT_OPTIONS_REQUEST',
  EQUIPMENT_OPTIONS_RESPONSE: prefix + 'EQUIPMENT_OPTIONS_RESPONSE',

  //--- INTERACTIONS ---
  CREATE_ALERT_REQUEST: prefix + 'CREATE_ALERT_REQUEST',
  CREATE_ALERT_RESPONSE: prefix + 'CREATE_ALERT_RESPONSE',
  UPDATE_ALERT_REQUEST: prefix + 'UPDATE_ALERT_REQUEST',
  UPDATE_ALERT_RESPONSE: prefix + 'UPDATE_ALERT_RESPONSE',
  DELETE_ALERT_REQUEST: prefix + 'DELETE_ALERT_REQUEST',
  DELETE_ALERT_RESPONSE: prefix + 'DELETE_ALERT_RESPONSE',
};

//--- [3] Action Creators ---
export const actions = {
  //--- ALERTS ---
  alertsRequest: createAction(types.ALERTS_REQUEST),
  alertsResponse: createAction(types.ALERTS_RESPONSE),

  //-- OPTIONS ---
  typeOptionsRequest: createAction(types.TYPE_OPTIONS_REQUEST),
  typeOptionsResponse: createAction(types.TYPE_OPTIONS_RESPONSE),
  buildingOptionsRequest: createAction(types.BUILDING_OPTIONS_REQUEST),
  buildingOptionsResponse: createAction(types.BUILDING_OPTIONS_RESPONSE),
  equipmentOptionsRequest: createAction(types.EQUIPMENT_OPTIONS_REQUEST),
  equipmentOptionsResponse: createAction(types.EQUIPMENT_OPTIONS_RESPONSE),

  //--- INTERACTIONS ---
  createAlertRequest: createAction(types.CREATE_ALERT_REQUEST),
  createAlertResponse: createAction(types.CREATE_ALERT_RESPONSE),
  updateAlertRequest: createAction(types.UPDATE_ALERT_REQUEST),
  updateAlertResponse: createAction(types.UPDATE_ALERT_RESPONSE),
  deleteAlertRequest: createAction(types.DELETE_ALERT_REQUEST),
  deleteAlertResponse: createAction(types.DELETE_ALERT_RESPONSE),
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - -  [4] API CALLS  - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -

// - - -  SITES - - -
export const fetchAlerts = siteId => async (dispatch, _getState, { api }) => {
  dispatch(actions.alertsRequest());
  try {
    const { data } = await api.alert.getAlerts(siteId);
    return dispatch(actions.alertsResponse(data));
  } catch (err) {
    return dispatch(actions.alertsResponse(err));
  }
};

// - - -  OPTIONS - - -
export const fetchTypeOptions = siteId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.typeOptionsRequest());
  try {
    const { data } = await api.alert.getAlertTypeOptions(siteId);
    return dispatch(actions.typeOptionsResponse(data));
  } catch (err) {
    return dispatch(actions.typeOptionsResponse(err));
  }
};

export const fetchBuildingOptions = (siteId, analyticIds = null) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.buildingOptionsRequest());
  try {
    const { data } = await api.alert.getAlertBuildingOptions(
      siteId,
      analyticIds
    );
    return dispatch(actions.buildingOptionsResponse(data));
  } catch (err) {
    return dispatch(actions.buildingOptionsResponse(err));
  }
};

export const fetchEquipmentOptions = (
  siteId,
  analyticIds = null,
  buildingIds = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.equipmentOptionsRequest());
  try {
    const { data } = await api.alert.getAlertEquipmentOptions(
      siteId,
      analyticIds,
      buildingIds
    );
    return dispatch(actions.equipmentOptionsResponse(data));
  } catch (err) {
    return dispatch(actions.equipmentOptionsResponse(err));
  }
};

// - - -  INTERACTIONS - - -
export const removeAlert = alertId => async (dispatch, _getState, { api }) => {
  dispatch(actions.deleteAlertRequest());
  try {
    await api.alert.deleteAlert(alertId);
    return dispatch(actions.deleteAlertResponse());
  } catch (err) {
    return dispatch(actions.deleteAlertResponse(err));
  }
};

export const modifyAlert = (alertId, data) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.updateAlertRequest());
  try {
    await api.alert.putAlert(alertId, data);
    return dispatch(actions.updateAlertResponse());
  } catch (err) {
    return dispatch(actions.updateAlertResponse(err));
  }
};

export const createAlert = data => async (dispatch, _getState, { api }) => {
  dispatch(actions.createAlertRequest());
  try {
    await api.alert.postAlert(data);
    return dispatch(actions.createAlertResponse());
  } catch (err) {
    return dispatch(actions.createAlertResponse(err));
  }
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - - [5] ACTIONS - - - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -
export default handleActions(
  {
    //--- ALERTS ---
    [actions.alertsRequest]: {
      next: state => ({
        ...state,
        alertsLoading: true,
      }),
    },
    [actions.alertsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        alerts: payload,
        alertsLoading: false,
        alertsError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        alertsError: payload.message,
        alertsLoading: false,
      }),
    },
    //--- OPTIONS ---
    [actions.typeOptionsRequest]: {
      next: state => ({
        ...state,
        typeOptionsLoading: true,
      }),
    },
    [actions.typeOptionsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        typeOptions: payload,
        typeOptionsLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        typeOptionsLoading: false,
      }),
    },
    [actions.buildingOptionsRequest]: {
      next: state => ({
        ...state,
        buildingOptionsLoading: true,
      }),
    },
    [actions.buildingOptionsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        buildingOptions: payload,
        buildingOptionsLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        buildingOptionsLoading: false,
      }),
    },
    [actions.equipmentOptionsRequest]: {
      next: state => ({
        ...state,
        equipmentOptionsLoading: true,
      }),
    },
    [actions.equipmentOptionsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        equipmentOptions: payload,
        equipmentOptionsLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        equipmentOptionsLoading: false,
      }),
    },
    //--- INTERACTIONS ---
    [actions.createAlertRequest]: {
      next: state => ({
        ...state,
        alertCreating: true,
      }),
    },
    [actions.createAlertResponse]: {
      next: state => ({
        ...state,
        alertCreating: false,
        alertInteractError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        alertInteractError: payload.message,
        alertCreating: false,
      }),
    },
    [actions.updateAlertRequest]: {
      next: state => ({
        ...state,
        alertUpdating: true,
      }),
    },
    [actions.updateAlertResponse]: {
      next: state => ({
        ...state,
        alertUpdating: false,
        alertInteractError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        alertInteractError: payload.message,
        alertUpdating: false,
      }),
    },
    [actions.deleteAlertRequest]: {
      next: state => ({
        ...state,
        alertDeleting: true,
      }),
    },
    [actions.deleteAlertResponse]: {
      next: state => ({
        ...state,
        alertDeleting: false,
        alertInteractError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        alertInteractError: payload.message,
        alertDeleting: false,
      }),
    },
  },
  initialState
);
