const getQueryStringList = (input, fieldName) => {
  let qString = '?';
  if (fieldName.length > 0) {
    qString += `${fieldName}=${input[0]}`;
  }
  for (let i = 1; i < input.length; i++) {
    qString += `&${fieldName}=${input[i]}`;
  }
  return qString;
};

export class SavingsService {
  constructor(http) {
    this.http = http;
  }

  async unitsChart(
    buildingIds,
    billSourceIds,
    billSourceGroupIds,
    beginDate,
    endDate,
    costSourceType,
    includeBaseline,
    configId,
    offsetIds
  ) {
    this.unitsChartParams = {
      buildingIds,
      billSourceIds,
      billSourceGroupIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      costSourceType,
      includeBaseline,
      configId,
    };
    if (configId != null && offsetIds != null) {
      this.unitsChartParams.configId = configId;
      this.unitsChartParams.offsetIds = offsetIds;
    }
    return this.http.post(`/savings/units/chart`, this.unitsChartParams);
  }
  async unitsChartCSV() {
    if (!this.unitsChartParams) return;
    return this.http.post(
      `/savings/units/chart?outputType=Csv`,
      this.unitsChartParams
    );
  }

  async costsChart(
    buildingIds,
    billSourceIds,
    billSourceGroupIds,
    beginDate,
    endDate,
    costSourceType,
    includeBaseline,
    configId,
    offsetIds
  ) {
    this.costsChartParams = {
      buildingIds,
      billSourceIds,
      billSourceGroupIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      costSourceType,
      includeBaseline,
    };
    if (configId != null && offsetIds != null) {
      this.costsChartParams.configId = configId;
      this.costsChartParams.offsetIds = offsetIds;
    }
    return this.http.post(`/savings/cost/chart`, this.costsChartParams);
  }
  async costsChartCSV() {
    if (!this.costsChartParams) return;
    return this.http.post(
      `/savings/cost/chart?outputType=Csv`,
      this.costsChartParams
    );
  }

  async unitsComparisonChart(
    buildingIds,
    billSourceIds,
    billSourceGroupIds,
    beginDate,
    endDate,
    costSourceType,
    includeBaseline,
    aliasId = null,
    configId,
    offsetIds
  ) {
    this.unitsComparisonChartParams = {
      buildingIds,
      billSourceIds,
      billSourceGroupIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      costSourceType,
      includeBaseline,
      configId,
    };
    if (configId != null && offsetIds != null) {
      this.unitsComparisonChartParams.configId = configId;
      this.unitsComparisonChartParams.offsetIds = offsetIds;
    }
    if (aliasId == null) {
      return this.http.post(
        `/savings/unit-comparison/chart`,
        this.unitsComparisonChartParams
      );
    } else {
      return this.http.post(
        `/savings/unit-comparison/chart?aliasNameSetId=${aliasId}`,
        this.unitsComparisonChartParams
      );
    }
  }
  async unitsComparisonChartCSV() {
    if (!this.unitsComparisonChartParams) return;
    return this.http.post(
      `/savings/unit-comparison/chart?outputType=Csv`,
      this.unitsComparisonChartParams
    );
  }

  async costsComparisonChart(
    buildingIds,
    billSourceIds,
    billSourceGroupIds,
    beginDate,
    endDate,
    costSourceType,
    includeBaseline,
    aliasId = null,
    configId,
    offsetIds
  ) {
    this.costsComparisonChartParams = {
      buildingIds,
      billSourceIds,
      billSourceGroupIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      costSourceType,
      includeBaseline,
    };
    if (configId != null && offsetIds != null) {
      this.costsComparisonChartParams.configId = configId;
      this.costsComparisonChartParams.offsetIds = offsetIds;
    }
    if (aliasId == null) {
      return this.http.post(
        `/savings/cost-comparison/chart`,
        this.costsComparisonChartParams
      );
    } else {
      return this.http.post(
        `/savings/cost-comparison/chart?aliasNameSetId=${aliasId}`,
        this.costsComparisonChartParams
      );
    }
  }
  async costsComparisonChartCSV() {
    if (!this.costsComparisonChartParams) return;
    return this.http.post(
      `/savings/cost-comparison/chart?outputType=Csv`,
      this.costsComparisonChartParams
    );
  }

  async savingsFilterOptions(configId) {
    return this.http.get(`/savings/filters/top-level/${configId}`);
  }

  async getOffsetsFile(scope, ids) {
    let url = `/regressions/offsets/${scope}/file`;
    url += getQueryStringList(ids, 'id');
    return this.http.pyGet(url, { responseType: 'arraybuffer' });
  }

  async postOffsetsFile(scope, ids, file) {
    let url = `/regressions/offsets/${scope}/file`;

    // create a form data object to hold the post data
    const formData = new FormData();

    // add file to post data
    formData.append('file', file, file.name);

    // add ids to post data
    formData.append('ids', ids);

    return this.http.pyPost(url, formData);
  }

  async getRegressionInfo(buildingIds){
    let url = `/savings/regression`
    url += getQueryStringList(buildingIds, 'bId');
    return this.http.get(url);
  }
}
