import React from 'react';

import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './noMatch.scss';

const NoMatch = props => {
  return (
    <div id="no-match-main" className="text-center">
      <div id="no-match-container">
        <h2 className="no-match-title">Page Not Found</h2>
        <p className="no-match-text">
          We're sorry, the requested page was not found.
        </p>
        <p className="no-match-text">
          Please use the links on the left to navigate to a different page.
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  router: state.router,
  user: state.auth.userName,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch),
  dispatch,
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(NoMatch)
);
