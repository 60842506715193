import Login from './Login';
import Logout from './Logout';
import Unverified from '../../components/UI/Unverified';
import Unregistered from '../../components/UI/Unregistered';

const AuthRoutes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/unverified-email',
    component: Unverified,
  },
  {
    path: '/unrecognized-user',
    component: Unregistered,
  },
];

export default AuthRoutes;
