//--- McK Roles for Frontend ---
const isStaff = role => {
  return ['Staff', 'Admin', 'SuperAdmin'].includes(role);
};
const isAdmin = role => {
  return ['Admin', 'SuperAdmin'].includes(role);
};
const isSuperAdmin = role => {
  return ['SuperAdmin'].includes(role);
};
const isSuperPartner = role => {
  return ['SuperPartner', 'Staff', 'SuperAdmin', 'Admin'].includes(role);
};

export { isStaff, isAdmin, isSuperAdmin, isSuperPartner };
