export class OpportunitiesService {
  constructor(http) {
    this.http = http;
  }

  async filterOptions() {
    return this.http.get(`/opportunities/options`);
  }

  async issueDetail(issueId) {
    return this.http.get(`/opportunities/issue/${issueId}`);
  }

  async opportunitiesSummary(
    buildingIds,
    savingsImpacts,
    feasibilities,
    statuses,
    aliasId = null
  ) {
    this.opportunitiesSummaryParams = {
      buildingIds,
      savingsImpacts,
      feasibilities,
      statuses,
    };
    if (aliasId == null) {
      return this.http.post(
        `/opportunities/summary`,
        this.opportunitiesSummaryParams
      );
    } else {
      return this.http.post(
        `/opportunities/summary?aliasNameSetId=${aliasId}`,
        this.opportunitiesSummaryParams
      );
    }
  }

  async opportunitiesSummaryCSV() {
    if (!this.opportunitiesSummaryParams) return;
    return this.http.post(
      `/opportunities/opportunities-list?outputType=Csv`,
      this.opportunitiesSummaryParams
    );
  }
}
