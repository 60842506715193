export class MckGeneralService {
  constructor(api) {
    this.api = api;
  }

  async getAllSites() {
    return this.api.pyGet(`/site/list`);
  }

  async getSiteEquip(
    siteId,
    buildingIds = null,
    equipClassIds = null,
    equipIds = null,
    grouping = 'equipment'
  ) {
    let url = `/analytics/filters?type=equipclass&siteid=${siteId}`;
    url += buildingIds ? `&buildingid=${buildingIds}` : '';
    url += equipClassIds ? `&equipclassid=${equipClassIds}` : '';
    url += equipIds ? `&equipid=${equipIds}` : '';
    url += grouping ? `&grouping=${grouping}` : '';
    return this.api.pyGet(url);
  }

  async getAllEquip(siteId) {
    return this.api.pyGet(`/trends/equipclasses?siteid=${siteId}`);
  }

  async getAllBuildings(siteId) {
    return this.api.pyGet(`/trends/buildings?siteid=${siteId}`);
  }

  async getAllScope(siteId) {
    return this.api.pyGet(`/analytics/scopes?siteid=${siteId}`);
  }
}
