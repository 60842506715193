export class BuildingsService {
  constructor(http) {
    this.http = http;
  }

  async overview(
    siteId,
    configId,
    buildingIds,
    beginDate,
    endDate,
    aliasId = null
  ) {
    this.overviewParams = {
      siteId,
      configId,
      buildingIds,
      beginDate,
      endDate,
    };
    if (aliasId == null) {
      return this.http.post(`/overview`, this.overviewParams);
    } else {
      return this.http.post(
        `/overview?aliasNameSetId=${aliasId}`,
        this.overviewParams
      );
    }
  }
  async overviewCSV() {
    if (!this.overviewParams) return;
    return this.http.post(
      `/overview/trend-data/chart?outputType=Csv`,
      this.overviewParams
    );
  }

  async facilityMetricComparisonChart(
    siteId,
    buildingIds,
    metricId,
    beginDate,
    endDate,
    aliasId = null
  ) {
    this.facilityMetricComparisonChartParams = {
      siteId,
      buildingIds,
      metricId,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    if (aliasId == null) {
      return this.http.post(
        `/overview/facility-metric-comparison/chart`,
        this.facilityMetricComparisonChartParams
      );
    } else {
      return this.http.post(
        `/overview/facility-metric-comparison/chart?aliasNameSetId=${aliasId}`,
        this.facilityMetricComparisonChartParams
      );
    }
  }
  async facilityMetricComparisonChartCSV() {
    if (!this.facilityMetricComparisonChartParams) return;
    return this.http.post(
      `/overview/facility-metric-comparison/chart?outputType=Csv`,
      this.facilityMetricComparisonChartParams
    );
  }

  async metricChart(buildingIds, metricId, beginDate, endDate) {
    this.metricChartParams = {
      buildingIds,
      metricId,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    return this.http.post(`/overview/metric/chart`, this.metricChartParams);
  }
  async metricChartCSV() {
    if (!this.metricChartParams) return;
    return this.http.post(
      `/overview/metric/chart?outputType=Csv`,
      this.metricChartParams
    );
  }

  async trendDataChart(buildingId, metricId, beginDate, endDate) {
    this.trendDataChartParams = {
      buildingId,
      metricId,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    return this.http.post(
      `/overview/trend-data/chart`,
      this.trendDataChartParams
    );
  }
  async trendDataChartCSV() {
    if (!this.trendDataChartParams) return;
    return this.http.post(
      `/overview/trend-data/chart?outputType=Csv`,
      this.trendDataChartParams
    );
  }

  async savingsSummaryChart(buildingIds, beginDate, endDate) {
    this.savingsSummaryChartParams = {
      buildingIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    return this.http.post(
      `/overview/savings-summary/chart`,
      this.savingsSummaryChartParams
    );
  }

  async savingsSummaryChartCSV() {
    if (!this.savingsSummaryChartParams) return;
    return this.http.post(
      `/overview/savings-summary/chart?outputType=Csv`,
      this.savingsSummaryChartParams
    );
  }

  async savingsCostSummaryChart(buildingIds, beginDate, endDate) {
    this.savingsCostSummaryChartParams = {
      buildingIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    return this.http.post(
      `/overview/savings-cost-summary/chart`,
      this.savingsCostSummaryChartParams
    );
  }

  async utilitiesCostSummaryChart(buildingId, metricId, beginDate, endDate) {
    this.utilitiesCostSummaryChartParams = {
      buildingId,
      metricId,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    return this.http.post(
      `/overview/utilities-cost-summary/chart`,
      this.utilitiesCostSummaryChartParams
    );
  }
  async utilitiesCostSummaryChartCSV() {
    if (!this.utilitiesCostSummaryChartParams) return;
    return this.http.post(
      `/overview/utilities-cost-summary/chart?outputType=Csv`,
      this.utilitiesCostSummaryChartParams
    );
  }

  async siteConfig(configId, aliasId = null) {
    let url = `/site/config/${configId}`;
    if (aliasId != null) {
      url = url.concat(`?aliasNameSetId=${aliasId}`);
    }
    return this.http.get(url);
  }
  async siteConfigs(aliasId = null) {
    let url = `/site/configs`;
    if (aliasId != null) {
      url = url.concat(`?aliasNameSetId=${aliasId}`);
    }
    return this.http.get(url);
  }

  // returns list of building name alias sets for requested config
  async buildingAliasSets(configId) {
    let url = `/site/config/${configId}/alias-sets`;
    return this.http.get(url);
  }

  async metricSummary(
    buildingIds,
    metricId,
    beginDate,
    endDate,
    absoluteLow = null,
    absoluteHigh = null,
    absoluteHighHigh = null
  ) {
    this.metricSummaryParams = {
      buildingIds,
      metricId,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      absoluteLow: absoluteLow,
      absoluteHigh: absoluteHigh,
      absoluteHighHigh: absoluteHighHigh
    };
    return this.http.post(`/overview/metric/summary`, this.metricSummaryParams);
  }
  async metricSummaryCSV() {
    if (!this.metricSummaryParams) return;
    return this.http.post(
      `/overview/metric/summary?outputType=Csv`,
      this.metricSummaryParams
    );
  }

  async billSourceChart(buildingId, billSourceId, beginDate, endDate) {
    this.billSourceChartParams = {
      buildingId,
      billSourceId,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    return this.http.post(
      `/overview/bill-source/chart`,
      this.billSourceChartParams,
      {},
      true
    );
  }
  async billSourceChartCSV() {
    if (!this.billSourceChartParams) return;
    return this.http.post(
      `/overview/bill-source/chart?outputType=Csv`,
      this.billSourceChartParams
    );
  }

  async powerMonitoring(buildingIds) {
    const queryString = buildingIds?.length > 0 ? `?b=${buildingIds.join('&b=')}` : '';
    return this.http.get(`/building/power-monitoring${queryString}`);
  }
}
