/**
 *  Service Worker Registration
 *
 * currently use this for:
 *    (1) caching
 *
 * to add:
 *    (1) receiving push notifications
 *
 *
 * resources:
 *    general: https://bit.ly/CRA-PWA
 *    CDN considerations: https://github.com/facebook/create-react-app/issues/2374
 */

import {
  isLocalhost,
  registerValidSW,
  checkValidServiceWorker,
} from './utilities/sw_utilities';

export function register(config) {
  console.log('- - - - -  REGISTERING SERVICE WORKER  - - - - -');
  console.log('ServiceWorker Configs: ', config);

  //---- [1] Determine whether ServiceWorkers should be Registered ----
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  const registerServiceWorker =
    //isLocalhost &&
    //process.env.REACT_APP_ENV === 'production' &&
    //process.env.REACT_APP_ENV === 'development' &&
    publicUrl.origin === window.location.origin && 'serviceWorker' in navigator;

  if (registerServiceWorker) {
    //---- [2] Load Service Worker ---
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://bit.ly/CRA-PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });

    //----- [3] Add API Caching Service Worker ------
    window.addEventListener('fetch', event => {
      console.log('Fetch Service Worker Activated!');
      console.log('API URL: ', event.request?.url);
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
