export class KPIsService {
  constructor(http) {
    this.http = http;
  }

  async options(buildingIds) {
    return this.http.post(`/kpis/options`, { buildingIds });
  }

  async treemap(
    buildingIds,
    kpiIds,
    subsystemIds,
    beginDate,
    endDate,
    nodeType,
    hierarchyType
  ) {
    // Populate kpiParams for the CSV Download.
    this.kpiParams = {
      buildingIds,
      kpiIds,
      subsystemIds,
      beginDate,
      endDate,
      type: hierarchyType,
    };

    return this.http.post(`/kpis/treemap?nodeType=${nodeType}`, {
      buildingIds,
      kpiIds,
      subsystemIds,
      beginDate,
      endDate,
      type: hierarchyType,
    });
  }

  async kpisCSV() {
    if (!this.kpiParams) return;
    return this.http.post(`/kpis?outputType=Csv`, this.kpiParams);
  }

  async historyChart(id, beginDate, endDate) {
    return this.http.post(`/kpis/history-chart`, { id, beginDate, endDate });
  }
}
