import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import App from './App.js';
import { history } from './configureStore';
import { ConnectedRouter } from 'connected-react-router';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../src/components/Auth/authConfig';
import { MsalProvider } from '@azure/msal-react';

const monitoredEnvironments = ['production', 'staging'];

const msalInstance = new PublicClientApplication(msalConfig);

if (
  monitoredEnvironments.includes(process.env.REACT_APP_ENV) &&
  process.env.REACT_APP_SENTRY_DSN
) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <ConnectedRouter history={history}>
      <MsalProvider instance={msalInstance}>
        <App history={history} />
      </MsalProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();
