import { createAction, handleActions } from 'redux-actions';
import { toast } from "react-toastify";
//--- [1] Initial State ---
const initialState = {
  adminHomeView: 'clients',

  //--- SITES ---
  clientId: null,
  profileId: null,
  sites: [],
  siteLoading: false,
  siteError: null,

  //--- BUIDLINGS ---
  buildings: [],
  buildingsLoading: false,
  buildingsError: null,
  buildingDetailsLoading: false,
  selectedBuildingDetails: null,
  buildingImageUploading: false,
  buildingImageUploadError: null,

  //--- GROUPS ---
  groups: [],
  groupsLoading: false,
  groupsError: null,
  groupDeleting: false,
  groupDeleteError: null,
  groupUpdating: false,
  groupsUpdating: false,
  groupsUpdateError: null,
  groupUpdateError: null,
  groupCreating: false,
  groupCreateError: null,

  //--- PROFILES ---
  profileImages: [],
  profileImagesLoading: false,
  profileImageUploading: false,
  profileImagesError: null,
  profiles: [],
  profilesLoading: false,
  profilesError: null,
  profilePosting: false,
  profilePostError: null,
  profilePutting: false,
  profilePutError: null,
  profileDeleting: false,
  profileDeleteError: null,
  facilityDetailOptions: null,
  facilityDetailOptionsLoading: false,
  //--- GROUP TYPES ---
  groupTypesOptions: [],
  groupTypesOptionsLoading: false,
  groupTypesOptionsError: null,
  groupTypeCreating: false,
  groupTypeCreateError: null,
  groupTypeUpdating: false,
  groupTypeUpdateError: null,

  //--- FLOORPLAN POINT CLASSES ---
  floorplanPointClassOptions: [],
  floorplanPointClassOptionsLoading: false,

  //--- Pages ---
  //overview as string for now??
  pages: [{ id: 1, name: 'Overview' }],
  pageOptions: null,
  pageOptionsLoading: false,

  //--- Metrics ---
  metrics: [],
  metricOptions: [],
  metricsLoading: false,
  metricOptionsLoading: false,
  metricsError: null,
  metricDeleting: false,
  metricDeleteError: null,
  metricOptionsError: null,
  metricUpdating: false,
  metricUpdateError: null,
  metricCreating: false,
  metricCreateError: null,
  metricsModalOpen: false,
  selectedMetric: null,

  // --- USERS ---
  users: [],
  usersLoading: false,
  usersError: null,
  usersList: [],
  usersListLoading: false,
  userCreating: false,
  userCreateError: null,
  userUpdating: false,
  userUpdateError: null,
  userDeleting: false,
  userDeleteError: null,
  userRoleOptions: [],
  userRoleOptionsLoading: false,
  userRoleOptionsError: null,
  userId: null,
  userSites: [],
  userSitesLoading: false,
  addingUserSites: false,
  userSitesError: null,

  //--- BULK USER CHANGES ---
  addingUsersToProfile: false,
  addUsersToProfileError: null,
  removingUsersFromProfile: false,
  removeUsersFromProfileError: null,

  deletingUsers: false,
  deleteUsersError: false,

  changingRoleForUsers: false,
  changeRoleForUsersError: null,

  facilitiesModalOpen: false,

  unitConversionsOptions: [],
  unitConversionsOptionsLoading: false,
  mappingOptionsLoading: false,
  unitOptions: [],

  // --- FCA Configuration ---
  fcaEquipmentMetrics: [],
  fcaEquipmentMetricsLoading: false,
  fcaEquipmentMetricsUpdating: false,
  fcaEquipmentMetricsError: null,
  fcaNonStandardClasses: [],
  fcaNonStandardClassesLoading: false,
  fcaNonStandardClassesUpdating: false,
  fcaNonStandardClassesError: null,
  fcaNonStandardTypes: localStorage.getItem('nonStandardTypes') ? JSON.parse(localStorage.getItem('nonStandardTypes')).types : [],
  fcaNonStandardTypesLoading: false,
  fcaNonStandardTypesError: null,
};

//--- [2] Action Types ---
const prefix = 'app/configuration/';
export const types = {
  SET_ADMIN_HOME_VIEW: prefix + 'SET_ADMIN_HOME_VIEW',

  //--- SITES ---
  SELECT_CLIENT: prefix + 'SELECT_CLIENT',
  CLEAR_CLIENT: prefix + 'CLEAR_CLIENT',

  SELECT_PROFILE: prefix + 'SELECT_PROFILE',
  CLEAR_PROFILE: prefix + 'CLEAR_PROFILE',

  SITES_REQUEST: prefix + 'SITES_REQUEST',
  SITES_RESPONSE: prefix + 'SITES_RESPONSE',

  //--- BUILDINGS ---
  BUILDINGS_REQUEST: prefix + 'BUILDINGS_REQUEST',
  BUILDINGS_RESPONSE: prefix + 'BUILDINGS_RESPONSE',
  SINGLE_BUILDING_REQUEST: prefix + 'SINGLE_BUILDING_REQUEST',
  SINGLE_BUILDING_RESPONSE: prefix + 'SINGLE_BUILDING_RESPONSE',
  BUILDING_IMAGE_UPLOAD_REQUEST: prefix + 'BUILDING_IMAGE_UPLOAD_REQUEST',
  BUILDING_IMAGE_UPLOAD_RESPONSE: prefix + 'BUILDING_IMAGE_UPLOAD_RESPONSE',

  //--- GROUPS ---
  GROUPS_REQUEST: prefix + 'GROUPS_REQUEST',
  GROUPS_RESPONSE: prefix + 'GROUPS_RESPONSE',
  GROUP_DELETE_REQUEST: prefix + 'GROUP_DELETE_REQUEST',
  GROUP_DELETE_RESPONSE: prefix + 'GROUP_DELETE_RESPONSE',
  GROUP_PUT_REQUEST: prefix + 'GROUP_PUT_REQUEST',
  GROUP_PUT_RESPONSE: prefix + 'GROUP_PUT_RESPONSE',
  GROUPS_PUT_REQUEST: prefix + 'GROUPS_PUT_REQUEST',
  GROUPS_PUT_RESPONSE: prefix + 'GROUPS_PUT_RESPONSE',
  GROUP_POST_REQUEST: prefix + 'GROUP_POST_REQUEST',
  GROUP_POST_RESPONSE: prefix + 'GROUP_POST_RESPONSE',

  //--- PROFILES --
  PROFILES_REQUEST: prefix + 'PROFILES_REQUEST',
  PROFILES_RESPONSE: prefix + 'PROFILES_RESPONSE',
  PROFILE_IMAGES_REQUEST: prefix + 'PROFILE_IMAGES_REQUEST',
  PROFILE_IMAGES_RESPONSE: prefix + 'PROFILE_IMAGES_RESPONSE',
  PROFILE_IMAGE_UPLOAD_REQUEST: prefix + 'PROFILE_IMAGE_UPLOAD_REQUEST',
  PROFILE_IMAGE_UPLOAD_RESPONSE: prefix + 'PROFILE_IMAGE_UPLOAD_RESPONSE',
  PROFILE_POST_REQUEST: prefix + 'PROFILE_POST_REQUEST',
  PROFILE_POST_RESPONSE: prefix + 'PROFILE_POST_RESPONSE',
  PROFILE_PUT_REQUEST: prefix + 'PROFILE_PUT_REQUEST',
  PROFILE_PUT_RESPONSE: prefix + 'PROFILE_PUT_RESPONSE',
  PROFILE_DELETE_REQUEST: prefix + 'PROFILE_DELETE_REQUEST',
  PROFILE_DELETE_RESPONSE: prefix + 'PROFILE_DELETE_RESPONSE',
  FACILITY_DETAIL_OPTIONS_REQUEST: prefix + 'FACILITY_DETAIL_OPTIONS_REQUEST',
  FACILITY_DETAIL_OPTIONS_RESPONSE: prefix + 'FACILITY_DETAIL_OPTIONS_RESPONSE',
  //--- GROUP TYPES ---
  GROUP_TYPES_OPTIONS_REQUEST: prefix + 'GROUP_TYPES_OPTIONS_REQUEST',
  GROUP_TYPES_OPTIONS_RESPONSE: prefix + 'GROUP_TYPES_OPTONS_RESPONSE',
  GROUP_TYPE_POST_REQUEST: prefix + 'GROUP_TYPE_POST_REQUEST',
  GROUP_TYPE_POST_RESPONSE: prefix + 'GROUP_TYPE_POST_RESPONSE',
  GROUP_TYPE_PUT_REQUEST: prefix + 'GROUP_TYPE_PUT_REQUEST',
  GROUP_TYPE_PUT_RESPONSE: prefix + 'GROUP_TYPE_PUT_RESPONSE',

  //--- FLOORPLAN POINT CLASSES
  FLOORPLAN_POINTCLASS_OPTIONS_REQUEST:
    prefix + 'FLOORPLAN_POINTCLASS_OPTIONS_REQUEST',
  FLOORPLAN_POINTCLASS_OPTIONS_RESPONSE:
    prefix + 'FLOORPLAN_POINTCLASS_OPTIONS_RESPONSE',

  //--- Metrics ---
  METRICS_REQUEST: prefix + 'METRICS_REQUEST',
  METRICS_RESPONSE: prefix + 'METRICS_RESPONSE',
  METRIC_DELETE_REQUEST: prefix + 'METRIC_DELETE_REQUEST',
  METRIC_DELETE_RESPONSE: prefix + 'METRIC_DELETE_RESPONSE',
  METRIC_POST_REQUEST: prefix + 'METRIC_POST_REQUEST',
  METRIC_POST_RESPONSE: prefix + 'METRIC_POST_RESPONSE',
  METRIC_OPTIONS_REQUEST: prefix + 'METRIC_OPTIONS_REQUEST',
  METRIC_OPTIONS_RESPONSE: prefix + 'METRIC_OPTIONS_RESPONSE',
  METRIC_PUT_REQUEST: prefix + 'METRIC_PUT_REQUEST',
  METRIC_PUT_RESPONSE: prefix + 'METRIC_PUT_RESPONSE',
  METRIC_MODAL_OPEN: prefix + 'METRIC_MODAL_OPEN',
  METRIC_MODAL_CLOSE: prefix + 'METRIC_MODAL_CLOSE',
  SELECT_METRIC: prefix + 'SELECT_METRIC',
  CLEAR_METRIC: prefix + 'CLEAR_METRIC',

  //--- PAGES ---
  PAGE_OPTIONS_REQUEST: prefix + 'PAGE_OPTIONS_REQUEST',
  PAGE_OPTIONS_RESPONSE: prefix + 'PAGE_OPTIONS_RESPONSE',

  // --- USERS ---
  USERS_REQUEST: prefix + 'USERS_REQUEST',
  USERS_RESPONSE: prefix + 'USERS_RESPONSE',
  USERS_LIST_REQUEST: prefix + 'USERS_LIST_REQUEST',
  USERS_LIST_RESPONSE: prefix + 'USERS_LIST_RESPONSE',
  USER_POST_REQUEST: prefix + 'USER_POST_REQUEST',
  USER_POST_RESPONSE: prefix + 'USER_POST_RESPONSE',
  USER_PUT_REQUEST: prefix + 'USER_PUT_REQUEST',
  USER_PUT_RESPONSE: prefix + 'USER_PUT_RESPONSE',
  USER_DELETE_REQUEST: prefix + 'USER_DELETE_REQUEST',
  USER_DELETE_RESPONSE: prefix + 'USER_DELETE_RESPONSE',
  USER_ROLE_OPTIONS_REQUEST: prefix + 'USER_ROLE_OPTIONS_REQUEST',
  USER_ROLE_OPTIONS_RESPONSE: prefix + 'USER_ROLE_OPTIONS_RESPONSE',
  SELECT_USER: prefix + 'SELECT_USER',
  SELECTED_USER: prefix + 'SELECTED_USER',

  /// --- BULK CHANGES TO USERS ---
  ADD_USERS_TO_PROFILE_REQUEST: prefix + 'ADD_USERS_TO_PROFILE_REQUEST',
  ADD_USERS_TO_PROFILE_RESPONSE: prefix + 'ADD_USERS_TO_PROFILE_RESPONSE',
  REMOVE_USERS_FROM_PROFILE_REQUEST:
    prefix + 'REMOVE_USERS_FROM_PROFILE_REQUEST',
  REMOVE_USERS_FROM_PROFILE_RESPONSE:
    prefix + 'REMOVE_USERS_FROM_PROFILE_RESPONSE',
  DELETE_USERS_REQUEST: prefix + 'DELETE_USERS_REQUEST',
  DELETE_USERS_RESPONSE: prefix + 'DELETE_USERS_RESPONSE',
  CHANGE_ROLE_FOR_USERS_REQUEST: prefix + 'CHANGE_ROLE_FOR_USERS_REQUEST',
  CHANGE_ROLE_FOR_USERS_RESPONSE: prefix + 'CHANGE_ROLE_FOR_USERS_RESPONSE',

  FACILITIES_MODAL_OPEN: prefix + 'FACILITIES_MODAL_OPEN',
  FACILITIES_MODAL_CLOSE: prefix + 'FACILITIES_MODAL_CLOSE',

  UNIT_CONVERSIONS_OPTIONS_REQUEST: prefix + 'UNIT_CONVERSIONS_OPTIONS_REQUEST',
  UNIT_CONVERSIONS_OPTIONS_RESPONSE:
    prefix + 'UNIT_CONVERSIONS_OPTIONS_RESPONSE',

  MAPPING_OPTIONS_REQUEST: prefix + 'MAPPING_OPTIONS_REQUEST',
  MAPPING_OPTIONS_RESPONSE: prefix + 'MAPPING_OPTIONS_RESPONSE',
  FETCH_USER_SITES_REQUEST: prefix + 'FETCH_USER_SITES_REQUEST',
  FETCH_USER_SITES_RESPONSE: prefix + 'FETCH_USER_SITES_RESPONSE',
  ADD_USER_SITES_REQUEST: prefix + 'ADD_USER_SITES_REQUEST',
  ADD_USER_SITES_RESPONSE: prefix + 'ADD_USER_SITES_RESPONSE',
  REMOVE_USER_SITES: prefix + 'REMOVE_USER_SITES',
  CLEAR_USER_SITES: prefix + 'CLEAR_USER_SITES',

  // --- FCA Configuration ---
  FCA_EQUIPMENT_METRICS_REQUEST: prefix + 'FCA_EQUIPMENT_METRICS_REQUEST',
  FCA_EQUIPMENT_METRICS_RESPONSE: prefix + 'FCA_EQUIPMENT_METRICS_RESPONSE',
  FCA_EQUIPMENT_METRICS_UPDATE_REQUEST: prefix + 'FCA_EQUIPMENT_METRICS_UPDATE_REQUEST',
  FCA_EQUIPMENT_METRICS_UPDATE_RESPONSE: prefix + 'FCA_EQUIPMENT_METRICS_UPDATE_RESPONSE',
  FCA_NON_STANDARD_CLASSES_REQUEST: prefix + 'FCA_NON_STANDARD_CLASSES_REQUEST',
  FCA_NON_STANDARD_CLASSES_RESPONSE: prefix + 'FCA_NON_STANDARD_CLASSES_RESPONSE',
  FCA_NON_STANDARD_CLASSES_UPDATE_REQUEST: prefix + 'FCA_NON_STANDARD_CLASSES_UPDATE_REQUEST',
  FCA_NON_STANDARD_CLASSES_UPDATE_RESPONSE: prefix + 'FCA_NON_STANDARD_CLASSES_UPDATE_RESPONSE',
  FCA_NON_STANDARD_TYPES_REQUEST: prefix + 'FCA_NON_STANDARD_TYPES_REQUEST',
  FCA_NON_STANDARD_TYPES_RESPONSE: prefix + 'FCA_NON_STANDARD_TYPES_RESPONSE',
};

//--- [3] Action Creators ---
export const actions = {
  setAdminHomeView: createAction(types.SET_ADMIN_HOME_VIEW),
  //--- SITES ---

  selectClientId: createAction(types.SELECT_CLIENT),
  clearClientId: createAction(types.CLEAR_CLIENT),

  selectProfileId: createAction(types.SELECT_PROFILE),
  clearProfileId: createAction(types.CLEAR_PROFILE),

  sitesRequest: createAction(types.SITES_REQUEST),
  sitesResponse: createAction(types.SITES_RESPONSE),

  //--- BUILDINGS ---
  buildingsRequest: createAction(types.BUILDINGS_REQUEST),
  buildingsResponse: createAction(types.BUILDINGS_RESPONSE),
  singleBuildingRequest: createAction(types.SINGLE_BUILDING_REQUEST),
  singleBuildingResponse: createAction(types.SINGLE_BUILDING_RESPONSE),
  buildingImageUploadRequest: createAction(types.BUILDING_IMAGE_UPLOAD_REQUEST),
  buildingImageUploadResponse: createAction(
    types.BUILDING_IMAGE_UPLOAD_RESPONSE
  ),

  //--- GROUPS ---
  groupsRequest: createAction(types.GROUPS_REQUEST),
  groupsResponse: createAction(types.GROUPS_RESPONSE),
  groupDeleteRequest: createAction(types.GROUP_DELETE_REQUEST),
  groupDeleteResponse: createAction(types.GROUP_DELETE_RESPONSE),
  groupPutRequest: createAction(types.GROUP_PUT_REQUEST),
  groupPutResponse: createAction(types.GROUP_PUT_RESPONSE),
  groupsPutRequest: createAction(types.GROUPS_PUT_REQUEST),
  groupsPutResponse: createAction(types.GROUPS_PUT_RESPONSE),
  groupPostRequest: createAction(types.GROUP_POST_REQUEST),
  groupPostResponse: createAction(types.GROUP_POST_RESPONSE),

  //--- PROFILES ---
  profilesRequest: createAction(types.PROFILES_REQUEST),
  profilesResponse: createAction(types.PROFILES_RESPONSE),
  profileImagesRequest: createAction(types.PROFILE_IMAGES_REQUEST),
  profileImagesResponse: createAction(types.PROFILE_IMAGES_RESPONSE),
  profileImageUploadRequest: createAction(types.PROFILE_IMAGE_UPLOAD_REQUEST),
  profileImageUploadResponse: createAction(types.PROFILE_IMAGE_UPLOAD_RESPONSE),
  profilePostRequest: createAction(types.PROFILE_POST_REQUEST),
  profilePostResponse: createAction(types.PROFILE_POST_RESPONSE),
  profilePutRequest: createAction(types.PROFILE_PUT_REQUEST),
  profilePutResponse: createAction(types.PROFILE_PUT_RESPONSE),
  profileDeleteRequest: createAction(types.PROFILE_DELETE_REQUEST),
  profileDeleteResponse: createAction(types.PROFILE_DELETE_RESPONSE),
  facilityDetailOptionsRequest: createAction(
    types.FACILITY_DETAIL_OPTIONS_REQUEST
  ),
  facilityDetailOptionsResponse: createAction(
    types.FACILITY_DETAIL_OPTIONS_RESPONSE
  ),
  // --- group types ---
  groupTypesOptionsRequest: createAction(types.GROUP_TYPES_OPTIONS_REQUEST),
  groupTypesOptionsResponse: createAction(types.GROUP_TYPES_OPTIONS_RESPONSE),
  groupTypePostRequest: createAction(types.GROUP_TYPE_POST_REQUEST),
  groupTypePostResponse: createAction(types.GROUP_TYPE_POST_RESPONSE),
  groupTypePutRequest: createAction(types.GROUP_PUT_REQUEST),
  groupTypePutResponse: createAction(types.GROUP_PUT_RESPONSE),

  //---FLOORPLAN POINT CLASS ---

  floorplanPointClassOptionsRequest: createAction(
    types.FLOORPLAN_POINTCLASS_OPTIONS_REQUEST
  ),
  floorplanPointClassOptionsResponse: createAction(
    types.FLOORPLAN_POINTCLASS_OPTIONS_RESPONSE
  ),

  //--- METRICS ---
  metricsRequest: createAction(types.METRICS_REQUEST),
  metricsResponse: createAction(types.METRICS_RESPONSE),
  metricDeleteRequest: createAction(types.METRIC_DELETE_REQUEST),
  metricDeleteResponse: createAction(types.METRIC_DELETE_RESPONSE),
  metricPostRequest: createAction(types.METRIC_POST_REQUEST),
  metricPostResponse: createAction(types.METRIC_POST_RESPONSE),
  metricOptionsRequest: createAction(types.METRIC_OPTIONS_REQUEST),
  metricOptionsResponse: createAction(types.METRIC_OPTIONS_RESPONSE),
  metricPutRequest: createAction(types.METRIC_PUT_REQUEST),
  metricPutResponse: createAction(types.METRIC_PUT_RESPONSE),
  openMetricsModal: createAction(types.METRIC_MODAL_OPEN),
  closeMetricsModal: createAction(types.METRIC_MODAL_CLOSE),
  selectMetric: createAction(types.SELECT_METRIC),
  clearMetric: createAction(types.CLEAR_METRIC),

  //--- PAGES ---
  pageOptionsRequest: createAction(types.PAGE_OPTIONS_REQUEST),
  pageOptionsResponse: createAction(types.PAGE_OPTIONS_RESPONSE),

  // --- USERS ---
  usersRequest: createAction(types.USERS_REQUEST),
  usersResponse: createAction(types.USERS_RESPONSE),
  usersListRequest: createAction(types.USERS_LIST_REQUEST),
  usersListResponse: createAction(types.USERS_LIST_RESPONSE),
  userPostRequest: createAction(types.USER_POST_REQUEST),
  userPostResponse: createAction(types.USER_POST_RESPONSE),
  userPutRequest: createAction(types.USER_PUT_REQUEST),
  userPutResponse: createAction(types.USER_PUT_RESPONSE),
  userDeleteRequest: createAction(types.USER_DELETE_REQUEST),
  userDeleteResponse: createAction(types.USER_DELETE_RESPONSE),
  userRoleOptionsRequest: createAction(types.USER_ROLE_OPTIONS_REQUEST),
  userRoleOptionsResponse: createAction(types.USER_ROLE_OPTIONS_RESPONSE),

  ///--- BULK USER CHANGES ---
  addUsersToProfileRequest: createAction(types.ADD_USERS_TO_PROFILE_REQUEST),
  addUsersToProfileResponse: createAction(types.ADD_USERS_TO_PROFILE_RESPONSE),
  removeUsersFromProfileRequest: createAction(
    types.REMOVE_USERS_FROM_PROFILE_REQUEST
  ),
  removeUsersFromProfileResponse: createAction(
    types.REMOVE_USERS_FROM_PROFILE_RESPONSE
  ),

  deleteUsersRequest: createAction(types.DELETE_USERS_REQUEST),
  deleteUsersResponse: createAction(types.DELETE_USERS_RESPONSE),

  changeRoleForUsersRequest: createAction(types.CHANGE_ROLE_FOR_USERS_REQUEST),
  changeRoleForUsersResponse: createAction(
    types.CHANGE_ROLE_FOR_USERS_RESPONSE
  ),

  openFacilitiesModal: createAction(types.FACILITIES_MODAL_OPEN),
  closeFacilitiesModal: createAction(types.FACILITIES_MODAL_CLOSE),

  unitConversionsOptionsRequest: createAction(
    types.UNIT_CONVERSIONS_OPTIONS_REQUEST
  ),
  unitConversionsOptionsResponse: createAction(
    types.UNIT_CONVERSIONS_OPTIONS_RESPONSE
  ),

  mappingOptionsRequest: createAction(
    types.MAPPING_OPTIONS_REQUEST
  ),
  mappingOptionsResponse: createAction(
    types.MAPPING_OPTIONS_RESPONSE
  ),
  fetchUserSitesRequest: createAction(types.FETCH_USER_SITES_REQUEST),
  fetchUserSitesResponse: createAction(types.FETCH_USER_SITES_RESPONSE),
  addUserSitesRequest: createAction(types.ADD_USER_SITES_REQUEST),
  addUserSitesResponse: createAction(types.ADD_USER_SITES_RESPONSE),
  removeUserSites: createAction(types.REMOVE_USER_SITES),
  clearUserSites: createAction(types.CLEAR_USER_SITES),

  // --- FCA Configuration ---
  fcaEquipmentMetricsRequest: createAction(types.FCA_EQUIPMENT_METRICS_REQUEST),
  fcaEquipmentMetricsResponse: createAction(types.FCA_EQUIPMENT_METRICS_RESPONSE),
  fcaEquipmentMetricsUpdateRequest: createAction(types.FCA_EQUIPMENT_METRICS_UPDATE_REQUEST),
  fcaEquipmentMetricsUpdateResponse: createAction(types.FCA_EQUIPMENT_METRICS_UPDATE_RESPONSE),
  fcaNonStandardClassesRequest: createAction(types.FCA_NON_STANDARD_CLASSES_REQUEST),
  fcaNonStandardClassesResponse: createAction(types.FCA_NON_STANDARD_CLASSES_RESPONSE),
  fcaNonStandardClassesUpdateRequest: createAction(types.FCA_NON_STANDARD_CLASSES_UPDATE_REQUEST),
  fcaNonStandardClassesUpdateResponse: createAction(types.FCA_NON_STANDARD_CLASSES_UPDATE_RESPONSE),
  fcaNonStandardTypesRequest: createAction(types.FCA_NON_STANDARD_TYPES_REQUEST),
  fcaNonStandardTypesResponse: createAction(types.FCA_NON_STANDARD_TYPES_RESPONSE),
};
// - - - - - - - - - - - - - - - - - - - - - - - -
// - - -  [4] API CALLS  - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -

export const setAdminHomeView = viewString => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.setAdminHomeView(viewString));
};

// - - -  SITES - - -

export const selectClientId = siteId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.selectClientId(siteId));
};

export const clearClientId = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearClientId());
};

export const selectProfileId = siteId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.selectProfileId(siteId));
};

export const clearProfileId = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearProfileId());
};

export const fetchSites = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.sitesRequest());
  try {
    const { data } = await api.configuration.getSites();
    return dispatch(actions.sitesResponse(data.data));
  } catch (err) {
    return dispatch(actions.sitesResponse(err));
  }
};

export const fetchAdminSites = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.sitesRequest());
  try {
    const { data } = await api.configuration.getAdminSites();
    return dispatch(actions.sitesResponse(data.data));
  } catch (err) {
    return dispatch(actions.sitesResponse(err));
  }
};

// - - - BUILDINGS - - -
export const fetchBuildings = siteId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.buildingsRequest());
  try {
    const { data } = await api.chartBuilder.getBuildings(siteId);
    return dispatch(actions.buildingsResponse(data.data));
  } catch (err) {
    return dispatch(actions.buildingsResponse(err));
  }
};

export const fetchBuildingDetails = buildingId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.singleBuildingRequest());
  try {
    const { data } = await api.configuration.getBuilding(buildingId);
    return dispatch(actions.singleBuildingResponse(data));
  } catch (err) {
    return dispatch(actions.singleBuildingResponse(err));
  }
};

export const uploadBuildingImage = (buildingId, formData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.buildingImageUploadRequest());
  try {
    const { data } = await api.configuration.postBuildingImage(
      buildingId,
      formData
    );
    return dispatch(actions.buildingImageUploadResponse(data));
  } catch (err) {
    return dispatch(actions.buildingImageUploadResponse(err));
  }
};
// - - - GROUPS - - -
export const fetchGroups = siteId => async (dispatch, _getState, { api }) => {
  dispatch(actions.groupsRequest());
  try {
    const { data } = await api.configuration.getBuildingGroupList(siteId);
    return dispatch(actions.groupsResponse(data));
  } catch (err) {
    return dispatch(actions.groupsResponse(err));
  }
};

export const removeGroup = groupId => async (dispatch, _getState, { api }) => {
  dispatch(actions.groupDeleteRequest());
  try {
    const { data } = await api.configuration.deleteBuildingGroup(groupId);
    return dispatch(actions.groupDeleteResponse(data));
  } catch (err) {
    return dispatch(actions.groupDeleteResponse(err));
  }
};

export const updateGroup = (groupId, groupData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.groupPutRequest());
  try {
    const { data } = await api.configuration.putBuildingGroup(
      groupId,
      groupData
    );
    return dispatch(actions.groupPutResponse(data));
  } catch (err) {
    return dispatch(actions.groupPutResponse(err));
  }
};
export const updateGroups = groupData => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.groupsPutRequest());
  try {
    const { data } = await api.configuration.putBuildingGroupsBulk(groupData);
    return dispatch(actions.groupsPutResponse(data));
  } catch (err) {
    return dispatch(actions.groupsPutResponse(err));
  }
};

export const createGroup = groupData => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.groupPostRequest());
  try {
    const { data } = await api.configuration.postBuildingGroup(groupData);
    return dispatch(actions.groupPostResponse(data));
  } catch (err) {
    return dispatch(actions.groupPostResponse(err));
  }
};

// - - - PROFILES - - -
export const fetchProfileImages = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.profileImagesRequest());
  try {
    const { data } = await api.configuration.getProfileImageList();
    return dispatch(actions.profileImagesResponse(data));
  } catch (err) {
    return dispatch(actions.profileImagesResponse(err));
  }
};

export const uploadProfileImage = formData => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.profileImageUploadRequest());
  try {
    const { data } = await api.configuration.postProfileImage(formData);
    return dispatch(actions.profileImageUploadResponse(data));
  } catch (err) {
    return dispatch(actions.profileImageUploadResponse(err));
  }
};

export const fetchProfiles = (siteIds, isSimple = false) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.profilesRequest());
  try {
    let _data = null;
    if (isSimple) {
      const { data } = await api.configuration.getSimpleProfileList(siteIds);
      _data = data;
    } else {
      const { data } = await api.configuration.getProfileList(siteIds);
      _data = data;
    }
    return dispatch(actions.profilesResponse(_data));
  } catch (err) {
    return dispatch(actions.profilesResponse(err));
  }
};

export const createProfile = profileData => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.profilePostRequest());
  try {
    const { data } = await api.configuration.postProfile(profileData);
    return dispatch(actions.profilePostResponse(data));
  } catch (err) {
    return dispatch(actions.profilePostResponse(err));
  }
};

export const updateProfile = (profileId, profileData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.profilePutRequest());
  try {
    const { data } = await api.configuration.putProfile(profileId, profileData);
    return dispatch(actions.profilePutResponse(data));
  } catch (err) {
    return dispatch(actions.profilePutResponse(err));
  }
};

export const removeProfile = profileId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.profileDeleteRequest());
  try {
    const { data } = await api.configuration.deleteProfile(profileId);
    return dispatch(actions.profileDeleteResponse(data));
  } catch (err) {
    return dispatch(actions.profileDeleteResponse(err));
  }
};

export const fetchFacilityDetailOptions = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.facilityDetailOptionsRequest());
  try {
    const { data } = await api.configuration.getFacilityDetailOptions();
    return dispatch(actions.facilityDetailOptionsResponse(data));
  } catch (err) {
    return dispatch(actions.facilityDetailOptionsResponse(err));
  }
};

export const fetchUnitConversionsOptions = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.unitConversionsOptionsRequest());
  try {
    const { data } = await api.configuration.getUnitConversionsOptions();
    return dispatch(actions.unitConversionsOptionsResponse(data));
  } catch (err) {
    return dispatch(actions.unitConversionsOptionsResponse(err));
  }
};

// - - - Group Types - - -
export const fetchGroupTypeOptions = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.groupTypesOptionsRequest());
  try {
    const { data } = await api.configuration.getBuildingGroupTypeOptions();
    return dispatch(actions.groupTypesOptionsResponse(data));
  } catch (err) {
    return dispatch(actions.groupTypesOptionsResponse(err));
  }
};

export const createGroupType = name => async (dispatch, _getState, { api }) => {
  dispatch(actions.groupTypePostRequest());
  try {
    const { data } = await api.configuration.postBuildingGroupType(name);
    return dispatch(actions.groupTypePostResponse(data));
  } catch (err) {
    return dispatch(actions.groupTypePostResponse(err));
  }
};

export const updateGroupType = (groupTypeId, name) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.groupTypePutRequest());
  try {
    const { data } = await api.configuration.putBuildingGroupType(
      groupTypeId,
      name
    );
    return dispatch(actions.groupTypePutResponse(data));
  } catch (err) {
    return dispatch(actions.groupTypePutResponse(err));
  }
};

export const fetchFloorplanPointClassOptions = siteId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.floorplanPointClassOptionsRequest());
  try {
    const { data } = await api.configuration.getFloorplanPointClassOptions(
      siteId
    );
    return dispatch(actions.floorplanPointClassOptionsResponse(data));
  } catch (err) {
    return dispatch(actions.floorplanPointClassOptionsResponse(err));
  }
};

// - - - METRICS - - -
export const fetchMetrics = siteId => async (dispatch, _getState, { api }) => {
  dispatch(actions.metricsRequest());
  try {
    const { data } = await api.configuration.getMetricList(siteId);
    return dispatch(actions.metricsResponse(data));
  } catch (err) {
    return dispatch(actions.metricsResponse(err));
  }
};

export const createMetric = metricData => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.metricPostRequest());
  try {
    const { data } = await api.configuration.postMetric(metricData);
    return dispatch(actions.metricPostResponse(data));
  } catch (err) {
    return dispatch(actions.metricPostResponse(err));
  }
};

export const removeMetric = metricId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.metricDeleteRequest());
  try {
    const { data } = await api.configuration.deleteMetric(metricId);
    return dispatch(actions.metricDeleteResponse(data));
  } catch (err) {
    return dispatch(actions.metricDeleteResponse(err));
  }
};

export const updateMetric = (metricId, metricData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.metricPutRequest());
  try {
    const { data } = await api.configuration.putMetric(metricId, metricData);
    return dispatch(actions.metricPutResponse(data));
  } catch (err) {
    return dispatch(actions.metricPutResponse(err));
  }
};

export const fetchMetricOptions = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.metricOptionsRequest());
  try {
    const { data } = await api.configuration.getMetricOptionsList();
    return dispatch(actions.metricOptionsResponse(data)); //?
  } catch (err) {
    return dispatch(actions.metricOptionsResponse(err));
  }
};

export const openMetricsModal = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.openMetricsModal());
};
export const closeMetricsModal = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.closeMetricsModal());
};

export const selectMetric = metric => async (dispatch, _getState, { api }) => {
  dispatch(actions.selectMetric(metric));
};
export const clearMetric = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearMetric());
};

// --- PAGES ---
export const fetchPageOptions = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.pageOptionsRequest());
  try {
    const { data } = await api.configuration.getPageOptions();
    return dispatch(actions.pageOptionsResponse(data));
  } catch (err) {
    return dispatch(actions.pageOptionsResponse(err));
  }
};

// --- USERS ---
export const fetchUsers = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.usersRequest());
  try {
    const { data } = await api.configuration.getUsers();
    return dispatch(actions.usersResponse(data));
  } catch (err) {
    return dispatch(actions.usersResponse(err));
  }
};

export const fetchUsersList = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.usersListRequest());
  try {
    const { data } = await api.configuration.getUsersList();
    return dispatch(actions.usersListResponse(data));
  } catch (err) {
    return dispatch(actions.usersListResponse(err));
  }
};

export const fetchUserRoleOptions = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.userRoleOptionsRequest());
  try {
    const { data } = await api.configuration.getUserRoleOptions();
    return dispatch(actions.userRoleOptionsResponse(data));
  } catch (err) {
    return dispatch(actions.userRoleOptionsResponse(err));
  }
};

export const updateUser = (userData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.userPutRequest());
  try {
    const { data } = await api.configuration.putUser(userData);
    return dispatch(actions.userPutResponse(data));
  } catch (err) {
    return dispatch(actions.userPutResponse(err));
  }
};

export const removeUser = userId => async (dispatch, _getState, { api }) => {
  dispatch(actions.userDeleteRequest());
  try {
    const { data } = await api.configuration.deleteUser(userId);
    return dispatch(actions.userDeleteResponse(data));
  } catch (err) {
    return dispatch(actions.userDeleteResponse(err));
  }
};

export const createUser = userData => async (dispatch, _getState, { api }) => {
  dispatch(actions.userPostRequest());
  try {
    const { data } = await api.configuration.postUser(userData);
    return dispatch(actions.userPostResponse(data));
  } catch (err) {
    return dispatch(actions.userPostResponse(err));
  }
};

export const openFacilitiesModal = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.openFacilitiesModal());
};
export const closeFacilitiesModal = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.closeFacilitiesModal());
};

export const changeRoleForUsers = (role, userIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.changeRoleForUsersRequest());
  try {
    let request = {
      role: role,
      userIds: userIds,
    };
    const { data } = await api.configuration.changeRoleForUsers(request);
    return dispatch(actions.changeRoleForUsersResponse(data));
  } catch (err) {
    return dispatch(actions.changeRoleForUsersResponse(err));
  }
};

export const addUsersToProfile = (_configId, _userIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.addUsersToProfileRequest());
  try {
    let request = {
      configId: _configId,
      userIds: _userIds,
    };
    const { data } = await api.configuration.addUsersToProfile(request);
    return dispatch(actions.addUsersToProfileResponse(data));
  } catch (err) {
    return dispatch(actions.addUsersToProfileResponse(err));
  }
};
export const removeUsersFromProfile = (_configId, _userIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.removeUsersFromProfileRequest());
  try {
    let request = {
      configId: _configId,
      userIds: _userIds,
    };
    const { data } = await api.configuration.removeUsersFromProfile(request);
    return dispatch(actions.removeUsersFromProfileResponse(data));
  } catch (err) {
    return dispatch(actions.removeUsersFromProfileResponse(err));
  }
};

export const deleteUsers = userIds => async (dispatch, _getState, { api }) => {
  dispatch(actions.deleteUsersRequest());
  try {
    const { data } = await api.configuration.deleteUsers(userIds);
    return dispatch(actions.deleteUsersResponse(data));
  } catch (err) {
    return dispatch(actions.deleteUsersResponse(err));
  }
};

export const fetchUserSites = siteIds => async (dispatch, _getState, { api }) => {
  dispatch(actions.fetchUserSitesRequest());
  try {
    const { data } = await api.configuration.getSimpleProfileList(siteIds);
    return dispatch(actions.fetchUserSitesResponse(data));
  } catch (err) {
    return dispatch(actions.fetchUserSitesResponse(err));
  }
};

export const addUserSites = siteIds => async (dispatch, _getState, { api }) => {
  dispatch(actions.addUserSitesRequest());
  try {
    const { data } = await api.configuration.getSimpleProfileList(siteIds);
    return dispatch(actions.addUserSitesResponse(data));
  } catch (err) {
    return dispatch(actions.addUserSitesResponse(err));
  }
};

export const removeUserSites = siteIds => async (dispatch, _getState, { api }) => {
  try {
    return dispatch(actions.removeUserSites(siteIds));
  } catch (err) {
    return dispatch(actions.removeUserSites(err));
  }
}

export const clearUserSites = () => async (dispatch, _getState, { api }) => {
  return dispatch(actions.clearUserSites());
}


//Mapping Options
export const fetchMappingOptions = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.mappingOptionsRequest());
  try {
    const { data } = await api.analyticsDeployer.getMappingOptions();
    return dispatch(
      actions.mappingOptionsResponse(data)
    );
  } catch (err) {
    return dispatch(actions.mappingOptionsResponse(err));
  }
};

/// --- FCA Configuration ---
export const fetchFCAEquipmentMetrics = (siteId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.fcaEquipmentMetricsRequest());
  try {
    const { data } = await api.configuration.getFCAEquipmentMetrics(siteId);
    return dispatch(
      actions.fcaEquipmentMetricsResponse(data)
    );
  } catch (err) {
    return dispatch(actions.fcaEquipmentMetricsResponse(err));
  }
};

export const upsertFCAEquipmentMetrics = (siteId, metrics) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.fcaEquipmentMetricsUpdateRequest());
  try {
    const { data } = await api.configuration.upsertFCAEquipmentMetrics(siteId, metrics);
    return dispatch(
      actions.fcaEquipmentMetricsUpdateResponse(data)
    );
  } catch (err) {
    return dispatch(actions.fcaEquipmentMetricsUpdateResponse(err));
  }
};

export const fetchFCANonStandardClasses = (siteId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.fcaNonStandardClassesRequest());
  try {
    const { data } = await api.configuration.getFCANonStandardClasses(siteId);
    return dispatch(
      actions.fcaNonStandardClassesResponse(data)
    );
  } catch (err) {
    return dispatch(actions.fcaNonStandardClassesResponse(err));
  }
};

export const upsertFCANonStandardClasses = (siteId, classes) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.fcaNonStandardClassesUpdateRequest());
  try {
    const { data } = await api.configuration.upsertFCANonStandardClasses(siteId, classes);
    return dispatch(
      actions.fcaNonStandardClassesUpdateResponse(data)
    );
  } catch (err) {
    return dispatch(actions.fcaNonStandardClassesUpdateResponse(err));
  }
};

export const fetchFCANonStandardTypes = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.fcaNonStandardTypesRequest());
  try {
    const { data } = await api.configuration.getFCANonStandardTypes();
    return dispatch(
      actions.fcaNonStandardTypesResponse(data)
    );
  } catch (err) {
    return dispatch(actions.fcaNonStandardTypesResponse(err));
  }
};
// - - - - - - - - - - - - - - - - - - - - - - - -
// - - - [6] ACTIONS - - - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -
export default handleActions(
  {
    [actions.setAdminHomeView]: {
      next: (state, { payload }) => ({
        ...state,
        adminHomeView: payload,
      }),
    },
    [actions.selectClientId]: {
      next: (state, { payload }) => ({
        ...state,
        clientId: payload,
      }),
    },
    [actions.clearClientId]: {
      next: (state, { payload }) => ({
        ...state,
        clientId: null,
      }),
    },
    [actions.selectProfileId]: {
      next: (state, { payload }) => ({
        ...state,
        profileId: payload,
      }),
    },
    [actions.clearProfileId]: {
      next: (state, { payload }) => ({
        ...state,
        profileId: null,
      }),
    },
    //--- SITES ---
    [actions.sitesRequest]: {
      next: state => ({
        ...state,
        siteLoading: true,
      }),
    },
    [actions.sitesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        sites: payload,
        siteLoading: false,
        siteError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        siteError: payload.message,
        siteLoading: false,
      }),
    },
    //--- BUILDINGS ---
    [actions.buildingsRequest]: {
      next: state => ({
        ...state,
        buildingsLoading: true,
      }),
    },
    [actions.buildingsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        buildings: payload,
        buildingsLoading: false,
        buildingsError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        buildingsError: payload.message,
        buildingsLoading: false,
      }),
    },
    [actions.singleBuildingRequest]: {
      next: state => ({
        ...state,
        buildingDetailsLoading: true,
      }),
    },
    [actions.singleBuildingResponse]: {
      next: (state, { payload }) => ({
        ...state,
        selectedBuildingDetails: payload,
        buildingDetailsLoading: false,
        buildingsError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        buildingsError: payload.message,
        buildingDetailsLoading: false,
      }),
    },
    [actions.buildingImageUploadRequest]: {
      next: state => ({
        ...state,
        buildingImageUploading: true,
      }),
    },
    [actions.buildingImageUploadResponse]: {
      next: (state, { payload }) => {
        toast.success('Building image Uploaded');
        return {
          ...state,
          buildingImageUploadError: null,
          buildingImageUploading: false,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          buildingImageUploadError: payload.response?.data,
          buildingImageUploading: false,
        }
      },
    },
    //--- GROUPS ---
    [actions.groupsRequest]: {
      next: state => ({
        ...state,
        groupsLoading: true,
      }),
    },
    [actions.groupsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        groups: payload,
        groupsLoading: false,
        groupsError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        groupsError: payload.message,
        groupsLoading: false,
      }),
    },
    [actions.groupDeleteRequest]: {
      next: state => ({
        ...state,
        groupDeleting: true,
      }),
    },
    [actions.groupDeleteResponse]: {
      next: state => {
        toast.success('Group Deleted');
        return {
          ...state,
          groupDeleting: false,
          groupDeleteError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          groupDeleteError: payload.response?.data,
          groupDeleting: false,
        }
      },
    },
    [actions.groupPutRequest]: {
      next: state => ({
        ...state,
        groupUpdating: true,
      }),
    },
    [actions.groupPutResponse]: {
      next: state => {
        toast.success('Group Updated');
        return {
          ...state,
          groupUpdating: false,
          groupUpdateError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          groupUpdateError: payload.response?.data,
          groupUpdating: false,
        }
      },
    },
    [actions.groupsPutRequest]: {
      next: state => ({
        ...state,
        groupsUpdating: true,
      }),
    },
    [actions.groupsPutResponse]: {
      next: state => {
        toast.success('Groups Updated');
        return {
          ...state,
          groupsUpdating: false,
          groupsUpdateError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          groupsUpdateError: payload.response?.data,
          groupsUpdating: false,
        }
      },
    },
    [actions.groupPostRequest]: {
      next: state => ({
        ...state,
        groupCreating: true,
      }),
    },
    [actions.groupPostResponse]: {
      next: state => {
        toast.success('Group Created');
        return {
          ...state,
          groupCreateError: null,
          groupCreating: false,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          groupCreateError: payload.response?.data,
          groupCreating: false,
        }
      },
    },
    //--- PROFILES ---
    [actions.profileImagesRequest]: {
      next: state => ({
        ...state,
        profileImagesLoading: true,
      }),
    },
    [actions.profileImagesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        profileImages: payload,
        profileImagesLoading: false,
        profileImagesError: null,
      }),
      throw: (state, { payload }) => ({
        ...state,
        profileImagesError: payload.message,
        profileImagesLoading: false,
      }),
    },
    [actions.metricsRequest]: {
      next: state => ({
        ...state,
        metricsLoading: true,
      }),
    },
    [actions.metricsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        metrics: payload,
        metricsLoading: false,
        metricsError: null,
      }),
      throw: (state, { payload }) => ({
        ...state,
        metricsError: payload.response?.data,
        metricsLoading: false,
      }),
    },
    [actions.metricDeleteRequest]: {
      next: state => ({
        ...state,
        metricDeleting: true,
      }),
    },
    [actions.metricDeleteResponse]: {
      next: state => {
        toast.success('Metric Deleted');
        return {
          ...state,
          metricDeleting: false,
          metricDeleteError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data);
        return {
          ...state,
          metricDeleteError: payload.response?.data,
          metricDeleting: false,
        }
      }
    },
    [actions.profileImageUploadRequest]: {
      next: state => ({
        ...state,
        profileImageUploading: true,
      }),
    },
    [actions.profileImageUploadResponse]: {
      next: (state, { payload }) => ({
        ...state,
        profileImageUploading: false,
        profileImagesError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        profileImagesError: payload.response?.data,
        profileImageUploading: false,
      }),
    },
    [actions.metricPostRequest]: {
      next: state => ({
        ...state,
        metricCreating: true,
      }),
    },
    [actions.metricPostResponse]: {
      next: state => {
        toast.success('Metric Created');
        return {
          ...state,
          metricCreating: false,
          metricCreateError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          metricCreateError: payload.response?.data,
          metricCreating: false,
        }
      },
    },
    [actions.metricOptionsRequest]: {
      next: state => ({
        ...state,
        metricOptionsLoading: true,
      }),
    },
    [actions.metricOptionsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        metricOptions: payload,
        metricOptionsLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        metricOptionsError: payload.response?.data,
        metricOptionsLoading: false,
      }),
    },
    [actions.metricPutRequest]: {
      next: state => ({
        ...state,
        metricUpdating: true,
      }),
    },
    [actions.metricPutResponse]: {
      next: state => {
        toast.success('Metric Updated');
        return {
          ...state,
          metricUpdating: false,
          metricUpdateError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data);
        return {
          ...state,
          metricUpdateError: payload.response?.data,
          metricUpdating: false,
        }
      },
    },
    [actions.profilesRequest]: {
      next: state => ({
        ...state,
        profilesLoading: true,
      }),
    },
    [actions.profilesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        profiles: payload,
        profilesLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        profilesError: payload,
        profilesLoading: false,
      }),
    },
    [actions.pageOptionsRequest]: {
      next: state => ({
        ...state,
        pageOptionsLoading: true,
      }),
    },
    [actions.pageOptionsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        pageOptions: payload,
        pageOptionsLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        pageOptionsLoading: false,
      }),
    },
    [actions.profilePostRequest]: {
      next: state => ({
        ...state,
        profilePosting: true,
      }),
    },
    [actions.profilePostResponse]: {
      next: (state, { payload }) => {
        toast.success('Profile Created');
        return {
          ...state,
          profilePosting: false,
          profilePostError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data);
        return {
          ...state,
          profilePostError: payload.response?.data,
          profilePosting: false,
        }
      },
    },
    [actions.profilePutRequest]: {
      next: state => ({
        ...state,
        profilePutting: true,
      }),
    },
    [actions.profilePutResponse]: {
      next: (state, { payload }) => {
        toast.success('Profile Updated')
        return {
          ...state,
          profilePutting: false,
          profilePutError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data);
        return {
          ...state,
          profilePutError: payload.response?.data,
          profilePutting: false,
        }
      },
    },
    [actions.profileDeleteRequest]: {
      next: state => ({
        ...state,
        profileDeleting: true,
      }),
    },
    [actions.profileDeleteResponse]: {
      next: (state, { payload }) => ({
        ...state,
        profileDeleting: false,
        profileDeleteError: null,
      }),
      throw: (state, { payload }) => ({
        ...state,
        profileDeleteError: payload.response?.data,
        profileDeleting: false,
      }),
    },
    [actions.usersRequest]: {
      next: state => ({
        ...state,
        usersLoading: true,
      }),
    },
    [actions.usersResponse]: {
      next: (state, { payload }) => ({
        ...state,
        users: payload,
        usersLoading: false,
        usersError: null,
      }),
      throw: (state, { payload }) => ({
        ...state,
        usersError: payload.response?.data,
        usersLoading: false,
      }),
    },
    [actions.usersListRequest]: {
      next: state => ({
        ...state,
        usersListLoading: true,
      }),
    },
    [actions.usersListResponse]: {
      next: (state, { payload }) => ({
        ...state,
        usersList: payload,
        usersListLoading: false,
        usersError: null,
      }),
      throw: (state, { payload }) => ({
        ...state,
        usersError: payload.response?.data,
        usersListLoading: false,
      }),
    },
    [actions.userPostRequest]: {
      next: state => ({
        ...state,
        userCreating: true,
      }),
    },
    [actions.userPostResponse]: {
      next: (state, { payload }) => {
        toast.success('User Created');
        return {
          ...state,
          userCreating: false,
          userCreateError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          userCreateError: payload.response?.data,
          userCreating: false,
        }
      },
    },
    [actions.userPutRequest]: {
      next: state => ({
        ...state,
        userUpdating: true,
      }),
    },
    [actions.userPutResponse]: {
      next: (state, { payload }) => {
        toast.success('User Updated');
        return {
          ...state,
          userUpdating: false,
          userUpdateError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          userUpdateError: payload.response?.data,
          userUpdating: false,
        }
      },
    },
    [actions.userDeleteRequest]: {
      next: state => ({
        ...state,
        userDeleting: true,
      }),
    },
    [actions.userDeleteResponse]: {
      next: (state, { payload }) => {
        toast.success('User Deleted');
        return {
          ...state,
          userDeleting: false,
          userDeleteError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          userDeleteError: payload.response?.data,
          userDeleting: false,
        }
      },
    },
    [actions.userRoleOptionsRequest]: {
      next: state => ({
        ...state,
        userRoleOptionsLoading: true,
      }),
    },
    [actions.userRoleOptionsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        userRoleOptions: payload,
        userRoleOptionsLoading: false,
        userRoleOptionsError: null,
      }),
      throw: (state, { payload }) => ({
        ...state,
        userRoleOptionsError: payload.response?.data,
        userRoleOptionsLoading: false,
      }),
    },
    [actions.facilityDetailOptionsRequest]: {
      next: state => ({
        ...state,
        facilityDetailOptionsLoading: true,
      }),
    },
    [actions.facilityDetailOptionsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        facilityDetailOptionsLoading: false,
        facilityDetailOptions: payload,
      }),
      throw: state => ({
        ...state,
        facilityDetailOptionsLoading: false,
      }),
    },
    [actions.unitConversionsOptionsRequest]: {
      next: state => ({
        ...state,
        unitConversionsOptionsLoading: true,
      }),
    },
    [actions.unitConversionsOptionsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        unitConversionsOptionsLoading: false,
        unitConversionsOptions: payload,
      }),
      throw: state => ({
        ...state,
        unitConversionsOptionsLoading: false,
      }),
    },
    [actions.groupTypesOptionsRequest]: {
      next: state => ({
        ...state,
        groupTypesOptionsLoading: true,
        groupTypesOptionsError: null,
      }),
    },
    [actions.groupTypesOptionsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        groupTypesOptionsLoading: false,
        groupTypesOptions: payload,
        groupTypesOptionsError: null,
      }),
      throw: (state, { payload }) => ({
        ...state,
        groupTypesOptionsLoading: false,
        groupTypesOptionsError: payload.response?.data,
      }),
    },
    [actions.groupTypePostRequest]: {
      next: state => ({
        ...state,
        groupTypeCreating: true,
      }),
    },
    [actions.groupTypePostResponse]: {
      next: state => {
        toast.success('Group Type Created');
        return {
          ...state,
          groupTypeCreateError: null,
          groupTypeCreating: false,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          groupTypeCreateError: payload.response?.data,
          groupTypeCreating: false,
        }
      },
    },
    [actions.groupTypePutRequest]: {
      next: state => ({
        ...state,
        groupTypeUpdating: true,
      }),
    },
    [actions.groupTypePutResponse]: {
      next: state => {
        toast.success('Group Type Updated');
        return {
          ...state,
          groupTypeUpdateError: null,
          groupTypeUpdating: false,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          groupTypeUpdateError: payload.response?.data,
          groupTypeUpdating: false,
        }
      },
    },
    [actions.floorplanPointClassOptionsRequest]: {
      next: state => ({
        ...state,
        floorplanPointClassOptionsLoading: true,
      }),
    },
    [actions.floorplanPointClassOptionsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        floorplanPointClassOptionsLoading: false,
        floorplanPointClassOptions: payload.data,
      }),
      throw: state => {
        toast.error('Failed to fetch floorplan point class options')
        return {
          ...state,
          floorplanPointClassOptionsLoading: false,
        }
      },
    },
    [actions.addUsersToProfileRequest]: {
      next: state => ({
        ...state,
        addingUsersToProfile: true,
        addUsersToProfileError: null,
      }),
    },
    [actions.addUsersToProfileResponse]: {
      next: (state, { payload }) => {
        toast.success('Users added to profile');
        return {
          ...state,
          addingUsersToProfile: false,
          addUsersToProfileError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          addingUsersToProfile: false,
          addUsersToProfileError: payload.response?.data,
        }
      },
    },
    [actions.removeUsersFromProfileRequest]: {
      next: state => ({
        ...state,
        removingUsersFromProfile: true,
        removeUsersFromProfileError: null,
      }),
    },
    [actions.removeUsersFromProfileResponse]: {
      next: (state, { payload }) => {
        toast.error('Users removed from profile');
        return {
          ...state,
          removingUsersFromProfile: false,
          removeUsersFromProfileError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          removingUsersFromProfile: false,
          removeUsersFromProfileError: payload.response?.data,
        }
      },
    },
    [actions.deleteUsersRequest]: {
      next: state => ({
        ...state,
        deletingUsers: true,
        deleteUsersError: null,
      }),
    },
    [actions.deleteUsersResponse]: {
      next: (state, { payload }) => {
        toast.success('User(s) deleted');
        return {
          ...state,
          deletingUsers: false,
          deleteUsersError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          deletingUsers: false,
          deleteUsersError: payload.response?.data,
        }
      },
    },
    [actions.changeRoleForUsersRequest]: {
      next: state => ({
        ...state,
        changingRoleForUsers: true,
        changeRoleForUsersError: null,
      }),
    },
    [actions.changeRoleForUsersResponse]: {
      next: (state, { payload }) => {
        toast.success('Role changed for user(s)');
        return {
          ...state,
          changingRoleForUsers: false,
          changeRoleForUsersError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          changingRoleForUsers: false,
          changeRoleForUsersError: payload.response?.data,
        }
      },
    },
    [actions.openMetricsModal]: {
      next: state => ({
        ...state,
        metricsModalOpen: true,
      }),
    },
    [actions.closeMetricsModal]: {
      next: state => ({
        ...state,
        metricsModalOpen: false,
      }),
    },
    [actions.openFacilitiesModal]: {
      next: state => ({
        ...state,
        facilitiesModalOpen: true,
      }),
    },
    [actions.closeFacilitiesModal]: {
      next: state => ({
        ...state,
        facilitiesModalOpen: false,
      }),
    },
    [actions.selectMetric]: {
      next: (state, { payload }) => ({
        ...state,
        selectedMetric: payload,
      }),
    },
    [actions.clearMetric]: {
      next: (state, { payload }) => ({
        ...state,
        selectedMetric: null,
      }),
    },
    [actions.fetchUserSitesRequest]: {
      next: state => ({
        ...state,
        userSitesLoading: true,
      }),
    },
    [actions.fetchUserSitesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        userSites: payload,
        userSitesLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        userSitesError: payload,
        userSitesLoading: false,
      }),
    },
    [actions.addUserSitesRequest]: {
      next: state => ({
        ...state,
        addingUserSites: true,
      }),
    },
    [actions.addUserSitesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        addingUserSites: false,
        userSites: [...state.userSites, ...payload],
      }),
      throw: (state, { payload }) => ({
        ...state,
        userSitesError: payload,
        addingUserSites: false,
      }),
    },
    [actions.removeUserSites]: {
      next: (state, { payload }) => {
        const sites = state.userSites.filter(site => !payload.includes(site.id));
        return {
          ...state,
          userSites: sites,
        };
      },
      throw: (state, { payload }) => {
        toast.error('Failed to remove sites')
        return {
          ...state,
          userSitesError: payload,
        }
      },
    },
    [actions.clearUserSites]: {
      next: (state, { payload }) => ({
        ...state,
        userSites: [],
      }),
    },
    [actions.mappingOptionsRequest]: {
      next: state => ({
        ...state,
        mappingOptionsLoading: true,
      }),
    },
    [actions.mappingOptionsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        unitOptions: payload.unit,
        mappingOptionsLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        mappingOptionsLoading: false,
      }),
    },
    [actions.fcaEquipmentMetricsRequest]: {
      next: state => ({
        ...state,
        fcaEquipmentMetricsLoading: true,
        fcaEquipmentMetricsError: null,
      }),
    },
    [actions.fcaEquipmentMetricsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        fcaEquipmentMetrics: payload?.metrics,
        fcaEquipmentMetricsLoading: false,
      }),
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          fcaEquipmentMetricsError: payload.response?.data,
          fcaEquipmentMetricsLoading: false,
        }
      },
    },
    [actions.fcaEquipmentMetricsUpdateRequest]: {
      next: state => ({
        ...state,
        fcaEquipmentMetricsUpdating: true,
        fcaEquipmentMetricsUpdateError: null,
      }),
    },
    [actions.fcaEquipmentMetricsUpdateResponse]: {
      next: (state, { payload }) => {
        toast.success('FCA Equipment Metrics Updated');
        return {
          ...state,
          fcaEquipmentMetricsUpdating: false,
          fcaEquipmentMetricsUpdateError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          fcaEquipmentMetricsUpdateError: payload.response?.data,
          fcaEquipmentMetricsUpdating: false,
        }
      },
    },
    [actions.fcaNonStandardClassesRequest]: {
      next: state => ({
        ...state,
        fcaNonStandardClassesLoading: true,
        fcaNonStandardClassesError: null,
      }),
    },
    [actions.fcaNonStandardClassesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        fcaNonStandardClasses: payload?.classes,
        fcaNonStandardClassesLoading: false,
      }),
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          fcaNonStandardClassesError: payload.response?.data,
          fcaNonStandardClassesLoading: false,
        }
      },
    },
    [actions.fcaNonStandardClassesUpdateRequest]: {
      next: state => ({
        ...state,
        fcaNonStandardClassesUpdating: true,
        fcaNonStandardClassesUpdateError: null,
      }),
    },
    [actions.fcaNonStandardClassesUpdateResponse]: {
      next: (state, { payload }) => {
        toast.success('FCA Non Standard Classes Updated');
        return {
          ...state,
          fcaNonStandardClassesUpdating: false,
          fcaNonStandardClassesUpdateError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          fcaNonStandardClassesUpdateError: payload.response?.data,
          fcaNonStandardClassesUpdating: false,
        }
      },
    },
    [actions.fcaNonStandardTypesRequest]: {
      next: state => ({
        ...state,
        fcaNonStandardTypesLoading: true,
        fcaNonStandardTypesError: null,
      }),
    },
    [actions.fcaNonStandardTypesResponse]: {
      next: (state, { payload }) => {;
        localStorage.setItem('nonStandardTypes', JSON.stringify(payload));
        return {
          ...state,
          fcaNonStandardTypes: payload?.types,
          fcaNonStandardTypesLoading: false,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data)
        return {
          ...state,
          fcaNonStandardTypesError: payload.response?.data,
          fcaNonStandardTypesLoading: false,
        }
      },
    },
  },
  initialState
);
