import PrivacyPolicy from './PrivacyPolicy';
import TermsConditions from './TermsConditions';

const MarketingRoutes = [
  {
    path: '/privacy_policy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms_conditions',
    component: TermsConditions,
  },
];

export default MarketingRoutes;
