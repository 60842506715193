import { createActions, handleActions } from "redux-actions";

const GET_LINKS_REQUEST = "GET_LINKS_REQUEST";
const GET_LINKS_RESPONSE = "GET_LINKS_RESPONSE";

const { getLinksRequest, getLinksResponse } = createActions(
  GET_LINKS_REQUEST,
  GET_LINKS_RESPONSE
);

const initialState = { isRefreshing: false };

export const getLinks = siteId => async (dispatch, _getState, { api }) => {
  dispatch(getLinksRequest());
  try {
    const { data } = await api.resources.externalLinks(siteId);
    return dispatch(getLinksResponse(data));
  } catch (err) {
    return dispatch(getLinksResponse(err));
  }
};

export default handleActions(
  {
    GET_LINKS_REQUEST: {
      next: state => ({
        ...state,
        isRefreshing: true
      })
    },
    GET_LINKS_RESPONSE: {
      next: (state, action) => {
        return {
          ...state,
          links: action.payload
        };
      },

      throw: (state, action) => {
        return {
          ...state,
          message: action.payload.message
        };
      }
    }
  },

  initialState
);
