export const baseTask = {
  type: '',
  equipmentTypeId: undefined,
  equipmentId: undefined,
  taskId: undefined,
  labor: undefined,
  months: [],
};

export const updateMPTaskState = (assets, currentTasks, newTasks) => {
  /*
  assets: stored in native format,
  newTasks: {
    type: 'equipmentType', 
    equipmentTypeId: equipmentTypeId,
    equipmentId: undefined, 
    taskId: undefined, 
    labor: bucketId,
    months: []
  }

  currentTasks & output =  { 
    assetId: {
      taskId: {
        labor: 0, // 0: inHouse, 1: outSource, 2: runToFail
        months: [0,3,6,9]
      },...
    }, ...
  }
  */
  switch (newTasks.type) {
    case 'equipmentType':
      return setEquipmentTypeTasks(assets, currentTasks, newTasks);
    case 'equipment':
      return setEquipmentIdTasks(assets, currentTasks, newTasks);
    case 'task':
      return setIndividualTask(assets, currentTasks, newTasks);
    default:
      return currentTasks;
  }
};

//--- Upsert Labor for all Tasks with EquipmentType ---
const setEquipmentTypeTasks = (assets, currentTasks, newTasks) => {
  const asset_equipType = assets.filter(
    d => d.equipmentTypeId === newTasks.equipmentTypeId
  );
  const assetLength = asset_equipType.length;
  for (let i = 0; i < assetLength; i++) {
    currentTasks = upsertAllTasks(
      currentTasks,
      asset_equipType[i].equipmentId,
      asset_equipType[i].tasks,
      newTasks.labor
    );
  }
  return currentTasks;
};

//--- Upsert Labor for all Tasks for this one piece of Equipment ---
const setEquipmentIdTasks = (assets, currentTasks, newTasks) => {
  const asset_equip = assets.find(d => d.equipmentId === newTasks.equipmentId);
  currentTasks = upsertAllTasks(
    currentTasks,
    asset_equip.equipmentId,
    asset_equip.tasks,
    newTasks.labor
  );
  return currentTasks;
};

//--- Upsert Labor for this one Task ---
const setIndividualTask = (assets, currentTasks, newTasks) => {
  currentTasks = upsertOneTask(
    assets,
    currentTasks,
    newTasks.equipmentId,
    newTasks.taskId,
    newTasks.labor,
    newTasks.months
  );
  return currentTasks;
};

//==== HELPER FUNCTIONS ============================
const upsertAllTasks = (currentTasks, equipmentId, tasks, labor) => {
  // [1] create or clear equipmentId if none exists;
  if (!currentTasks[equipmentId]) {
    currentTasks[equipmentId] = {};
  }

  // [2] now update or create each task
  const taskLength = tasks.length;
  for (let i = 0; i < taskLength; i++) {
    if (!currentTasks[equipmentId][tasks[i].taskId]) {
      currentTasks[equipmentId][tasks[i].taskId] = {
        labor: labor,
        months: tasks[i].months,
      };
    } else {
      currentTasks[equipmentId][tasks[i].taskId].labor = labor;
    }
  }
  return currentTasks;
};

const upsertOneTask = (
  assets,
  currentTasks,
  equipmentId,
  taskId,
  labor,
  months
) => {
  //---- [1] create equipmentId if none exists ----
  if (!currentTasks[equipmentId]) {
    currentTasks[equipmentId] = {};
  }

  //---- [2] CREATE or UPDATE Task ----

  //-- (2.1) No Task Info in Scenario => CREATE --
  if (!currentTasks[equipmentId][taskId]) {
    //-- (2.1.1) only one was set from frontend: labor, or months
    if (labor === undefined || months.length === 0) {
      const asset_equip = assets.find(d => d.equipmentId === equipmentId);
      const task_original = asset_equip.tasks.find(d => d.taskId === taskId);
      currentTasks[equipmentId][taskId] = {
        labor: labor === undefined ? task_original.labor : labor,
        months: months.length === 0 ? task_original.months : months,
      };

      //-- (2.1.2) Both labor and months was set in the frontend (rare) --
    } else {
      currentTasks[equipmentId][taskId] = {
        labor: labor,
        months: months,
      };
    }

    //-- (2.2) We have Task Info in Scenario => UPDATE --
  } else {
    // update labor source
    if (labor !== undefined) {
      currentTasks[equipmentId][taskId].labor = labor;
    }
    // update months
    if (months.length > 0) {
      currentTasks[equipmentId][taskId].months = months;
    }
  }

  return currentTasks;
};
