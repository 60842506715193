import { createAction, handleActions } from 'redux-actions';

import concat from 'lodash/concat';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';
import cloneDeep from 'lodash/cloneDeep';

//--- [1] Initial State ---
const initialState = {
  //--- SCOPE ---
  scopeList: [],
  scopeListRefreshing: false,
  scopeListError: null,
  scopePatchResult: null,
  scopePatching: false,
  scopePatchError: null,
  analyticPatching: false,
  analyticPatchError: null,
  analyticPatchResult: null,

  analyticsList: [],
  analyticsListRefreshing: false,
  analyticsListError: null,

  scopeImpacts: [],
  scopeImpactsError: null,
  scopeImpactsLoading: false,
  scopeImpactsSaving: false,
  scopeImpactsSavingError: null,

  //** incorrectly named - should be meta data **/
  analyticsDetail: {},
  analyticsDetailRefreshing: false,
  analyticsDetailError: null,

  analyticsLibrary: [],
  analyticsLibraryRefreshing: false,
  analyticsLibraryError: null,

  analyticsEquip: [],
  analyticsEquipRefreshing: false,
  analyticsEquipError: null,

  analyticsEligibleEquip: null, // either null, or [array, with, entries]

  analyticsEquipCheck: [],
  analyticsEquipCheckRefreshing: false,
  analyticsEquipCheckError: null,

  analyticsLibraryMeta: [],
  analyticsLibraryMetaRefreshing: false,
  analyticsLibraryMetaError: null,

  analyticsLibraryScope: {},
  analyticsLibraryScopeRefreshing: false,
  analyticsLibraryScopeError: null,

  analyticsPointClassOptions: [],
  analyticsPointClassOptionsLoading: false,
  analyticsPointClassOptionsError: null,

  //--- ui state ---
  augmentedEquip: [],
  deselectedEquip: [],
};

//--- [2] Action Types ---
// why? => string mismatch doesn't generate error messages; JS object keys do
const prefix = 'app/analyticsDeployer/';
export const types = {
  //--- SCOPE -- Need to bring GET over from McK General ---
  CLEAR_SCOPE: prefix + 'CLEAR_SCOPE',
  SET_SCOPE_REQUEST: prefix + 'SET_SCOPE_REQUEST',
  SET_SCOPE_RESPONSE: prefix + 'SET_SCOPE_RESPONSE',

  SET_PATCH_SCOPE_REQUEST: prefix + 'SET_PATCH_SCOPE_REQUEST',
  SET_PATCH_SCOPE_RESPONSE: prefix + 'SET_PATCH_SCOPE_RESPONSE',

  GET_SCOPE_IMPACTS_REQUEST: prefix + 'GET_SCOPE_IMPACTS_REQUEST',
  GET_SCOPE_IMPACTS_RESPONSE: prefix + 'GET_SCOPE_IMPACTS_RESPONSE',

  POST_SCOPE_IMPACTS_REQUEST: prefix + 'POST_SCOPE_IMPACTS_REQUEST',
  POST_SCOPE_IMPACTS_RESPONSE: prefix + 'POST_SCOPE_IMPACTS_RESPONSE',

  SET_PATCH_ANALYTIC_REQUEST: prefix + 'SET_PATCH_ANALYTIC_REQUEST',
  SET_PATCH_ANALYTIC_RESPONSE: prefix + 'SET_PATCH_ANALYTIC_RESPONSE',

  CLEAR_ANALYTICS: prefix + 'CLEAR_ANALYTICS',
  GET_ANALYTICS_REQUEST: prefix + 'GET_ANALYTICS_REQUEST',
  GET_ANALYTICS_RESPONSE: prefix + 'GET_ANALYTICS_RESPONSE',

  CLEAR_ANALYTIC_DETAIL: prefix + 'CLEAR_ANALYTIC_DETAIL',
  GET_ANALYTIC_DETAIL_REQUEST: prefix + 'GET_ANALYTIC_DETAIL_REQUEST',
  GET_ANALYTIC_DETAIL_RESPONSE: prefix + 'GET_ANALYTIC_DETAIL_RESPONSE',

  CLEAR_ANALYTICS_LIBRARY: prefix + 'CLEAR_ANALYTICS_LIBRARY',
  GET_ANALYTICS_LIBRARY_REQUEST: prefix + 'GET_ANALYTICS_LIBRARY_REQUEST',
  GET_ANALYTICS_LIBRARY_RESPONSE: prefix + 'GET_ANALYTICS_LIBRARY_RESPONSE',

  CLEAR_ANALYTICS_EQUIP: prefix + 'CLEAR_ANALYTICS_EQUIP',
  GET_ANALYTICS_EQUIP_REQUEST: prefix + 'GET_ANALYTICS_EQUIP_REQUEST',
  GET_ANALYTICS_EQUIP_RESPONSE: prefix + 'GET_ANALYTICS_EQUIP_RESPONSE',

  GET_ANALYTICS_ELIGIBLE_EQUIP: prefix + 'GET_ANALYTICS_ELIGIBLE_EQUIP',
  CLEAR_ANALYTICS_ELIGIBLE_EQUIP: prefix + 'CLEAR_ANALYTICS_ELIGIBLE_EQUIP',

  CLEAR_ANALYTICS_EQUIP_CHECK: prefix + 'CLEAR_ANALYTICS_EQUIP_CHECK',
  GET_ANALYTICS_EQUIP_CHECK_REQUEST:
    prefix + 'GET_ANALYTICS_EQUIP_CHECK_REQUEST',
  GET_ANALYTICS_EQUIP_CHECK_RESPONSE:
    prefix + 'GET_ANALYTICS_EQUIP_CHECK_RESPONSE',

  CLEAR_ANALYTICS_LIBRARY_META: prefix + 'CLEAR_ANALYTICS_LIBRARY_META',
  GET_ANALYTICS_LIBRARY_META_REQUEST:
    prefix + 'GET_ANALYTICS_LIBRARY_META_REQUEST',
  GET_ANALYTICS_LIBRARY_META_RESPONSE:
    prefix + 'GET_ANALYTICS_LIBRARY_META_RESPONSE',

  PATCH_ANALYTICS_META_REQUEST: prefix + 'PATCH_ANALYTICS_META_REQUEST',
  PATCH_ANALYTICS_META_RESPONSE: prefix + 'PATCH_ANALYTICS_META_RESPONSE',

  CLEAR_ANALYTICS_LIBRARY_SCOPE: prefix + 'CLEAR_ANALYTICS_LIBRARY_SCOPE',
  POST_ANALYTICS_LIBRARY_SCOPE_REQUEST:
    prefix + 'POST_ANALYTICS_LIBRARY_SCOPE_REQUEST',
  POST_ANALYTICS_LIBRARY_SCOPE_RESPONSE:
    prefix + 'POST_ANALYTICS_LIBRARY_SCOPE_RESPONSE',

  SET_DESELECTED_EQUIP: prefix + 'SET_DESELECTED_EQUIP',
  CLEAR_DESELECTED_EQUIP: prefix + 'CLEAR_DESELECTED_EQUIP',

  SET_AUGMENTED_EQUIP: prefix + 'SET_AUGMENTED_EQUIP',
  CLEAR_AUGMENTED_EQUIP: prefix + 'CLEAR_AUGMENTED_EQUIP',

  ANALYTICS_POINTCLASS_OPTIONS_REQUEST:
    prefix + 'ANALYTICS_POINTCLASS_OPTIONS_REQUEST',
  ANALYTICS_POINTCLASS_OPTIONS_RESPONSE:
    prefix + 'ANALYTICS_POINTCLASS_OPTIONS_RESPONSE',

  ANALYTICS_LOG_REQUEST: prefix + 'ANALYTICS_LOG_REQUEST',
  ANALYTICS_LOG_RESPONSE: prefix + 'ANALYTICS_LOG_RESPONSE',
  CLEAR_ANALYTICS_LOG: prefix + 'CLEAR_ANALYTICS_LOG',

  DELETE_ANALYTIC_TASK_REQUEST: prefix + 'DELETE_ANALYTICS_TASK_REQUEST',
  DELETE_ANALYTIC_TASK_RESPONSE: prefix + 'DELETE_ANALYTIC_TASK_RESPONSE',

  RUN_ANALYTIC_TASK_REQUEST: prefix + 'RUN_ANALYTICS_TASK_REQUEST',
  RUN_ANALYTIC_TASK_RESPONSE: prefix + 'RUN_ANALYTIC_TASK_RESPONSE',
};

//--- [3] Action Creators ---
export const actions = {
  //--- SCOPE ---
  clearScope: createAction(types.CLEAR_SCOPE),
  setScopeRequest: createAction(types.SET_SCOPE_REQUEST),
  setScopeResponse: createAction(types.SET_SCOPE_RESPONSE),
  setPatchScopeRequest: createAction(types.SET_PATCH_SCOPE_REQUEST),
  setPatchScopeResponse: createAction(types.SET_PATCH_SCOPE_RESPONSE),
  setPatchAnalyticRequest: createAction(types.SET_PATCH_ANALYTIC_REQUEST),
  setPatchAnalyticResponse: createAction(types.SET_PATCH_ANALYTIC_RESPONSE),

  getScopeImpactsRequest: createAction(types.GET_SCOPE_IMPACTS_REQUEST),
  getScopeImpactsResponse: createAction(types.GET_SCOPE_IMPACTS_RESPONSE),

  postScopeImpactsRequest: createAction(types.POST_SCOPE_IMPACTS_REQUEST),
  postScopeImpactsResponse: createAction(types.POST_SCOPE_IMPACTS_RESPONSE),

  clearAnalytics: createAction(types.CLEAR_ANALYTICS),
  getAnalyticsRequest: createAction(types.GET_ANALYTICS_REQUEST),
  getAnalyticsResponse: createAction(types.GET_ANALYTICS_RESPONSE),

  clearAnalyticDetail: createAction(types.CLEAR_ANALYTIC_DETAIL),
  getAnalyticDetailRequest: createAction(types.GET_ANALYTIC_DETAIL_REQUEST),
  getAnalyticDetailResponse: createAction(types.GET_ANALYTIC_DETAIL_RESPONSE),

  clearAnalyticsLibrary: createAction(types.CLEAR_ANALYTICS_LIBRARY),
  getAnalyticsLibraryRequest: createAction(types.GET_ANALYTICS_LIBRARY_REQUEST),
  getAnalyticsLibraryResponse: createAction(
    types.GET_ANALYTICS_LIBRARY_RESPONSE
  ),

  clearAnalyticsEquip: createAction(types.CLEAR_ANALYTICS_EQUIP),
  getAnalyticsEquipRequest: createAction(types.GET_ANALYTICS_EQUIP_REQUEST),
  getAnalyticsEquipResponse: createAction(types.GET_ANALYTICS_EQUIP_RESPONSE),

  getAnalyticsEligibleEquip: createAction(types.GET_ANALYTICS_ELIGIBLE_EQUIP),
  clearAnalyticsEligibleEquip: createAction(
    types.CLEAR_ANALYTICS_ELIGIBLE_EQUIP
  ),

  clearAnalyticsEquipCheck: createAction(types.CLEAR_ANALYTICS_EQUIP_CHECK),
  getAnalyticsEquipCheckRequest: createAction(
    types.GET_ANALYTICS_EQUIP_CHECK_REQUEST
  ),
  getAnalyticsEquipCheckResponse: createAction(
    types.GET_ANALYTICS_EQUIP_CHECK_RESPONSE
  ),

  clearAnalyticsLibraryMeta: createAction(types.CLEAR_ANALYTICS_LIBRARY_META),
  getAnalyticsLibraryMetaRequest: createAction(
    types.GET_ANALYTICS_LIBRARY_META_REQUEST
  ),
  getAnalyticsLibraryMetaResponse: createAction(
    types.GET_ANALYTICS_LIBRARY_META_RESPONSE
  ),
  patchAnalyticsMetaRequest: createAction(types.PATCH_ANALYTICS_META_REQUEST),
  patchAnalyticsMetaResponse: createAction(types.PATCH_ANALYTICS_META_RESPONSE),

  clearAnalyticsLibraryScope: createAction(types.CLEAR_ANALYTICS_LIBRARY_SCOPE),
  postAnalyticsLibraryScopeRequest: createAction(
    types.POST_ANALYTICS_LIBRARY_SCOPE_REQUEST
  ),
  postAnalyticsLibraryScopeResponse: createAction(
    types.POST_ANALYTICS_LIBRARY_SCOPE_RESPONSE
  ),

  setDeselectedEquip: createAction(types.SET_DESELECTED_EQUIP),
  clearDeselectedEquip: createAction(types.CLEAR_DESELECTED_EQUIP),

  setAugmentedEquip: createAction(types.SET_AUGMENTED_EQUIP),
  clearAugmentedEquip: createAction(types.CLEAR_AUGMENTED_EQUIP),

  analyticsPointClassOptionsRequest: createAction(
    types.ANALYTICS_POINTCLASS_OPTIONS_REQUEST
  ),
  analyticsPointClassOptionsResponse: createAction(
    types.ANALYTICS_POINTCLASS_OPTIONS_RESPONSE
  ),
  analyticsLogRequest: createAction(types.ANALYTICS_LOG_REQUEST),
  analyticsLogResponse: createAction(types.ANALYTICS_LOG_RESPONSE),
  clearAnalyticLogs: createAction(types.CLEAR_ANALYTICS_LOG),

  deleteAnalyticTaskRequest: createAction(types.DELETE_ANALYTIC_TASK_REQUEST),
  deleteAnalyticTaskResponse: createAction(types.DELETE_ANALYTIC_TASK_RESPONSE),

  runAnalyticTaskRequest: createAction(types.RUN_ANALYTIC_TASK_REQUEST),
  runAnalyticTaskResponse: createAction(types.RUN_ANALYTIC_TASK_RESPONSE),
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - -  [4] API CALLS  - - - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -

// - - -  SCOPES - - - - - - - - - - - - - - - - -
export const getAllScope = siteId => async (dispatch, _getState, { api }) => {
  dispatch(actions.setScopeRequest());
  try {
    const { data } = await api.analyticsDeployer.getScope(siteId);
    return dispatch(actions.setScopeResponse(data.data));
  } catch (err) {
    return dispatch(actions.setScopeResponse(err));
  }
};

export const getScope = (
  siteId,
  scopeId,
  buildingIds = [],
  equipClassIds = []
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.setScopeRequest());
  try {
    const { data } = await api.analyticsDeployer.getScope(
      siteId,
      scopeId,
      buildingIds,
      equipClassIds
    );
    return dispatch(actions.setScopeResponse(data.data));
  } catch (err) {
    return dispatch(actions.setScopeResponse(err));
  }
};

export const patchScope = (siteId, scopeId, patchData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.setPatchScopeRequest());
  try {
    const { data } = await api.analyticsDeployer.patchScope(patchData, scopeId);
    return dispatch(actions.setPatchScopeResponse(data.data));
  } catch (err) {
    return dispatch(actions.setPatchScopeResponse(err));
  }
};

export const fetchScopeImpacts = (scopeId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.getScopeImpactsRequest());
  try {
    const { data } = await api.analyticsDeployer.getScopeImpacts(scopeId);
    return dispatch(actions.getScopeImpactsResponse(data.data))
  } catch (err) {
    return dispatch(actions.getScopeImpactsResponse(err))
  }
};

export const postScopeImpacts = (scopeId, impacts) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.postScopeImpactsRequest());
  try {
    const { data } = await api.analyticsDeployer.postScopeImpacts(scopeId, impacts);
    return dispatch(actions.postScopeImpactsResponse(data))
  } catch (err) {
    return dispatch(actions.postScopeImpactsResponse(err))
  }
};

export const patchAnalytic = (siteId, taskId, patchData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.setPatchAnalyticRequest());
  try {
    const { data } = await api.analyticsDeployer.patchAnalytic(
      patchData,
      taskId
    );
    return dispatch(actions.setPatchAnalyticResponse(data.data));
  } catch (err) {
    return dispatch(actions.setPatchAnalyticResponse(err));
  }
};

export const deleteAnalyticTask = taskId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.deleteAnalyticTaskRequest());
  const { data } = await api.analyticsDeployer.deleteAnalyticTask(taskId);
  return dispatch(actions.deleteAnalyticTaskResponse(data));
};

export const runAnalyticTask = taskId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.runAnalyticTaskRequest());
  const { data } = await api.analyticsDeployer.runAnalyticTask(taskId);
  return dispatch(actions.runAnalyticTaskResponse(data));
};

export const deleteScope = (siteId, scopeId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.setScopeRequest());
  try {
    await api.analyticsDeployer.deleteScope(scopeId);
    const { data } = await api.analyticsDeployer.getScope(siteId);
    return dispatch(actions.setScopeResponse(data.data));
  } catch (err) {
    return dispatch(actions.setScopeResponse(err));
  }
};

export const clearScope = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearScope());
};

// - - -  LIST OF ANALYTICS  - - - - - - - - - - -
export const getAllAnalytics = (
  siteId,
  buildingIds,
  equipIds,
  scopeIds
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.getAnalyticsRequest());
  try {
    const { data } = await api.analyticsDeployer.getAnalyticsDetails(
      siteId,
      buildingIds,
      equipIds,
      scopeIds
    );
    return dispatch(actions.getAnalyticsResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAnalyticsResponse(err));
  }
};

export const clearAnalytics = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearAnalytics());
};

// - - -  ANALYTIC DETAIL  - - - - - - - - - - - -
//'point', 'equip', 'building', 'site', 'task'
export const getAnalyticDetail = (taskIds, type) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.getAnalyticDetailRequest());
  try {
    const { data } = await api.analyticsDeployer.getAnalyticsMeta(
      taskIds,
      type
    );
    return dispatch(actions.getAnalyticDetailResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAnalyticDetailResponse(err));
  }
};
export const clearAnalyticDetail = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.clearAnalyticDetail());
};

// - - -  ANALYTICS LIBRARY  - - - - - - - - - - -
export const getAnalyticsLibrary = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.getAnalyticsLibraryRequest());
  try {
    const { data } = await api.analyticsDeployer.getAnalyticsLibrary();
    return dispatch(actions.getAnalyticsLibraryResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAnalyticsLibraryResponse(err));
  }
};
export const clearAnalyticsLibrary = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.clearAnalyticsLibrary());
};

// - - -  ANALYTICS EQUIPMENT - - - - - - - - - - -
export const getAnalyticsEquip = (
  siteId,
  buildingIds,
  equipClassIds,
  equipIds,
  grouping
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.getAnalyticsEquipRequest());
  try {
    const { data } = await api.analyticsDeployer.getAnalyticsEquip(
      siteId,
      buildingIds,
      equipClassIds,
      equipIds,
      grouping
    );
    return dispatch(actions.getAnalyticsEquipResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAnalyticsEquipResponse(err));
  }
};
export const clearAnalyticsEquip = () => async (
  dispatch,
  _getState,
  { api }
) => {
  return dispatch(actions.clearAnalyticsEquip());
};

// - - -  ANALYTICS ELIGIBLE EQUIPMENT - - - - - - - - - - -
export const getAnalyticsEligibleEquip = eligibleIds => async (
  dispatch,
  _getState,
  { api }
) => {
  const equipData = _getState().analyticsDeployer.analyticsEquip;
  //-- Handle null --
  let filterIds = eligibleIds || [];

  //-- Handle arrays --
  if (filterIds.length === 0) {
    return dispatch(actions.getAnalyticsEligibleEquip(equipData));
  } else {
    if (Array.isArray(equipData)) {
      const filteredData = filterArrayByKey(
        equipData,
        'EquipClassID',
        filterIds
      );
      return dispatch(actions.getAnalyticsEligibleEquip(filteredData));
    }
  }
};

export const clearAnalyticsEligibleEquip = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.clearAnalyticsEligibleEquip());
};

// - - -  ANALYTICS EQUIPMENT CHECK - - - - - - - - - - -
export const getAnalyticsEquipCheck = (libraryId, equipIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.getAnalyticsEquipCheckRequest());
  try {
    const { data } = await api.analyticsDeployer.getAnalyticsEquipCheck(
      libraryId,
      equipIds
    );

    const mergedData = mergeCheckedAnalytics(
      _getState().analyticsDeployer.analyticsEligibleEquip,
      data.data
    );

    return dispatch(actions.getAnalyticsEquipCheckResponse(mergedData));
  } catch (err) {
    return dispatch(actions.getAnalyticsEquipCheckResponse(err));
  }
};
export const clearAnalyticsEquipCheck = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.clearAnalyticsEquipCheck());
};

// - - -  ANALYTICS LIBRARY META - - - - - - - - - - -
export const getAnalyticsLibraryMeta = (
  libraryId,
  equipIds,
  create = false
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.getAnalyticsLibraryMetaRequest());
  try {
    const { data } = await api.analyticsDeployer.getAnalyticsLibraryMeta(
      libraryId,
      equipIds,
      create
    );
    const augmented = getAugmentedEquip(
      data.data,
      _getState().analyticsDeployer.deselectedEquip
    );
    dispatch(actions.setAugmentedEquip(augmented));
    return dispatch(actions.getAnalyticsLibraryMetaResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAnalyticsLibraryMetaResponse(err));
  }
};

//--- Update Meta Data ---
export const patchAnalyticsMeta = (
  dataUpdates,
  libraryId,
  equipIds,
  create = false
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.getAnalyticsLibraryMetaRequest());
  try {
    await api.analyticsDeployer.patchAnalyticsMeta(dataUpdates);
    const { data } = await api.analyticsDeployer.getAnalyticsLibraryMeta(
      libraryId,
      equipIds,
      create
    );
    const augmented = getAugmentedEquip(
      data.data,
      _getState().analyticsDeployer.deselectedEquip
    );
    dispatch(actions.setAugmentedEquip(augmented));
    return dispatch(actions.getAnalyticsLibraryMetaResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAnalyticsLibraryMetaResponse(err));
  }
};

export const clearAnalyticsLibraryMeta = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.clearAnalyticsLibraryMeta());
  const augmented = getAugmentedEquip(
    _getState().analyticsDeployer.analyticsLibraryMeta,
    []
  );
  dispatch(actions.setAugmentedEquip(augmented));
};

/**
  sample data = {
    libraryid: 51,
    siteid: 89,
    displayName: 'Library Analytic 1',
    buildingIds: [1,2,3,4,5],
    equipClassIds: [1,2,3,4,5],
    equipExcludeIds: [1,2,3,4,5],
    duration: 3600,
    startDate: '2020-01-01',
    queue: 'Prod',
    interval: 6,
    notes: 'Some notes describing analytic.'
  }
  */
// - - -  ANALYTICS LIBRARY SCOPE - - - - - - - - - - -
export const postAnalyticsLibraryScope = (siteId, postData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.postAnalyticsLibraryScopeRequest());
  try {
    //--- update data ---
    const { checkPost } = await api.analyticsDeployer.postAnalyticsLibraryScope(
      postData
    );
    return dispatch(actions.postAnalyticsLibraryScopeResponse(checkPost.data));
    //--- refresh scope list ---
    //const { data } = await api.analyticsDeployer.getAllScope(siteId);
    //return dispatch(actions.setScopeResponse(data.data));
  } catch (err) {
    return dispatch(actions.postAnalyticsLibraryScopeResponse(err));
  }
};
export const clearAnalyticsLibraryScope = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.clearAnalyticsLibraryScope());
};

export const setDeselectedEquip = (equipIDs, action = 'deselect') => async (
  dispatch,
  _getState,
  { api }
) => {
  const deselectedEquip = _getState().analyticsDeployer.deselectedEquip;
  let payload = cloneDeep(deselectedEquip);
  if (action === 'deselect') {
    payload = uniq(concat(deselectedEquip, equipIDs));
  } else if (action === 'select') {
    payload = difference(deselectedEquip, equipIDs);
  } else {
    return;
  }
  dispatch(actions.setDeselectedEquip(payload));

  const augmented = getAugmentedEquip(
    _getState().analyticsDeployer.analyticsLibraryMeta,
    payload
  );
  dispatch(actions.setAugmentedEquip(augmented));
  return;
};

export const clearDeselectedEquip = () => async (
  dispatch,
  _getState,
  { api }
) => {
  const augmented = getAugmentedEquip(
    _getState().analyticsDeployer.analyticsLibraryMeta,
    []
  );
  dispatch(actions.setAugmentedEquip(augmented));
  return;
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - - [5] DATA MANIPULATION - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -
const getKeysByValue = (object, value) => {
  return Object.keys(object)
    .filter(key => object[key] === value)
    .join(', ');
};

const checkEquipResults = d => {
  let extraPoints = getKeysByValue(d, false);
  let missingPoints = getKeysByValue(d, null);
  let status = extraPoints + missingPoints === '' ? 'pass' : 'fail';
  return {
    id: d.id,
    status: status,
    extraPoints: extraPoints,
    missingPoints: missingPoints,
  };
};

const mergeEquipInfoById = (initialArray, updateArray) => {
  return initialArray.map(initialItem => {
    const updateItem = updateArray.find(uItem => initialItem.id === uItem.id);
    const checkInfoItem = checkEquipResults(updateItem);
    return {
      ...initialItem,
      ...checkInfoItem,
    };
  });
};

const mergeCheckedAnalytics = (equipInfo, checkEquipInfo) => {
  const combinedEquipInfo = mergeEquipInfoById(equipInfo, checkEquipInfo);
  return combinedEquipInfo;
};

const filterArrayByKey = (data, key, eligibleKeys) => {
  return data.filter(item => eligibleKeys.includes(item[key]));
};

const getAugmentedEquip = (analyticsLibraryMeta, deselected) => {
  let payload = cloneDeep(analyticsLibraryMeta);
  for (let i = 0; i < payload.length; i++) {
    payload[i]['Include'] = deselected.includes(payload[i].EquipID) ? 0 : 1;
  }
  return payload;
};

export const fetchAnalyticsPointClassOptions = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.analyticsPointClassOptionsRequest());
  try {
    const { data } = await api.analyticsDeployer.getMappingOptions();
    return dispatch(
      actions.analyticsPointClassOptionsResponse(data.pointclass)
    );
  } catch (err) {
    return dispatch(actions.analyticsPointClassOptionsResponse(err));
  }
};

export const fetchAnalyticLogs = taskId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.analyticsLogRequest());
  try {
    var { data } = await api.analyticsDeployer.getAnalyticsLogs(taskId);

    if (!data) {
      data = { messages: [] };
    }

    return dispatch(
      actions.analyticsLogResponse(
        data.messages.map(x => ({
          message: x,
        }))
      )
    );
  } catch (error) {
    return dispatch(actions.analyticsLogResponse(error));
  }
};

export const clearAnalyticLogs = () => async (dispatch, _getState, { api }) => {
  return dispatch(actions.clearAnalyticLogs());
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - - [6] ACTIONS - - - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -
export default handleActions(
  {
    //--- Scope ---------------------------------
    [actions.setScopeRequest]: {
      next: state => ({
        ...state,
        scopeListRefreshing: true,
      }),
    },
    [actions.setScopeResponse]: {
      next: (state, { payload }) => ({
        ...state,
        scopeList: payload,
        scopeListRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        scopeListError: payload.message,
        scopeListRefreshing: false,
      }),
    },
    [actions.clearScope]: {
      next: state => ({
        ...state,
        scopeList: [],
      }),
    },
    [actions.setPatchScopeRequest]: {
      next: state => ({
        ...state,
        scopePatching: true,
        scopePatchError: null,
        scopePatchResult: null,
      }),
    },
    [actions.setPatchScopeResponse]: {
      next: (state, { payload }) => ({
        ...state,
        scopePatchResult: payload,
        scopePatching: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        scopePatchError: payload.response.data,
        scopePatching: false,
      }),
    },
    [actions.getScopeImpactsRequest]: {
      next: state => ({
        ...state,
        scopeImpacts: [],
        scopeImpactsError: null,
        scopeImpactsLoading: true,
      }),
    },
    [actions.getScopeImpactsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        scopeImpacts: payload,
        scopeImpactsError: null,
        scopeImpactsLoading: false
      }),
      throw: (state, { payload }) => ({
        ...state,
        scopeImpacts: [],
        scopeImpactsError: payload.message,
        scopeImpactsLoading: false,
      })
    },
    [actions.postScopeImpactsRequest]: {
      next: state => ({
        ...state,
        scopeImpactsError: null,
        scopeImpactsSaving: true,
      }),
    },
    [actions.postScopeImpactsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        scopeImpactsSavingError: null,
        scopeImpactsSaving: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        scopeImpactsSavingError: payload.message,
        scopeImpactsSaving: false,
      })
    },
    [actions.setPatchAnalyticRequest]: {
      next: state => ({
        ...state,
        analyticPatching: true,
        analyticPatchError: null,
        analyticPatchResult: null,
      }),
    },
    [actions.setPatchAnalyticResponse]: {
      next: (state, { payload }) => ({
        ...state,
        analyticPatchResult: payload,
        analyticPatching: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        analyticPatchError: payload.response.data,
        analyticPatching: false,
      }),
    },

    //--- Analytics List ------------------------
    [actions.getAnalyticsRequest]: {
      next: state => ({
        ...state,
        analyticsListRefreshing: true,
      }),
    },
    [actions.getAnalyticsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        analyticsList: payload,
        analyticsListRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        analyticsList: [],
        analyticsListError: payload.message,
        analyticsListRefreshing: false,
      }),
    },
    [actions.clearAnalytics]: {
      next: state => ({
        ...state,
        analyticsList: [],
      }),
    },

    //--- Analytics Detail ------------------------
    [actions.getAnalyticDetailRequest]: {
      next: state => ({
        ...state,
        analyticsDetailRefreshing: true,
      }),
    },
    [actions.getAnalyticDetailResponse]: {
      next: (state, { payload }) => ({
        ...state,
        analyticsDetail: payload,
        analyticsDetailRefreshing: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        analyticsDetailError: payload.message,
        analyticsDetailRefreshing: false,
      }),
    },
    [actions.clearAnalyticDetail]: {
      next: state => ({
        ...state,
        analyticsDetail: {},
      }),
    },

    //--- Analytics Detail ------------------------
    [actions.getAnalyticsLibraryRequest]: {
      next: state => ({
        ...state,
        analyticsLibraryRefreshing: true,
      }),
    },
    [actions.getAnalyticsLibraryResponse]: {
      next: (state, { payload }) => ({
        ...state,
        analyticsLibrary: payload,
        analyticsLibraryRefreshing: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        analyticsLibraryError: payload.message,
        analyticsLibraryRefreshing: false,
      }),
    },
    [actions.clearAnalyticsLibrary]: {
      next: state => ({
        ...state,
        analyticsLibrary: [],
      }),
    },

    //--- Analytics Equipment ------------------------
    [actions.getAnalyticsEquipRequest]: {
      next: state => ({
        ...state,
        analyticsEquipRefreshing: true,
      }),
    },
    [actions.getAnalyticsEquipResponse]: {
      next: (state, { payload }) => ({
        ...state,
        analyticsEquip: payload,
        analyticsEquipRefreshing: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        analyticsEquipError: payload.message,
        analyticsEquipRefreshing: false,
      }),
    },
    [actions.clearAnalyticsEquip]: {
      next: state => ({
        ...state,
        analyticsEquip: [],
      }),
    },

    //--- Analytics Equipment Check ------------------------
    [actions.getAnalyticsEligibleEquip]: {
      next: (state, { payload }) => ({
        ...state,
        analyticsEligibleEquip: payload,
      }),
    },
    [actions.clearAnalyticsEligibleEquip]: {
      next: state => ({
        ...state,
        analyticsEligibleEquip: null,
      }),
    },

    //--- Analytics Equipment Check ------------------------
    [actions.getAnalyticsEquipCheckRequest]: {
      next: state => ({
        ...state,
        analyticsEquipCheckRefreshing: true,
      }),
    },
    [actions.getAnalyticsEquipCheckResponse]: {
      next: (state, { payload }) => ({
        ...state,
        analyticsEquipCheck: payload,
        analyticsEquipCheckRefreshing: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        analyticsEquipCheckError: payload.message,
        analyticsEquipCheckRefreshing: false,
      }),
    },
    [actions.clearAnalyticsEquipCheck]: {
      next: state => ({
        ...state,
        analyticsEquipCheck: [],
      }),
    },

    //--- Analytics Library Metadata ------------------------
    [actions.getAnalyticsLibraryMetaRequest]: {
      next: state => ({
        ...state,
        analyticsLibraryMetaRefreshing: true,
      }),
    },
    [actions.getAnalyticsLibraryMetaResponse]: {
      next: (state, { payload }) => ({
        ...state,
        analyticsLibraryMeta: payload,
        analyticsLibraryMetaRefreshing: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        analyticsLibraryMetaError: payload.message,
        analyticsLibraryMetaRefreshing: false,
      }),
    },
    [actions.clearAnalyticsLibraryMeta]: {
      next: state => ({
        ...state,
        analyticsLibraryMeta: {},
      }),
    },

    //--- Analytics Library Scope ------------------------
    [actions.postAnalyticsLibraryScopeRequest]: {
      next: state => ({
        ...state,
        analyticsLibraryScopeRefreshing: true,
      }),
    },
    [actions.postAnalyticsLibraryScopeResponse]: {
      next: (state, { payload }) => ({
        ...state,
        analyticsLibraryScope: payload,
        analyticsLibraryScopeRefreshing: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        analyticsLibraryScopeError: payload.message,
        analyticsLibraryScopeRefreshing: false,
      }),
    },
    [actions.analyticsPointClassOptionsRequest]: {
      next: state => ({
        ...state,
        analyticsPointClassOptionsLoading: true,
      }),
    },
    [actions.analyticsPointClassOptionsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        analyticsPointClassOptions: payload,
        analyticsPointClassOptionsLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        analyticsPointClassOptionsError: payload.message,
        analyticsPointClassOptionsLoading: false,
      }),
    },
    [actions.clearAnalyticsLibraryScope]: {
      next: state => ({
        ...state,
        analyticsLibraryScope: [],
      }),
    },

    [actions.setDeselectedEquip]: {
      next: (state, { payload }) => ({
        ...state,
        deselectedEquip: payload,
      }),
    },

    [actions.clearDeselectedEquip]: {
      next: state => ({
        ...state,
        deselectedEquip: [],
      }),
    },

    [actions.setAugmentedEquip]: {
      next: (state, { payload }) => ({
        ...state,
        augmentedEquip: payload,
      }),
    },

    [actions.clearAugmentedEquip]: {
      next: state => ({
        ...state,
        augmentedEquip: [],
      }),
    },
    [actions.analyticsLogRequest]: {
      next: state => ({
        ...state,
        logsLoading: true,
      }),
    },
    [actions.analyticsLogResponse]: {
      next: (state, { payload }) => ({
        ...state,
        logsLoading: false,
        logs: payload,
      }),
    },
    [actions.clearAnalyticLogs]: {
      next: state => ({
        ...state,
        logsLoading: false,
        logs: undefined,
      }),
    },
    [actions.deleteAnalyticTaskRequest]: {
      next: state => ({
        ...state,
        analyticsListRefreshing: true,
      }),
    },
    [actions.deleteAnalyticTaskResponse]: {
      next: state => ({
        ...state,
        analyticsListRefreshing: false,
      }),
    },
  },
  initialState
);
