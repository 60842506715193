const getQueryStringList = (input, fieldName) => {
  let qString = '';
  for (let i = 0; i < input.length; i++) {
    if (i !== 0) {
      qString += '&';
    }

    qString += `${fieldName}=${input[i]}`;
  }
  return qString;
};

export class UtilityService {
  constructor(http) {
    this.http = http;
  }

  async unitsChart(
    buildingIds,
    billSourceIds,
    beginDate,
    endDate,
    isYearOverYear
  ) {
    this.unitsChartParams = {
      buildingIds,
      billSourceIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      isYearOverYear,
    };
    return this.http.post(`/utilities/units/chart`, this.unitsChartParams);
  }
  async unitsChartCSV() {
    if (!this.unitsChartParams) return;
    return this.http.post(
      `/utilities/units/chart?outputType=Csv`,
      this.unitsChartParams
    );
  }

  async costsChart(
    buildingIds,
    billSourceIds,
    billSourceGroupIds,
    beginDate,
    endDate,
    costSourceType,
    isYearOverYear
  ) {
    this.costsChartParams = {
      buildingIds,
      billSourceIds,
      billSourceGroupIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      costSourceType,
      isYearOverYear,
    };
    return this.http.post(`/utilities/cost/chart`, this.costsChartParams);
  }
  async costsChartCSV() {
    if (!this.costsChartParams) return;
    return this.http.post(
      `/utilities/cost/chart?outputType=Csv`,
      this.costsChartParams
    );
  }

  async costsComparison(
    buildingIds,
    billSourceIds,
    billSourceGroupIds,
    beginDate,
    endDate,
    costSourceType,
    aliasId = null
  ) {
    this.costsComparisonParams = {
      buildingIds,
      billSourceIds,
      billSourceGroupIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      costSourceType,
    };
    if (aliasId == null) {
      return this.http.post(
        `/utilities/site-utilities-cost-comparison/chart`,
        this.costsComparisonParams
      );
    } else {
      return this.http.post(
        `/utilities/site-utilities-cost-comparison/chart?aliasNameSetId=${aliasId}`,
        this.costsComparisonParams
      );
    }
  }
  async costsComparisonCSV() {
    if (!this.costsComparisonParams) return;
    return this.http.post(
      `/utilities/site-utilities-cost-comparison/chart?outputType=Csv`,
      this.costsComparisonParams
    );
  }

  async unitsComparison(
    buildingIds,
    billSourceIds,
    beginDate,
    endDate,
    aliasId = null
  ) {
    this.unitsComparisonParams = {
      buildingIds,
      billSourceIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    if (aliasId == null) {
      return this.http.post(
        `/utilities/site-utilities-unit-comparison/chart`,
        this.unitsComparisonParams
      );
    } else {
      return this.http.post(
        `/utilities/site-utilities-unit-comparison/chart?aliasNameSetId=${aliasId}`,
        this.unitsComparisonParams
      );
    }
  }
  async unitsComparisonCSV() {
    if (!this.unitsComparisonParams) return;
    return this.http.post(
      `/utilities/site-utilities-unit-comparison/chart?outputType=Csv`,
      this.unitsComparisonParams
    );
  }

  async costSummaryChart(buildingIds, beginDate, endDate) {
    this.costSummaryChartParams = {
      buildingIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    return this.http.post(
      `/utilities/utilities-cost-summary/chart`,
      this.costSummaryChartParams
    );
  }
  async costSummaryChartCSV() {
    if (!this.costSummaryChartParams) return;
    return this.http.post(
      `/utilities/utilities-cost-summary/chart?outputType=Csv`,
      this.costSummaryChartParams
    );
  }

  async savingsTotal(
    buildingIds,
    beginDate,
    endDate,
    aliasId = null,
    configId = null,
    offsetIds = []
  ) {
    this.savingsTotalParams = {
      buildingIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    if (configId != null && offsetIds != null) {
      this.savingsTotalParams.configId = configId;
      this.savingsTotalParams.offsetIds = offsetIds;
    }
    if (aliasId == null) {
      return this.http.post(`/savings/total`, this.savingsTotalParams);
    } else {
      return this.http.post(
        `/savings/total?aliasNameSetId=${aliasId}`,
        this.savingsTotalParams
      );
    }
  }
  async savingsTotalCSV() {
    if (!this.savingsTotalParams) return;
    return this.http.post(
      `/savings/total?outputType=Csv`,
      this.savingsTotalParams
    );
  }

  async unitDemandDailyChart(
    buildingIds,
    billSourceGroupIds,
    billSourceIds,
    beginDate,
    endDate
  ) {
    this.unitDemandDailyChartParams = {
      buildingIds,
      billSourceGroupIds,
      billSourceIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    return this.http.post(
      `/utilities/unit-demand-daily/chart`,
      this.unitDemandDailyChartParams
    );
  }
  async unitDemandDailyChartCSV() {
    if (!this.unitDemandDailyChartParams) return;
    return this.http.post(
      `/utilities/unit-demand-daily/chart?outputType=Csv`,
      this.unitDemandDailyChartParams
    );
  }

  async unitDemandIntervalChart(
    buildingIds,
    billSourceGroupIds,
    billSourceIds,
    beginDate,
    endDate
  ) {
    this.unitDemandIntervalChartParams = {
      buildingIds,
      billSourceGroupIds,
      billSourceIds,
      beginDate: beginDate,
      endDate: endDate,
    };
    return this.http.post(
      `/utilities/unit-demand-interval/chart`,
      this.unitDemandIntervalChartParams
    );
  }
  async unitDemandIntervalChartCSV() {
    if (!this.unitDemandIntervalChartParams) return;
    return this.http.post(
      `/utilities/unit-demand-interval/chart?outputType=Csv`,
      this.unitDemandIntervalChartParams
    );
  }
  async serviceSummary(buildingIds, beginDate = null, endDate = null) {
    let url = `/utilities/service/summary?`;

    url += getQueryStringList(buildingIds, 'buildingId');

    if (beginDate) {
      url += `&beginDate=${encodeURIComponent(beginDate.toISOString())}`;
    }

    if (endDate) {
      url += `&endDate=${encodeURIComponent(endDate.toISOString())}`;
    }

    return this.http.get(url);
  }
  async serviceDeterminants(serviceId) {
    let url = `/utilities/service/${serviceId}/determinant`;

    return this.http.get(url);
  }
  async serviceBills(serviceId, beginDate = null, endDate = null) {
    let url = `/utilities/service/${serviceId}/bill`;

    if (beginDate || endDate) {
      url += '?';
    }

    if (beginDate) {
      url += `beginDate=${encodeURIComponent(beginDate.toISOString())}`;

      if (endDate) {
        url += '&';
      }
    }

    if (endDate) {
      url += `endDate=${encodeURIComponent(endDate.toISOString())}`;
    }

    return this.http.get(url);
  }
  async postBill(serviceId, payload) {
    let url = `/utilities/service/${serviceId}/bill`;

    return this.http.post(url, payload);
  }
  async deleteBill(billId) {
    let url = `/utilities/bill/${billId}`;

    return this.http.delete(url);
  }
  async putBill(billId, payload) {
    let url = `/utilities/bill/${billId}`;

    return this.http.put(url, payload);
  }
}
