export class ResourcesService {
  constructor(http) {
    this.http = http;
  }

  async externalLinks(siteId) {
    return this.http.post(`/resources/external-links`, {
      siteId,
    });
  }
}
