import { createAction, handleActions } from 'redux-actions';
import { toast } from "react-toastify";

const initialState = {
  propertyTypes: [],
  propertyTypeOptions: [],
  propertyTypesLoading: false,
  propertyTypesError: null,

  portfolioManagerBuildings: [],
  portfolioManagerBuildingsLoading: false,
  portfolioManagerBuildingsError: null,

  siteMeters: [],
  siteMetersLoading: false,
  siteMetersError: null,

  synchronizePortfolioManagerBuildingsLoading: false,
  synchronizePortfolioManagerBuildingsError: null,

  updatingPortfolioManagerBuildingsLoading: false,
  updatingPortfolioManagerBuildingsError: null,

  deletingPortfolioManagerBuildings: false,
  deletingPortfolioManagerBuildingsError: null,

  syncInstanceId: null,

  loadingSyncStatus: false,
  loadingSyncStatusError: null,
  syncStatus: null,
  checkSyncStatus: false,
};

const prefix = 'app/portfolioManager/';

export const types = {
  PROPERTY_TYPES_REQUEST: prefix + 'PROPERTY_TYPES_REQUEST',
  PROPERTY_TYPES_RESPONSE: prefix + 'PROPERTY_TYPES_RESPONSE',

  PORTFOLIO_MANAGER_BUILDIINGS_REQUEST:
    prefix + 'PORTFOLIO_MANAGER_BUILDIINGS_REQUEST',
  PORTFOLIO_MANAGER_BUILDIINGS_RESPONSE:
    prefix + 'PORTFOLIO_MANAGER_BUILDIINGS_RESPONSE',

  SITE_METERS_REQUEST: prefix + 'SITE_METERS_REQUEST',
  SITE_METERS_RESPONSE: prefix + 'SITE_METERS_RESPONSE',

  SYNCHRONIZE_PORTFOLIO_MANAGER_BUILDINGS_REQUEST:
    prefix + 'SYNCHRONIZE_PORTFOLIO_MANAGER_BUILDINGS_REQUEST',
  SYNCHRONIZE_PORTFOLIO_MANAGER_BUILDINGS_RESPONSE:
    prefix + 'SYNCHRONIZE_PORTFOLIO_MANAGER_BUILDINGS_RESPONSE',

  UPDATE_PORTFOLIO_MANAGER_BUILDINGS_REQUEST:
    prefix + 'UPDATE_PORTFOLIO_MANAGER_BUILDINGS_REQUEST',
  UPDATE_PORTFOLIO_MANAGER_BUILDINGS_RESPONSE:
    prefix + 'UPDATE_PORTFOLIO_MANAGER_BUILDINGS_RESPONSE',

  DELETE_PORTFOLIO_MANAGER_BUILDING_REQUEST:
    prefix + 'DELETE_PORTFOLIO_MANAGER_BUILDING_REQUEST',
  DELETE_PORTFOLIO_MANAGER_BUILDING_RESPONSE:
    prefix + 'DELETE_PORTFOLIO_MANAGER_BUILDING_RESPONSE',

  FETCH_PORTFOLIO_MANAGER_SYNC_STATUS_REQUEST:
    prefix + 'FETCH_PORTFOLIO_MANAGER_SYNC_STATUS_REQUEST',
  FETCH_PORTFOLIO_MANAGER_SYNC_STATUS_RESPONSE:
    prefix + 'FETCH_PORTFOLIO_MANAGER_SYNC_STATUS_RESPONSE',
};

export const actions = {
  getPropertyTypesRequest: createAction(types.PROPERTY_TYPES_REQUEST),
  getPropertyTypesResponse: createAction(types.PROPERTY_TYPES_RESPONSE),

  getPortfolioManagerBuildingsRequest: createAction(
    types.PORTFOLIO_MANAGER_BUILDIINGS_REQUEST
  ),
  getPortfolioManagerBuildingsResponse: createAction(
    types.PORTFOLIO_MANAGER_BUILDIINGS_RESPONSE
  ),

  getSiteMetersRequest: createAction(types.SITE_METERS_REQUEST),

  getSiteMetersResponse: createAction(types.SITE_METERS_RESPONSE),

  synchronizePortfolioManagerBuildingsRequest: createAction(
    types.SYNCHRONIZE_PORTFOLIO_MANAGER_BUILDINGS_REQUEST
  ),
  synchronizePortfolioManagerBuildingsResponse: createAction(
    types.SYNCHRONIZE_PORTFOLIO_MANAGER_BUILDINGS_RESPONSE
  ),

  updatePortfolioManagerBuildingsRequest: createAction(
    types.UPDATE_PORTFOLIO_MANAGER_BUILDINGS_REQUEST
  ),
  updatePortfolioManagerBuildingsResponse: createAction(
    types.UPDATE_PORTFOLIO_MANAGER_BUILDINGS_RESPONSE
  ),

  deletePortfolioManagerBuildingRequest: createAction(
    types.DELETE_PORTFOLIO_MANAGER_BUILDING_REQUEST
  ),
  deletePortfolioManagerBuildingResponse: createAction(
    types.DELETE_PORTFOLIO_MANAGER_BUILDING_RESPONSE
  ),

  getPortfolioManagerSyncStatusRequest: createAction(
    types.FETCH_PORTFOLIO_MANAGER_SYNC_STATUS_REQUEST
  ),
  getPortfolioManagerSyncStatusResponse: createAction(
    types.FETCH_PORTFOLIO_MANAGER_SYNC_STATUS_RESPONSE
  ),
};

export const fetchPropertyTypes = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.getPropertyTypesRequest());
  try {
    const { data } = await api.portfolioManager.getPropertyTypes();
    return dispatch(actions.getPropertyTypesResponse(data));
  } catch (error) {
    return dispatch(actions.getPropertyTypesResponse(error));
  }
};

export const fetchPortfolioManagerBuildings = siteId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.getPortfolioManagerBuildingsRequest());
  try {
    const data = await api.portfolioManager.getPortfolioManagerBuildings(
      siteId
    );

    return dispatch(actions.getPortfolioManagerBuildingsResponse(data.data));
  } catch (error) {
    return dispatch(actions.getPortfolioManagerBuildingsResponse(error));
  }
};

export const fetchSiteMeters = siteId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.getSiteMetersRequest());
  try {
    const data = await api.portfolioManager.getSiteMeters(siteId);

    return dispatch(actions.getSiteMetersResponse(data.data));
  } catch (error) {
    return dispatch(actions.getSiteMetersResponse(error));
  }
};

export const synchronizePortfolioManagerBuildings = siteId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.synchronizePortfolioManagerBuildingsRequest());
  try {
    const {
      data,
    } = await api.portfolioManager.postSynchronizePortfolioManagerBuildings(
      siteId
    );
    return dispatch(actions.synchronizePortfolioManagerBuildingsResponse(data));
  } catch (error) {
    return dispatch(
      actions.synchronizePortfolioManagerBuildingsResponse(error)
    );
  }
};

export const updatePortfolioManagerBuildings = (siteId, buildings) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.updatePortfolioManagerBuildingsRequest());
  try {
    const { data } = await api.portfolioManager.putPortfolioManagerBuildings(
      siteId,
      buildings
    );
    return dispatch(actions.updatePortfolioManagerBuildingsResponse(data));
  } catch (error) {
    return dispatch(actions.updatePortfolioManagerBuildingsResponse(error));
  }
};

export const deletePortfolioManagerBuilding = (siteId, buildingId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.deletePortfolioManagerBuildingRequest());
  try {
    const { data } = await api.portfolioManager.deletePortfolioManagerBuilding(
      siteId,
      buildingId
    );
    return dispatch(actions.deletePortfolioManagerBuildingResponse(data));
  } catch (error) {
    return dispatch(actions.deletePortfolioManagerBuildingResponse(error));
  }
};

export const fetchPortfolioManagerSyncStatus = (siteId, taskId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.getPortfolioManagerSyncStatusRequest());
  try {
    const { data } = await api.portfolioManager.getSynchronizeStatus(
      siteId,
      taskId
    );
    return dispatch(actions.getPortfolioManagerSyncStatusResponse(data));
  } catch (error) {
    return dispatch(actions.getPortfolioManagerSyncStatusResponse(error));
  }
};

export default handleActions(
  {
    [actions.getPropertyTypesRequest]: {
      next: state => ({
        ...state,
        propertyTypesLoading: true,
      }),
    },
    [actions.getPropertyTypesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        propertyTypes: payload,
        propertyTypeOptions: Object.keys(payload).map(x => ({
          label: x,
          value: x,
        })),
        propertyTypesLoading: false,
        propertyTypesError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        propertyTypesLoading: false,
        propertyTypesError: payload.message,
      }),
    },
    [actions.getPortfolioManagerBuildingsRequest]: {
      next: state => ({
        ...state,
        portfolioManagerBuildingsLoading: true,
        syncInstanceId: null,
      }),
    },
    [actions.getPortfolioManagerBuildingsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        portfolioManagerBuildings: payload,
        portfolioManagerBuildingsLoading: false,
        portfolioManagerBuildingsError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        portfolioManagerBuildingsLoading: false,
        portfolioManagerBuildingsError: payload.message,
      }),
    },
    [actions.getSiteMetersRequest]: {
      next: state => ({
        ...state,
        siteMetersLoading: true,
      }),
    },
    [actions.getSiteMetersResponse]: {
      next: (state, { payload }) => ({
        ...state,
        siteMeters: payload,
        siteMetersLoading: false,
        siteMetersError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        siteMetersLoading: false,
        siteMetersError: payload.message,
      }),
    },
    [actions.getPortfolioManagerSyncStatusRequest]: {
      next: state => ({
        ...state,
        loadingSyncStatus: true,
        checkSyncStatus: false,
      }),
    },
    [actions.getPortfolioManagerSyncStatusResponse]: {
      next: (state, { payload }) => {
        const checkSyncStatus = payload.status !== 'Completed' && payload.status !== 'Failed';
        if (payload.status === 'Completed') {
          toast.success(`Sync finished with status: ${payload.status}`);
        } else if (payload.status === 'Failed') { 
          toast.error(`Sync finished with status: ${payload.status}`);
        }
        return {
          ...state,
          syncStatus: payload.status,
          loadingSyncStatus: false,
          loadingSyncStatusError: null,
          checkSyncStatus: checkSyncStatus,
        }
      },

      throw: (state, { payload }) => {
        return {
          ...state,
          loadingSyncStatus: false,
          loadingSyncStatusError: payload.message,
          syncStatus: null,
          checkSyncStatus: false,
        }
      },
    },
    [actions.synchronizePortfolioManagerBuildingsRequest]: {
      next: state => ({
        ...state,
        synchronizePortfolioManagerBuildingsLoading: true,
        syncStatus: null,
      }),
    },
    [actions.synchronizePortfolioManagerBuildingsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        synchronizePortfolioManagerBuildingsLoading: false,
        synchronizePortfolioManagerBuildingsError: null,
        syncInstanceId: payload,
      }),

      throw: (state, { payload }) => {
        return {
          ...state,
          synchronizePortfolioManagerBuildingsLoading: false,
          synchronizePortfolioManagerBuildingsError: payload.message,
          syncInstanceId: null,
        }
      },
    },
    [actions.updatePortfolioManagerBuildingsRequest]: {
      next: state => ({
        ...state,
        updatingPortfolioManagerBuildingsLoading: true,
      }),
    },
    [actions.updatePortfolioManagerBuildingsResponse]: {
      next: (state, { payload }) => {
        toast.success('Buildings updated');
        return {
          ...state,
          updatingPortfolioManagerBuildingsLoading: false,
          updatingPortfolioManagerBuildingsError: null,
        }
      },
      throw: (state, { payload }) => {
        toast.error(payload.response?.data);
        return{
          ...state,
          updatingPortfolioManagerBuildingsLoading: false,
          updatingPortfolioManagerBuildingsError: payload.response?.data,
        }
      },
    },
    [actions.deletePortfolioManagerBuildingRequest]: {
      next: state => ({
        ...state,
        deletingPortfolioManagerBuildings: true,
      }),
    },
    [actions.deletePortfolioManagerBuildingResponse]: {
      next: (state, { payload }) => {
        toast.success('Building marked for deletion');
        return {
          ...state,
          deletingPortfolioManagerBuildings: false,
          deletingPortfolioManagerBuildingsError: null,
        }
      },

      throw: (state, { payload }) => {
        toast.error(payload.response?.data);
        return {
          ...state,
          deletingPortfolioManagerBuildings: false,
          deletingPortfolioManagerBuildingsError: payload.response?.data,
        }
      },
    },
  },
  initialState
);
