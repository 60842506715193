const getAssetBlob = container => {
  let hostname = new URL(window.location.origin).hostname;

  let environment, hostEnvironment;

  let domain = hostname.split('.')[0];
  let subdomainParts = domain.split('-');

  if (subdomainParts.length === 2) {
    hostEnvironment = subdomainParts[1];
    hostname = hostname.replace(`-${hostEnvironment}`, '');
  } else if (hostname === 'localhost') {
    hostname = 'torrens.edoenergy.com';
    hostEnvironment = 'develop';
  } else {
    hostEnvironment = null;
  }

  switch (hostEnvironment) {
    case 'develop':
      environment = 'torrensdevelop';
      break;
    case 'staging':
      environment = 'torrensstaging';
      break;
    default:
      environment = 'torrens';
  }
  if (container === 'whitelabel-assets') {
    return new URL(
      `https://${environment}.blob.core.windows.net/${container}/${hostname}/`
    );
  } else {
    return new URL(
      `https://${environment}.blob.core.windows.net/${container}/`
    );
  }
};

export const assetBlob = getAssetBlob('whitelabel-assets');

export { getAssetBlob };
