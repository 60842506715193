//--- API calls ---
export class SchedulerService {
  constructor(api) {
    this.api = api;
  }

  async getSitesAndTimezones() {
    let url = `/scheduler/site_timezone`;
    return this.api.pyGet(url);
  }

  async getAreasBySite(siteID) {
    let url = `/scheduler/areas/${siteID}`;
    return this.api.pyGet(url);
  }

  async getEquipmentBySite(siteID) {
    let url = `/scheduler/equipment/${siteID}`;
    return this.api.pyGet(url);
  }

  async getOperatingModes() {
    let url = `/scheduler/operating_modes`;
    return this.api.pyGet(url);
  }

  async getOperatingPriorities() {
    let url = `/scheduler/operating_priorities`;
    return this.api.pyGet(url);
  }

  //--- Appointments --
  //--- Appointments --
  //--- Appointments --
  async getAllAppointments(siteID) {
    let url = `/scheduler/appointments_by_site?siteid=${siteID}`;
    return this.api.pyGet(url);
  }
  async createAppointment(data) {
    let url = `/scheduler/appointments`;
    return this.api.pyPost(url, data);
  }

  async updateAppointment(appointmentID, data) {
    let url = `/scheduler/appointments/${appointmentID}`;
    return this.api.pyPut(url, data);
  }

  async deleteAppointment(appointmentID, siteID = null, customID = null) {
    let url = `/scheduler/appointments/${appointmentID}`;
    if (siteID !== null) {
      url += `?siteid=${siteID}`;
      return this.api.pyDelete(url);
    }
    if (customID !== null) {
      url += `?customid=${customID}`;
      return this.api.pyDelete(url);
    }
    console.error(
      'Error deleting appointment - siteID or customID must be populated.'
    );
    return;
  }

  //---- HOLIDAYS ----
  //---- HOLIDAYS ----
  //---- HOLIDAYS ----
  async getAllHolidays() {
    let url = `/scheduler/holidays`;
    return this.api.pyGet(url);
  }

  async createHoliday(data) {
    let url = `/scheduler/appointments`;
    return this.api.pyPost(url, data);
  }

  async updateHoliday(appointmentID, data) {
    let url = `/scheduler/appointments/${appointmentID}`;
    return this.api.pyPut(url, data);
  }

  async deleteHoliday(appointmentID) {
    let url = `/scheduler/appointments/${appointmentID}`;
    return this.api.pyDelete(url);
  }

  //---- INHERITANCE ----
  //---- INHERITANCE ----
  //---- INHERITANCE ----
  async getAllInheritance(siteID, buildingID = null) {
    let url = `/scheduler/inheritance?siteid=${siteID}`;
    if (buildingID !== null) {
      url += `&buildingid=${buildingID}`;
    }
    return this.api.pyGet(url);
  }

  async createInheritance(data) {
    let url = `/scheduler/inheritance`;
    return this.api.pyPost(url, data);
  }

  async updateInheritance(inheritanceID, data) {
    let url = `/scheduler/inheritance/${inheritanceID}`;
    return this.api.pyPut(url, data);
  }

  async deleteInheritance(inheritanceID, siteID, buildingID = null) {
    let url = `/scheduler/inheritance/${inheritanceID}?siteid=${siteID}`;
    if (buildingID !== null) {
      url += `&buildingid=${buildingID}`;
    }
    return this.api.pyDelete(url);
  }
}
