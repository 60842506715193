export class AnalyticsService {
  constructor(http) {
    this.http = http;
  }

  async filterOptions(buildingIds, beginDate, endDate) {
    return this.http.post(`/analytics/options`, {
      buildingIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
  }

  async faultHoursSummary(
    buildingIds,
    analyticTypes,
    equipmentTypeIds,
    beginDate,
    endDate
  ) {
    return this.http.post(`/analytics/fault-hours/summary`, {
      buildingIds,
      analyticTypes,
      equipmentTypeIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
  }

  async faultTableNodes(
    nodeType,
    buildingIds,
    analyticTypes,
    equipmentClassIds,
    beginDate,
    endDate
  ) {
    return this.http.post(`/analytics/node?nodeType=${nodeType}`, {
      buildingIds,
      analyticTypes,
      equipmentTypeIds: equipmentClassIds,
      beginDate,
      endDate,
    });
  }

  async faultHeatmapNodes(
    nodeType,
    aggregationInterval,
    buildingIds,
    analyticTypes,
    equipmentClassIds,
    beginDate,
    endDate
  ) {
    return this.http.post(
      `/analytics/interval-node?nodeType=${nodeType}&aggregationInterval=${aggregationInterval}`,
      {
        buildingIds,
        analyticTypes,
        equipmentTypeIds: equipmentClassIds,
        beginDate: beginDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      }
    );
  }

  async faultList(
    buildingIds,
    analyticTypes,
    equipmentClassIds,
    beginDate,
    endDate,
    pageSize,
    pageNumber,
    sortSelection,
    orderDescending,
    searchTerm
  ) {
    return this.http.post(
      `/analytics/fault-hours/breakdown?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${sortSelection}&orderDesc=${orderDescending}&search=${searchTerm}`,
      {
        buildingIds,
        analyticTypes,
        equipmentTypeIds: equipmentClassIds,
        beginDate: beginDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      }
    );
  }

  async faultNodeChart(
    buildingIds,
    analyticTypes,
    equipmentClassIds,
    taskId,
    beginDate,
    endDate
  ) {
    return this.http.post(`/analytics/node/chart`, {
      buildingIds,
      analyticTypes,
      equipmentTypeIds: equipmentClassIds,
      taskId,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
  }

  async faultHoursBreakdownCSV(params) {
    return this.http.post(`/analytics/fault-hours/breakdown?outputType=Csv`, {
      buildingIds: params.buildingIds,
      analyticTypes: params.analyticTypes,
      equipmentTypeIds: params.equipmentTypeIds,
      beginDate: params.beginDate.format('YYYY-MM-DD'),
      endDate: params.endDate.format('YYYY-MM-DD'),
    });
  }

  async taskChartDetail(taskId, beginDate, endDate,timezone) {
    this.taskFaultDetailParams = {
      taskId,
      beginDate: beginDate,
      endDate: endDate,
      timezone: timezone
    };

    return this.http.post(
      `/analytics/task-fault-detail`,
      this.taskFaultDetailParams
    );
  }

  async taskChart(taskId, beginDate, endDate, timezone) {
    this.taskFaultDetailParams = {
      taskId,
      beginDate: beginDate,
      endDate: endDate,
      timezone: timezone
    };

    return this.http.post(
      `/analytics/task-fault-detail/chart`,
      this.taskFaultDetailParams
    );
  }

  async taskFaultDetailCSV() {
    if (!this.taskFaultDetailParams) return;
    return this.http.post(
      `/analytics/task-fault-detail/chart?outputType=Csv`,
      this.taskFaultDetailParams
    );
  }
}
