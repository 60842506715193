import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch } from 'react-router'; // react-router v4/v5
import { fetchPartnerConfig } from './reducers/auth';

import locale_en from 'react-intl/locale-data/en';
import locale_es from 'react-intl/locale-data/es';
import { addLocaleData, IntlProvider } from 'react-intl';
import flatten from 'flat';

import messages_en from './translations/en.json';
import messages_es from './translations/es.json';

import asyncComponent from './utilities/asyncComponent';
import AuthRoutes from './routes/Auth/Routes';
import MarketingRoutes from './routes/Marketing/Routes';

import NoMatch from './routes/NoMatch';

//import { StylesProvider, withStyles } from '@material-ui/core/styles';
import './App.scss';
import 'react-toastify/dist/ReactToastify.min.css';

//--- Remove global focus from Material UI default (for screen readers) ---
/*
const GlobalCss = withStyles({
  '@global': {
    '*:focus': {
      'outline': 'none',
    },
  },
})(() => null);
*/

const messages = {
  en: flatten(messages_en),
  es: flatten(messages_es),
};

let currentLanguage = localStorage.getItem('lang') || 'en';
addLocaleData([...locale_en, ...locale_es]);

const AuthenticatedRoutes = asyncComponent(() =>
  import('./routes/MED/Routes').then(module => module.default)
);

class App extends Component {
  componentDidMount() {
    this.props.actions.fetchPartnerConfig();
    const { pathname, search } = this.props.router.location;
    if (pathname && (pathname === '/app/charting' || (pathname.split('analytics/')[0] === '/app/insights/'))) {
      sessionStorage.setItem('passthroughRoute', pathname + search);
    }
  }

  render() {
    currentLanguage = this.props.language;

    const _site = (
      <>
        {/* <StylesProvider injectFirst> */}
        {/* <GlobalCss /> */}
        <IntlProvider
          locale={currentLanguage}
          messages={messages[currentLanguage]}
        >
          <Switch>
            {AuthRoutes.map((route, index) => (
              <Route
                component={route.component}
                exact={route.exact}
                key={index}
                path={route.path}
              />
            ))}
            {MarketingRoutes.map((route, index) => (
              <Route
                component={route.component}
                exact={route.exact}
                key={index}
                path={route.path}
              />
            ))}
            <Route component={AuthenticatedRoutes} path="/app" />
            <Route component={AuthenticatedRoutes} path="/" />
            <Route component={NoMatch} path="*" />
          </Switch>
        </IntlProvider>
        {/* </StylesProvider> */}
      </>
    );

    // if (this.props.bugsnagKey && process.env.REACT_APP_ENV !== 'development') {
    //   const bugsnagClient = bugsnag(this.props.bugsnagKey);
    //   bugsnagClient.use(bugsnagReact, React);
    //   const ErrorBoundary = bugsnagClient.getPlugin('react');
    //   return <ErrorBoundary>{_site}</ErrorBoundary>;
    // }

    return _site;
  }
}

const mapStateToProps = state => ({
  showNav: state.navigation.showNav,
  language: state.localization.language,
  role: state.auth.role,
  router: state.router
  // state: state,
  // bugsnagKey: state.auth.bugSnagKey,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchPartnerConfig }, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
