
const formatMoney = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const formatMoneyRounded = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const formatMoneyRoundedTens = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

const formatRoundedTens = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

const formatMoneyMagnitude = val => {
  const labels = ['', 'k', 'M', 'B', 'T', 'Q'];
  const multiplier = val >= 0 ? 1 : -1;
  let formatted = '';

  if (undefined === val || val === null || val === '') {
    return '--';
  }

  val = Math.abs(val);
  // must be in the 1000s
  if (val >= 10 ** 5) {
    labels.some((mag, idx) => {
      if (idx > 0 && val < 10 ** (idx * 3)) {
        formatted = `${formatMoneyRoundedTens.format(
          (val / 10 ** ((idx - 1) * 3)) * multiplier
        )} ${labels[idx - 1]}`;
        return true;
      }

      return false;
    });
  }

  if (!formatted) {
    const valueFixed = Number(Number(val).toFixed(0)) * multiplier;
    formatted = formatMoneyRoundedTens.format(valueFixed);
  }

  return formatted;
};

const roundToDigits = (val, digits = 0) => {
  if (digits < 1) {
    return ~~(val + Math.sign(val) * 0.5);
  }
  const multiplier = 10 ** digits;
  const roundedValue = Math.round(val * multiplier) / multiplier;
  if (Math.abs(roundedValue) < 0.0001) return 0;
  return roundedValue;
};

/**
 * standardized format strings for energy and cost measurement units
 *
 * @param val
 * @param abbrev
 * @returns {string|*}
 */
const formatMetric = (val, abbrev) => {
  const abbreviations = {
    CARBON_MT: 'MTCO2 Eq.',
    COST_SQ_FT: '$/sqft',
    COST_SQ_FT_YR: '$/sqft/yr',
    DOLLARS: '$',
    ENERGY_USE: 'kBtu/sqft/yr',
    PERCENT: '%',
    ENERGY_PER_STUDENT: 'kWh/Occupant/yr',
  };

  const nilVal = '---';

  if (abbrev === abbreviations.CARBON_MT) {
    const formatVal = notPresent(val)
      ? nilVal
      : parseFloat(val).toLocaleString();
    return `${formatVal} ${abbrev}`;
  }

  if (abbrev === abbreviations.COST_SQ_FT) {
    const formatVal = notPresent(val) ? nilVal : formatMoneyRounded.format(val);
    return `${formatVal}`;
  }

  if (abbrev === abbreviations.COST_SQ_FT_YR) {
    const formatVal = notPresent(val) ? nilVal : formatMoneyRounded.format(val);
    return `${formatVal}/sqft/yr`;
  }

  if (abbrev === abbreviations.DOLLARS) {
    const formatVal = notPresent(val) ? nilVal : formatMoneyRounded.format(val);
    return `${formatVal}`;
  }

  if (abbrev === abbreviations.ENERGY_USE) {
    const formatVal = notPresent(val) ? nilVal : formatRoundedTens.format(val);
    return `${formatVal} ${abbrev}`;
  }

  if (abbrev === abbreviations.PERCENT) {
    const formatVal = notPresent(val) ? nilVal : formatRoundedTens.format(val);
    return `${formatVal} ${abbrev}`;
  }

  if (abbrev === abbreviations.ENERGY_PER_STUDENT) {
    const formatVal = notPresent(val) ? nilVal : formatRoundedTens.format(val);
    return `${formatVal} ${abbrev.toLowerCase()}`;
  }

  return val;
};

const notPresent = val => val === null || val === undefined || val === '';

const colorIndicationClassName = value => {
  if (value > 0) {
    return 'positive';
  }
  if (value < 0) {
    return 'negative';
  }
  if (value === 0) {
    return '';
  }
};

export {
  colorIndicationClassName,
  formatMetric,
  formatMoney,
  formatMoneyMagnitude,
  formatMoneyRounded,
  roundToDigits,
};
