export class LiveMapService {
  constructor(http) {
    this.http = http;
  }

  async getLiveMapMeters(configId, buildingIds) {
    let liveMapMeterParams = {
      buildingIds: buildingIds,
      configId: configId,
    };
    return this.http.post(`/live-map/meter-types`, liveMapMeterParams);
  }

  async getLiveMapValues(
    configId,
    buildingIds,
    equipClassID,
    primaryPointClassID,
    secondaryPointClassID
  ) {
    let liveMapValueParams = {
      configId,
      buildingIds,
      equipClassID,
      primaryPointClassID,
      secondaryPointClassID,
    };
    return this.http.post(`/live-map/values`, liveMapValueParams);
  }
}
