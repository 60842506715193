import { createAction, handleActions } from 'redux-actions';

import { actions as capitalPlanningActions } from './capitalPlanning';

export const types = {
  SCENARIOS_REQUEST: 'app/capitalPlanning/SCENARIOS_REQUEST',
  SCENARIOS_RESPONSE: 'app/capitalPlanning/SCENARIOS_RESPONSE',
  SAVE_SCENARIO_REQUEST: 'app/capitalPlanning/SAVE_SCENARIO_REQUEST',
  SAVE_SCENARIO_RESPONSE: 'app/capitalPlanning/SAVE_SCENARIO_RESPONSE',
  UPDATE_SCENARIO_REQUEST: 'app/capitalPlanning/UPDATE_SCENARIO_REQUEST',
  UPDATE_SCENARIO_RESPONSE: 'app/capitalPlanning/UPDATE_SCENARIO_RESPONSE',
  SET_ACTIVE_SCENARIO_REQUEST:
    'app/capitalPlanning/SET_ACTIVE_SCENARIO_REQUEST',
  SET_ACTIVE_SCENARIO_RESPONSE:
    'app/capitalPlanning/SET_ACTIVE_SCENARIO_RESPONSE',
  DELETE_SCENARIO_REQUEST: 'app/capitalPlanning/DELETE_SCENARIO_REQUEST',
  DELETE_SCENARIO_RESPONSE: 'app/capitalPlanning/DELETE_SCENARIO_RESPONSE',
  SCENARIO_ACCESS_REQUEST: 'app/capitalPlanning/SCENARIO_ACCESS_REQUEST',
  SCENARIO_ACCESS_RESPONSE: 'app/capitalPlanning/SCENARIO_ACCESS_RESPONSE',
  SHARE_SCENARIO_REQUEST: 'app/capitalPlanning/SHARE_SCENARIO_REQUEST',
  SHARE_SCENARIO_RESPONSE: 'app/capitalPlanning/SHARE_SCENARIO_RESPONSE',
  UNSHARE_SCENARIO_REQUEST: 'app/capitalPlanning/UNSHARE_SCENARIO_REQUEST',
  UNSHARE_SCENARIO_RESPONSE: 'app/capitalPlanning/UNSHARE_SCENARIO_RESPONSE',
  RESET_SCENARIO: 'app/capitalPlanning/RESET_SCENARIO',
};

export const actions = {
  scenariosRequest: createAction(types.SCENARIOS_REQUEST),
  scenariosResponse: createAction(types.SCENARIOS_RESPONSE),
  saveScenarioRequest: createAction(types.SAVE_SCENARIO_REQUEST),
  saveScenarioResponse: createAction(types.SAVE_SCENARIO_RESPONSE),
  updateScenarioRequest: createAction(types.UPDATE_SCENARIO_REQUEST),
  updateScenarioResponse: createAction(types.UPDATE_SCENARIO_RESPONSE),
  setActiveScenarioRequest: createAction(types.SET_ACTIVE_SCENARIO_REQUEST),
  setActiveScenarioResponse: createAction(types.SET_ACTIVE_SCENARIO_RESPONSE),
  deleteScenarioRequest: createAction(types.DELETE_SCENARIO_REQUEST),
  deleteScenarioResponse: createAction(types.DELETE_SCENARIO_RESPONSE),
  scenarioAccessRequest: createAction(types.SCENARIO_ACCESS_REQUEST),
  scenarioAccessResponse: createAction(types.SCENARIO_ACCESS_RESPONSE),
  shareScenarioRequest: createAction(types.SHARE_SCENARIO_REQUEST),
  shareScenarioResponse: createAction(types.SHARE_SCENARIO_RESPONSE),
  unshareScenarioRequest: createAction(types.UNSHARE_SCENARIO_REQUEST),
  unshareScenarioResponse: createAction(types.UNSHARE_SCENARIO_RESPONSE),
  resetScenario: createAction(types.RESET_SCENARIO),
};

const defaultState = {
  scenarios: [],
  accessList: [],
  activeScenario: null,
  isScenarioRefreshing: false,
  isSavingScenario: false,
  isLoadingScenario: false,
  isUpdatingScenatio: false,
  isDeletingScenario: false,
};

export const fetchScenarios = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.scenariosRequest());
  try {
    const { data } = await api.budgetplanning.scenarios();
    return dispatch(actions.scenariosResponse(data));
  } catch (err) {
    return dispatch(actions.scenariosResponse(err));
  }
};

export const saveScenario = _scenario => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.saveScenarioRequest());
  try {
    const { data } = await api.budgetplanning.saveScenario(_scenario);
    return dispatch(actions.saveScenarioResponse(data));
  } catch (err) {
    return dispatch(actions.saveScenarioResponse(err));
  }
};

export const updateScenario = _scenario => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.updateScenarioRequest());
  try {
    const { data } = await api.budgetplanning.updateScenario(_scenario);
    return dispatch(actions.updateScenarioResponse(data));
  } catch (err) {
    return dispatch(actions.updateScenarioResponse(err));
  }
};

export const setActiveScenario = _id => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.setActiveScenarioRequest());
  try {
    const { data } = await api.budgetplanning.getScenario(_id);
    dispatch(capitalPlanningActions.rehydrateBudgetPlanningScenario(data));
    return dispatch(actions.setActiveScenarioResponse(data));
  } catch (err) {
    return dispatch(actions.setActiveScenarioResponse(err));
  }
};

export const deleteScenario = _id => async (dispatch, _getState, { api }) => {
  dispatch(actions.deleteScenarioRequest());
  try {
    await api.budgetplanning.deleteScenario(_id);
    return dispatch(actions.deleteScenarioResponse(_id));
  } catch (err) {
    return dispatch(actions.deleteScenarioResponse(err));
  }
};

export const scenarioAccessList = _id => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scenarioAccessRequest());
  try {
    const { data } = await api.budgetplanning.getScenarioAccessList(_id);
    return dispatch(actions.scenarioAccessResponse(data));
  } catch (err) {
    return dispatch(actions.scenarioAccessResponse(err));
  }
};

export const shareScenario = (_id, _userid) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.shareScenarioRequest());
  try {
    const { data } = await api.budgetplanning.shareScenario(_id, {
      userId: _userid,
    });
    return dispatch(actions.shareScenarioResponse(data));
  } catch (err) {
    return dispatch(actions.shareScenarioResponse(err));
  }
};

export const unshareScenario = (_id, _userid) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.unshareScenarioRequest());
  try {
    const { data } = await api.budgetplanning.unshareScenario(_id, [_userid]);
    return dispatch(actions.unshareScenarioResponse(data));
  } catch (err) {
    return dispatch(actions.unshareScenarioResponse(err));
  }
};

export const resetScenario = () => dispatch => {
  dispatch(actions.resetScenario());
};

export default handleActions(
  {
    [actions.scenariosRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },
    [actions.scenariosResponse]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          scenarios: payload,
          isRefreshing: false,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.scenarioAccessRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
      }),
    },
    [actions.scenarioAccessResponse]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          accessList: payload,
          isScenarioRefreshing: false,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isScenarioRefreshing: false,
      }),
    },

    [actions.saveScenarioRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
        isSavingScenario: true,
      }),
    },
    [actions.saveScenarioResponse]: {
      next: (_state, { payload }) => {
        const _newState = _state.scenarios
          ? {
              owned: [..._state.scenarios.owned, payload],
              shared: _state.scenarios.shared,
            }
          : { owned: [payload], shared: [] };
        payload.noRefresh = true;

        return {
          ..._state,
          isScenarioRefreshing: false,
          isSavingScenario: false,
          scenarios: _newState,
          activeScenario: payload,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isScenarioRefreshing: false,
      }),
    },

    [actions.updateScenarioRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
        isUpdatingScenario: true,
      }),
    },
    [actions.updateScenarioResponse]: {
      next: (_state, { payload }) => {
        const _owned = _state.scenarios.owned.filter(
          val => val.id !== payload.id
        );
        _owned.push(payload);
        const _newState = {
          owned: _owned,
          shared: _state.scenarios.shared,
        };

        return {
          ..._state,
          isScenarioRefreshing: false,
          isUpdatingScenario: false,
          scenarios: _newState,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isScenarioRefreshing: false,
      }),
    },

    [actions.deleteScenarioRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
        isDeletingScenario: true,
      }),
    },
    [actions.deleteScenarioResponse]: {
      next: (_state, { payload }) => {
        const newOwnedState = _state.scenarios.owned.filter(
          val => val.id !== payload
        );
        const newSharedState = _state.scenarios.shared.filter(
          val => val.id !== payload
        );
        return {
          ..._state,
          isScenarioRefreshing: false,
          isDeletingScenario: false,
          scenarios: { owned: newOwnedState, shared: newSharedState },
          activeScenario:
            _state.activeScenario?.id === payload
              ? null
              : _state.activeScenario,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isScenarioRefreshing: false,
      }),
    },

    [actions.shareScenarioRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
      }),
    },
    [actions.shareScenarioResponse]: {
      next: (_state, { payload }) => {
        let newState = _state.scenarios.owned.filter(
          val => val.id !== payload.id
        );
        newState = newState.concat([payload]);
        return {
          ..._state,
          isScenarioRefreshing: false,
          scenarios: { owned: newState, shared: _state.scenarios.shared },
          activeScenario:
            _state.activeScenario.id === payload.id
              ? payload
              : _state.activeScenario,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isScenarioRefreshing: false,
      }),
    },

    [actions.unshareScenarioRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
      }),
    },
    [actions.unshareScenarioResponse]: {
      next: (_state, { payload }) => {
        // owned scenarios....
        const _ownedScenario = _state.scenarios.owned.find(
          _s => _s.id === payload.id
        );
        let newOwnedState = _state.scenarios.owned.filter(
          val => val.id !== payload.id
        );
        _ownedScenario && (newOwnedState = newOwnedState.concat([payload]));

        // shared scenarios....
        let newSharedState = _state.scenarios.shared.filter(
          val => val.id !== payload.id
        );

        return {
          ..._state,
          isScenarioRefreshing: false,
          scenarios: { owned: newOwnedState, shared: newSharedState },
          activeScenario:
            _state.activeScenario?.id === payload.id
              ? payload
              : _state.activeScenario,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },
    [actions.setActiveScenarioRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
        isLoadingScenario: true,
      }),
    },
    [actions.setActiveScenarioResponse]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          activeScenario: payload,
          isScenarioRefreshing: false,
          isLoadingScenario: false,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isScenarioRefreshing: false,
      }),
    },
    [actions.resetScenario]: {
      next: state => ({
        ...state,
        activeScenario: null,
      }),
    },
  },
  defaultState
);
