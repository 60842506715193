import { createAction, handleActions } from 'redux-actions';

//--- Initial State ---
const initialState = {
  //--- data state ---
  siteList: [],
  siteListRefreshing: false,
  siteListError: null,

  buildingList: [],
  buildingListRefreshing: false,
  buildingListError: null,

  equipList: [],
  equipListRefreshing: false,
  equipListError: null,

  scopeList: [],
  scopeListRefreshing: false,
  scopeListError: null,

  //--- UI State (NOT USED) ---
  selectedSite: '',
  selectedBuildings: [],
  selectedEquipClasses: [],
};

//--- Action Types ---
export const types = {
  GET_SITES_REQUEST: 'app/mckGeneral/GET_SITES_REQUEST',
  GET_SITES_RESPONSE: 'app/mckGeneral/GET_SITES_RESPONSE',

  CLEAR_BUILDINGS: 'app/mckGeneral/CLEAR_BUILDINGS',
  GET_BUILDINGS_REQUEST: 'app/mckGeneral/GET_BUILDINGS_REQUEST',
  GET_BUILDINGS_RESPONSE: 'app/mckGeneral/GET_BUILDINGS_RESPONSE',

  CLEAR_EQUIP: 'app/mckGeneral/CLEAR_EQUIP',
  GET_EQUIP_REQUEST: 'app/mckGeneral/GET_EQUIP_REQUEST',
  GET_EQUIP_RESPONSE: 'app/mckGeneral/GET_EQUIP_RESPONSE',

  CLEAR_SCOPE: 'app/mckGeneral/CLEAR_SCOPE',
  GET_SCOPE_REQUEST: 'app/mckGeneral/GET_SCOPE_REQUEST',
  GET_SCOPE_RESPONSE: 'app/mckGeneral/GET_SCOPE_RESPONSE',

  SELECT_SITE: 'app/mckGeneral/SELECT_SITE',
  SELECT_BUILDINGS: 'app/mckGeneral/SELECT_BUILDINGS',
  SELECT_EQUIP_CLASSES: 'app/mckGeneral/SELECT_EQUIP_CLASSES',
};

//--- Action Creators ---
export const actions = {
  getSitesRequest: createAction(types.GET_SITES_REQUEST),
  getSitesResponse: createAction(types.GET_SITES_RESPONSE),

  clearBuildings: createAction(types.CLEAR_BUILDINGS),
  getBuildingsRequest: createAction(types.GET_BUILDINGS_REQUEST),
  getBuildingsResponse: createAction(types.GET_BUILDINGS_RESPONSE),

  clearEquip: createAction(types.CLEAR_EQUIP),
  getEquipRequest: createAction(types.GET_EQUIP_REQUEST),
  getEquipResponse: createAction(types.GET_EQUIP_RESPONSE),

  clearScope: createAction(types.CLEAR_SCOPE),
  getScopeRequest: createAction(types.GET_SCOPE_REQUEST),
  getScopeResponse: createAction(types.GET_SCOPE_RESPONSE),

  //--- UI State (NOT USED) ---
  selectSite: createAction(types.SELECT_SITE),
  selectBuildings: createAction(types.SELECT_BUILDINGS),
  selectEquipClasses: createAction(types.SELECT_EQUIP_CLASSES),
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - -  SITES  - - - - - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -
export const getAllSites = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.getSitesRequest());
  try {
    const { data } = await api.mckGeneral.getAllSites();
    return dispatch(actions.getSitesResponse(data.data));
  } catch (err) {
    return dispatch(actions.getSitesResponse(err));
  }
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - -  BUILDINGS  - - - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -
export const getAllBuildings = siteId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.getBuildingsRequest());
  try {
    const { data } = await api.mckGeneral.getAllBuildings(siteId);
    return dispatch(actions.getBuildingsResponse(data.data));
  } catch (err) {
    return dispatch(actions.getBuildingsResponse(err));
  }
};

export const clearBuildings = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearBuildings());
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - -  EQUIPMENT  - - - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -
export const getAllEquip = siteId => async (dispatch, _getState, { api }) => {
  dispatch(actions.getEquipRequest());
  try {
    const { data } = await api.mckGeneral.getAllEquip(siteId);
    return dispatch(actions.getEquipResponse(data.data));
  } catch (err) {
    return dispatch(actions.getEquipResponse(err));
  }
};
export const clearEquip = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearEquip());
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - -  SCOPES - - - - - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -
export const getAllScope = siteId => async (dispatch, _getState, { api }) => {
  dispatch(actions.getScopeRequest());
  try {
    const { data } = await api.mckGeneral.getAllScope(siteId);
    return dispatch(actions.getScopeResponse(data.data));
  } catch (err) {
    return dispatch(actions.getScopeResponse(err));
  }
};
export const clearScope = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearScope());
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - -  STORE UI SELECTS (NOT USED) - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -
export const selectSite = payload => async (dispatch, _getState, { api }) => {
  dispatch(actions.selectSite(payload));
};
export const selectBuildings = payload => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.selectBuildings(payload));
};
export const selectEquipClasses = payload => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.selectEquipClasses(payload));
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - - ACTIONS - - - - - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -
export default handleActions(
  {
    //--- Sites ---------------------------------
    [actions.getSitesRequest]: {
      next: state => ({
        ...state,
        siteListRefreshing: true,
      }),
    },
    [actions.getSitesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        siteList: payload,
        siteListRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        siteListError: payload.message,
        siteListRefreshing: false,
      }),
    },

    //--- Buildings -----------------------------
    [actions.getBuildingsRequest]: {
      next: state => ({
        ...state,
        buildingListRefreshing: true,
      }),
    },
    [actions.getBuildingsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        buildingList: payload,
        buildingListRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        buildingListError: payload.message,
        buildingListRefreshing: false,
      }),
    },
    [actions.clearBuildings]: {
      next: state => ({
        ...state,
        buildingList: [],
      }),
    },

    //--- Equipment -----------------------------
    [actions.getEquipRequest]: {
      next: state => ({
        ...state,
        equipListRefreshing: true,
      }),
    },
    [actions.getEquipResponse]: {
      next: (state, { payload }) => ({
        ...state,
        equipList: payload,
        equipListRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        equipListError: payload.message,
        equipListRefreshing: false,
      }),
    },
    [actions.clearEquip]: {
      next: state => ({
        ...state,
        equipList: [],
      }),
    },

    //--- Scope ---------------------------------
    [actions.getScopeRequest]: {
      next: state => ({
        ...state,
        scopeListRefreshing: true,
      }),
    },
    [actions.getScopeResponse]: {
      next: (state, { payload }) => ({
        ...state,
        scopeList: payload,
        scopeListRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        scopeList: [],
        scopeListError: payload.message,
        scopeListRefreshing: false,
      }),
    },
    [actions.clearScope]: {
      next: state => ({
        ...state,
        scopeList: [],
      }),
    },

    //--- UI Fields (NOT USED) ---
    [actions.selectSite]: {
      next: (state, { payload }) => ({
        ...state,
        selectedSite: payload,
      }),
    },

    [actions.selectBuildings]: {
      next: (state, { payload }) => ({
        ...state,
        selectedBuildings: payload,
      }),
    },

    [actions.selectEquipClasses]: {
      next: (state, { payload }) => ({
        ...state,
        selectedEquipClasses: payload,
      }),
    },
  },

  //--- initial state ---
  initialState
);
