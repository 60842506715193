import {
  NAVIGATION_CLOSE_NAV,
  NAVIGATION_TOGGLE_FILTER_NAV,
  NAVIGATION_TOGGLE_NAV,
} from '../utilities/navigation';

const counterReducer = (state = { showNav: true }, action) => {
  switch (action.type) {
    case NAVIGATION_CLOSE_NAV:
      return { ...state, showNav: false };
    case NAVIGATION_TOGGLE_NAV:
      return { ...state, showNav: !state.showNav };
    case NAVIGATION_TOGGLE_FILTER_NAV:
      return { ...state, showFilterNav: action.show };
    default:
      return state;
  }
};

export default counterReducer;
