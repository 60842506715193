import { FlareSharp, PlaylistAddOutlined } from '@material-ui/icons';
import { NAVIGATION_TOGGLE_FILTER_NAV } from 'utilities/navigation';
import { data } from 'browserslist';
import { trimEnd } from 'lodash-es';
import { TransitionPropTypeKeys } from 'reactstrap/lib/utils';
import { createAction, handleActions } from 'redux-actions';

//--- [1] Initial State ---
const initialState = {
  //--- SVG ---
  svg: {},
  svgGetLoading: false,
  svgGetError: null,
  svgPostLoading: false,
  svgPostError: null,
  svgDeleteLoading: false,
  svgDeleteError: null,
  svgImagePutLoading: false,
  svgImagePutError: null,
  svgPolygonPutLoading: false,
  svgPolygonPutError: null,

  //--- EquipmentGroups ---
  equipmentGroups: [],
  equipmentGroupsGetLoading: false,
  equipmentGroupsGetError: null,
  equipmentGroupsPostLoading: false,
  equipmentGroupsPostError: null,
  equipmentGroupsDeleteLoading: false,
  equipmentGroupsDeleteError: null,

  polygonData: [],
  polygonDataLoading: false,
  polygonDataError: null,

  floorplans: [],
  floorplansLoading: false,
  floorplansError: null,

  publishing: false,
  publishingError: null,

  colorScaleType: null,
  absoluteScaleMin: null,
  absoluteScaleMax: null,

  navModalOpen: false,
};

//--- [2] Action Types ---
const prefix = 'app/floorplan/';
export const types = {
  //SVG
  SVG_GET_REQUEST: prefix + 'SVG_GET_REQUEST',
  SVG_GET_RESPONSE: prefix + 'SVG_GET_RESPONSE',
  SVG_CLEAR_REQUEST: prefix + 'SVG_CLEAR_REQUEST',
  SVG_CLEAR_RESPONSE: prefix + 'SVG_CLEAR_RESPONSE',
  SVG_POST_REQUEST: prefix + 'SVG_POST_REQUEST',
  SVG_POST_RESPONSE: prefix + 'SVG_POST_RESPONSE',
  SVG_PUT_IMAGE_REQUEST: prefix + 'SVG_PUT_IMAGE_REQUEST',
  SVG_PUT_IMAGE_RESPONSE: prefix + 'SVG_PUT_IMAGE_RESPONSE',
  SVG_PUT_POLYGON_REQUEST: prefix + 'SVG_PUT_POLYGON_REQUEST',
  SVG_PUT_POLYGON_RESPONSE: prefix + 'SVG_PUT_POLYGON_RESPONSE',
  SVG_DELETE_REQUEST: prefix + 'SVG_DELETE_REQUEST',
  SVG_DELETE_RESPONSE: prefix + 'SVG_DELETE_RESPONSE',

  CLEAR_FLOORPLANS_LIST: prefix + 'CLEAR_FLOORPLANS_LIST',

  //EQUIPMENT
  POLYGON_EQUIPMENT_POST_REQUEST: prefix + 'POLYGON_EQUIPMENT_POST_REQUEST',
  POLYGON_EQUIPMENT_POST_RESPONSE: prefix + 'POLYGON_EQUIPMENT_POST_RESPONSE',
  POLYGON_EQUIPMENT_GET_REQUEST: prefix + 'POLYGON_EQUIPMENT_GET_REQUEST',
  POLYGON_EQUIPMENT_GET_RESPONSE: prefix + ' POLYGON_EQUIPMENT_GET_RESPONSE',
  POLYGON_EQUIPMENT_DELETE_REQUEST: prefix + 'POLYGON_EQUIPMENT_DELETE_REQUEST',
  POLYGON_EQUIPMENT_DELETE_RESPONSE:
    prefix + 'POLYGON_EQUIPMENT_DELETE_RESPONSE',

  //DATA
  POLYGON_DATA_GET_REQUEST: prefix + 'POLYGON_DATA_GET_REQUEST',
  POLYGON_DATA_GET_RESPONSE: prefix + 'POLYGON_DATA_GET_RESPONSE',
  CLEAR_POLYGON_DATA: prefix + 'CLEAR_POLYGON_DATA',

  //FLOORPLANS
  BUILDING_FLOORPLANS_GET_REQUEST: prefix + 'BUILDING_FLOORPLANS_GET_REQUEST',
  BUILDING_FLOORPLANS_GET_RESPONSE: prefix + 'BUILDING_FLOORPLANS_GET_RESPONSE',

  SITE_FLOORPLANS_GET_REQUEST: prefix + 'SITE_FLOORPLANS_GET_REQUEST',
  SITE_FLOORPLANS_GET_RESPONSE: prefix + 'SITE_FLOORPLANS_GET_RESPONSE',

  PUBLISH_FLOORPLAN_REQUEST: prefix + 'PUBLISH_FLOORPLAN_REQUEST',
  PUBLISH_FLOORPLAN_RESPONSE: prefix + 'PUBLISH_FLOORPLAN_RESPONSE',

  SET_FLOORPLAN_COLOR_SCALE_TYPE: prefix + 'SET_FLOORPLAN_COLOR_SCALE_TYPE',
  SET_FLOORPLAN_COLOR_SCALE_VALUES: prefix + 'SET_FLOORPLAN_COLOR_SCALE_VALUES',

  SET_CONFIRM_NAV_MODAL_OPEN: prefix + 'SET_CONFIRM_NAV_MODAL_OPEN',
  SET_CONFIRM_NAV_MODAL_CLOSED: prefix + 'SET_CONFIRM_NAV_MODAL_CLOSED',
};

//--- [3] Action Creators ---
export const actions = {
  svgGetRequest: createAction(types.SVG_GET_REQUEST),
  svgGetResponse: createAction(types.SVG_GET_RESPONSE),
  svgPostRequest: createAction(types.SVG_POST_REQUEST),
  svgPostResponse: createAction(types.SVG_POST_RESPONSE),
  svgClearRequest: createAction(types.SVG_CLEAR_REQUEST),
  svgClearResponse: createAction(types.SVG_CLEAR_RESPONSE),
  svgPutImageRequest: createAction(types.SVG_PUT_IMAGE_REQUEST),
  svgPutImageResponse: createAction(types.SVG_PUT_IMAGE_RESPONSE),
  svgPutPolygonRequest: createAction(types.SVG_PUT_POLYGON_REQUEST),
  svgPutPolygonResponse: createAction(types.SVG_PUT_POLYGON_RESPONSE),
  svgDeleteRequest: createAction(types.SVG_DELETE_REQUEST),
  svgDeleteResponse: createAction(types.SVG_DELETE_RESPONSE),

  clearFloorplansList: createAction(types.CLEAR_FLOORPLANS_LIST),

  polygonEquipmentPostRequest: createAction(
    types.POLYGON_EQUIPMENT_POST_REQUEST
  ),
  polygonEquipmentPostResponse: createAction(
    types.POLYGON_EQUIPMENT_POST_RESPONSE
  ),
  polygonEquipmentGetRequest: createAction(types.POLYGON_EQUIPMENT_GET_REQUEST),
  polygonEquipmentGetResponse: createAction(
    types.POLYGON_EQUIPMENT_GET_RESPONSE
  ),
  polygonEquipmentDeleteRequest: createAction(
    types.POLYGON_EQUIPMENT_DELETE_REQUEST
  ),
  polygonEquipmentDeleteResponse: createAction(
    types.POLYGON_EQUIPMENT_DELETE_RESPONSE
  ),

  polygonDataGetRequest: createAction(types.POLYGON_DATA_GET_REQUEST),
  polygonDataGetResponse: createAction(types.POLYGON_DATA_GET_RESPONSE),
  clearPolygonData: createAction(types.CLEAR_POLYGON_DATA),

  buildingFloorplansRequest: createAction(
    types.BUILDING_FLOORPLANS_GET_REQUEST
  ),
  buildingFloorplansResponse: createAction(
    types.BUILDING_FLOORPLANS_GET_RESPONSE
  ),

  siteFloorplansRequest: createAction(types.SITE_FLOORPLANS_GET_REQUEST),
  siteFloorplansResponse: createAction(types.SITE_FLOORPLANS_GET_RESPONSE),

  publishFloorplanRequest: createAction(types.PUBLISH_FLOORPLAN_REQUEST),
  publishFloorplanResponse: createAction(types.PUBLISH_FLOORPLAN_RESPONSE),

  setFloorplanColorScaleType: createAction(
    types.SET_FLOORPLAN_COLOR_SCALE_TYPE
  ),
  setFloorplanColorScaleValues: createAction(
    types.SET_FLOORPLAN_COLOR_SCALE_VALUES
  ),
  setConfirmNavModalOpen: createAction(types.SET_CONFIRM_NAV_MODAL_OPEN),
  setConfirmNavModalClosed: createAction(types.SET_CONFIRM_NAV_MODAL_CLOSED),
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - -  [4] API CALLS  - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -

export const fetchFloorplan = floorplanID => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.svgGetRequest());
  try {
    const { data } = await api.floorplan.getFloorplan(floorplanID);
    return dispatch(actions.svgGetResponse(data));
  } catch (err) {
    return dispatch(actions.svgGetResponse(err));
  }
};

export const createFloorplan = (floorplanData, buildingID) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.svgPostRequest());
  try {
    await api.floorplan.postFloorplan(floorplanData);
    const { data } = await api.floorplan.getBuildingFloorplans(buildingID);
    return dispatch(actions.svgPostResponse(data));
  } catch (err) {
    return dispatch(actions.svgPostResponse(err));
  }
};

export const deleteFloorplan = floorplanID => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.svgDeleteRequest());
  try {
    const { data } = await api.floorplan.deleteFloorplan(floorplanID);
    return dispatch(actions.svgDeleteResponse(data));
  } catch (err) {
    return dispatch(actions.svgDeleteResponse(err));
  }
};
export const publishFloorplan = (floorplanID, isPublished) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.publishFloorplanRequest());
  try {
    const { data } = await api.floorplan.publishFloorplan(
      floorplanID,
      isPublished
    );
    return dispatch(actions.publishFloorplanResponse(data));
  } catch (err) {
    return dispatch(actions.publishFloorplanResponse(err));
  }
};

export const putFloorplanImage = (floorplanID, floorplanData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.svgPutImageRequest());
  try {
    const { data } = await api.floorplan.putFloorplanImage(
      floorplanID,
      floorplanData
    );
    return dispatch(actions.svgPutImageResponse(data));
  } catch (err) {
    return dispatch(actions.svgPutImageResponse(err));
  }
};

export const putFloorplanPolygon = (polygonData, floorplanID) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.svgPutPolygonRequest());
  try {
    await api.floorplan.putFloorplanPolygon(polygonData);
    const { data } = await api.floorplan.getFloorplan(floorplanID);
    return dispatch(actions.svgPutPolygonResponse(data));
  } catch (err) {
    return dispatch(actions.svgPutPolygonResponse(err));
  }
};

export const postPolygonEquipment = (
  polygonID,
  equipmentGroups,
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.polygonEquipmentPostRequest());
  try {
    let { data } = await api.floorplan.postPolygonEquipment(
      polygonID,
      equipmentGroups
    );
    return dispatch(actions.polygonEquipmentPostResponse(data));
  } catch (err) {
    return dispatch(actions.polygonEquipmentPostResponse(err));
  }
};

export const fetchFloorplanEquipmentGroups = bldgID => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.polygonEquipmentGetRequest());
  try {
    const { data } = await api.floorplan.getBuildingEquipmentGroups(bldgID);

    let responseData = [];
    if (data && data.length > 0) {
      responseData = data.map(equipGroup => ({ ...equipGroup, label: equipGroup.name, value: equipGroup.id }))
    }
    return dispatch(actions.polygonEquipmentGetResponse(responseData));
  } catch (err) {
    return dispatch(actions.polygonEquipmentGetResponse(err));
  }
};

export const deletePolygonEquipment = polyID => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.polygonEquipmentDeleteRequest());
  try {
    const { data } = await api.floorplan.deletePolygonEquipment(polyID);
    return dispatch(actions.polygonEquipmentDeleteResponse(data));
  } catch (err) {
    return dispatch(actions.polygonEquipmentDeleteResponse(err));
  }
};

export const fetchFloorplanData = (floorplanID, pointClassId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.polygonDataGetRequest());
  try {
    const { data } = await api.floorplan.getFloorplanData(
      floorplanID,
      pointClassId
    );
    return dispatch(actions.polygonDataGetResponse(data));
  } catch (err) {
    return dispatch(actions.polygonDataGetResponse(err));
  }
};

export const clearPolygonData = () => async (
  dispatch,
) => {
  dispatch(actions.clearPolygonData());
}

export const fetchBuildingFloorplans = (bldgID, onlyPublished) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.buildingFloorplansRequest());
  try {
    const { data } = await api.floorplan.getBuildingFloorplans(
      bldgID,
      onlyPublished
    );
    return dispatch(actions.buildingFloorplansResponse(data));
  } catch (err) {
    return dispatch(actions.buildingFloorplansResponse(err));
  }
};

export const fetchSiteFloorplans = siteId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.siteFloorplansRequest(siteId));
  try {
    const { data } = await api.floorplan.getSiteFloorplans(siteId);
    return dispatch(actions.siteFloorplansResponse(data));
  } catch (err) {
    return dispatch(actions.siteFloorplansResponse(err));
  }
};

export const clearFloorplan = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.svgClearRequest());
  try {
    return dispatch(actions.svgClearResponse());
  } catch (err) {
    return dispatch(actions.svgClearResponse(err));
  }
};

export const clearFloorplansList = () => async (dispatch, _getState) => {
  dispatch(actions.clearFloorplansList());
};

export const setFloorplanColorScaleType = (
  type,
  configId,
  pointClassId
) => async (dispatch, _getState) => {
  localStorage.setItem(
    `colorScaleType-${configId}-${pointClassId}`,
    JSON.stringify(type)
  );
  dispatch(actions.setFloorplanColorScaleType(type));
};
export const setFloorplanColorScaleValues = (
  minValue,
  maxValue,
  configId,
  pointClassId
) => async (dispatch, _getState) => {
  localStorage.setItem(
    `absoluteScaleMin-${configId}-${pointClassId}`,
    JSON.stringify(minValue)
  );
  localStorage.setItem(
    `absoluteScaleMax-${configId}-${pointClassId}`,
    JSON.stringify(maxValue)
  );
  let data = { min: minValue, max: maxValue };
  dispatch(actions.setFloorplanColorScaleValues(data));
};

export const setConfirmNavModalOpen = () => async (dispatch, _getState) => {
  dispatch(actions.setConfirmNavModalOpen());
};

export const setConfirmNavModalClosed = () => async (dispatch, _getState) => {
  dispatch(actions.setConfirmNavModalClosed());
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - - [6] ACTIONS - - - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -

export default handleActions(
  {
    [actions.svgClearRequest]: {
      next: state => ({
        ...state,
      }),
    },
    [actions.svgClearResponse]: {
      next: state => ({
        ...state,
        svg: {},
      }),

      throw: (state, { payload }) => ({
        ...state,
      }),
    },
    [actions.svgGetRequest]: {
      next: state => ({
        ...state,
        svgGetLoading: true,
      }),
    },
    [actions.svgGetResponse]: {
      next: (state, { payload }) => ({
        ...state,
        svg: payload,
        svgGetLoading: false,
        svgGetError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        svgGetError: payload.message,
        svgGetLoading: false,
      }),
    },

    [actions.svgPostRequest]: {
      next: state => ({
        ...state,
        svgPostLoading: true,
        floorplansLoading: true,
      }),
    },
    [actions.svgPostResponse]: {
      next: (state, { payload }) => ({
        ...state,
        floorplans: payload,
        svgPostError: null,
        svgPostLoading: false,
        floorplansLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        svgPostError: payload.response.data,
        svgPostLoading: false,
        floorplansLoading: false,
      }),
    },

    [actions.svgDeleteRequest]: {
      next: state => ({
        ...state,
        svgDeleteLoading: true,
        floorplansLoading: true,
      }),
    },

    [actions.svgDeleteResponse]: {
      next: (state, { payload }) => ({
        ...state,
        svgDeleteError: null,
        svgDeleteLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        svgDeleteError: payload.response.data,
        svgDeleteLoading: false,
      }),
    },

    [actions.svgPutImageRequest]: {
      next: state => ({
        ...state,
        svgImagePutLoading: true,
      }),
    },

    [actions.svgPutImageResponse]: {
      next: state => ({
        ...state,
        svgImagePutError: null,
        svgImagePutLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        svgImagePutError: payload.response.data,
        svgImagePutLoading: false,
      }),
    },

    [actions.svgPutPolygonRequest]: {
      next: state => ({
        ...state,
        svgPolygonPutLoading: true,
        svgGetLoading: true,
      }),
    },

    [actions.svgPutPolygonResponse]: {
      next: (state, { payload }) => ({
        ...state,
        svg: payload,
        svgPolygonPutError: null,
        svgPolygonPutLoading: false,
        svgGetLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        svgPolygonPutError: payload.response.data,
        svgPolygonPutLoading: false,
        svgGetLoading: false,
      }),
    },

    [actions.polygonEquipmentPostRequest]: {
      next: state => ({
        ...state,
        equipmentGroupsPostLoading: true,
      }),
    },

    [actions.polygonEquipmentPostResponse]: {
      next: (state, { payload }) => ({
        ...state,
        equipmentGroupsPostLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        equipmentGroupsPostError: payload.response.data,
        equipmentGroupsPostLoading: false,
      }),
    },

    [actions.polygonEquipmentGetRequest]: {
      next: state => ({
        ...state,
        equipmentGroupsGetLoading: true,
      }),
    },
    [actions.polygonEquipmentGetResponse]: {
      next: (state, { payload }) => ({
        ...state,
        equipmentGroups: payload,
        equipmentGroupsGetLoading: false,
        equipmentGroupsGeterror: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        equipmentGroupsGetError: payload.message,
        equipmentGroupsGetLoading: false,
      }),
    },

    [actions.polygonEquipmentDeleteRequest]: {
      next: state => ({
        ...state,
        equipmentGroupsDeleteLoading: true,
      }),
    },

    [actions.polygonEquipmentDeleteResponse]: {
      next: state => ({
        ...state,
        equipmentGroupsDeleteError: null,
        equipmentGroupsDeleteLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        equipmentGroupsDeleteError: payload.response.data,
        equipmentGroupsDeleteLoading: false,
      }),
    },

    [actions.polygonDataGetRequest]: {
      next: state => ({
        ...state,
        polygonDataLoading: true,
      }),
    },
    [actions.polygonDataGetResponse]: {
      next: (state, { payload }) => ({
        ...state,
        polygonData: payload,
        polygonDataLoading: false,
        polygonDataError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        polygonData: [],
        polygonDataError: payload.message,
        polygonDataLoading: false,
      }),
    },
    [actions.clearPolygonData]: {
      next: state => ({
        ...state,
        polygonDataLoading: false,
        polygonData: [],
      }),
    },
    [actions.publishFloorplanRequest]: {
      next: state => ({
        ...state,
        publishing: true,
      }),
    },
    [actions.publishFloorplanResponse]: {
      next: (state, { payload }) => ({
        ...state,
        publishing: false,
        publishingError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        publishingError: payload.message,
        publishing: false,
      }),
    },
    [actions.buildingFloorplansRequest]: {
      next: state => ({
        ...state,
        floorplansLoading: true,
      }),
    },
    [actions.buildingFloorplansResponse]: {
      next: (state, { payload }) => ({
        ...state,
        floorplans: payload,
        floorplansLoading: false,
        floorplansError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        floorplansError: payload.message,
        floorplansLoading: false,
      }),
    },
    [actions.siteFloorplansRequest]: {
      next: state => ({
        ...state,
        floorplansLoading: true,
      }),
    },
    [actions.siteFloorplansResponse]: {
      next: (state, { payload }) => ({
        ...state,
        floorplans: payload,
        floorplansLoading: false,
        floorplansError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        floorplansError: payload.message,
        floorplansLoading: false,
      }),
    },
    [actions.clearFloorplansList]: {
      next: state => ({
        ...state,
        floorplans: [],
      }),
    },
    [actions.setFloorplanColorScaleType]: {
      next: (state, { payload }) => ({
        ...state,
        colorScaleType: payload,
      }),
    },
    [actions.setFloorplanColorScaleValues]: {
      next: (state, { payload }) => ({
        ...state,
        absoluteScaleMin: payload.min,
        absoluteScaleMax: payload.max,
      }),
    },
    [actions.setConfirmNavModalOpen]: {
      next: (state, { payload }) => ({
        ...state,
        navModalOpen: true,
      }),
    },
    [actions.setConfirmNavModalClosed]: {
      next: (state, { payload }) => ({
        ...state,
        navModalOpen: false,
      }),
    },
  },
  initialState
);
