//---- Merge second array of objects into first AND overwrite ----------
export const mergeArrays = (originalArray, newArray, primaryKey = 'id') => {
  if (!Array.isArray(originalArray)) {
    originalArray = [];
  } // pre-map ids for speed
  const originalIds = originalArray.map(d => d[primaryKey]); // pre-map ids for speed

  const newArrayLength = newArray.length;
  for (let i = 0; i < newArrayLength; i++) {
    let idx = originalIds.indexOf(newArray[i][primaryKey]);
    if (idx > -1) {
      originalArray[idx] = newArray[i];
    } else {
      originalArray.push(newArray[i]);
    }
  }
  return originalArray;
};

//---- This focuses on merging Maintenance Planning Tasks ----
export const mergeMaintPlanningAssets = (originalArray, newArray, costInfo) => {
  let out = { assets: undefined, calculated: {}, errors: undefined };

  //--- Ensure array format & pre-map Ids for speed ---
  if (!Array.isArray(originalArray)) {
    originalArray = [];
  }
  const originalIds = originalArray.map(d => d.id);

  const newArrayLength = newArray.length;
  for (let i = 0; i < newArrayLength; i++) {
    let idx = originalIds.indexOf(newArray[i].assetId);
    if (idx > -1) {
      originalArray[idx] = newArray[i];
    }
  }
  out.assets = originalArray;
  return out;
};

/**
 * sorts an array of objects by key
 * 
 * @param {Array} array array to be sorted
 * @param {string} key object key to sort by 
 * @returns alphabetically sorted array by a key
 */
export const alphaSortObjectArray = (array, key) => {
  if(!array || array.length === 0 ) {
    return [];
  }

  if (!key) {
    throw new Error('A sort key is required');
  }

  array.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });

  return array;
};
