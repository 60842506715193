import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import cookie from 'react-cookies';
var launchConfig = null;
export const types = {
  LOGOUT: 'LOGOUT',
  LOGIN_REQUEST: 'app/auth/LOGIN_REQUEST',
  LOGIN_RESPONSE: 'app/auth/LOGIN_RESPONSE',
  LOGIN_TOKEN_REQUEST: 'app/auth/LOGIN_TOKEN_REQUEST',
  SELECT_SITE: 'app/buildings/SELECT_SITE',
  SELECT_ALIAS_SET: 'app/auth/SELECT_ALIAS_SET',
  CLEAR_ALIAS_SET: 'app/auth/CLEAR_ALIAS_SET',
  SITE_CONFIG_REQUEST: 'app/auth/SITE_CONFIG_REQUEST',
  SITE_CONFIG_RESPONSE: 'app/auth/SITE_CONFIG_RESPONSE',
  SET_PUBLIC_TOKEN: 'app/auth/SET_PUBLIC_TOKEN',
  AUTH0_TOKEN_RESPONSE: 'app/auth/AUTH0_TOKEN_RESPONSE',
  PARTNER_CONFIG_REQUEST: 'app/auth/PARTNER_CONFIG_REQUEST',
  PARTNER_CONFIG_RESPONSE: 'app/auth/PARTNER_CONFIG_RESPONSE',
  USER_CONFIGS_REQUEST: 'app/auth/USER_CONFIGS_REQUEST',
  USER_CONFIGS_RESPONSE: 'app/auth/USER_CONFIGS_RESPONSE',
  LAUNCH_CONFIG_REQUEST: 'app/auth/LAUNCH_CONFIG_REQUEST',
  LAUNCH_CONFIG_RESPONSE: 'app/auth/LAUNCH_CONFIG_RESPONSE',
  CLEAR_LAUNCH_CONFIG: 'app/auth/CLEAR_LAUNCH_CONFIG',
};

export const actions = {
  logout: createAction(types.LOGOUT),
  loginRequest: createAction(types.LOGIN_REQUEST),
  loginTokenRequest: createAction(types.LOGIN_TOKEN_REQUEST),
  loginResponse: createAction(types.LOGIN_RESPONSE),
  selectSite: createAction(types.SELECT_SITE),
  selectAliasSet: createAction(types.SELECT_ALIAS_SET),
  clearAliasSet: createAction(types.CLEAR_ALIAS_SET),
  siteConfigRequest: createAction(types.SITE_CONFIG_REQUEST),
  siteConfigResponse: createAction(types.SITE_CONFIG_RESPONSE),
  setPublicToken: createAction(types.SET_PUBLIC_TOKEN),
  auth0TokenResponse: createAction(types.AUTH0_TOKEN_RESPONSE),
  partnerConfigRequest: createAction(types.PARTNER_CONFIG_REQUEST),
  partnerConfigResponse: createAction(types.PARTNER_CONFIG_RESPONSE),
  siteConfigLaunchResponse: createAction(types.LAUNCH_CONFIG_RESPONSE),
  userConfigsRequest: createAction(types.USER_CONFIGS_REQUEST),
  userConfigsResponse: createAction(types.USER_CONFIGS_RESPONSE),
  clearLaunchConfig: createAction(types.CLEAR_LAUNCH_CONFIG),
};

// - - - LOGOUT - - -
// - - - LOGOUT - - -
// - - - LOGOUT - - -

export const logout = () => async dispatch => {
  dispatch(actions.logout());
};

export const login = (token = null) => async (dispatch, _getState, { api }) => {
  dispatch(actions.loginRequest());

  // nuke filter prefs
  Object.keys(localStorage).forEach(_d => {
    if (_d !== 'state') localStorage.removeItem(_d);
  });

  // set the login token if provided
  if (token) {
    dispatch(actions.setPublicToken(token));
  }

  try {
    //-- get login data from the server --
    const { data } = await api.auth.login();

    return dispatch(actions.loginResponse(data));
  } catch (err) {
    return dispatch(actions.loginResponse(err));
  }
};

// - - - SET SITE ID - - -
// - - - SET SITE ID - - -
// - - - SET SITE ID - - -
export const selectSite = (siteId, configId = null) => async (
  dispatch,
  _getState,
  { api }
) => {
  localStorage.removeItem('selectedSites');
  cookie.save('selectedSite', siteId, { path: '/' });
  cookie.save('selectedConfig', configId, { path: '/' });
  return dispatch(
    actions.selectSite({
      siteId,
      configId,
    })
  );
};

// - - - SET ALIAS SET ID - - -
// - - - SET ALIAS SET ID - - -
// - - - SET ALIAS SET ID - - -
export const selectAliasSet = (aliasSetId, configId) => async (
  dispatch,
  _getState,
  { api }
) => {
  localStorage.removeItem('selectedSites');
  cookie.save(`selectedAliasSet-${configId}`, aliasSetId, { path: '/' });
  return dispatch(
    actions.selectAliasSet({
      aliasSetId,
    })
  );
};

export const clearAliasSet = configId => async (
  dispatch,
  _getState,
  { api }
) => {
  return dispatch(actions.clearAliasSet());
};

// - - - FETCH SITE CONFIG - - -
// - - - FETCH SITE CONFIG - - -
// - - - FETCH SITE CONFIG - - -

export const fetchSiteConfig = (configId, aliasId = null) => async (
  dispatch,
  _getState,
  { api }
) => {
  try {
    const { data } = await api.buildings.siteConfig(configId, aliasId);
    return Promise.resolve(dispatch(actions.siteConfigResponse(data)));
  } catch (err) {
    return Promise.reject(dispatch(actions.siteConfigResponse(err)));
  }
};

export const setAuth0Token = token => (dispatch, _getState, { api }) => {
  return dispatch(actions.auth0TokenResponse(token));
};

export const fetchPartnerConfig = () => async (
  dispatch,
  _getState,
  { api }
) => {
  // leave default if localhost
  if (window.location.hostname === 'localhost') {
    return;
  }

  dispatch(actions.partnerConfigRequest());
  try {
    const { data } = await api.auth.partnerConfig();
    return dispatch(actions.partnerConfigResponse(data));
  } catch (err) {
    return dispatch(actions.partnerConfigResponse(err));
  }
};
export const fetchAndLaunchSiteConfig = configId => async (
  dispatch,
  _getState,
  { api }
) => {
  try {
    launchConfig = configId;
    const { data } = await api.buildings.siteConfig(configId);
    return Promise.resolve(dispatch(actions.siteConfigLaunchResponse(data)));
  } catch (err) {
    return Promise.reject(dispatch(actions.siteConfigLaunchResponse(err)));
  }
};
export const fetchConfigsForUser = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.userConfigsRequest());
  try {
    const { data } = await api.buildings.siteConfigs();
    return Promise.resolve(dispatch(actions.userConfigsResponse(data)));
  } catch (err) {
    return Promise.reject(dispatch(actions.userConfigsResponse(err)));
  }
};

export const clearLaunchConfig = () => (dispatch, _getState, { api }) => {
  return dispatch(actions.clearLaunchConfig(launchConfig));
};

const defaultState = {
  siteId: null,
  isRefreshing: false,
  grabbingToken: false,
  aliasSetId: null,
  auth0Token: null,
  failed: false,
  disableLogin: false,
  publicToken: null,
  partnerConfigLoading: true,
  partnerConfig: {
    id: process.env.REACT_APP_PARTNER_CONFIG_ID ?? 'edo',
    name: 'Edo',
    product: 'Torrens',
    primaryColor: '#3D2F90',
    supportEmail: 'support@edoenergy.com',
  },
};

export default handleActions(
  {
    [actions.logout]: {
      next: (_state, { payload }) => ({
        ..._state,
        isRefreshing: false,
        publicToken: null,
        disableLogin: false,
        failed: false,
      }),
    },

    [actions.loginRequest]: {
      next: (state, { payload }) => {
        return {
          ...state,
          message: null,
          grabbingToken: true,
        };
      },
    },

    [actions.loginTokenRequest]: {
      next: (state, { payload }) => {
        return {
          ...state,
          message: null,
          grabbingToken: true,
          disableLogin: true,
        };
      },
    },

    [actions.loginResponse]: {
      next: (state, { payload }) => {
        let siteId = cookie.load('selectedSite');
        let configId = cookie.load('selectedConfig');
        const passthroughRoute = sessionStorage.getItem('passthroughRoute');
        const searchQuery = passthroughRoute?.split('?')[1];
        const searchQueryParams = new URLSearchParams('?' + searchQuery);
        const passthroughConfigId = (searchQuery && searchQueryParams.has("configId")) ? parseInt(searchQueryParams.get("configId")) : null;
        const passthroughSiteId = payload.sites?.find(x => x.configId === passthroughConfigId)?.id;

        if (passthroughConfigId && passthroughSiteId) {
          siteId = passthroughSiteId;
          configId = passthroughConfigId;
        }

        return {
          ...state,
          siteId:
            siteId && payload.sites.find(_s => _s.id === parseInt(siteId, 10))
              ? parseInt(siteId, 10)
              : payload.sites[0].id,
          configId:
            configId &&
              payload.sites.find(_s => _s.configId === parseInt(configId, 10))
              ? parseInt(configId, 10)
              : payload.sites[0].configId,
          grabbingToken: false,
          ...payload,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        failed: true,
      }),
    },
    [actions.selectSite]: {
      next: (state, { payload }) => ({
        ...state,
        siteId: payload.siteId,
        configId: payload.configId,
      }),
    },

    [actions.selectAliasSet]: {
      next: (state, { payload }) => {
        return {
          ...state,
          aliasSetId: payload.aliasSetId,
        };
      },
    },

    [actions.clearAliasSet]: {
      next: state => ({
        ...state,
        aliasSetId: null,
      }),
    },

    [actions.siteConfigResponse]: {
      next: (_state, { payload }) => {
        var sites = _state.sites;
        var curIndex = sites.findIndex(
          _s => _s.configId === parseInt(payload.configId)
        );
        if (curIndex) {
          sites[curIndex] = {
            ...sites[curIndex],
            buildings: payload.buildings,
            settings: payload.settings,
            types: payload.types,
          };
          return {
            ..._state,
            sites: sites,
          };
        }
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
      }),
    },
    [actions.setPublicToken]: {
      next: (_state, { payload }) => ({
        ..._state,
        publicToken: payload,
        disableLogin: true,
      }),
    },

    [actions.partnerConfigRequest]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          partnerConfigLoading: true,
        };
      },
    },
    [actions.partnerConfigResponse]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          partnerConfig: payload,
          partnerConfigLoading: false,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        partnerConfigLoading: false,
      }),
    },
    [actions.siteConfigLaunchResponse]: {
      next: (_state, { payload }) => {
        var payloadConfig = payload;
        var sites = _state.sites;
        if (sites.map(x => x.configId).includes(payloadConfig.configId)) {
          //if already added, update with newest
          sites = sites.filter(x => x.configId !== payloadConfig.configId);
        }
        payloadConfig['id'] = payloadConfig.siteId;
        sites.push(payloadConfig);
        return {
          ..._state,
          sites: sites,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
      }),
    },
    [actions.userConfigsRequest]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          isRefreshing: true,
        };
      },
    },
    [actions.userConfigsResponse]: {
      next: (_state, { payload }) => {
        var _sites = payload;
        _sites.forEach(x => {
          x['id'] = x.siteId;
        });
        return {
          ..._state,
          sites: _sites,
          isRefreshing: false,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },
    [actions.clearLaunchConfig]: {
      next: (_state, { payload }) => {
        var sites = _state.sites;
        var filteredSites = sites.filter(x => x.configId !== launchConfig);
        launchConfig = null;
        return {
          ..._state,
          sites: filteredSites,
        };
      },
    },
  },
  defaultState
);

//--- LISTENER ON SITEID ----
export function sitesSelector(state) {
  return state.auth.sites || [];
}

export function currentConfigIdSelector(state) {
  if (state.auth && !state.auth.sites) {
    return null;
  }
  return state.auth.configId
    ? state.auth.configId
    : state.auth.sites[0].configId;
}

// createSelector info here: https://github.com/reduxjs/reselect
export const getCurrentSite = createSelector(
  sitesSelector,
  currentConfigIdSelector,
  (site, configId) => site.find(_d => _d.configId === parseInt(configId, 10))
);
