//Helper Functions
const getQueryStringList = (input, fieldName) => {
  let qString = '';
  for (let i = 0; i < input.length; i++) {
    qString += `&${fieldName}=${input[i]}`;
  }
  return qString;
};

//API calls
export class FloorplanService {
  constructor(api) {
    this.api = api;
  }

  async getFloorplan(floorplanID) {
    let url = `/floorplan/${floorplanID}`;
    return this.api.get(url);
  }

  async postFloorplan(data) {
    let url = `/floorplan`;
    return this.api.post(url, data);
  }

  async deleteFloorplan(floorplanID) {
    let url = `/floorplan/${floorplanID}`;
    return this.api.delete(url);
  }

  async putFloorplanImage(floorplanID, data) {
    let url = `/floorplan/${floorplanID}`;
    return this.api.put(url, data);
  }

  async putFloorplanPolygon(data) {
    let url = `/floorplan/polygon`;
    return this.api.put(url, data);
  }

  async postPolygonEquipment(polygonID, equipmentGroups) {
    let url = `/floorplan/equipment/`;
    let request = { equipmentGroups, polygonID };
    return this.api.post(url, request);
  }

  async getBuildingEquipmentGroups(bldgID) {
    let url = `/floorplan/equipment/${bldgID}`;
    return this.api.get(url);
  }

  async deletePolygonEquipment(polyID) {
    let url = `/floorplan/equipment/${polyID}`;
    return this.api.delete(url);
  }

  async getFloorplanData(floorplanID, pointClassId) {
    let url = `/floorplan/${floorplanID}/equipment/data?pointClassId=${pointClassId}`;
    return this.api.get(url);
  }

  async getBuildingFloorplans(bldgID, onlyPublished = false) {
    let url = `/floorplan/building/${bldgID}?onlyPublished=${onlyPublished}`;
    return this.api.get(url);
  }
  async getSiteFloorplans(siteId) {
    let url = `/floorplan/site/${siteId}`;
    return this.api.get(url);
  }

  async publishFloorplan(floorplanID, isPublished) {
    let url = `/floorplan/${floorplanID}/publish/${isPublished}`;
    return this.api.put(url);
  }
}
