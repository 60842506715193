import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';

const addedDiff = require('deep-object-diff').addedDiff;

//---- Deep Diff of Filters -----------------------------------------
export const checkFilters = (fActive, fArchive) => {
  //--- Remove task updates from diffing ---
  let fActiveCopy = JSON.parse(JSON.stringify(fActive));
  let fArchiveCopy = JSON.parse(JSON.stringify(fArchive));
  if (fActiveCopy?.updatedTasks) {
    delete fActiveCopy.updatedTasks;
  }
  if (fArchiveCopy?.updatedTasks) {
    delete fArchiveCopy.updatedTasks;
  }

  const activeMissingFromArchive = addedDiff(fArchiveCopy, fActiveCopy);
  const archiveMissingFromActive = addedDiff(fActiveCopy, fArchiveCopy);
  return {
    activeInArchive: Object.keys(activeMissingFromArchive).length === 0,
    activeSameAsArchive: Object.keys(archiveMissingFromActive).length === 0,
    activeMissingKeys: activeMissingFromArchive,
    archiveMissingKeys: archiveMissingFromActive,
  };
};

//---- Merge filters (to find union all) ----------------------------
export const mergeFilters = (fActive, fArchive) => {
  return mergeWith(fArchive, fActive, combineArrays);
};

//-- Algorithm used in merge --
const combineArrays = (objValue, srcValue) => {
  if (isArray(objValue)) {
    const fullArray = objValue.concat(srcValue);
    return [...new Set(fullArray)]; // return unique array members only
  }
};
