import { AuthService } from './services/AuthService';
import { BuildingsService } from './services/Buildings';
import { CapitalPlanningService } from './services/CapitalPlanning';
import { UtilityService } from './services/Utility';
import { SavingsService } from './services/Savings';
import { AnalyticsService } from './services/Analytics';
import { OpportunitiesService } from './services/Opportunities';
import { RenewablesService } from './services/Renewables';
import { KPIsService } from './services/KPIs';
import { ResourcesService } from './services/Resources';
import { PrevMaitenanceService } from './services/PrevMaintenance';
import { BudgetPlanningService } from './services/BudgetPlanning';
import { PowerBiEmbeddedService } from './services/PowerBiEmbedded';
import { LiveMapService } from './services/LiveMap';
import { AlertService } from './services/Alert';
import { PortfolioManagerService } from './services/PortfolioManager';

//--- Internal Python APIs -
import { AnalyticsDeployerService } from './services/AnalyticsDeployer';
import { MckGeneralService } from './services/MckGeneral';
import { ChartBuilderService } from './services/ChartBuilder';
import { SchedulerService } from './services/Scheduler';
import { ConfigurationService } from './services/Configuration';

import axios from 'axios';
import { tokenService } from '../utilities/tokenService';
import { FloorplanService } from './services/Floorplan';

class Api {
  constructor(hostname, pythonHostname, accessToken, refreshToken) {
    //this.debug = true;

    this.hostname = hostname;
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;

    this.http = axios.create({
      baseURL: `https://${hostname}`,
      //baseURL: 'https://reveal-api.mckinstry.com',
      //baseURL: 'https://localhost:5001',
    });

    this.pythonHttp = axios.create({
      baseURL: `https://${pythonHostname}`,
      //baseURL: `http://127.0.0.1:8000`,
      //
    });

    this.authHttp = axios.create({
      baseURL: `https://${hostname}`,
      //baseURL: 'https://reveal-api.mckinstry.com',
      //baseURL: 'https://localhost:5001',
    });

    this.serviceHttp = axios.create({
      baseURL: 'https://services.apis.edoenergy.com',
    });

    this.auth = new AuthService(this);
    this.buildings = new BuildingsService(this);
    this.capitalPlanning = new CapitalPlanningService(this);
    this.utility = new UtilityService(this);
    this.savings = new SavingsService(this);
    this.analytics = new AnalyticsService(this);
    this.opportunities = new OpportunitiesService(this);
    this.renewables = new RenewablesService(this);
    this.kpis = new KPIsService(this);
    this.resources = new ResourcesService(this);
    this.prevmaintenance = new PrevMaitenanceService(this);
    this.budgetplanning = new BudgetPlanningService(this);
    this.powerBiEmbedded = new PowerBiEmbeddedService(this);
    this.livemap = new LiveMapService(this);
    this.alert = new AlertService(this);
    this.floorplan = new FloorplanService(this);
    this.cancels = [];

    //-- internal --
    this.analyticsDeployer = new AnalyticsDeployerService(this);
    this.mckGeneral = new MckGeneralService(this);
    this.chartBuilder = new ChartBuilderService(this);
    this.scheduler = new SchedulerService(this);
    this.configuration = new ConfigurationService(this);
    this.portfolioManager = new PortfolioManagerService(this);

    //--- helper functions ---
    this.segmentTrack = this.segmentTrack.bind(this);

    //--- Add authorization
    this.http.interceptors.request.use(
      async config => {
        // get the access token if it's available
        // don't fail if it doesn't because we may be authenticating
        // through another means
        let token;
        try {
          token = await tokenService.getAccessTokenSilently()();
        } catch {
          return config;
        }
        // set authorization header with the bearer token
        config.headers['Authorization'] = `Bearer ${token.accessToken}`;

        return config;
      },
      error => {
        console.log(error);
        return Promise.reject(error);
      }
    );

    this.pythonHttp.interceptors.request.use(
      async config => {
        // get access token
        const token = await tokenService.getAccessTokenSilently()();
        // set authorization header with the bearer token
        config.headers['Authorization'] = `Bearer ${token.accessToken}`;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  //--- Track url calls in Segment ---
  segmentTrack(type, url) {
    if (
      process.env.REACT_APP_ENV !== 'development' &&
      process.env.NODE_ENV !== 'development' &&
      window.analytics
    ) {
      window.analytics.track('apiCall', {
        type: type,
        url: url,
        environment: process.env.REACT_APP_ENV,
      });
    }
  }

  //--- C# backend ----------------------------------------
  get(url = '', config = {}) {
    if (this.debug) console.log('GET: ' + url);
    if (!this.cancels[url]) this.cancels[url] = [];
    if (this.cancels[url].length) this.cancels[url][0].cancel();
    if (this.cancels[url].length > 4) this.cancels[url].pop();
    this.cancels[url].unshift(axios.CancelToken.source());
    this.segmentTrack('get', url);
    return this.http.get(url, {
      ...config,
      cancelToken: this.cancels[url][0].token,
    }).catch(error => {
      if (axios.isCancel(error)) {
        throw new Error("Request cancelled")
      } else {
        throw error;
      }
    });
  }

  post(url = '', data = {}, config = {}, preventCancel = false) {
    if (this.debug) console.log('POST: ' + url);
    if (!this.cancels[url]) this.cancels[url] = [];
    if (!preventCancel && this.cancels[url].length)
      this.cancels[url][0].cancel();
    if (this.cancels[url].length > 4) this.cancels[url].pop();
    this.cancels[url].unshift(axios.CancelToken.source());
    this.segmentTrack('post', url);
    return this.http.post(url, data, {
      ...config,
      cancelToken: this.cancels[url][0].token,
    }).catch(error => {
      if (axios.isCancel(error)) {
        throw new Error("Request cancelled")
      } else {
        throw error;
      }
    });
  }

  patch(url = '', data = {}, config = {}) {
    this.segmentTrack('patch', url);
    return this.http.patch(url, data, config);
  }

  put(url = '', data = {}, config = {}) {
    this.segmentTrack('put', url);
    return this.http.put(url, data, config);
  }

  delete(url = '', data = {}, config = {}) {
    this.segmentTrack('delete', url);
    return this.http.delete(url, data, config);
  }

  head(url = '', data = {}, config = {}) {
    this.segmentTrack('head', url);
    return this.http.head(url, data, config);
  }

  //--- Python backend ------------------------------------
  pyGet(url = '', config = {}) {
    if (this.debug) console.log('GET: ' + url);
    let cancelUrl = url.split('?')[0];
    if (!this.cancels[cancelUrl]) this.cancels[cancelUrl] = [];
    if (this.cancels[cancelUrl].length) this.cancels[cancelUrl][0].cancel();
    if (this.cancels[cancelUrl].length > 4) this.cancels[cancelUrl].pop();
    this.cancels[cancelUrl].unshift(axios.CancelToken.source());
    this.segmentTrack('get', cancelUrl);
    return this.pythonHttp.get(url, {
      ...config,
      cancelToken: this.cancels[cancelUrl][0].token,
    }).catch(error => {
      if (axios.isCancel(error)) {
        throw new Error("Request cancelled")
      } else {
        throw error;
      }
    });
  }

  pyPost(url = '', data = {}, config = {}, preventCancel = false) {
    if (this.debug) console.log('POST: ' + url);
    if (!this.cancels[url]) this.cancels[url] = [];
    if (!preventCancel && this.cancels[url].length)
      this.cancels[url][0].cancel();
    if (this.cancels[url].length > 4) this.cancels[url].pop();
    this.cancels[url].unshift(axios.CancelToken.source());
    this.segmentTrack('post', url);
    return this.pythonHttp.post(url, data, {
      ...config,
      cancelToken: this.cancels[url][0].token,
    }).catch(error => {
      if (axios.isCancel(error)) {
        throw new Error("Request cancelled")
      } else {
        throw error;
      }
    });
  }

  pyPatch(url = '', data = {}, config = {}) {
    return this.pythonHttp.patch(url, data, config);
  }

  pyPut(url = '', data = {}, config = {}) {
    return this.pythonHttp.put(url, data, config);
  }

  pyDelete(url = '', config = {}) {
    return this.pythonHttp.delete(url, config);
  }

  pyHead(url = '', config = {}) {
    return this.pythonHttp.head(url, config);
  }
}

export default Api;
