import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  tourIsActive: false,
  displayTour: false,
};

export const types = {
  TOUR_START_REQUEST: 'app/tour/TOUR_START_REQUEST',
  TOUR_CANCEL_REQUEST: 'app/tour/TOUR_CANCEL_REQUEST',
  DISPLAY_TOUR_REQUEST: 'app/tour/DISPLAY_TOUR_REQUEST',
};

export const actions = {
  tourStartRequest: createAction(types.TOUR_START_REQUEST),
  tourCancelRequest: createAction(types.TOUR_CANCEL_REQUEST),
  displayTourRequest: createAction(types.DISPLAY_TOUR_REQUEST),
};

// - - - TOUR START REQUEST - - -
// - - - TOUR START REQUEST - - -
// - - - TOUR START REQUEST - - -

export const tourStartRequest = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.tourStartRequest());
};

// - - - TOUR CANCEL REQUEST - - -
// - - - TOUR CANCEL REQUEST - - -
// - - - TOUR CANCEL REQUEST - - -

export const tourCancelRequest = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.tourCancelRequest());
};

// - - - DISPLAY TOUR REQUEST - - -
// - - - DISPLAY TOUR REQUEST - - -
// - - - DISPLAY TOUR REQUEST - - -

export const displayTourRequest = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.displayTourRequest());
};

// - - - ACTIONS - - -
// - - - ACTIONS - - -
// - - - ACTIONS - - -

export default handleActions(
  {
    [actions.tourStartRequest]: {
      next: state => ({
        ...state,
        tourIsActive: true,
      }),
    },
    [actions.tourCancelRequest]: {
      next: state => ({
        ...state,
        tourIsActive: false,
      }),
    },
    [actions.displayTourRequest]: {
      next: state => ({
        ...state,
        displayTour: true,
      }),
    },
  },

  defaultState
);
