import orderBy from 'lodash/orderBy';

import {
  sourceMap,
  getAnnualLaborMultiplier,
} from '../../../reducers/calculations/maintenancePlanning';

var Rainbow = require('rainbowvis.js');

const colors = {
  POSITIVE: '#088A42',
  NEUTRAL: '#D2D6D3',
  NEGATIVE: '#B91106',
};

//--- For the distributed assets chart on MP landing ---
export const getDistributionAssets = (_assets, updatedTasks) => {
  //--- [1] Define Output Format & Variables ---
  let _chartData = {
    labels: ['In House', 'Out Source', 'Run to Fail'],
    datasets: [],
    nodes: [],
  };

  const minAssetScore = 1.25;
  const maxAssetScore = 4.5;
  const _rainbow = new Rainbow();
  _rainbow.setSpectrum(colors.POSITIVE, colors.NEUTRAL, colors.NEGATIVE);
  _rainbow.setNumberRange(minAssetScore, maxAssetScore);

  let _data = [[], [], []];
  let _avgMin = 100001;
  let _avgMax = 0;

  let _maxLength = 0;
  let _totalHours = [0, 0, 0];

  //--- [2] Loop Over Assets ---
  const assetLength = _assets.length;
  for (let _a = 0; _a < assetLength; _a++) {
    let _asset = JSON.parse(JSON.stringify(_assets[_a])); //Object.assign({}, _assets[_a])

    //-- (2.1) calc average score for asset & assign color --
    const _avg = calcAvgMetricScore(_asset);

    if (_avg < _avgMin) _avgMin = _avg;
    if (_avg > _avgMax) _avgMax = _avg;
    _asset['avgScore'] = _avg;
    _asset['color'] = '#' + _rainbow.colorAt(_avg);

    //-- (2.2) calc task hours, and bin tasks by labor --
    let taskHours = [0, 0, 0];
    let parsedTasks = [[], [], []];

    const equipId = _asset.equipmentId;
    //let count = 0

    //-- loop over tasks --
    for (let _x = 0; _x < _asset.tasks.length; _x++) {
      // start w/ default info
      const task = _asset.tasks[_x];
      let bin = sourceMap[task.performedBy];

      const updatedTask =
        (updatedTasks[equipId] && updatedTasks[equipId][task.taskId]) ||
        undefined;

      if (updatedTask?.labor) {
        bin = sourceMap[updatedTask.labor];
      }
      const laborMultiplier = getAnnualLaborMultiplier(task.frequency) || 1;
      let maintenanceHours = task.hours * laborMultiplier;
      taskHours[bin] += maintenanceHours;
      _totalHours[bin] += maintenanceHours;
      parsedTasks[bin].push(task);
    }

    //-- (2.3) Assign Tasks to each Bin
    for (let i = 0; i < _data.length; i++) {
      if (_data[i].length > _maxLength) {
        _maxLength = _data[i].length;
      }

      let _assetNew = Object.assign({}, _asset);
      _assetNew['tasks'] = parsedTasks[i];
      _assetNew['hours'] = taskHours[i];
      _data[i].push(_assetNew);
    }
    //count += 1
  }

  //-- Sort Data (high -> low); data=[[...],[...],[...]] --

  for (let _b = 0; _b < _data.length; _b++) {
    _data[_b] = orderBy(_data[_b], ['avgScore'], ['desc']);
  }

  //-- Now create the Figure --
  let _sums = [0, 0, 0];
  for (let _a = 0; _a < _data.length; _a++) {
    for (let _x = 0; _x <= _maxLength; _x++) {
      // need to init the node, first loop will handle this
      if (!_chartData.datasets[_x]) {
        _chartData.datasets[_x] = {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
          label: [],
          hours: [],
          hoverBorderWidth: 0,
        };
      }

      const _b = _data[_a][_x];
      if (_b.hours > 0) {
        // push the data into the chart return
        _sums[_a] += _b.hours;
        //_chartData.nodes.push(_b);
        _chartData.datasets[_x].hours.push(_b.hours); // .hours gives stacked bar width
        _chartData.datasets[_x].data.push(_sums[_a]); // .data gives offset
        _chartData.datasets[_x].backgroundColor.push(_b.color);
        _chartData.datasets[_x].hoverBackgroundColor.push(_b.color);
        //_chartData.datasets[_x].label.push(_b.id);
      } else {
        // push nulls into the chart return to space everything evenly
        _chartData.datasets[_x].hours.push(null);
        _chartData.datasets[_x].data.push(null);
        _chartData.datasets[_x].backgroundColor.push('rgba(0,0,0,0)');
        _chartData.datasets[_x].hoverBackgroundColor.push('rgba(0,0,0,0)');
        _chartData.datasets[_x].label.push(null);
      }
    }
  }

  _chartData.totalHours = _totalHours;
  _chartData.avgHours = [minAssetScore, maxAssetScore];
  return _chartData;
};

//--- Bin data by EquipmentType ---
export const getTableAssets = _assets => {
  let _data = {};
  _assets.forEach((_a, _idx) => {
    //-- create equipment type key in object if none exists --
    if (!_data[_a.equipmentTypeId]) {
      _data[_a.equipmentTypeId] = {
        equipmentTypeId: _a.equipmentTypeId,
        id: _a.equipmentTypeId,
        equipmentType: _a.equipmentType,
        data: [],
      };
    }

    // add node to data
    let _node = JSON.parse(JSON.stringify(_a));
    _data[_a.equipmentTypeId].data.push(_node);
  });

  let _returnData = [];
  Object.keys(_data).forEach(_d => {
    _returnData.push(_data[_d]);
  });
  return _returnData;
};

const calcAvgMetricScore = _asset => {
  const _score = _asset.metricScores.reduce((total, score) => {
    return total + score.score;
  }, 0);
  return parseFloat((_score / _asset.metricScores.length).toFixed(1));
};

/**
 * gets a list of dates for the current year
 * @returns [Date]
 */
const getCalendarYearOfMonths = () => {
  return Array.apply(0, Array(12)).map((_, i) => {
    const year = new Date().getFullYear();
    return new Date(`${i + 1}/1/${year}`);
  });
};

/**
 * filters a list of assets down to those that require a given task
 * @param assets
 * @param task
 * @returns [{asset}]
 */
const assetsRequiringTask = (assets, task) => {
  return assets.filter(asset =>
    asset.tasks.map(task => task.taskId).includes(task.taskId)
  );
};

/**
 * gets an avg score for a task based on the assets that require the task
 * @param assets
 * @param task
 * @returns {number}
 */
const avgTaskScore = (assets, task) => {
  const totalScores = assetsRequiringTask(assets, task).map(
    asset => asset.totalScore
  );
  return totalScores.reduce((a, b) => a + b, 0) / totalScores.length;
};

/**
 * gets the high and low avg totalScore values for a set of assets
 * @param assets
 * @param taskTypes
 * @returns {[number, number]}
 */
const avgTaskTypeScoreRange = (assets, taskTypes) => {
  const avgScores = taskTypes.map(taskType => avgTaskScore(assets, taskType));
  return [Math.min(...avgScores), Math.max(...avgScores)];
};

/**
 * gets unique task types from a set of tasks
 * @param tasks
 * @returns [{taskId: number, description: string}]
 */
const getTaskTypes = tasks => {
  const map = new Map();

  return tasks
    .map(task => {
      if (!map.has(task.taskId)) {
        map.set(task.taskId, true);

        return {
          taskId: task.taskId,
          description: task.description,
        };
      }

      return undefined;
    })
    .filter(taskType => taskType !== undefined);
};

/**
 * indicates whether a given task needs to be done during the month of a given date
 * @param task
 * @param date
 * @returns {boolean}
 * if months array use that, else fall back to defaults based on interval for annual occurences
 */
const happensInMonth = (task, date) => {
  const monthNumber = date.getMonth() + 1;
  if (task.months) {
    return task.months.includes(monthNumber);
  } else {
    const annualOccurrences = task['annualOccurences'];
    const intervalMap = {
      12: 1,
      4: 3,
      2: 6,
      1: 12,
    };
    return monthNumber % intervalMap[annualOccurrences] === 0;
  }
};

/**
 * gets a rollup of hours required by type of task for a given set of tasks
 * @param date
 * @param tasks
 * @param taskId
 */
const monthlyHoursByTaskType = (date, tasks, taskId) => {
  return tasks
    .filter(task => task.taskId === taskId && happensInMonth(task, date))
    .reduce((hours, task) => {
      return hours + task.hours;
    }, 0);
};

/**
 * gets a Rainbow color manager instance
 * @param rangeStart
 * @param rangeEnd
 * @returns {Rainbow}
 */
const getRainbow = (rangeStart, rangeEnd) => {
  const rainbow = new Rainbow();
  rainbow.setSpectrum(colors.POSITIVE, colors.NEUTRAL, colors.NEGATIVE);
  rainbow.setNumberRange(rangeStart, rangeEnd);
  return rainbow;
};

/**
 * gets task types, sorted, including chartable attributes for color
 * @param assets
 * @param allAssetTasks
 * @param rainbow
 */
const chartTaskTypes = (assets, allAssetTasks, rainbow) => {
  return getTaskTypes(allAssetTasks)
    .map(taskType => {
      const score = avgTaskScore(assets, taskType);
      const color = `#${rainbow.colorAt(score)}`;

      return Object.assign(taskType, { color: color, avgScore: score });
    })
    .sort((a, b) => (a.avgScore < b.avgScore ? 1 : -1));
};

export const getLaborHoursByMonth = assets => {
  const allAssetTasks = assets.map(asset => asset.tasks).flat();
  const avgScoreRange = avgTaskTypeScoreRange(
    assets,
    getTaskTypes(allAssetTasks)
  );
  const rainbow = getRainbow(avgScoreRange[0], avgScoreRange[1]);
  const taskTypes = chartTaskTypes(assets, allAssetTasks, rainbow);
  // const chartDates = datesForwardOneYear();
  const chartDates = getCalendarYearOfMonths();
  const datasets = [],
    nodes = [];

  taskTypes.forEach(taskType => {
    let data = {
      data: [],
      label: taskType.description,
      backgroundColor: [],
      borderColor: [],
      borderWidth: 0,
      minBarThickness: 30,
      maxBarThickness: 30,
    };

    chartDates.forEach((date, i) => {
      const tally = monthlyHoursByTaskType(
        date,
        allAssetTasks,
        taskType.taskId
      );
      data.data.push(tally);
      nodes.push({
        date: date,
        dateIndex: i,
        taskId: taskType.taskId,
        taskDescription: taskType.description,
        taskHours: tally,
      });
    });

    data.backgroundColor.push(taskType.color);
    data.borderColor.push(taskType.color);

    datasets.push(data);
  });

  return {
    avgHours: avgScoreRange,
    nodes: nodes,
    data: {
      labels: chartDates,
      datasets: datasets,
    },
  };
};

/**
 * map month numbers to strings
 * @param months [1, 4, 6, 9]
 * @returns {array} months ['January', 'April', 'June', 'September']
 */

export const getMonthNames = months => {
  const monthStrings = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months.map(num => monthStrings[num - 1]);
};
