import { toast } from "react-toastify";

const options = { theme: "light" };

export default {
  success(msg) {
    return toast.success(msg || "Success", options);
  },

  error(err) {
    let msg = "An error has occurred";
    if (err && err.response && err.response.data) {
      if (err.response.data.errorMessage) {
        msg = err.response.data.errorMessage;
      }
      if (err.response.data.message) {
        msg = err.response.data.message;
      }
    }

    return toast.error(msg, options);
  }
};
