import { createAction, handleActions } from 'redux-actions';
import memoizeOne from 'memoize-one';

import { buildFullApptModel } from './utilities/schedulerUtilities';

const defaultState = {
  isRefreshing: false,
  isSiteListRefreshing: false,
  scheduleId: null,
  scheduleInfo: [], // filtered appointments (building, area, equipment) that renders UI;

  // subset of appointments
  sites: [], // all sites
  buildings: [], // all buildings for selected site

  isAreaRefreshing: false,
  areas: [], // all areas for selected site

  isEquipmentRefreshing: false,
  equipment: [], // all equipment for selected site
  appointments: [], // all appointments for selected site

  holidays: [], // all holidays for selected site
  inheritance: [], // all inheritance for selected site

  operatingModes: [], // modes of operation for Appointments
  operatingPriorities: [], // priorities for Appointments

  site: {},
  timezone: '',
};

const prefix = 'app/scheduler/';
export const types = {
  SCHEDULE_REQUEST: prefix + 'SCHEDULE_REQUEST',

  GET_SITES_AND_TIMEZONES_REQUEST: prefix + 'GET_SITES_AND_TIMEZONES_REQUEST',
  GET_SITES_AND_TIMEZONES_RESPONSE: prefix + 'GET_SITES_AND_TIMEZONES_RESPONSE',

  GET_AREAS_REQUEST: prefix + 'GET_AREAS_REQUEST',
  GET_AREAS_RESPONSE: prefix + 'GET_AREAS_RESPONSE',
  CLEAR_AREAS: prefix + 'CLEAR_AREAS',

  GET_EQUIPMENT_REQUEST: prefix + 'GET_EQUIPMENT_REQUEST',
  GET_EQUIPMENT_RESPONSE: prefix + 'GET_EQUIPMENT_RESPONSE',
  CLEAR_EQUIPMENT: prefix + 'CLEAR_EQUIPMENT',

  GET_OPERATING_MODES: prefix + 'GET_OPERATING_MODES',
  CLEAR_OPERATING_MODES: prefix + 'CLEAR_OPERATING_MODES',

  GET_OPERATING_PRIORITIES: prefix + 'GET_OPERATING_PRIORITIES',
  CLEAR_OPERATING_PRIORITIES: prefix + 'CLEAR_OPERATING_PRIORITIES',

  GET_SCHEDULE_RESPONSE: prefix + 'GET_SCHEDULE_RESPONSE',
  GET_ALL_APPOINTMENTS_RESPONSE: prefix + 'GET_ALL_APPOINTMENTS_RESPONSE',
  CLEAR_APPOINTMENTS: prefix + 'CLEAR_APPOINTMENTS',

  GET_ALL_HOLIDAY_RESPONSE: prefix + 'GET_ALL_HOLIDAY_RESPONSE',
  CLEAR_HOLIDAY: prefix + 'CLEAR_HOLIDAY',

  GET_ALL_INHERITANCE_RESPONSE: prefix + 'GET_ALL_INHERITANCE_RESPONSE',
  CLEAR_INHERITANCE: prefix + 'CLEAR_INHERITANCE',

  SET_TIMEZONE: prefix + 'SET_TIMEZONE',
  SET_SITE: prefix + 'SET_SITE',
};

export const actions = {
  scheduleRequest: createAction(types.SCHEDULE_REQUEST),
  //-- Responses --
  getSitesAndTimezonesRequest: createAction(
    types.GET_SITES_AND_TIMEZONES_REQUEST
  ),
  getSitesAndTimezonesResponse: createAction(
    types.GET_SITES_AND_TIMEZONES_RESPONSE
  ),

  getAreasRequest: createAction(types.GET_AREAS_REQUEST),
  getAreasResponse: createAction(types.GET_AREAS_RESPONSE),
  clearAreas: createAction(types.CLEAR_AREAS),

  getEquipmentRequest: createAction(types.GET_EQUIPMENT_REQUEST),
  getEquipmentResponse: createAction(types.GET_EQUIPMENT_RESPONSE),
  clearEquipment: createAction(types.CLEAR_EQUIPMENT),

  getScheduleResponse: createAction(types.GET_SCHEDULE_RESPONSE),

  getOperatingModes: createAction(types.GET_OPERATING_MODES),
  clearOperatingModes: createAction(types.CLEAR_OPERATING_MODES),

  getOperatingPriorities: createAction(types.GET_OPERATING_PRIORITIES),
  clearOperatingPriorities: createAction(types.CLEAR_OPERATING_PRIORITIES),

  getAllAppointmentsResponse: createAction(types.GET_ALL_APPOINTMENTS_RESPONSE),
  clearAppointments: createAction(types.CLEAR_APPOINTMENTS),

  getAllHolidayResponse: createAction(types.GET_ALL_HOLIDAY_RESPONSE),
  clearHoliday: createAction(types.CLEAR_HOLIDAY),

  getAllInheritanceResponse: createAction(types.GET_ALL_INHERITANCE_RESPONSE),
  clearInheritance: createAction(types.CLEAR_INHERITANCE),

  //-- TimeZones
  setTimezone: createAction(types.SET_TIMEZONE),
  setSite: createAction(types.SET_SITE),
};

// - - - GET SCHEDULE BY SITE, BUILDING, AREA, EQUIPMENT, CUSTOM - - -
const getSitesAndTimezones_raw = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.getSitesAndTimezonesRequest());
  try {
    const { data } = await api.scheduler.getSitesAndTimezones();
    return dispatch(actions.getSitesAndTimezonesResponse(data.data));
  } catch (err) {
    return dispatch(actions.getSitesAndTimezonesResponse(err));
  }
};
export const getSitesAndTimezones = memoizeOne(getSitesAndTimezones_raw);

// - - - GET OPERATING MODES - - -
const getOperatingModes_raw = () => async (dispatch, _getState, { api }) => {
  try {
    const { data } = await api.scheduler.getOperatingModes();
    return dispatch(actions.getOperatingModes(data.data));
  } catch (err) {
    return dispatch(actions.getOperatingModes(err));
  }
};
export const getOperatingModes = memoizeOne(getOperatingModes_raw);

// - - - GET OPERATING PRIORITIES - - -
const getOperatingPriorities_raw = () => async (
  dispatch,
  _getState,
  { api }
) => {
  try {
    const { data } = await api.scheduler.getOperatingPriorities();
    return dispatch(actions.getOperatingPriorities(data.data));
  } catch (err) {
    return dispatch(actions.getOperatingPriorities(err));
  }
};
export const getOperatingPriorities = memoizeOne(getOperatingPriorities_raw);

// - - - GET AREAS BY SITE - - -
const getAreasBySite_raw = siteID => async (dispatch, _getState, { api }) => {
  dispatch(actions.getAreasRequest());
  try {
    const { data } = await api.scheduler.getAreasBySite(siteID);
    const areas = data?.data?.sort(function(a, b) {
      var itemA = a.areaName.toLowerCase(),
        itemB = b.areaName.toLowerCase();
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      return 0;
    });
    return dispatch(actions.getAreasResponse(areas));
  } catch (err) {
    return dispatch(actions.getAreasResponse(err));
  }
};
export const getAreasBySite = memoizeOne(getAreasBySite_raw);

export const clearAreas = () => async dispatch => {
  dispatch(actions.clearAreas());
};

// - - - GET EQUIPMENT BY SITE - - -
const getEquipmentBySite_raw = siteID => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.getEquipmentRequest());
  try {
    const { data } = await api.scheduler.getEquipmentBySite(siteID);
    const equipments = data?.data?.sort(function(a, b) {
      var itemA = a.equipName.toLowerCase(),
        itemB = b.equipName.toLowerCase();
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      return 0;
    });
    return dispatch(actions.getEquipmentResponse(equipments));
  } catch (err) {
    return dispatch(actions.getEquipmentResponse(err));
  }
};
export const getEquipmentBySite = memoizeOne(getEquipmentBySite_raw);

export const clearEquipment = () => async dispatch => {
  dispatch(actions.clearEquipment());
};

//---------  APPOINTMENTS -----------------------
//---------  APPOINTMENTS -----------------------
//---------  APPOINTMENTS -----------------------
export const getAppointments = siteID => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scheduleRequest());

  try {
    const { data } = await api.scheduler.getAllAppointments(siteID);
    return dispatch(actions.getAllAppointmentsResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllAppointmentsResponse(err));
  }
};

export const addAppointment = (added, filters) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scheduleRequest());
  try {
    const {
      buildingID,
      equipmentID,
      areaID,
      siteId,
      ...updatedFilters
    } = filters;
    const added_FullModel = buildFullApptModel(added, updatedFilters);
    const { data } = await api.scheduler.createAppointment(added_FullModel);
    return dispatch(actions.getAllAppointmentsResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllAppointmentsResponse(err));
  }
};

export const addAndUpdateAppointment = (added, updated, filters) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scheduleRequest());
  try {
    //-- remove keys (ID is PK, needs to be unique) --
    delete added.ID;
    delete added.id;

    //-- add appointment (add non-recurrent ammendment to appointments) --
    const {
      buildingID,
      equipmentID,
      areaID,
      siteId,
      ...updatedFilters
    } = filters;
    const added_FullModel = buildFullApptModel(added, updatedFilters);
    const { _ } = await api.scheduler.createAppointment(added_FullModel);

    //-- update appointment (cancel recurrence for one appointment) --
    const { data } = await api.scheduler.updateAppointment(updated.ID, updated);

    return dispatch(actions.getAllAppointmentsResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllAppointmentsResponse(err));
  }
};

export const updateAppointment = updated => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scheduleRequest());
  try {
    const { data } = await api.scheduler.updateAppointment(updated.ID, updated);
    return dispatch(actions.getAllAppointmentsResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllAppointmentsResponse(err));
  }
};

export const deleteAppointment = (
  deletedID,
  siteID = null,
  customID = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.scheduleRequest());
  try {
    const { data } = await api.scheduler.deleteAppointment(
      deletedID,
      siteID,
      customID
    );
    return dispatch(actions.getAllAppointmentsResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllAppointmentsResponse(err));
  }
};

export const clearAppointments = () => async dispatch => {
  return dispatch(actions.clearAppointments());
};

//---------  HOLIDAYS -----------------------
//---------  HOLIDAYS -----------------------
//---------  HOLIDAYS -----------------------
export const getHolidays = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.scheduleRequest());

  try {
    const { data } = await api.scheduler.getAllHolidays();
    return dispatch(actions.getAllHolidayResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllHolidayResponse(err));
  }
};

export const addHoliday = (added, filters) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scheduleRequest());
  try {
    const added_FullModel = buildFullApptModel(added, filters);
    const { data } = await api.scheduler.createHoliday(added_FullModel);
    return dispatch(actions.getAllHolidayResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllHolidayResponse(err));
  }
};

export const addAndUpdateHoliday = (added, updated, filters) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scheduleRequest());
  try {
    //-- remove keys (ID is PK, needs to be unique) --
    delete added.ID;
    delete added.id;

    //-- add appointment (add non-recurrent ammendment to appointments) --
    const added_FullModel = buildFullApptModel(added, filters);
    const { _ } = await api.scheduler.createHoliday(added_FullModel);

    //-- update appointment (cancel recurrence for one appointment) --
    const { data } = await api.scheduler.updateHoliday(updated.ID, updated);

    return dispatch(actions.getAllHolidayResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllHolidayResponse(err));
  }
};

export const updateHoliday = updated => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scheduleRequest());
  try {
    const { data } = await api.scheduler.updateHoliday(updated.ID, updated);
    return dispatch(actions.getAllHolidayResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllHolidayResponse(err));
  }
};

export const deleteHoliday = (
  deletedID,
  siteID = null,
  customID = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.scheduleRequest());
  try {
    const { data } = await api.scheduler.deleteHoliday(
      deletedID,
      siteID,
      customID
    );
    return dispatch(actions.getAllHolidayResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllHolidayResponse(err));
  }
};

export const clearHoliday = () => async dispatch => {
  return dispatch(actions.clearHoliday());
};

//---------  INHERITANCE -----------------------
//---------  INHERITANCE -----------------------
//---------  INHERITANCE -----------------------
export const getInheritance = siteID => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scheduleRequest());

  try {
    const { data } = await api.scheduler.getAllInheritance(siteID);
    return dispatch(actions.getAllInheritanceResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllInheritanceResponse(err));
  }
};

export const addInheritance = payload => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scheduleRequest());
  try {
    const { data } = await api.scheduler.createInheritance(payload);
    return dispatch(actions.getAllInheritanceResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllInheritanceResponse(err));
  }
};

export const addAndUpdateInheritance = (added, updated, filters) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scheduleRequest());
  try {
    //-- remove keys (ID is PK, needs to be unique) --
    delete added.ID;
    delete added.id;

    //-- add appointment (add non-recurrent ammendment to appointments) --
    const added_FullModel = buildFullApptModel(added, filters);
    const { _ } = await api.scheduler.createInheritance(added_FullModel);

    //-- update appointment (cancel recurrence for one appointment) --
    const { data } = await api.scheduler.updateInheritance(updated.ID, updated);

    return dispatch(actions.getAllInheritanceResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllInheritanceResponse(err));
  }
};

export const updateInheritance = updated => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scheduleRequest());
  try {
    const { data } = await api.scheduler.updateInheritance(updated.ID, updated);
    return dispatch(actions.getAllInheritanceResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllInheritanceResponse(err));
  }
};

export const deleteInheritance = (
  deletedID,
  siteID = null,
  customID = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.scheduleRequest());
  try {
    const { data } = await api.scheduler.deleteInheritance(
      deletedID,
      siteID,
      customID
    );
    return dispatch(actions.getAllInheritanceResponse(data.data));
  } catch (err) {
    return dispatch(actions.getAllInheritanceResponse(err));
  }
};

export const clearInheritance = () => async dispatch => {
  return dispatch(actions.clearInheritance());
};

// - - - SET TIMEZONE - - -
export const setTimezone = data => async dispatch => {
  return dispatch(actions.setTimezone(data));
};

// - - - SET SITE - - -
export const setSite = data => async dispatch => {
  return dispatch(actions.setSite(data));
};

// - - - ACTIONS - - -
// - - - ACTIONS - - -
// - - - ACTIONS - - -
export default handleActions(
  {
    [actions.scheduleRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.getSitesAndTimezonesRequest]: {
      next: state => ({
        ...state,
        isSiteListRefreshing: true,
      }),
    },
    [actions.getSitesAndTimezonesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        sites: payload,
        isSiteListRefreshing: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        //  message: payload.message,
        isSiteListRefreshing: false,
      }),
    },

    [actions.getOperatingModes]: {
      next: (state, { payload }) => ({
        ...state,
        operatingModes: payload,
      }),
      throw: (state, { payload }) => ({
        ...state,
        //  message: payload.message,
      }),
    },

    [actions.getOperatingPriorities]: {
      next: (state, { payload }) => ({
        ...state,
        operatingPriorities: payload,
      }),
      throw: (state, { payload }) => ({
        ...state,
        //  message: payload.message,
      }),
    },

    [actions.getAreasRequest]: {
      next: state => ({
        ...state,
        isAreaRefreshing: true,
      }),
    },
    [actions.getAreasResponse]: {
      next: (state, { payload }) => ({
        ...state,
        areas: payload,
        isAreaRefreshing: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        //  message: payload.message,
        isAreaRefreshing: false,
      }),
    },
    [actions.clearAreas]: {
      next: state => ({
        ...state,
        areas: [],
      }),
    },

    [actions.getEquipmentRequest]: {
      next: state => ({
        ...state,
        isEquipmentRefreshing: true,
      }),
    },

    [actions.getEquipmentResponse]: {
      next: (state, { payload }) => ({
        ...state,
        equipment: payload,
        isEquipmentRefreshing: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        //  message: payload.message,
        isEquipmentRefreshing: false,
      }),
    },

    [actions.clearEquipment]: {
      next: state => ({
        ...state,
        equipment: [],
      }),
    },

    [actions.getAllAppointmentsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        appointments: payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        //  message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.clearAppointments]: {
      next: state => ({
        ...state,
        isRefreshing: false,
        appointments: [],
      }),
    },

    // HOLIDAYS
    [actions.getAllHolidayResponse]: {
      next: (state, { payload }) => ({
        ...state,
        holidays: payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        //  message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.clearHoliday]: {
      next: state => ({
        ...state,
        isRefreshing: false,
        holidays: [],
      }),
    },

    // INHERITANCE
    [actions.getAllInheritanceResponse]: {
      next: (state, { payload }) => ({
        ...state,
        inheritance: payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        //  message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.clearInheritance]: {
      next: state => ({
        ...state,
        isRefreshing: false,
        inheritance: [],
      }),
    },

    [actions.setTimezone]: {
      next: (state, { payload }) => ({
        ...state,
        timezone: payload,
      }),
    },

    [actions.setSite]: {
      next: (state, { payload }) => ({
        ...state,
        site: payload,
      }),
    },
  },
  defaultState
);
