const setLanguage = (state = { language: "en" }, action) => {
  switch (action.type) {
    case "LOCALIZATION.SET_ENGLISH":
      return { ...state, language: "en" };
    case "LOCALIZATION.SET_SPANISH":
      return { ...state, language: "es" };
    default:
      return state;
  }
};

export default setLanguage;
