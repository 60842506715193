import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  isRefreshing: false,
  lmMeters: [],
  lmValues: [],
};

const prefix = 'app/livemap/';
export const types = {
  LM_METERS_REQUEST: prefix + 'LM_METERS_REQUEST',
  LM_METERS_RESPONSE: prefix + 'LM_METERS_RESPONSE',

  LM_VALUES_REQUEST: prefix + 'LM_VALUES_REQUEST',
  LM_VALUES_RESPONSE: prefix + 'LM_VALUES_RESPONSE',
};

export const actions = {
  lmMetersRequest: createAction(types.LM_METERS_REQUEST),
  lmMetersResponse: createAction(types.LM_METERS_RESPONSE),

  lmValuesRequest: createAction(types.LM_VALUES_REQUEST),
  lmValuesResponse: createAction(types.LM_VALUES_RESPONSE),
};

// - - - FETCH LIVEMAP METERS - - -
// - - - FETCH LIVEMAP METERS - - -
// - - - FETCH LIVEMAP METERS - - -
export const fetchLiveMapMeters = (configId, buildingIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.lmMetersRequest());
  try {
    const { data } = await api.livemap.getLiveMapMeters(configId, buildingIds);
    return dispatch(actions.lmMetersResponse(data));
  } catch (err) {
    return dispatch(actions.lmMetersResponse(err));
  }
};
export const resetLiveMapMeters = () => async (
  dispatch,
  _getState,
  { api }
) => {
  return dispatch(actions.lmMetersResponse(defaultState.lmMeters));
};

// - - - FETCH LIVEMAP VALUES - - -
// - - - FETCH LIVEMAP VALUES - - -
// - - - FETCH LIVEMAP VALUES - - -
export const fetchLiveMapValues = (
  configId,
  buildingIds,
  equipClassID,
  primaryPointClassID,
  secondaryPointClassID
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.lmValuesRequest());
  try {
    const { data } = await api.livemap.getLiveMapValues(
      configId,
      buildingIds,
      equipClassID,
      primaryPointClassID,
      secondaryPointClassID
    );
    return dispatch(actions.lmValuesResponse(data));
  } catch (err) {
    return dispatch(actions.lmValuesResponse(err));
  }
};
export const resetLiveMapValues = () => async (
  dispatch,
  _getState,
  { api }
) => {
  return dispatch(actions.lmValuesResponse(defaultState.lmValues));
};

// - - - ACTIONS - - -
// - - - ACTIONS - - -
// - - - ACTIONS - - -
export default handleActions(
  {
    [actions.lmMetersRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.lmMetersResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        lmMeters: payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.lmValuesRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.lmValuesResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        lmValues: payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },
  },
  defaultState
);
