import { createAction, handleActions } from 'redux-actions';
var moment = require('moment');

var config = JSON.parse(localStorage.getItem('state'))
  ? JSON.parse(localStorage.getItem('state')).auth.configId
  : '';

const defaultState = {
  isRefreshing: false,
  selected_sites: JSON.parse(localStorage.getItem(`selectedSites-${config}`))
    ? JSON.parse(localStorage.getItem(`selectedSites-${config}`))
    : [],
  selected_site_types: [],
  beginDate: localStorage.getItem('dates')
    ? moment(new Date(JSON.parse(localStorage.getItem('dates'))[0]))
    : moment()
        .subtract(1, 'y')
        .startOf('month'),
  endDate: localStorage.getItem('dates')
    ? moment(new Date(JSON.parse(localStorage.getItem('dates'))[1]))
    : moment()
        .subtract(1, 'month')
        .endOf('month'),
  groupTypeFilters: JSON.parse(localStorage.getItem('siteSelectorGrouping'))
    ? JSON.parse(localStorage.getItem('siteSelectorGrouping'))
    : [],
};

export const types = {
  SELECT_SITE: 'app/filter/SELECT_SITE',
  SELECT_SITES: 'app/filter/SELECT_SITES',
  SELECT_SITE_TYPE: 'app/filter/SELECT_SITE_TYPE',
  SELECT_SITE_CLEAR_ALL: 'app/filter/SELECT_SITE_CLEAR_ALL',
  SELECT_DATE_RANGE: 'app/filter/SELECT_DATE_RANGE',
  SELECT_METRIC: 'app/filter/SELECT_METRIC',

  SELECT_GROUPING: 'app/filter/SELECT_GROUPING',
};

export const actions = {
  selectSite: createAction(types.SELECT_SITE),
  selectSites: createAction(types.SELECT_SITES),
  selectSiteType: createAction(types.SELECT_SITE_TYPE),
  selectSiteClearAll: createAction(types.SELECT_SITE_CLEAR_ALL),
  selectDateRange: createAction(types.SELECT_DATE_RANGE),
  selectMetric: createAction(types.SELECT_METRIC),

  selectGrouping: createAction(types.SELECT_GROUPING),
};

export const selectSite = _siteId => async (dispatch, _getState) => {
  dispatch(actions.selectSite(_siteId));
};

export const selectSites = (_siteIds, configId) => async (
  dispatch,
  _getState
) => {
  localStorage.setItem(`selectedSites-${configId}`, JSON.stringify(_siteIds));
  dispatch(actions.selectSites(_siteIds));
};

export const selectMetric = _metricId => async (dispatch, _getState) => {
  dispatch(actions.selectMetric(_metricId));
};

export const selectSiteType = (_typeId, _buildings) => async (
  dispatch,
  _getState
) => {
  dispatch(actions.selectSiteType({ typeId: _typeId, buildings: _buildings }));
};

export const selectSiteClearAll = () => async (dispatch, _getState) => {
  dispatch(actions.selectSiteClearAll());
};

export const selectDateRange = (beginDate, endDate) => async (
  dispatch,
  _getState
) => {
  dispatch(actions.selectDateRange({ beginDate, endDate }));
};

export const selectGrouping = _grouping => async (dispatch, _getState) => {
  localStorage.setItem('siteSelectorGrouping', JSON.stringify(_grouping));
  dispatch(actions.selectGrouping(_grouping));
};

export default handleActions(
  {
    [actions.selectSite]: {
      next: (state, { payload }) => {
        let _selected = [];
        if (state.selected_sites.includes(payload)) {
          // # # # ALREADY EXISTS, LETS REMOVE IT # # #
          _selected = state.selected_sites.filter(_site => _site !== payload);
        } else {
          // # # # NOT IN THERE, LETS ADD IT # # #
          _selected = [...state.selected_sites, payload];
        }
        return {
          ...state,
          selected_sites: _selected,
        };
      },
    },

    [actions.selectSites]: {
      next: (state, { payload }) => {
        return {
          ...state,
          selected_sites: payload,
        };
      },
    },

    [actions.selectSiteType]: {
      next: (state, { payload }) => {
        let _selected = [];
        let _selected_sites = state.selected_sites;
        if (state.selected_site_types.includes(payload.typeId)) {
          // # # # ALREADY EXISTS, LETS REMOVE IT # # #
          _selected = state.selected_site_types.filter(
            _site => _site !== payload.typeId
          );
          // # # # LETS REMOVE THIS TYPES SELECTED SITES # # #
          payload.buildings.map(_site => {
            if (_selected_sites.includes(_site)) {
              return (_selected_sites = _selected_sites.filter(
                _building => _building !== _site
              ));
            } else {
              return _selected_sites;
            }
          });
        } else {
          // # # # NOT IN THERE, LETS ADD IT # # #
          _selected = [...state.selected_site_types, payload.typeId];
          // # # # LETS ADD THESE TO SELECTED SITES # # #
          payload.buildings.map(_site => {
            if (_selected_sites.includes(_site)) {
              return (_selected_sites = _selected_sites.filter(
                _building => _building !== _site
              ));
            } else {
              return _selected_sites;
            }
          });
          _selected_sites = _selected_sites.concat(payload.buildings);
        }

        return {
          ...state,
          selected_sites: _selected_sites,
          selected_site_types: _selected,
        };
      },
    },

    [actions.selectSiteClearAll]: {
      next: (state, { payload }) => {
        return {
          ...state,
          selected_sites: [],
          selected_site_types: [],
        };
      },
    },

    [actions.selectDateRange]: {
      next: (state, { payload }) => {
        return {
          ...state,
          beginDate: payload.beginDate,
          endDate: payload.endDate,
        };
      },
    },

    [actions.selectMetric]: {
      next: (state, { payload }) => {
        return {
          ...state,
          metricId: payload,
        };
      },
    },
    [actions.selectGrouping]: {
      next: (state, { payload }) => {
        return {
          ...state,
          groupTypeFilters: payload,
        };
      },
    },
  },
  defaultState
);
