import { createAction, handleActions } from 'redux-actions';

import cookie from 'react-cookies';

const getCookie_ShowInternalPages = () => {
  if (cookie.load('showInternalPages') === 'false') return false;
  return true;
};

let defaultState = {
  showInternalPages: getCookie_ShowInternalPages(),
};

const prefix = 'app/ui/';
export const types = {
  SHOW_INTERNAL_PAGES: prefix + 'SHOW_INTERNAL_PAGES',
};

export const actions = {
  showInternalPages: createAction(types.SHOW_INTERNAL_PAGES),
};

// - - - BUSINESS LOGIC  - - -
export const setShowInternalPages = showInternalPages => async (
  dispatch,
  _getState,
  { api }
) => {
  cookie.save('showInternalPages', showInternalPages, { path: '/' });
  dispatch(actions.showInternalPages(showInternalPages));
};

// - - - ACTIONS - - -
export default handleActions(
  {
    [actions.showInternalPages]: {
      next: (state, { payload }) => ({
        ...state,
        showInternalPages: payload,
      }),
    },
  },
  defaultState
);
