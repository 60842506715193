import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware, push } from 'connected-react-router';
import createRootReducer from './reducers';
import { googleAnalytics } from './store/reactGAMiddlewares';
import { segementAnalytics } from './store/segmentMiddleware';
import Api from './api/Api';
import { loadState, saveState } from './store/localStorage';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'remote-redux-devtools';
import { debounce } from 'lodash';
import logger from 'redux-logger';

export const history = createBrowserHistory();

export default function configureStore() {
  const persistedState = loadState();
  const hostname = process.env.REACT_APP_API_URL ?? 'localhost:44348';
  const pythonHostname =
    process.env.REACT_APP_PYTHON_API_URL ?? 'p-develop.apis.edoenergy.com';

  let accessToken;
  let refreshToken;

  if (persistedState && persistedState.auth) {
    const { auth } = persistedState;
    const { access_token, refresh_token } = auth;

    accessToken = access_token;
    refreshToken = refresh_token;
  }

  const client = new Api(hostname, pythonHostname, accessToken, refreshToken);

  const preloadedState = {
    ...persistedState,
    // provider: {
    //   ...client
    // }
  };

  const composeEnhancer = !process.env.REACT_APP_REDUX_DEVTOOLS
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : composeWithDevTools({ port: 8000, suppressConnectErrors: true });

  const middleware = [
    thunk.withExtraArgument({ api: client }),
    routerMiddleware(history),
    googleAnalytics,
    segementAnalytics,
  ];

  //--- Show Redux Logger ---
  if (process.env.REACT_APP_REDUX_LOGGER) {
    middleware.push(logger);
  }

  const resetEnhancer = rootReducer => (state, action) => {
    if (action.type !== 'LOGOUT') return rootReducer(state, action);

    const newState = rootReducer(undefined, {});
    newState.router = state.router;
    return newState;
  };

  const store = createStore(
    resetEnhancer(createRootReducer(history)),
    preloadedState,
    composeEnhancer(applyMiddleware(...middleware))
  );

  client.http.interceptors.request.use(config => {
    // UPDATE TO USE CONFIG ID
    if (store.getState().auth.configId || store.getState().auth.sites) {
      const _configId = store.getState().auth.configId
        ? store.getState().auth.configId
        : store.getState().auth.sites[0].configId;

      const _site = store
        .getState()
        .auth.sites.find(_d => _d.configId === parseInt(_configId, 10));

      if (_site && _site.configId) {
        config.headers.RevealSiteConfig = _site.configId;
      }
    }

    let publicToken = store.getState().auth.publicToken;
    if (publicToken) {
      config.headers['Edo-PublicKey'] = publicToken;
    }
    // add partnerid when available
    let partnerid = store.getState().auth.partnerConfig.id;
    if (partnerid) {
      config.headers['Edo-PartnerId'] = partnerid;
    }

    return config;
  }, Promise.reject);

  client.pythonHttp.interceptors.request.use(config => {
    //add RevealSiteID to headers in python API
    if (store.getState().auth.siteId || store.getState().auth.sites) {
      const _configId = store.getState().auth.configId
        ? store.getState().auth.configId
        : store.getState().auth.sites[0].configId;

      const _site = store
        .getState()
        .auth.sites.find(_d => _d.configId === parseInt(_configId, 10));

      if (_site && _site.configId) {
        config.headers.RevealSite = _site.siteId;
      }
    }

    // add partnerid when available
    let partnerid = store.getState().auth.partnerConfig.id;
    if (partnerid) {
      config.headers['Edo-PartnerId'] = partnerid;
    }

    return config;
  }, Promise.reject);

  client.authHttp.interceptors.response.use(null, async error => {
    if (error.config && error.response && error.response.status === 401) {
      return Promise.reject(error);
    }
  });

  store.subscribe(() => {
    saveState({
      auth: store.getState().auth,
      navigation: store.getState().navigation,
      localization: store.getState().localization,
    });
  });

  store.subscribe(
    debounce(
      () => {
        let filters = store.getState().filters;
        if (filters) {
          let key = `dates`;

          localStorage.setItem(
            key,
            JSON.stringify([filters.beginDate, filters.endDate])
          );
        }
      },
      1000,
      { leading: true }
    )
  );

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
}
