import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { logout } from '../../reducers/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { bindActionCreators } from 'redux';

import { useMsal } from '@azure/msal-react';
import { msalConfig } from '../../components/Auth/authConfig';

const Logout = props => {
  const { instance } = useMsal();
  const revealSubDomains = ['reveal', 'reveal-staging', 'reveal-develop'];

  useEffect(() => {
    // run logout action to clean state
    // then execute msal logout redirect
    props.actions.logout().then(() =>
      instance.logoutRedirect({
        postLogoutRedirectUri: window.location.origin,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="fallback">
      {revealSubDomains.includes(window.location.host.split('.')[0]) && (
        <img
          src={'/images/reveal-white.svg'}
          alt="App Logo"
          className="logo-center"
        />
      )}
      <div className="loader-text">Logging Out...</div>
      <ReactLoading
        className="loader"
        height={25}
        type="spin"
        width={25}
      />{' '}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      logout,
    },
    dispatch
  ),
  dispatch,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Logout));
