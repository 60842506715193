export const NAVIGATION_CLOSE_NAV = 'NAVIGATION.CLOSE_NAV';
export const NAVIGATION_TOGGLE_NAV = 'NAVIGATION.TOGGLE_NAV';
export const NAVIGATION_TOGGLE_FILTER_NAV = 'NAVIGATION.TOGGLE_FILTER_NAV';

export const closeLeftNav = () => ({
  type: NAVIGATION_CLOSE_NAV,
});

export const toggleNav = () => ({
  type: NAVIGATION_TOGGLE_NAV,
});

export const toggleFilterNav = show => ({
  type: NAVIGATION_TOGGLE_FILTER_NAV,
  show,
});
