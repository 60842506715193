export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID, // This is the ONLY mandatory field; everything else is optional.
    authority: process.env.REACT_APP_B2C_AUTHORITY, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [process.env.REACT_APP_B2C_KNOW_AUTHORITIES], // You must identify your tenant's domain as a known authority.
    redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
};

export const apiConfig = {
  b2cScopes: [process.env.REACT_APP_B2C_SCOPES],
};

export const loginRequest = {
  scopes: ['openid', ...apiConfig.b2cScopes],
};
