import { roundToDigits } from '../../utilities/formatting';

let default_assetSummary = {
  annualTotalMaintenanceCost: 0,
  assetCount: 0,
  // assets: [,…]
  averageAssetLifeRemaining: 0,
  averageAssetScore: 0,
  baselineAnnualTotalMaintenanceCost: 0,
  baselineLifeCycleCapitalCosts: 0,
  lifeCycleCapitalCosts: 0,
  lifeCycleMaintenanceCost: 0,
  lifeCycleYears: 0,
  missingFTEsRequired: null,
  // frontend vars mirrored back ( we are doing this for another purpose now - to fill data based on
  //                              priorities - see getLaborInfo() )
  inHouseFteLaborRate: 50,
  inHouseFtes: 2,
  inHouseUtilization: 30,
  materialsMultiplier: 25,
  outSourcedLaborRate: 100,
  hours: {
    scenario: { inHouse: 0, outSource: 0, runToFail: 0 },
    baseline: { inHouse: 0, outSource: 0, runToFail: 0 },
  },
};

// Possibly make into a front end config later
const financialAnalysisPeriod = 30;

export const calc_assetSummary = (
  assets,
  filters,
  activeScenario,
  discountRate
) => {
  let out = { data: default_assetSummary, errors: undefined };

  //-- [1] prep loop vars --
  const remainingLifeParameter = filters.useObserved
    ? 'observedLifeRemaining'
    : 'industryLifeRemaining';

  const assetCount = assets.length;
  let totalScore = 0;
  let lifeRemaining = 0;

  //-- [2] run general calcs  --
  for (let i = 0; i < assetCount; i++) {
    totalScore += assets[i].totalScore;
    lifeRemaining += assets[i][remainingLifeParameter];
  }

  //-- [3] run scenario calcs --
  const options = activeScenario?.options;
  let scenarioCosts = getMaintenanceCosts(
    options,
    filters,
    assets,
    discountRate,
    remainingLifeParameter
  );

  //-- [4] aggregate results --
  const years = financialAnalysisPeriod;
  out.data.lifeCycleYears = years;
  out.data.annualTotalMaintenanceCost = scenarioCosts.scenario;
  out.data.baselineAnnualTotalMaintenanceCost = scenarioCosts.baseline;
  out.data.lifeCycleMaintenanceCost = scenarioCosts.scenario * years;
  out.data.baselineLifeCycleCapitalCosts =
    scenarioCosts.fullCapitalReplacementCost;
  out.data.lifeCycleCapitalCosts =
    scenarioCosts.fullCapitalReplacementCost - scenarioCosts.runToFailCost;
  out.data.missingFTEsRequired = getMissingFte(
    filters,
    scenarioCosts.hours.scenario
  );
  out.data.assetCount = assetCount;
  out.data.averageAssetScore = totalScore / assetCount;
  out.data.averageAssetLifeRemaining = ~~(lifeRemaining / assetCount + 0.5); // round
  out.data.hours = scenarioCosts.hours;

  //-- (4.1) Get Labor Rates --
  const laborRates = getLaborInfo(options, filters, default_assetSummary);

  out.data.inHouseFteLaborRate = laborRates.inHouseFteLaborRate;
  out.data.inHouseFtes = laborRates.inHouseFtes;
  out.data.inHouseUtilization = laborRates.inHouseUtilization;
  out.data.materialsMultiplier = laborRates.materialsMultiplier;
  out.data.outSourcedLaborRate = laborRates.outSourcedLaborRate;

  return out;
};

const getMaintenanceCosts = (
  options,
  filters,
  assets,
  discountRate,
  remainingLifeParameter
) => {
  //--- Get Maintenance Hours (both baseline and scenario) ---
  const maintenanceHours = getMaintenanceHours(
    options,
    filters?.updatedTasks,
    assets,
    discountRate,
    remainingLifeParameter
  );

  //--- Get Maintenance Costs ---
  const laborRates = getScenarioInfo(options, filters);
  const costBaseline = getAnnualCost(maintenanceHours.baseline, laborRates);
  const costScenario = getAnnualCost(maintenanceHours.scenario, laborRates);

  //--- Organize Data Output ---
  const out = {
    baseline: costBaseline,
    scenario: costScenario,
    runToFailCost: maintenanceHours.runToFailCost,
    fullCapitalReplacementCost: maintenanceHours.fullCapitalReplacementCost,
    hours: {
      baseline: maintenanceHours.baseline,
      scenario: maintenanceHours.scenario,
    },
  };

  return out;
};

/*
  Logical steps:
    (1) loop through the full list of tasks
      (1.1) fill the baseline hours tasks with the sum of each task
      (1.2) fill the scenario hours
        (1.2.1) fill the outSource hours
        (1.2.2) fill the runToFail hours
        (1.2.3) put the remainder in the inHouse hours
    (2) add the task hours to the total aggregate hours
    (3) use the 'run to fail fraction' of hours to estimate how much more 
        quickly the equipment will fail. 
        Fail more quickly (yrs) = ( (1-runToFail%) * runToFailImpact * remaining life )
        remainingLife (yrs) = originalRemaining * 
          ( 1 - ( ∑ [ (RTF PM Hours)_i * runToFailImpact_i ] / ∑ [Total PM Hours] ) )
*/
//--- LOOP OVER ALL ASSETS ---
const getMaintenanceHours = (
  options,
  updatedTasks,
  assets,
  discountRate,
  remainingLifeParameter
) => {
  let scenarioHours = { inHouse: 0, outSource: 0, runToFail: 0 };
  let baselineHours = { inHouse: 0, outSource: 0, runToFail: 0 };

  //--- Calc Task Ids once for speed ---
  const nAssets = assets.length;

  let runToFailCost = 0;
  let fullCapitalReplacementCost = 0;

  // loop over all assets
  for (let i = 0; i < nAssets; i++) {
    const asset = assets[i];

    // bin labor hours
    const laborHours = getHoursByLaborSource(
      asset.tasks,
      updatedTasks[asset.equipmentId] // either undefined or object w/ { taskId: {labor=0-2, months=[0,3,6,9]}, ...}
    );
    baselineHours = sumByKey(baselineHours, laborHours.base);
    scenarioHours = sumByKey(scenarioHours, laborHours.scenario);

    // get run to fail costs
    if (laborHours.runToFailFrac > 0) {
      runToFailCost += getRunToFailCost(
        asset,
        laborHours.runToFailFrac,
        discountRate,
        remainingLifeParameter
      );
    }

    // collect full asset replacement cost for financial analysis window (30yr default)
    if (asset[remainingLifeParameter] <= financialAnalysisPeriod) {
      fullCapitalReplacementCost += asset.estimatedReplacementCost;
    }
  }

  return {
    baseline: baselineHours,
    scenario: scenarioHours,
    runToFailCost: runToFailCost,
    fullCapitalReplacementCost: fullCapitalReplacementCost,
  };
};

//--- LOOP OVER ALL TASKS  (for one asset) ---
const getHoursByLaborSource = (tasks, scenarioTasks) => {
  let out = {
    base: { inHouse: 0, outSource: 0, runToFail: 0 },
    scenario: { inHouse: 0, outSource: 0, runToFail: 0 },
    runToFailFrac: 0,
  };
  const nTasks = tasks.length;
  let totalMaintHours = 0;
  let runToFailNumerator = 0;
  for (let i = 0; i < nTasks; i++) {
    // get number of times a task is completed over 1 year
    const task = tasks[i];
    const laborMultiplier = getAnnualLaborMultiplier(task.frequency) || 1;
    const totalHours = task.hours * laborMultiplier;
    totalMaintHours += totalHours;

    // add hours to baseline
    const baseKey = sourceMapToKey[sourceMap[task.performedBy]];
    out.base[baseKey] += totalHours;

    // add hours to scenario
    if (scenarioTasks === undefined) {
      out.scenario[baseKey] += totalHours; // no override for any tasks for this asset in scenario
    } else if (scenarioTasks[task.taskId] === undefined) {
      out.scenario[baseKey] += totalHours; // no override for asset/task combo in scenario
    } else {
      const labor = scenarioTasks[task.taskId].labor;
      out.scenario[sourceMapToKey[labor]] += totalHours;
      //--- if runToFail, add to runToFail fraction variable ---
      if (labor === 2) {
        runToFailNumerator += totalHours * task.runToFailImpact;
      }
    }
  }

  //-- Calc RunToFail Frac (f = sum( maintHours_RunToFail * runToFailImpact ) / maintHours_Total ) --
  out.runToFailFrac = runToFailNumerator / totalMaintHours;
  return out;
};

//============== HELPER FUNCTIONS ===================================
const getMissingFte = (filters, scenarioHours) => {
  const hoursFteAnnual = filters.inHouseUtilization * 52; // hours/week * # of hours worked per year
  const hoursAvailable = filters.inHouseFtes * hoursFteAnnual;
  const missingFTEs = (scenarioHours.inHouse - hoursAvailable) / hoursFteAnnual;
  return roundToDigits(missingFTEs, 2);
};

//-- Run To Fail Fraction --
const getRunToFailCost = (
  asset,
  rtfFraction,
  discountRate,
  remainingLifeParameter
) => {
  //-- (1) Find Years to Replace Asset --
  const yearsToReplacement_original = Math.max(
    0,
    asset[remainingLifeParameter]
  );
  const yearsToReplacement_rtf =
    (1 - rtfFraction) * yearsToReplacement_original;
  // eliminate rounding error issues for assets that are about to be replaced, or past due for replacement
  if (yearsToReplacement_rtf < 0.05) {
    return 0;
  }

  //-- (2) Find depreciated asset value (straight line) --
  const assetLifetime = Math.max(
    yearsToReplacement_original, // based on numerator below - we never want the asset to be >100% of original
    asset.industryReplacementYear - asset.installYear
  );
  const depreciatedValue =
    (asset.estimatedReplacementCost / assetLifetime) *
    yearsToReplacement_original;

  //-- (3) Based on Team Input --
  const annualCost_original = getEAC(
    depreciatedValue,
    discountRate,
    yearsToReplacement_original
  );
  const annualCost_rtf = getEAC(
    depreciatedValue,
    discountRate,
    yearsToReplacement_rtf
  );
  const capImpact = annualCost_rtf - annualCost_original;
  return ~~(capImpact + 0.5); // round to the nearest dollar
};

//-- Equivalent Annual Cost (EAC) --  https://www.investopedia.com/terms/e/eac.asp
const getEAC = (assetPrice, discountRate, years) => {
  if (discountRate < 0.01) {
    discountRate = 0.01;
  } // can't divide by zero
  const denominator = 1 - 1 / (1 + discountRate) ** years;
  const eac = (assetPrice * discountRate) / denominator;
  return eac;
};

//-- Annual Maintenance Cost --
const getAnnualCost = (hours, laborRates) => {
  const costInHouse =
    hours.inHouse *
    laborRates.inHouse.hourlyRate *
    (1 + laborRates.inHouse.materialsMultiplier / 100);
  const costOutSource = hours.outSource * laborRates.outSource.hourlyRate;
  return costInHouse + costOutSource;
};

const sourceMap = {
  0: 0, // .NET Core introduced an issue where sometimes it responds with enum int instead of string value
  'In-House': 0,
  'In House': 0, // quotation marks so the space is handled correctly
  1: 1,
  'Out-Source': 1,
  'Out Source': 1,
  2: 2,
  'Run-to-Fail': 2,
  'Run to Fail': 2,
  undefined: undefined,
};

const sourceMapToKey = ['inHouse', 'outSource', 'runToFail'];

const annualLaborMultiplier = {
  Monthly: 12,
  Quarterly: 4,
  SemiAnnually: 2,
  Annually: 1,
};

// this returns undefined if the key is missing; directly accessing an object with a missing key returns an error
const getAnnualLaborMultiplier = key => {
  return annualLaborMultiplier[key];
};

//--- Cleans missing data, and organizes to simplify algorithms ---
const getScenarioInfo = (options, filters) => {
  const laborRates = getLaborInfo(filters, options, default_assetSummary);
  return {
    inHouse: {
      hourlyRate: laborRates.inHouseFteLaborRate,
      materialsMultiplier: laborRates.materialsMultiplier,
    },
    outSource: {
      hourlyRate: laborRates.outSourcedLaborRate,
      materialsMultiplier: 0,
    },
    runToFail: {
      hourlyRate: 0,
      materialsMultiplier: 0,
    },
  };
};

//--- Sum keys ---
const sumByKey = (original, addition) => {
  const keys = ['inHouse', 'outSource', 'runToFail'];
  for (let k = 0; k < keys.length; k++) {
    original[keys[k]] += addition[keys[k]];
  }
  return original;
};

//--- Choose from Priority Order ---
const getLaborInfo = (first, second, third) => {
  if (first) {
    return first;
  }
  if (second) {
    return second;
  }
  return third;
};

export { sourceMap, getAnnualLaborMultiplier };
