import db from './utilities/dbConnectUtilities';

import { isCacheActive } from './utilities/timeUtilities';
import { getFilters, setFilters, checkFilterHash } from './dbFilters';
import { checkFilters } from './utilities/filterUtilities';

import { calc_assetSummary } from '../calculations/maintenancePlanning';

export const dbMP_getTasks = async (
  api,
  filters,
  expiration,
  activeScenario,
  discountRate
) => {
  let summaryData = undefined;
  let assets = [];
  const data = await callApi_MPTasks(api, filters);
  assets = data.tasks;
  db.mp_tasks
    .bulkPut(assets)
    .then(() => setFilters('mp_tasks', filters, 'full'));

  summaryData = calc_assetSummary(
    assets,
    filters,
    activeScenario,
    discountRate
  );

  const output = {
    ...summaryData.data,
    assets: assets,
  };
  return output;
};

//========= Interacting with the API ================================
const callApi_MPTasks = async (api, filters) => {
  const { data } = await api.prevmaintenance.assetTasks(
    filters.buildingIds,
    filters.equipmentTypeIds,
    filters.metricFilters,
    filters.useObserved,
    filters.assetScoreFilter,
    filters.inHouseFTEs,
    filters.inHouseUtilization,
    filters.inHouseFTELaborRate,
    filters.outSourcedLaborRate,
    filters.materialsMultiplier,
    filters.updatedTasks
  );
  return data;
};

//========= Interacting with IndexedDB ==============================
const getDB_MPTasksSubset = async filters => {
  //-- [1] return larger subset - can only use one index (!?) --
  let rawData = await db
    .table('mp_tasks')
    .where('buildingId')
    .anyOf(filters.buildingIds)
    .toArray();

  //-- [2] pare down this larger subset based on non-indexed filters --
  let data = [];
  let dataSize = rawData.length;

  // pre-prep metrics filters
  const filterMetricsIds = filters.metricFilters.map(d => d.metricId);
  const filterMetricsMin = filters.metricFilters.map(d => d.minValue);
  const filterMetricsMax = filters.metricFilters.map(d => d.maxValue);

  for (let i = 0; i < dataSize; i++) {
    //-- (2.1) Check EquipmentIDs --
    const check_equipTypeIds = filters.equipmentTypeIds.includes(
      rawData[i].equipmentTypeId
    );

    //-- (2.2) Check Metrics --
    let check_metrics = true;
    const assetMetrics = rawData[i].metricScores;
    for (let j = 0; j < assetMetrics.length; j++) {
      const idx = filterMetricsIds.indexOf(assetMetrics[j].metricId);
      if (idx > -1) {
        if (
          assetMetrics[j].score > filterMetricsMax[idx] ||
          assetMetrics[j].score < filterMetricsMin[idx]
        ) {
          check_metrics = false;
        }
      }
    }

    //-- (2.3) Compile results --
    if (check_equipTypeIds && check_metrics) data.push(rawData[i]);
  }

  //-- [3] update stored filters --
  setFilters('mp_tasks_filters', filters, 'hashOnly');

  return data;
};
