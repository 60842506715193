import Dexie from 'dexie';

//---- Base Parameters ----
const db_version = 1;
const db = new Dexie('edo-datastore');

//---- Configure Stores (each like a table) ----
db.version(db_version).stores({
  //-- Filters --
  filters: `name, cachedAt`,

  //-- Capital Planning --
  //cp_assets: '',
  //cp_assets_filters: '',

  //-- Maintenance Planning --
  mp_tasks_filters: `name, cachedAt`,
  mp_tasks: `id, buildingId, equipmentTypeId, equipmentSubsystem`,
});

export default db;
