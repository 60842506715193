import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import './styles/marketingMain.scss';

const PrivacyPolicy = () => {
  const [partnerVariables, setPartnerVariables] = useState(false);
  const [heading, setHeading] = useState('Loading...');

  useEffect(() => {
    fetch('https://services.apis.edoenergy.com/auth/partner/privacy-policy')
      .then(response => response.json())
      .then(j => {
        setPartnerVariables(j);
        setHeading('Privacy Policy');
      })
      .catch(() => setHeading('Loading Failed'));
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <div className="marketing-basic-text">
        <h1>{heading}</h1>
        {partnerVariables && (
          <>
            <p style={{ marginBottom: '45px' }}>Revised April 24, 2020</p>

            <p>
              {partnerVariables.legalName} (“{partnerVariables.name}” or “we” or
              “us”) is the owner and operator of the {partnerVariables.product}{' '}
              website (the “Site”).
            </p>

            <p>Our postal address is </p>

            <ul className="marketing-list-textonly">
              <li>{partnerVariables.legalName}</li>
              <li>{partnerVariables.address}</li>
              <li>
                {partnerVariables.city}, {partnerVariables.state}{' '}
                {partnerVariables.zip}{' '}
              </li>
            </ul>

            <p>
              We also can be reached via e-mail at {partnerVariables.legalEmail}{' '}
              or you can reach us by telephone at {partnerVariables.legalPhone}
              . 
            </p>

            <h3 className="marketing-textpage-header">
              INFORMATION COLLECTION AND USE
            </h3>

            <p>
              The information and data collected on this Site or related to this
              Site are the property of {partnerVariables.name}.  This site
              contains text, artwork, photos or other content that may be
              copyrighted by others and is being used with permission of the
              copyright holder. Therefore, we recommend that you contact us for
              permission to use any content contained on this site.
            </p>

            <p>
              For each visitor to the Site, our web server automatically
              recognizes the consumer’s domain name (where possible). 
            </p>

            <p>
              Personally identifiable information (“PII”) as defined in this
              Privacy Policy, means information about a natural person that is
              readily identifiable to that specific individual. Personal
              information includes such things as your name, address, email
              address, and phone number.
            </p>

            <p>
              We will not collect PII about you unless you voluntarily provide
              it to us by sending us email, or by providing it on the Site.  If
              you choose to provide PII by emailing us, participating in a
              survey, or completing an online form, we may store this
              information.  We may place a “cookie” on the browser of your
              computer. Cookies are pieces of information that a Site transfers
              to your computer’s hard drive for record-keeping purposes. The use
              of cookies is common on sites and many major sites use them to
              provide useful features to their visitors. The cookie itself does
              not contain any personally identifying information, but may be
              used to tell when your computer has contacted our Site. You may
              set your browser to reject cookies, but please understand that
              certain features of the Site may not be available if your browser
              does not accept cookies. We will not use or install spyware on
              your computer.
            </p>

            <p>
              We also may use cookies, clear gifs, and log file information to:
              (a) store information so that you will not have to re-enter it
              during your visit or the next time you visit the Site; (b) monitor
              aggregate metrics such as total number of visitors, pages viewed,
              etc.; and (c) track entries, submissions, and status in any
              interactive portion of the Site.
            </p>

            <p>
              When using the Site we may record information about your
              experience. For example, details of how you used the Site, what
              pages, charts or functions you utilized and how often, and
              information about crashes.
            </p>

            <p>
              We may use the information and data we collect as needed to
              enhance the Site, including customer experience, track user
              habits, or target personal preferences.   We may share this
              information with our service providers as needed to assist or
              improve the functioning of the Site.  We will not sell any
              personally identifiable information to third parties for
              commercial purposes unrelated to our operation of the Site.
            </p>

            <p>
              We may process the information and data we collect.{' '}
              {partnerVariables.name} reserves the right to retain, use,
              disclose or transfer any such data as long as such data is in an
              aggregate form that does not include any individually identifiable
              user data. {partnerVariables.name} will not identify you as the
              source of such aggregated data. This aggregated data may be used
              to further enhance the Site, to improve systems, and to provide a
              greater experience using our system and services.
            </p>

            <p>
              We may disclose information about you (i) if we are required to do
              so by law or legal process, (ii) to law enforcement authorities or
              other government officials, or (iii) when we believe disclosure is
              necessary or appropriate to prevent physical harm or financial
              loss or in connection with an investigation of suspected or actual
              illegal activity.
            </p>

            <p>
              We reserve the right to transfer any information we have about you
              in the event we sell or transfer all or a portion of our business
              or assets. Should such a sale or transfer occur, we will use
              reasonable efforts to direct the transferee to use personal
              information you have provided through this website in a manner
              that is consistent with this statement.
            </p>

            <p>
              If you do not want to receive e-mail from us in the future, please
              let us know by sending us e-mail at the above address. Please
              provide us with your exact name and address. You may also
              unsubscribe by selecting the unsubscribe link available on any{' '}
              {partnerVariables.product} generated email.
            </p>

            <p>
              If you supply us with your postal address you may receive periodic
              mailings from us with information on new products and services or
              upcoming events.   If you do not wish to receive such mailings,
              please let us know by emailing us at the address provided above.
              Please provide us with your exact name and address.
            </p>

            <p>
              Persons who supply us with their telephone numbers may receive
              contact from us with information regarding new products and
              services or upcoming events. If you do not wish to receive such
              information, please let us know by sending us e-mail at the above
              address. Please provide us with your exact name and address. 
            </p>

            <p>
              From time to time, we may use visitor information for new,
              unanticipated uses not previously disclosed in our privacy
              notice.  If our information practices change at some time in the
              future, we will post the policy changes to our website to notify
              you of these changes and provide you with the ability to opt out
              of these new uses.  If you are concerned about how your
              information is used, you should check back at our website
              periodically. 
            </p>

            <p>
              Visitors may prevent their information from being used for
              purposes other than those for which it was originally collected by
              emailing or calling us at the numbers provided above. 
            </p>

            <p>
              Upon request we provide site visitors with access to contact
              information (e.g., name, address, phone number) that we maintain
              about them and a description of information that we maintain about
              them. Consumers can access this information by e-mailing us at the
              above address. 
            </p>

            <p>
              Upon request we offer visitors the ability to have inaccuracies
              corrected in contact information.  Consumers can have this
              information corrected by sending us e-mail at the above address. 
            </p>

            <p>
              With respect to security: We always use industry-standard
              encryption technologies when transferring and receiving consumer
              data exchanged with our site. 
            </p>

            <p>
              The {partnerVariables.product} website contains links to other
              websites. These may include links to websites operated by other
              government agencies, nonprofit organizations and private
              businesses. When you link to another site, you are no longer on
              the {partnerVariables.product} site and this Privacy Notice will
              not apply. When you link to another website, you are subject to
              the privacy policy of that new site. Reference in this Site to any
              specific commercial products, processes, or services, or the use
              of any trade, firm, or corporation name is for the information and
              convenience of the public and does not constitute endorsement,
              recommendation, or favoring by
              {partnerVariables.name} or its officers, employees or agents.{' '}
              {partnerVariables.name} does not make any warranties, express or
              implied, regarding any third party information or any links to
              other websites, and {partnerVariables.name} assumes no
              responsibility for the accuracy, completeness, reliability or
              suitability of the information provided by third parties or
              information, software (if any), offers or activity found on other
              websites which may be linked to our Site.
            </p>

            <p>
              Personal information collected on the Site may be stored and
              processed in the United States or any other country in which{' '}
              {partnerVariables.name} or its affiliates, subsidiaries or agents
              maintain facilities, and by using the Site, you consent to any
              such transfer of information outside of your country.
            </p>

            <p>
                Although the data found using the Site access systems have been
              produced and processed from sources believed to be reliable, no
              warranty expressed or implied is made regarding accuracy,
              adequacy, completeness, legality, reliability or usefulness of any
              information. This disclaimer applies to both isolated and
              aggregate uses of the information.
              {partnerVariables.name} provides this information on an "AS IS"
              basis. All warranties of any kind, express or implied, including
              but not limited to the IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, freedom from contamination by
              computer viruses and non-infringement of proprietary rights ARE
              DISCLAIMED.
            </p>

            <p>
              Data can also quickly become out-of-date. It is recommended that
              the user pay careful attention to the contents of any metadata
              associated with a file, and that the originator of the data or
              information be contacted with any questions regarding appropriate
              use. If the user finds any errors or omissions, we encourage the
              user to report them to the contact information above.
            </p>

            <h3 className="marketing-textpage-header">
              CHILDREN'S PRIVACY POLICY
            </h3>

            <p>
              We recognize the need to provide additional privacy protections
              when children visit the Site. We explain those additional
              protections here in this section of our Privacy Policy. 
            </p>

            <p>
              The Children’s Online Privacy Protection Act ("COPPA") requires
              that we inform parents and legal guardians about how we collect,
              use, and disclose personal information from children under 13
              years of age; and that we obtain the consent of parents and
              guardians in order for children under 13 years of age to use
              certain features of our Site. Below we explain how we do that for
              these children.   Also, when we use the term "parent" below, we
              mean to include legal guardians.
            </p>

            <p>
              We do not currently intend to collect information from children
              under 13 years of age and have no plans to collect or use such
              information. 
            </p>

            <p>
              Part of {partnerVariables.product} is designed to increase
              awareness of the need to reduce schools’ and other buildings’
              environmental impact. Children play a critical role in the process
              by engaging in activities that help this effort and sharing this
              information with others. We believe that involving children will
              help them adopt lifelong behaviors that will help to preserve and
              protect the planet.  However, we do not ask children to submit any
              personal information to us in order to use the Site. 
            </p>

            <p>
              If a parent believes that we have collected personal information
              from their child, he or she can contact us as provided at the
              beginning of this Privacy Policy and we will remove all
              information from and about that child from our system.  
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default PrivacyPolicy;
