import { createAction, handleActions } from 'redux-actions';

export const types = {
  SUMMARY_REQUEST: 'app/savings/SUMMARY_REQUEST',
  SUMMARY_RESPONSE: 'app/savings/SUMMARY_RESPONSE',
  AVG_DAILY_SOLAR_CHART_REQUEST: 'app/savings/AVG_DAILY_SOLAR_CHART_REQUEST',
  AVG_DAILY_SOLAR_CHART_RESPONSE: 'app/savings/AVG_DAILY_SOLAR_CHART_RESPONSE',
  SOLAR_POWER_GEN_CHART_REQUEST: 'app/savings/SOLAR_POWER_GEN_CHART_REQUEST',
  SOLAR_POWER_GEN_CHART_RESPONSE: 'app/savings/SOLAR_POWER_GEN_CHART_RESPONSE',
  SITE_ENERGY_SOURCE_CHART_REQUEST:
    'app/savings/SITE_ENERGY_SOURCE_CHART_REQUEST',
  SITE_ENERGY_SOURCE_CHART_RESPONSE:
    'app/savings/SITE_ENERGY_SOURCE_CHART_RESPONSE',
};

export const actions = {
  summaryRequest: createAction(types.SUMMARY_REQUEST),
  summaryResponse: createAction(types.SUMMARY_RESPONSE),
  avgDailySolarChartRequest: createAction(types.AVG_DAILY_SOLAR_CHART_REQUEST),
  avgDailySolarChartResponse: createAction(
    types.AVG_DAILY_SOLAR_CHART_RESPONSE
  ),
  solarPowerGenChartRequest: createAction(types.SOLAR_POWER_GEN_CHART_REQUEST),
  solarPowerGenChartResponse: createAction(
    types.SOLAR_POWER_GEN_CHART_RESPONSE
  ),
  siteEnergySourceChartRequest: createAction(
    types.SITE_ENERGY_SOURCE_CHART_REQUEST
  ),
  siteEnergySourceChartResponse: createAction(
    types.SITE_ENERGY_SOURCE_CHART_RESPONSE
  ),
};

const defaultState = { isRefreshing: false };

// - - - FETCH SUMMARY - - -
// - - - FETCH SUMMARY - - -
// - - - FETCH SUMMARY - - -

export const fetchSummary = (buildingIds, beginDate, endDate) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.summaryRequest());
  try {
    const { data } = await api.renewables.summary(
      buildingIds,
      beginDate,
      endDate
    );
    data.isValid = data.totalKilowattHours > 0;
    return dispatch(actions.summaryResponse(data));
  } catch (err) {
    return dispatch(actions.summaryResponse(err));
  }
};

// - - - FETCH AVG DAILY SOLAR CHART - - -
// - - - FETCH AVG DAILY SOLAR CHART - - -
// - - - FETCH AVG DAILY SOLAR CHART - - -

export const fetchAvgDailySolarChart = (
  buildingIds,
  beginDate,
  endDate,
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.avgDailySolarChartRequest());
  try {
    const { data } = await api.renewables.avgDailySolarChart(
      buildingIds,
      beginDate,
      endDate
    );
    return dispatch(actions.avgDailySolarChartResponse(data));
  } catch (err) {
    return dispatch(actions.avgDailySolarChartResponse(err));
  }
};

// - - - FETCH SOLAR POWER GEN CHART - - -
// - - - FETCH SOLAR POWER GEN CHART - - -
// - - - FETCH SOLAR POWER GEN CHART - - -

export const fetchSolarPowerGenChart = (
  buildingIds,
  beginDate,
  endDate,
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.solarPowerGenChartRequest());
  try {
    const { data } = await api.renewables.solarPowerGenChart(
      buildingIds,
      beginDate,
      endDate
    );
    return dispatch(actions.solarPowerGenChartResponse(data));
  } catch (err) {
    return dispatch(actions.solarPowerGenChartResponse(err));
  }
};

// - - - FETCH SITE ENERGY SOURCE CHART - - -
// - - - FETCH SITE ENERGY SOURCE CHART - - -
// - - - FETCH SITE ENERGY SOURCE CHART - - -

export const fetchSiteEnergySourceChart = (
  buildingIds,
  beginDate,
  endDate,
  aliasId = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.siteEnergySourceChartRequest());
  try {
    const { data } = await api.renewables.siteEnergySourceChart(
      buildingIds,
      beginDate,
      endDate,
      aliasId
    );
    return dispatch(actions.siteEnergySourceChartResponse(data));
  } catch (err) {
    return dispatch(actions.siteEnergySourceChartResponse(err));
  }
};

// - - - ACTIONS - - -
// - - - ACTIONS - - -
// - - - ACTIONS - - -

export default handleActions(
  {
    [actions.summaryRequest]: {
      next: state => ({
        ...state,
        avgDailySolarChart: null,
        isRefreshing: true,
      }),
    },

    [actions.summaryResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        summary: payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.avgDailySolarChartRequest]: {
      next: state => ({
        ...state,
        avgDailySolarChart: null,
        isRefreshing: true,
      }),
    },

    [actions.avgDailySolarChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        avgDailySolarChart: payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.solarPowerGenChartRequest]: {
      next: state => ({
        ...state,
        solarPowerGenChart: null,
        isRefreshing: true,
      }),
    },

    [actions.solarPowerGenChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        solarPowerGenChart: payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.siteEnergySourceChartRequest]: {
      next: state => ({
        ...state,
        siteEnergySourceChart: null,
        isRefreshing: true,
      }),
    },

    [actions.siteEnergySourceChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        siteEnergySourceChart: payload?.data,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },
  },

  defaultState
);
