import { createAction, handleActions } from 'redux-actions';

export const types = {
  FILTER_OPTS_REQUEST: 'app/opportunities/FILTER_OPTS_REQUEST',
  FILTER_OPTS_RESPONSE: 'app/opportunities/FILTER_OPTS_RESPONSE',

  SUMMARY_REQUEST: 'app/opportunities/OPPORTUNITIES_SUMMARY_REQUEST',
  SUMMARY_RESPONSE: 'app/opportunities/OPPORTUNITIES_SUMMARY_RESPONSE',

  ISSUE_DETAIL_REQUEST: 'app/opportunities/ISSUE_DETAIL_REQUEST',
  ISSUE_DETAIL_RESPONSE: 'app/opportunities/ISSUE_DETAIL_RESPONSE',
};

export const actions = {
  filterOptsRequest: createAction(types.FILTER_OPTS_REQUEST),
  filterOptsResponse: createAction(types.FILTER_OPTS_RESPONSE),

  summaryRequest: createAction(types.SUMMARY_REQUEST),
  summaryResponse: createAction(types.SUMMARY_RESPONSE),

  issueDetailRequest: createAction(types.ISSUE_DETAIL_REQUEST),
  issueDetailResponse: createAction(types.ISSUE_DETAIL_RESPONSE),
};

const defaultState = {
  isRefreshing: false,
  filterOpts: null,
  issueDetails: [],
};

// - - - FETCH FILTER OPTIONS - - -
// - - - FETCH FILTER OPTIONS - - -
// - - - FETCH FILTER OPTIONS - - -

export const fetchFilterOptions = () => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.filterOptsRequest());
  try {
    const { data } = await api.opportunities.filterOptions();
    return dispatch(actions.filterOptsResponse(data));
  } catch (err) {
    return dispatch(actions.filterOptsResponse(err));
  }
};

// - - - FETCH HOURS SUMMARY - - -
// - - - FETCH HOURS SUMMARY - - -
// - - - FETCH HOURS SUMMARY - - -

export const fetchOpportunitiesSummary = (
  buildingIds,
  savingsImpacts,
  feasibilities,
  statuses,
  aliasId = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.summaryRequest());
  try {
    const { data } = await api.opportunities.opportunitiesSummary(
      buildingIds,
      savingsImpacts,
      feasibilities,
      statuses,
      aliasId
    );
    data.isValid = data.listItems.length > 0;
    return dispatch(actions.summaryResponse(data));
  } catch (err) {
    return dispatch(actions.summaryResponse(err));
  }
};

// - - - FETCH ISSUE DETAIL - - -
// - - - FETCH ISSUE DETAIL - - -
// - - - FETCH ISSUE DETAIL - - -

export const fetchIssueDetail = issueId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.issueDetailRequest());
  try {
    const { data } = await api.opportunities.issueDetail(issueId);
    return dispatch(actions.issueDetailResponse(data));
  } catch (err) {
    return dispatch(actions.issueDetailResponse(err));
  }
};

// - - - ACTIONS - - -
// - - - ACTIONS - - -
// - - - ACTIONS - - -

export default handleActions(
  {
    [actions.filterOptsRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.filterOptsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        filterOpts: payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.summaryRequest]: {
      next: state => ({
        ...state,
        // summaryInfo: null,
        isRefreshing: true,
      }),
    },

    [actions.summaryResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        summaryInfo: payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.issueDetailRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.issueDetailResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        issueDetails: _state.issueDetails.concat(payload),
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },
  },
  defaultState
);
