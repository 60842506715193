//--- Helper Functions (REFACTOR SOON) ---

const getQueryStringLong = (input, fieldName, key = 'id') => {
  let qString = '';
  for (let i = 0; i < input.length; i++) {
    qString += `&${fieldName}=${input[i][key]}`;
  }
  return qString;
};

//--- API calls ---
export class AnalyticsDeployerService {
  constructor(api) {
    this.api = api;
  }

  async getAnalyticsDetails(
    siteId,
    buildingIds = null,
    equipIds = null,
    scopeIds = null
  ) {
    let url = `/analytics/details?siteid=${siteId}&errors=true`;
    url += getQueryStringLong(buildingIds, 'buildingid', 'id');
    url += getQueryStringLong(equipIds, 'equipclassid', 'id');
    url += scopeIds ? `&ptaskid=${scopeIds}` : '';
    return this.api.pyGet(url);
  }

  async getAnalyticsMeta(taskId, analyticType = 'equip') {
    let url = `/analytics/metadata?taskid=${taskId}&type=${analyticType}`;
    return this.api.pyGet(url);
  }
  //-- Call with query parameters to return the same data as the GET --
  async patchAnalyticsMeta(data) {
    let url = `/analytics/metadata`;
    return this.api.pyPatch(url, data);
  }

  async getAnalyticsLibrary() {
    let url = `/analytics/library`;
    return this.api.pyGet(url);
  }

  async getAnalyticsEquip(
    siteId,
    buildingIds,
    equipClassIds,
    equipIds,
    grouping
  ) {
    let url = `/site/equipment?siteid=${siteId}`;
    url += getQueryStringLong(buildingIds, 'buildingid', 'id');
    url += getQueryStringLong(equipClassIds, 'equipclassid', 'id');
    url += getQueryStringLong(equipIds, 'equipid', 'id');
    url += grouping ? `&grouping=${grouping}` : '';
    return this.api.pyGet(url);
  }

  async getAnalyticsEquipCheck(libraryId, equipIds) {
    let url = `/analytics/library/check?libraryid=${libraryId}`;
    if (equipIds.length < 25) {
      url += getQueryStringLong(equipIds, 'equipid', 'id');
      return this.api.pyGet(url);
    } else {
      //use post instead of query string in a get if list longer than 25
      let equipIdList = equipIds.map(x => x.id);
      return this.api.pyPost(url, { equipIds: equipIdList });
    }
  }

  async getAnalyticsLibraryMeta(libraryId, equipIds, create = false) {
    let url = `/analytics/library/metadata?libraryid=${libraryId}`;
    url += getQueryStringLong(equipIds, 'equipid', 'id');
    url += create ? `&create=${create}` : '';
    return this.api.pyGet(url);
  }

  //--- SCOPE ---
  //-- Create - returns a 'ptaskId' --
  async postAnalyticsLibraryScope(data) {
    let url = `/analytics/library/scope`;
    return this.api.pyPost(url, data);
  }

  //-- only siteId is required --
  async getScope(siteId, ptaskId = null, buildingIds = [], equipClassIds = []) {
    let url = `/analytics/scopes?siteid=${siteId}`;
    url += ptaskId ? `&ptaskid=${ptaskId}` : '';
    url += getQueryStringLong(buildingIds, 'buildingid', 'id');
    url += getQueryStringLong(equipClassIds, 'equipclassid', 'id');
    return this.api.pyGet(url);
  }

  //-- site, ptask info is in data object --
  /* {
    "pTaskId": 1,
    "buildingIds": [1,2,3,4,5],
    "equipClassIds": [1,2,3,4,5],
    "equipIds": [1,2,3,4,5],
    "remove": True
  } */
  async patchScope(data, ptaskId) {
    return this.api.pyPatch(`/analytics/scopes/${ptaskId}`, data);
  }

  async patchAnalytic(data, taskId) {
    return this.api.pyPatch(`/analytics/details/${taskId}`, data);
  }

  //-- both siteId and ptaskId are required --
  async deleteScope(ptaskId) {
    let url = `/analytics/scopes/${ptaskId}`;
    return this.api.pyDelete(url);
  }

  async deleteAnalyticTask(taskId) {
    let url = `/analytics/tasks/${taskId}`;
    return this.api.pyDelete(url);
  }

  async runAnalyticTask(taskId) {
    let url = `/analytics/tasks/${taskId}/run`;
    return this.api.pyPost(url);
  }

  async getMappingOptions() {
    let url = `mapping/options`;
    return this.api.get(url);
  }

  async getAnalyticsLogs(taskId) {
    let url = `/analytics/${taskId}/logs`;
    return this.api.get(url);
  }

  async getScopeImpacts(ptaskId) {
    let url = `/analytics/scopes/${ptaskId}/impacts`;
    return this.api.pyGet(url);
  }
  
  async postScopeImpacts(ptaskId, impacts) {
    let url = `/analytics/scopes/${ptaskId}/impacts`;
    return this.api.pyPost(url, {equipClassImpacts: impacts});
  }
}
