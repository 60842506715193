import { createAction, handleActions } from 'redux-actions';
import { clearAliasSet, selectAliasSet } from './auth';
import cookie from 'react-cookies';
import memoizeOne from 'memoize-one';

export const types = {
  OVERVIEW_REQUEST: 'app/buildings/OVERVIEW_REQUEST',
  OVERVIEW_RESPONSE: 'app/buildings/OVERVIEW_RESPONSE',
  METRIC_CHART_REQUEST: 'app/buildings/METRIC_CHART_REQUEST',
  METRIC_CHART_RESPONSE: 'app/buildings/METRIC_CHART_RESPONSE',
  METRIC_COMPARISON_REQUEST: 'app/buildings/METRIC_COMPARISON_REQUEST',
  METRIC_COMPARISON_RESPONSE: 'app/buildings/METRIC_COMPARISON_RESPONSE',
  METRIC_SUMMARY_REQUEST: 'app/buildings/METRIC_SUMMARY_REQUEST',
  METRIC_SUMMARY_RESPONSE: 'app/buildings/METRIC_SUMMARY_RESPONSE',
  BILL_SOURCE_CHART_REQUEST: 'app/buildings/BILL_SOURCE_CHART_REQUEST',
  BILL_SOURCE_CHART_RESPONSE: 'app/buildings/BILL_SOURCE_CHART_RESPONSE',
  TREND_CHART_REQUEST: 'app/buildings/TREND_CHART_REQUEST',
  TREND_CHART_RESPONSE: 'app/buildings/TREND_CHART_RESPONSE',
  SAVINGS_CHART_REQUEST: 'app/buildings/SAVINGS_CHART_REQUEST',
  SAVINGS_CHART_RESPONSE: 'app/buildings/SAVINGS_CHART_RESPONSE',
  SAVINGS_COST_CHART_REQUEST: 'app/buildings/SAVINGS_COST_CHART_REQUEST',
  SAVINGS_COST_CHART_RESPONSE: 'app/buildings/SAVINGS_COST_CHART_RESPONSE',
  UTIL_COST_CHART_REQUEST: 'app/buildings/UTIL_COST_CHART_REQUEST',
  UTIL_COST_CHART_RESPONSE: 'app/buildings/UTIL_COST_CHART_RESPONSE',
  ALIAS_SETS_REQUEST: 'app/buildings/ALIAS_SETS_REQUEST',
  ALIAS_SETS_REPONSE: 'app/buildings/ALIAS_SETS_RESPONSE',
  POWER_MONITORING_REQUEST: 'app/buildings/POWER_MONITORING_REQUEST',
  POWER_MONITORING_RESPONSE: 'app/buildings/POWER_MONITORING_RESPONSE',
};

export const actions = {
  overviewRequest: createAction(types.OVERVIEW_REQUEST),
  overviewResponse: createAction(types.OVERVIEW_RESPONSE),
  metricChartRequest: createAction(types.METRIC_CHART_REQUEST),
  metricChartResponse: createAction(types.METRIC_CHART_RESPONSE),
  metricComparisonRequest: createAction(types.METRIC_COMPARISON_REQUEST),
  metricComparisonResponse: createAction(types.METRIC_COMPARISON_RESPONSE),
  metricSummaryRequest: createAction(types.METRIC_SUMMARY_REQUEST),
  metricSummaryResponse: createAction(types.METRIC_SUMMARY_RESPONSE),
  billSourceChartRequest: createAction(types.BILL_SOURCE_CHART_REQUEST),
  billSourceChartResponse: createAction(types.BILL_SOURCE_CHART_RESPONSE),
  trendChartRequest: createAction(types.TREND_CHART_REQUEST),
  trendChartResponse: createAction(types.TREND_CHART_RESPONSE),
  savingsChartRequest: createAction(types.SAVINGS_CHART_REQUEST),
  savingsChartResponse: createAction(types.SAVINGS_CHART_RESPONSE),
  savingsCostChartRequest: createAction(types.SAVINGS_COST_CHART_REQUEST),
  savingsCostChartResponse: createAction(types.SAVINGS_COST_CHART_RESPONSE),
  utilCostChartRequest: createAction(types.UTIL_COST_CHART_REQUEST),
  utilCostChartResponse: createAction(types.UTIL_COST_CHART_RESPONSE),
  aliasSetsRequest: createAction(types.ALIAS_SETS_REQUEST),
  aliasSetsResponse: createAction(types.ALIAS_SETS_REPONSE),
  powerMonitoringRequest: createAction(types.POWER_MONITORING_REQUEST),
  powerMonitoringResponse: createAction(types.POWER_MONITORING_RESPONSE),
};

const defaultState = {
  site: null,
  isRefreshing: false,
  metricCharts: [],
  billSourceCharts: [],
  aliasSets: [],
  aliasSetsLoading: false,
  buildingAliasedNames: [],
  powerMonitorGateways: [],
  gatewaysLoaded: false,
  gatewaysLoading: false,
  gatewaysRefreshing: false,
  powerMonitorError: null,
};

// - - - FETCH OVERVIEW - - -
// - - - FETCH OVERVIEW - - -
// - - - FETCH OVERVIEW - - -

const fetchOverview_raw = (
  siteId,
  configId,
  buildings,
  beginDate,
  endDate,
  aliasId = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.overviewRequest());
  try {
    const { data } = await api.buildings.overview(
      siteId,
      configId,
      buildings,
      beginDate,
      endDate,
      aliasId
    );
    return dispatch(actions.overviewResponse(data));
  } catch (err) {
    return dispatch(actions.overviewResponse(err));
  }
};
export const fetchOverview = memoizeOne(fetchOverview_raw);

// - - - FETCH METRIC CHART - - -
// - - - FETCH METRIC CHART - - -
// - - - FETCH METRIC CHART - - -
const fetchMetricChart_raw = (
  buildingIds,
  metricId,
  beginDate,
  endDate
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.metricChartRequest());
  try {
    const { data } = await api.buildings.metricChart(
      buildingIds,
      metricId,
      beginDate,
      endDate
    );
    data.metricId = metricId;
    data.buildingIds = buildingIds;
    return dispatch(actions.metricChartResponse(data));
  } catch (err) {
    return dispatch(actions.metricChartResponse(err));
  }
};
export const fetchMetricChart = memoizeOne(fetchMetricChart_raw);

// - - - FETCH METRIC COMPARISON - - -
// - - - FETCH METRIC COMPARISON - - -
// - - - FETCH METRIC COMPARISON - - -
const fetchMetricComparison_raw = (
  siteId,
  buildingIds,
  metricId,
  beginDate,
  endDate,
  aliasId = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.metricComparisonRequest());
  try {
    const { data } = await api.buildings.facilityMetricComparisonChart(
      siteId,
      buildingIds,
      metricId,
      beginDate,
      endDate,
      aliasId
    );
    data.metricId = metricId;
    return dispatch(actions.metricComparisonResponse(data));
  } catch (err) {
    return dispatch(actions.metricComparisonResponse(err));
  }
};
export const fetchMetricComparison = memoizeOne(fetchMetricComparison_raw);

// - - - FETCH METRIC SUMMARY - - -
// - - - FETCH METRIC SUMMARY - - -
// - - - FETCH METRIC SUMMARY - - -
const fetchMetricSummary_raw = (
  buildingIds,
  metricId,
  beginDate,
  endDate,
  absoluteLow = null,
  absoluteHigh = null,
  absoluteHighHigh = null,
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.metricSummaryRequest());
  try {
    const { data } = await api.buildings.metricSummary(
      buildingIds,
      metricId,
      beginDate,
      endDate,
      absoluteLow,
      absoluteHigh,
      absoluteHighHigh,
    );
    data.metricId = metricId;
    // data.buildingId = buildingId;
    return dispatch(actions.metricSummaryResponse(data));
  } catch (err) {
    return dispatch(actions.metricSummaryResponse(err));
  }
};
export const fetchMetricSummary = memoizeOne(fetchMetricSummary_raw);

// - - - FETCH BILL SOURCE CHART - - -
// - - - FETCH BILL SOURCE CHART - - -
// - - - FETCH BILL SOURCE CHART - - -
const fetchBillSourceChart_raw = (
  buildingId,
  billSourceId,
  beginDate,
  endDate
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.billSourceChartRequest());
  try {
    const { data } = await api.buildings.billSourceChart(
      buildingId,
      billSourceId,
      beginDate,
      endDate
    );
    data.billSourceId = billSourceId;
    data.buildingId = buildingId;
    return dispatch(actions.billSourceChartResponse(data));
  } catch (err) {
    return dispatch(actions.billSourceChartResponse(err));
  }
};
export const fetchBillSourceChart = memoizeOne(fetchBillSourceChart_raw);

// - - - FETCH TREND CHART - - -
// - - - FETCH TREND CHART - - -
// - - - FETCH TREND CHART - - -
const fetchTrendChart_raw = (
  buildingId,
  metricId,
  beginDate,
  endDate
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.trendChartRequest());
  try {
    const { data } = await api.buildings.trendDataChart(
      buildingId,
      metricId,
      beginDate,
      endDate
    );
    data.metricId = metricId;
    data.chart.isValid = data.chart.data.datasets.length > 0;
    return dispatch(actions.trendChartResponse(data));
  } catch (err) {
    return dispatch(actions.trendChartResponse(err));
  }
};
export const fetchTrendChart = memoizeOne(fetchTrendChart_raw);

// - - - FETCH SAVINGS CHART - - -
// - - - FETCH SAVINGS CHART - - -
// - - - FETCH SAVINGS CHART - - -
const fetchSavingsChart_raw = (buildingIds, beginDate, endDate) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.savingsChartRequest());
  try {
    const { data } = await api.buildings.savingsSummaryChart(
      buildingIds,
      beginDate,
      endDate
    );
    data.chart.isValid = data.chart.data.datasets[0].data.length > 0;
    return dispatch(actions.savingsChartResponse(data));
  } catch (err) {
    return dispatch(actions.savingsChartResponse(err));
  }
};
export const fetchSavingsChart = memoizeOne(fetchSavingsChart_raw);

// - - - FETCH SAVINGS COST CHART - - -
// - - - FETCH SAVINGS COST CHART - - -
// - - - FETCH SAVINGS COST CHART - - -
const fetchSavingsCostChart_raw = (buildingIds, beginDate, endDate) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.savingsCostChartRequest());
  try {
    const { data } = await api.buildings.savingsCostSummaryChart(
      buildingIds,
      beginDate,
      endDate
    );
    let validatedData = data;
    validatedData.chart['isValid'] =
      data.chart.data.datasets[0]?.data.length > 0;
    return dispatch(actions.savingsCostChartResponse(validatedData));
  } catch (err) {
    return dispatch(actions.savingsCostChartResponse(err));
  }
};
export const fetchSavingsCostChart = memoizeOne(fetchSavingsCostChart_raw);

// - - - FETCH UTIL COSTS CHART - - -
// - - - FETCH UTIL COSTS CHART - - -
// - - - FETCH UTIL COSTS CHART - - -
const fetchUtilityCostsChart_raw = (
  buildingId,
  metricId,
  beginDate,
  endDate
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.utilCostChartRequest());
  try {
    const { data } = await api.buildings.utilitiesCostSummaryChart(
      buildingId,
      metricId,
      beginDate,
      endDate
    );
    data.metricId = metricId;
    data.chart.isValid = data.chart.data.datasets.length > 0;
    return dispatch(actions.utilCostChartResponse(data));
  } catch (err) {
    return dispatch(actions.utilCostChartResponse(err));
  }
};
export const fetchUtilityCostsChart = memoizeOne(fetchUtilityCostsChart_raw);

// - - - FETCH NAME ALIAS SETS - - -
// - - - FETCH NAME ALIAS SETS - - -
// - - - FETCH NAME ALIAS SETS - - -
const fetchAliasSets_raw = configId => async (dispatch, _getState, { api }) => {
  dispatch(actions.aliasSetsRequest());
  try {
    if (configId !== undefined) {
      const { data } = await api.buildings.buildingAliasSets(configId);
      const aliasId = cookie.load(`selectedAliasSet-${configId}`);
      if (aliasId && data.find(_s => _s.id === parseInt(aliasId, 10))) {
        dispatch(selectAliasSet(parseInt(aliasId, 10), configId));
      } else {
        dispatch(clearAliasSet());
      }
      return dispatch(actions.aliasSetsResponse(data));
    }
  } catch (err) {
    return dispatch(actions.aliasSetsResponse(err));
  }
};
export const fetchAliasSets = memoizeOne(fetchAliasSets_raw);

// - - - FETCH POWER MONITORING - - -
export const fetchPowerMonitoring = (buildingIds) => async (dispatch, _getState, { api }) => {
  dispatch(actions.powerMonitoringRequest());
  try {
    const pmDataTasks = [];
    const pmBuildingBatchSize = 25;
    var buildingIdBatch = [];
    for (var i = 0; i < buildingIds.length; i++) {
      buildingIdBatch.push(buildingIds[i]);
      if (buildingIdBatch.length >= pmBuildingBatchSize || i === buildingIds.length - 1) {
        pmDataTasks.push(api.buildings.powerMonitoring(buildingIdBatch));
        buildingIdBatch = [];
      }
    }
    var taskResponses = await Promise.all(pmDataTasks);
    const validResponses = [...taskResponses].filter(response => response.status === 200);
    var data = [];
    validResponses.forEach(response => {
      data = data.concat(response.data.gateways);
    });
    return dispatch(actions.powerMonitoringResponse(data));
  } catch (err) {
    return dispatch(actions.powerMonitoringResponse(err));
  }
};

// - - - ACTIONS - - -
// - - - ACTIONS - - -
// - - - ACTIONS - - -
export default handleActions(
  {
    [actions.overviewRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.overviewResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        ...payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.metricChartRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.metricChartResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isRefreshing: false,
        metricCharts: state.metricCharts
          ? state.metricCharts.concat(payload)
          : [payload],
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.metricComparisonRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.metricComparisonResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isRefreshing: false,
        metricComparison: payload,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.metricSummaryRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.metricSummaryResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isRefreshing: false,
        metricSummary: payload,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.billSourceChartRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.billSourceChartResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isRefreshing: false,
        billSourceCharts: state.billSourceCharts
          ? state.billSourceCharts.concat(payload)
          : [payload],
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.trendChartRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
        trendChart: null,
      }),
    },

    [actions.trendChartResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isRefreshing: false,
        trendChart: payload,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.savingsChartRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
        savingsChart: null,
      }),
    },

    [actions.savingsChartResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isRefreshing: false,
        savingsChart: payload,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.savingsCostChartRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
        savingsCostChart: null,
      }),
    },

    [actions.savingsCostChartResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isRefreshing: false,
        savingsCostChart: payload,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.utilCostChartRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
        utilCostChart: null,
      }),
    },

    [actions.utilCostChartResponse]: {
      next: (state, { payload }) => ({
        ...state,
        isRefreshing: false,
        utilCostChart: payload,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.aliasSetsRequest]: {
      next: state => ({
        ...state,
        aliasSetsLoading: true,
      }),
    },

    [actions.aliasSetsResponse]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          aliasSetsLoading: false,
          aliasSets: payload,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        aliasSetsLoading: false,
      }),
    },
    [actions.powerMonitoringRequest]: {
      next: state => ({
        ...state,
        gatewaysRefreshing: true,
        gatewaysLoading: true,
      }),
    },
    [actions.powerMonitoringResponse]: {
      next: (state, { payload }) => ({
        ...state,
        powerMonitorGateways: payload,
        gatewaysRefreshing: false,
        gatewaysLoaded: true,
        gatewaysLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        gatewaysRefreshing: false,
        gatewaysLoading: false,
        gatewaysLoaded: false,
      }),
    },
  },

  defaultState
);
