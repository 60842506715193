export class AuthService {
  constructor(api) {
    this.http = api.http;
    this.serviceHttp = api.serviceHttp;
  }

  async login() {
    return this.http.get(`/login`);
  }

  async partnerConfig() {
    return this.serviceHttp.get(`/auth/partner`);
  }
}
