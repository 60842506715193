const getQueryStringList = (input, fieldName) => {
  let qString = '';
  for (let i = 0; i < input.length; i++) {
    qString += `&${fieldName}=${input[i]}`;
  }
  return qString;
};

//--- API calls ---
export class AlertService {
  constructor(api) {
    this.api = api;
  }

  async getAlerts(siteId) {
    let url = `/alert?siteId=${siteId}`;
    return this.api.get(url);
  }

  async postAlert(data) {
    let url = `/alert`;
    return this.api.post(url, data);
  }

  async putAlert(alertId, data) {
    let url = `/alert/${alertId}`;
    return this.api.put(url, data);
  }

  async deleteAlert(alertId) {
    let url = `/alert/${alertId}`;
    return this.api.delete(url);
  }

  async getAlertTypeOptions(siteId) {
    let url = `/alert/option/type?siteId=${siteId}`;
    return this.api.get(url);
  }

  async getAlertBuildingOptions(siteId, analyticIds = null) {
    let url = `/alert/option/building?siteId=${siteId}`;
    if (analyticIds) {
      url += getQueryStringList(analyticIds, 'analyticId');
    }
    return this.api.get(url);
  }

  async getAlertEquipmentOptions(
    siteId,
    analyticIds = null,
    buildingIds = null
  ) {
    let url = `/alert/option/equipment?siteId=${siteId}`;
    if (analyticIds) {
      url += getQueryStringList(analyticIds, 'analyticId');
    }
    if (buildingIds) {
      url += getQueryStringList(buildingIds, 'buildingId');
    }
    return this.api.get(url);
  }
}
