import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import './styles/marketingMain.scss';

const TermsConditions = () => {
  const [partnerVariables, setPartnerVariables] = useState(false);
  const [heading, setHeading] = useState('Loading...');

  useEffect(() => {
    fetch(
      'https://services.apis.edoenergy.com/auth/partner/terms-and-conditions'
    )
      .then(response => response.json())
      .then(j => {
        setPartnerVariables(j);
        setHeading('Terms and Conditions');
      })
      .catch(() => setHeading('Loading Failed'));
  }, []);

  return (
    <>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <div className="marketing-basic-text">
        <h1>{heading}</h1>
        {partnerVariables && (
          <>
            <p style={{ marginBottom: '45px' }}>Revised April 29, 2020</p>

            <h3 className="marketing-textpage-header">
              {partnerVariables.product} Terms & Conditions for Use
            </h3>

            <p>
              {partnerVariables.legalName} (“{partnerVariables.name}” or “we” or
              “us”) created, maintains and operates the{' '}
              {partnerVariables.product} platform. {partnerVariables.product}{' '}
              may include access to multiple different sections or parts,
              including various data dashboards, websites and materials
              (collectively or in parts, the “Service”) that you, as an
              individual or representing a specific member identification /
              log-in, (“you”) are viewing. 
            </p>

            <p>
              By accessing and using the Service, in whole or in part, you agree
              to each of the terms and conditions set forth herein ("Terms of
              Use"). Additional terms and conditions applicable to specific
              areas of the Service, to content, and/or transactions may also be
              posted separate areas of the Service and, together with our
              Privacy Policy (https://{partnerVariables.product}.
              {partnerVariables.name}.com/privacy_policy) and these Terms of
              Use, govern your use of those areas, content or transactions.
              These Terms of Use, together with applicable additional terms and
              conditions, are referred to as this "Agreement". These terms and
              conditions apply to your access to and the use of the Services and
              does not alter the terms and conditions of any other arrangement
              you may have with {partnerVariables.name}.
            </p>

            <p>
              {partnerVariables.name} reserves the right to modify this
              Agreement at any time without giving you prior notice. You should
              review these Terms of Service and any updated terms before using
              the Service. Your continued use of the Service following any such
              modification constitutes your agreement to follow and be bound by
              the Agreement as modified. The last date these Terms of Use were
              revised is set forth below.
            </p>

            <p>
              During the term of this agreement to the extent applicable,{' '}
              {partnerVariables.name}
              grants to you a non-exclusive, non-sublicensesable,
              non-transferable, limited license to use the information,
              writings, images and/or other works that you see, hear or
              otherwise experience from the Service (individually or
              collectively, the “Content”) solely for your non-commercial,
              personal and/or for education purposes. No right, title or
              interest in any Content is transferred to you, whether as a result
              of downloading such Content or otherwise. {
                partnerVariables.name
              }{' '}
              reserves complete title and full intellectual property rights in
              all Content. Except as expressly authorized by this Agreement, you
              may not use, alter, copy, distribute, transmit, or derive another
              work from any Content, except as expressly permitted by the
              agreement.
            </p>

            <p>
              The Service and the Content are protected by U.S. and/or foreign
              copyright laws, and belong to {partnerVariables.name} or its
              partners, affiliates, contributors or third parties. The
              copyrights in the Content are owned by {partnerVariables.name} or
              other copyright owners who have authorized their use of the
              Service. You may download and reprint Content for non-commercial,
              non-public, personal use only. If you are using the Service as an
              employee or member of any business or organization, you may
              download and reprint Content only for educational or other
              non-commercial purposes within your business or organization,
              except as otherwise permitted by
              {partnerVariables.name}.
            </p>

            <p>
              You are prohibited from using any of the marks or logos appearing
              throughout the Service without permission from the trademark
              owner, except as permitted by applicable law.  You agree not to
              add to, subtract from, or otherwise modify the Content, or to
              attempt to access any Content that is not intended for you. You
              may not engage in any of the following prohibited activities: (i)
              copying, distributing, or disclosing any part of the Service in
              any medium, including without limitation by any automated or
              non-automated "scraping", (ii) using any automated system,
              including without limitation "robots," "spiders," "offline
              readers," etc., to access the Service, (iii) attempting to
              interfere with, compromise the system integrity or security, or
              decipher any transmissions of the Service, (iv)  interfering with
              the operations of the Service, (v) accessing any content on the
              Service through any technology or means other than those provided
              or authorized by
              {partnerVariables.name}, or (vi) bypassing the measures we may use
              to prevent or restrict access to the Service, including without
              limitation features that prevent or restrict use or copying of any
              content or enforce limitations on use of the Service or the
              content therein.
            </p>

            <p>
              Any links provided to third party websites or information may be
              provided by the Service are provided solely as a convenience to
              you. If you use these links, you will leave the Service
              environment. Such links do not constitute or imply an endorsement,
              sponsorship, or recommendation by {partnerVariables.name} of the
              third party, the third-party website, or the information contained
              therein. {partnerVariables.name} is not responsible for the
              availability of any such websites. {partnerVariables.name} is not
              responsible or liable for any such website or the content thereon.
              If you use the links to the websites of {partnerVariables.name}{' '}
              affiliates or service providers, you will leave the Service
              Environment, and will be subject to the terms of use and privacy
              policy applicable to those websites.
            </p>

            <p>
              The Service and Content is provided "as is," without any
              warranties of any kind. To the fullest extent permissible under
              applicable law,
              {partnerVariables.name} disclaims all such warranties, express or
              implied, including, but not limited to, warranties of
              merchantability, fitness for a particular purpose,
              non-infringement, accuracy, freedom from errors, suitability of
              content, or availability. {partnerVariables.name} DOES NOT
              WARRANTY THE USE OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR
              FREE. YOU HEREBY CONFIRM AND UNDERSTAND THAT THE SERVICE IS
              SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE
              USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS.{' '}
              {partnerVariables.name} NOR ITS AFFILIATES ARE NOT RESPONSIBLE FOR
              ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE THAT MAY RESULT
              FROM SUCH LIMITATIONS AND PROBLEMS.
            </p>

            <p>
              {partnerVariables.name} cares about the integrity and security of
              your personal information. However, the Company cannot guarantee
              that unauthorized third parties will never be able to defeat the
              Service’s security measures or use any personal information you
              provide to us for improper purposes. You acknowledge that you
              provide your personal information at your own risk.{' '}
              {partnerVariables.name} cannot and does not guarantee or warrant
              that files available for downloading through the Service will be
              free of infection by software viruses or other harmful computer
              code, files or programs. 
            </p>

            <p>
              You agree to use the Service and Content only for lawful purposes.
               You understand and agree that you are responsible for your use of
              the Service.  You are responsible for maintaining the
              confidentiality of your member identification; password and/or
              login information, and for restricting access to your computer.
              You agree to accept responsibility for all activities that occur
              under your member identification and password. You understand and
              agree that you are responsible for your behavior when using the
              Service. You agree to indemnify, defend and hold harmless{' '}
              {partnerVariables.name}, its parent companies, subsidiaries,
              affiliated companies, joint venturers, business partners,
              licensors, employees, agents, and any third-party information
              providers to the Site from and against all claims, losses,
              expenses, damages and costs (including, but not limited to,
              direct, incidental, consequential, exemplary and indirect
              damages), and reasonable attorneys’ fees, resulting from or
              arising out of your use, misuse, or inability to use the Service
              or the Content, or any violation by you of this Agreement.
            </p>

            <p>
              Unless otherwise specifically provided for in this Agreement or
              unless
              {partnerVariables.name} has rights independent of this Agreement,{' '}
              {partnerVariables.name} shall not assert any ownership rights to
              any data, information or material that You submit to the Service
              in the course of using the Service in accordance with the terms of
              this Agreement (“Customer Data”). You are fully, solely and
              exclusively responsible for the accuracy, quality, integrity,
              lawfulness, reliability, appropriateness and usage and
              distribution rights, including all intellectual property rights as
              concerns all Customer Data. You hereby confirm that other than the
              responsibility to restore a backup, neither{' '}
              {partnerVariables.name} nor its affiliates are responsible nor
              shall {partnerVariables.name} or its affiliates be liable for the
              deletion, correction, destruction, damage, loss or failure to
              store any Customer Data whatsoever. 
            </p>

            <p>
              {partnerVariables.name} and its licensors are the exclusive and
              sole owner of all rights, title, and interest in and to the
              Service and the Content (including, without limitation, ownership
              of all copyright rights and other intellectual property rights)
              and any suggestions, ideas, enhancement requests, feedback,
              recommendations, specifications or other information provided by
              You or any of your employees, and authorized end-users relating to
              the Service and/or the Content. This Agreement is not a contract
              for a sale and does not convey to You any rights of ownership in
              or related to the Service, the Content and/or the Intellectual
              Property Rights owned and asserted by {partnerVariables.name} and
              its licensors. You hereby agree not to contest{' '}
              {partnerVariables.name}’s ownership rights as described in this
              Section. {partnerVariables.name} exclusively owns all intellectual
              property rights, title and interest to and in any documentation,
              materials, reports, programs, graphics and the like that may be
              produced as part of the consulting and/or configuration services.
              Further, as concerns any possible future consulting and/or
              configuration services, {partnerVariables.name} shall have sole
              and exclusive ownership of all modifications to the Service, the
              Content and all media and documentation relating to the
              modifications (including their development, or their operation,
              testing or use, and all reports and copies thereof), and all
              intellectual property rights associated therewith (including,
              without limitation, rights to copyrights, trade secrets, or
              know-how).
            </p>

            <p>
              You acknowledge and agree that {partnerVariables.name} may derive
              or create data and information about the use of the Service or
              Content during your use (“Use Data”) and {partnerVariables.name}{' '}
              may use and disclose Use Data to its third party service providers
              in order to improve the Service.
            </p>

            <p>
              You acknowledge and agree that {partnerVariables.name} may obtain
              and aggregate technical and other data about your use of the
              Services excluding any personally identifiable with respect to
              Customer (“Aggregated Anonymous Data”), and{' '}
              {partnerVariables.name} may use the Aggregated Anonymous Data to
              analyze, improve, support and operate the Services and otherwise
              for any business purpose, during and after the term of this
              Agreement, including without limitation to generate industry
              benchmarks or best practices guidance, recommendations or similar
              reports for distribution to and consumption by you and other
              customers and prospects. For clarity, this section does not give{' '}
              {partnerVariables.name} the right to identify any single user as
              the source of any Aggregated Anonymous Data.
            </p>

            <p>
              {partnerVariables.name} may terminate or suspend your access to or
              ability to use the Service immediately, without prior notice or
              liability, for any reason or no reason, including breach of this
              agreement. {partnerVariables.name} may immediately terminate or
              suspend accounts that have been flagged for repeat copyright
              infringement. Upon termination of your access to or ability to use
              the Service, your right to use or access the Service will
              immediately cease. This agreement’s provisions that by their
              nature should survive termination shall survive termination,
              including, without limitation, ownership provisions, warranty
              disclaimers, and limitations of liability. Termination of your
              access to and use of the Service shall not relieve you of any
              obligations arising or accruing prior to termination or limit any
              liability that you otherwise may have to
              {partnerVariables.name} or any third party.
            </p>

            <p>
              To the fullest extent permitted by applicable law in no event
              shall
              {partnerVariables.name} be liable for any direct, special,
              indirect or consequential damages, or any other damages of any
              kind, including but not limited to loss of use, loss of profits or
              loss of data, whether in an action in contract, tort (including
              but not limited to negligence) or otherwise, arising out of or in
              any way connected with the use of or inability to use the Service,
              including without limitation any damages caused by or resulting
              from reliance by user on any information obtained from Service, or
              that result from mistakes, omissions, interruptions, deletion of
              files or email, errors, defects, viruses, delays in operation or
              transmission or any failure of performance. For all losses related
              to the use of the Service, under no circumstances shall
              {partnerVariables.name}’s or it’s affiliates’ or licensor’s
              aggregate liability resulting from or relating to this agreement
              or the services exceed the price paid for the service.
            </p>

            <p>
              Certain state laws do not allow limitations on implied warranties
              or the exclusions or limitations of certain damages.  If these
              laws apply to you, some or all of the above disclaimer, exclusions
              or limitations may not apply to you, and you may have additional
              rights.
            </p>

            <p>
              If any portion of this Agreement is held to be invalid or
              unenforceable, the remaining portions of this Agreement will
              remain in full force and effect.  Any failures to enforce any
              provision of this Agreement will not constitute a present or
              future waiver of such provision, nor limit our right to enforce
              such provision at a later time.  All waivers by
              {partnerVariables.name} must be in writing to be effective.
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default TermsConditions;
