import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Input,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faSearch } from '@fortawesome/pro-solid-svg-icons';

export const DRILL_HIERARCHY_OPTIONS = [
  {
    id: 1,
    name: 'Building > Equipment Class > Fault Category',
    drillType: ['building', 'equipmentClass', 'faultCategory', 'task'],
    rootTag: 'All Buildings',
  },
  {
    id: 2,
    name: 'Building > Fault Category > Equipment Class',
    drillType: ['building', 'faultCategory', 'equipmentClass', 'task'],
    rootTag: 'All Buildings',
  },
  {
    id: 3,
    name: 'Fault Category > Building > Equipment Class',
    drillType: ['faultCategory', 'building', 'equipmentClass', 'task'],
    rootTag: 'All Fault Categories',
  },
  {
    id: 4,
    name: 'Fault Category > Equipment Class > Building',
    drillType: ['faultCategory', 'equipmentClass', 'building', 'task'],
    rootTag: 'All Fault Categories',
  },
  {
    id: 5,
    name: 'Equipment Class > Building > Fault Category',
    drillType: ['equipmentClass', 'building', 'faultCategory', 'task'],
    rootTag: 'All Equipment Classes',
  },
  {
    id: 6,
    name: 'Equipment Class > Fault Category > Building',
    drillType: ['equipmentClass', 'faultCategory', 'building', 'task'],
    rootTag: 'All Equipment Classes',
  }
];

export const FAULT_VIEWS = [
  {
    identifier: 'heatmap',
    title: 'Heatmap View',
    iconClassName: 'fa-solid fa-grid',
  },
  {
    identifier: 'table',
    title: 'Table View',
    iconClassName: 'fa-solid fa-table',
  },
  { identifier: 'list', title: 'List View', iconClassName: 'fa-solid fa-list' },
];

export const LIST_VIEW_SORT_BY_OPTIONS = [
  { display: '---', value: 'taskId' },
  { display: 'Fault Percentage', value: 'FaultPercentage' },
  { display: 'Fault Hours', value: 'FaultHours' },
  { display: 'Impact', value: 'ImpactValue' },
];

export const LIST_VIEW_SORT_ORDER_OPTIONS = [
  {
    identifier: 'ascending',
    title: 'Sort Ascending',
    iconClassName: 'fa-solid fa-arrow-up-wide-short',
  },
  {
    identifier: 'descending',
    title: 'Sort Descending',
    iconClassName: 'fa-solid fa-arrow-down-wide-short',
  },
];

export const HEATMAP_VIEW_COLOR_PALETTES = [
  {
    name: 'orange',
    colors: ['#f9ede7', '#e8b399', '#d87e52', '#cc5f2e', '#c04a20'],
  },
  {
    name: 'greenToRed',
    colors: ['#68AB64', '#A4C05D', '#EED064', '#FA9F4E', '#c43f51'],
  },
];

export const IMPACTS = { 1: 'Low', 2: 'Medium', 3: 'High' };

export const PAGE_SIZE_OPTIONS = [10, 25, 50];

export const determineSelectionParameters = ({
  drillPath,
  drillHierarchy,
  selectedSites,
  selectedFaultCats,
  selectedEquipment,
}) => {
  let requestingNodeType = drillHierarchy.drillType[0];
  let taskId = undefined;

  // 1) Determine which type of nodeType we are fetching.
  // 2) Narrow the selection for each node within the drill path.
  if (drillPath.length > 0) {
    for (let node of drillPath) {
      switch (node.type) {
        case 'building':
          selectedSites = [node.id];
          break;
        case 'faultCategory':
          // Fault categories are queried by string, not ID.
          selectedFaultCats = [node.name];
          break;
        case 'equipmentClass':
          selectedEquipment = [node.id];
          break;
        case 'task':
          taskId = node.id;
          break;
        default:
          throw new Error(`Invalid nodeType ${node.type}`);
      }
    }

    // Each faultView dictates the nodeType for subsequent drilling.
    requestingNodeType = drillHierarchy.drillType[drillPath.length];
  }

  return {
    sites: selectedSites,
    faultCategories: selectedFaultCats,
    equipmentCategories: selectedEquipment,
    requestingNodeType,
    taskId,
  };
};

export const isDrillPathValid = (drillPath, selectedOptions) => {
  for (let node of drillPath) {
    switch (node.type) {
      case 'building':
        if (!selectedOptions.sites.includes(node.id)) {
          return false;
        }
        break;
      case 'faultCategory':
        if (!selectedOptions.faultCategories.includes(node.name)) {
          return false;
        }
        break;
      case 'equipmentClass':
        if (!selectedOptions.equipmentClasses.includes(node.id)) {
          return false;
        }
        break;
      default:
        break;
    }
  }
  return true;
};

export const filterNodes = (nodesToFilter, searchTerm) => {
  // No nodes, return nothing.
  if (!nodesToFilter) {
    return null;
  }

  // No filter, return all nodes.
  if (!searchTerm) {
    return nodesToFilter;
  }

  return nodesToFilter.filter(x => x.name.toLowerCase().includes(searchTerm));
};

export const DrillHierarchyDropdown = props => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      className="picker-metrics"
    >
      <DropdownToggle
        color="primary"
        outline={true}
        className="filter-top-level"
      >
        <div
          className="float-left d-none d-md-block"
          style={{ marginRight: '5px' }}
        >
          View by:{' '}
        </div>
        {props.activeOption.name}
        <FontAwesomeIcon
          icon={faAngleLeft}
          style={{
            float: 'right',
            marginTop: '5px',
            marginLeft: '10px',
          }}
          id="navToggle"
          rotation={isOpen ? 90 : 270}
        />
      </DropdownToggle>
      <DropdownMenu>
        {DRILL_HIERARCHY_OPTIONS.map((option, index) => {
          return (
            <div key={'m-' + index}>
              <DropdownItem
                key={'t_' + option.id}
                onClick={() => {
                  props.onChange(option);
                }}
                active={option.id === props.activeOption.id}
              >
                {option.name}
              </DropdownItem>
            </div>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export const PageSizeDropdown = props => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      className="picker-metrics"
    >
      <DropdownToggle
        color="primary"
        outline={true}
        className="filter-top-level"
      >
        <div
          className="float-left d-none d-md-block"
          style={{ marginRight: '5px' }}
        >
          Show {props.activeOption} entries
        </div>
        <FontAwesomeIcon
          icon={faAngleLeft}
          style={{
            float: 'right',
            marginTop: '5px',
            marginLeft: '10px',
          }}
          id="navToggle"
          rotation={isOpen ? 90 : 270}
        />
      </DropdownToggle>
      <DropdownMenu>
        {PAGE_SIZE_OPTIONS.map((option, index) => {
          return (
            <div key={'m-' + index}>
              <DropdownItem
                key={'t_' + option}
                onClick={() => props.onChange(option)}
                active={option === props.activeOption}
              >
                {option}
              </DropdownItem>
            </div>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export const createSearchBar = setSearch => {
  return (
    <div id="fault-breakdown-search">
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <span>
            <FontAwesomeIcon icon={faSearch} className="icon-search" />
          </span>
        </InputGroupAddon>
        <Input
          className="input-search"
          placeholder="Search"
          onChange={e => setSearch(e.target.value)}
        />
      </InputGroup>
    </div>
  );
};
