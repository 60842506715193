//--- API calls ---
export class PortfolioManagerService {
  constructor(api) {
    this.api = api;
  }

  //GET

  async getPropertyTypes() {
    let url = `/portfolio-manager/property-type`;
    return this.api.get(url);
  }

  async getPortfolioManagerBuildings(siteId) {
    let url = `/portfolio-manager/site/${siteId}/building`;
    return this.api.get(url);
  }

  async getSiteMeters(siteId) {
    let url = `/portfolio-manager/site/${siteId}/meters`;
    return this.api.get(url);
  }

  async getSynchronizeStatus(siteId, taskId) {
    let url = `/portfolio-manager/site/${siteId}/status/${taskId}`;
    return this.api.get(url);
  }

  //POST

  async postSynchronizePortfolioManagerBuildings(siteId) {
    let url = `/portfolio-manager/site/${siteId}/synchronize`;
    return this.api.post(url);
  }

  //PUT
  async putPortfolioManagerBuildings(siteId, buildingData) {
    let url = `/portfolio-manager/site/${siteId}/building`;
    return this.api.put(url, { buildings: buildingData });
  }

  //DELETE
  async deletePortfolioManagerBuilding(siteId, buildingId) {
    let url = `/portfolio-manager/site/${siteId}/building/${buildingId}`;
    return this.api.delete(url);
  }
}
