import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import './errorBoundary.scss';

class UnverifiedEmail extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Unverified Email</title>
        </Helmet>
        <div className="error-card">
          <h1>Unverified Email</h1>
          <p>The email address for this account has not been verified.</p>
          <p>
            Please check your email for the verification link that was sent when
            you signed up for your account. If you are unable to find the email
            or continue to have problems, please email&nbsp;
            <a href="mailto:reveal@mckinstry.com?subject=Reveal Unverified Email">
              reveal@mckinstry.com
            </a>
            .
          </p>
          <Link
            to="/logout"
            style={{ color: '#0a3f54', textDecoration: 'none' }}
          >
            <u>Return to Login</u>
          </Link>
          <p></p>
          <div className="logo">
            <img
              src={'/images/reveal-white.svg'}
              alt="reveal-logo"
              width="100px"
              style={{ marginRight: '15px' }}
              className="img text-center"
            />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(UnverifiedEmail);
