export class PrevMaitenanceService {
  constructor(http) {
    this.http = http;
  }

  async filterOptions(siteId, buildingIds) {
    this.filterOptionsParams = { siteId, buildingIds };
    return this.http.post(
      `/preventative-maintenance/options`,
      this.filterOptionsParams
    );
  }

  async equipmentTypes(buildingIds, subSystemIds) {
    this.equipmentTypesParams = { buildingIds, subSystemIds };
    return this.http.post(
      `/preventative-maintenance/options/equipment-types`,
      this.equipmentTypesParams
    );
  }

  async assetTasks(
    buildingIds,
    equipmentTypeIds,
    metricFilters,
    useObserved,
    assetScoreFilter,
    inHouseFTEs,
    inHouseUtilization,
    inHouseFTELaborRate,
    outSourcedLaborRate,
    materialsMultiplier,
    updatedTasks
  ) {
    this.assetListParams = {
      buildingIds,
      equipmentTypeIds,
      metricFilters,
      useObserved,
      assetScoreFilter,
      inHouseFTEs,
      inHouseUtilization,
      inHouseFTELaborRate,
      outSourcedLaborRate,
      materialsMultiplier,
      updatedTasks,
    };
    return this.http.post(
      `/preventative-maintenance/tasks`,
      this.assetListParams,
      true
    );
  }

  //---- BASE SCENARIO TASKS ----
  async scenarios() {
    return this.http.get(`/preventative-maintenance/scenarios`);
  }

  async saveScenario(scenario) {
    return this.http.post(`/preventative-maintenance/scenario`, scenario);
  }

  async getScenario(scenarioId) {
    return this.http.get(`/preventative-maintenance/scenario/${scenarioId}`);
  }

  async updateScenario(scenario) {
    const _id = scenario.id;
    delete scenario.id;
    return this.http.put(`/preventative-maintenance/scenario/${_id}`, scenario);
  }

  async deleteScenario(scenarioId) {
    return this.http.delete(`/preventative-maintenance/scenario/${scenarioId}`);
  }

  //---- ADDITIONAL SCENARIO TASKS ----
  async getScenarioAssets(scenarioId) {
    return this.http.get(
      `/preventative-maintenance/scenario/${scenarioId}/assets`
    );
  }

  async getScenarioAccessList(scenarioId) {
    return this.http.get(
      `/preventative-maintenance/scenario/${scenarioId}/access-list`
    );
  }

  async shareScenario(scenarioId, userId) {
    return this.http.post(
      `/preventative-maintenance/scenario/${scenarioId}/share`,
      [userId]
    );
  }

  async unshareScenario(scenarioId, userIds) {
    return this.http.post(
      `/preventative-maintenance/scenario/${scenarioId}/remove-access`,
      userIds
    );
  }
}
