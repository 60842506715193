import { createAction, handleActions } from 'redux-actions';

export const types = {
  COSTS_CHART_REQUEST: 'app/buildings/COSTS_CHART_REQUEST',
  COSTS_CHART_RESPONSE: 'app/buildings/COSTS_CHART_RESPONSE',
  COSTS_OPTS_REQUEST: 'app/buildings/COSTS_OPTS_REQUEST',
  COSTS_OPTS_RESPONSE: 'app/buildings/COSTS_OPTS_RESPONSE',

  UNITS_CHART_REQUEST: 'app/buildings/UNITS_CHART_REQUEST',
  UNITS_CHART_RESPONSE: 'app/buildings/UNITS_CHART_RESPONSE',
  UNITS_OPTS_REQUEST: 'app/buildings/UNITS_OPTS_REQUEST',
  UNITS_OPTS_RESPONSE: 'app/buildings/UNITS_OPTS_RESPONSE',

  SAVINGS_TOTAL_REQUEST: 'app/buildings/SAVINGS_TOTAL_REQUEST',
  SAVINGS_TOTAL_RESPONSE: 'app/buildings/SAVINGS_TOTAL_RESPONSE',

  COSTS_SUMMARY_CHART_REQUEST: 'app/buildings/COSTS_CHART_SUMMARY_REQUEST',
  COSTS_SUMMARY_CHART_RESPONSE: 'app/buildings/COSTS_CHART_SUMMARY_RESPONSE',

  COSTS_COMPARISON_CHART_REQUEST:
    'app/buildings/COSTS_COMPARISON_CHART_REQUEST',
  COSTS_COMPARISON_CHART_RESPONSE:
    'app/buildings/COSTS_COMPARISON_CHART_RESPONSE',
  UNITS_COMPARISON_CHART_REQUEST:
    'app/buildings/UNITS_COMPARISON_CHART_REQUEST',
  UNITS_COMPARISON_CHART_RESPONSE:
    'app/buildings/UNITS_COMPARISON_CHART_RESPONSE',

  DEMAND_CHART_OPTS_REQUEST: 'app/buildings/DEMAND_CHART_OPTS_REQUEST',
  DEMAND_CHART_OPTS_RESPONSE: 'app/buildings/DEMAND_CHART_OPTS_RESPONSE',

  DEMAND_DAILY_CHART_OPTS_REQUEST:
    'app/buildings/DEMAND_DAILY_CHART_OPTS_REQUEST',
  DEMAND_DAILY_CHART_OPTS_RESPONSE:
    'app/buildings/DEMAND_DAILY_CHART_OPTS_RESPONSE',
  DEMAND_INTERVAL_CHART_OPTS_REQUEST:
    'app/buildings/DEMAND_INTERVAL_CHART_OPTS_REQUEST',
  DEMAND_INTERVAL_CHART_OPTS_RESPONSE:
    'app/buildings/DEMAND_INTERVAL_CHART_OPTS_RESPONSE',

  SERVICE_SUMMARY_REQUEST: 'app/buildings/SERVICE_SUMMARY_REQUEST',
  SERVICE_SUMMARY_RESPONSE: 'app/buildings/SERVICE_SUMMARY_RESPONSE',
  SERVICE_DETERMINANTS_REQUEST: 'app/buildings/SERVICE_DETERMINANTS_REQUEST',
  SERVICE_DETERMINANTS_RESPONSE: 'app/buildings/SERVICE_DETERMINANTS_RESPONSE',
  SERVICE_BILLS_REQUEST: 'app/buildings/SERVICE_BILLS_REQUEST',
  SERVICE_BILLS_RESPONSE: 'app/buildings/SERVICE_BILLS_RESPONSE',

  CREATE_BILL_REQUEST: 'app/buildings/CREATE_BILL_REQUEST',
  CREATE_BILL_RESPONSE: 'app/buildings/CREATE_BILL_RESPONSE',
  DELETE_BILL_REQUEST: 'app/buildings/DELETE_BILL_REQUEST',
  DELETE_BILL_RESPONSE: 'app/buildings/DELETE_BILL_RESPONSE',
  UPDATE_BILL_REQUEST: 'app/buildings/UPDATE_BILL_REQUEST',
  UPDATE_BILL_RESPONSE: 'app/buildings/UPDATE_BILL_RESPONSE',
};

export const actions = {
  costsChartRequest: createAction(types.COSTS_CHART_REQUEST),
  costsChartResponse: createAction(types.COSTS_CHART_RESPONSE),
  costsOptsRequest: createAction(types.COSTS_OPTS_REQUEST),
  costsOptsResponse: createAction(types.COSTS_OPTS_RESPONSE),

  unitsChartRequest: createAction(types.UNITS_CHART_REQUEST),
  unitsChartResponse: createAction(types.UNITS_CHART_RESPONSE),
  unitsOptsRequest: createAction(types.UNITS_OPTS_REQUEST),
  unitsOptsResponse: createAction(types.UNITS_OPTS_RESPONSE),

  savingsTotalRequest: createAction(types.SAVINGS_TOTAL_REQUEST),
  savingsTotalResponse: createAction(types.SAVINGS_TOTAL_RESPONSE),

  costsSummaryChartRequest: createAction(types.COSTS_SUMMARY_CHART_REQUEST),
  costsSummaryChartResponse: createAction(types.COSTS_SUMMARY_CHART_RESPONSE),

  costsComparisonChartRequest: createAction(
    types.COSTS_COMPARISON_CHART_REQUEST
  ),
  costsComparisonChartResponse: createAction(
    types.COSTS_COMPARISON_CHART_RESPONSE
  ),
  unitsComparisonChartRequest: createAction(
    types.UNITS_COMPARISON_CHART_REQUEST
  ),
  unitsComparisonChartResponse: createAction(
    types.UNITS_COMPARISON_CHART_RESPONSE
  ),

  demandChartOptsRequest: createAction(types.DEMAND_CHART_OPTS_REQUEST),
  demandChartOptsResponse: createAction(types.DEMAND_CHART_OPTS_RESPONSE),
  demandDailyChartRequest: createAction(types.DEMAND_DAILY_CHART_OPTS_REQUEST),
  demandDailyChartResponse: createAction(
    types.DEMAND_DAILY_CHART_OPTS_RESPONSE
  ),
  demandIntervalChartRequest: createAction(
    types.DEMAND_INTERVAL_CHART_OPTS_REQUEST
  ),
  demandIntervalChartResponse: createAction(
    types.DEMAND_INTERVAL_CHART_OPTS_RESPONSE
  ),

  serviceSummaryRequest: createAction(types.SERVICE_SUMMARY_REQUEST),
  serviceSummaryResponse: createAction(types.SERVICE_SUMMARY_RESPONSE),
  serviceDeterminantsRequest: createAction(types.SERVICE_DETERMINANTS_REQUEST),
  serviceDeterminantsResponse: createAction(
    types.SERVICE_DETERMINANTS_RESPONSE
  ),
  serviceBillsRequest: createAction(types.SERVICE_BILLS_REQUEST),
  serviceBillsResponse: createAction(types.SERVICE_BILLS_RESPONSE),

  createBillRequest: createAction(types.CREATE_BILL_REQUEST),
  createBillResponse: createAction(types.CREATE_BILL_RESPONSE),
  deleteBillRequest: createAction(types.DELETE_BILL_REQUEST),
  deleteBillResponse: createAction(types.DELETE_BILL_RESPONSE),
  updateBillRequest: createAction(types.UPDATE_BILL_REQUEST),
  updateBillResponse: createAction(types.UPDATE_BILL_RESPONSE),
};

const defaultState = {
  isRefreshing: false,
  costsOpts: null,
  unitsOpts: null,
  serviceSummary: [],
  serviceSummaryUpdating: false,
  serviceBills: [],
  serviceDeterminants: [],
  serviceDeterminantsUpdating: false,
  serviceBillsUpdating: false,
  billCreating: false,
  billCreateError: null,
  billUpdating: false,
  billUpdateError: null,
  billDeleting: false,
  billDeleteError: null,
};

// - - - FETCH UTILS COSTS CHART - - -
// - - - FETCH UTILS COSTS CHART - - -
// - - - FETCH UTILS COSTS CHART - - -

export const fetchUtilCostsChart = (
  buildingIds,
  billSourceIds,
  billSourceGroupIds,
  beginDate,
  endDate,
  costSourceType,
  isYearOverYear
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.costsChartRequest());
  try {
    const { data } = await api.utility.costsChart(
      buildingIds,
      billSourceIds.length ? billSourceIds : [],
      billSourceGroupIds.length ? billSourceGroupIds : [],
      beginDate,
      endDate,
      costSourceType,
      isYearOverYear
    );
    if (data.chart) {
      data.chart.data.datasets.forEach(_d => {
        _d.hidden = false;
      });
    }
    data.chart.isValid = data.chart.data.datasets.length > 0;
    return dispatch(actions.costsChartResponse(data));
  } catch (err) {
    return dispatch(actions.costsChartResponse(err));
  }
};

// - - - FETCH UTILS COSTS OPTS - - -
// - - - FETCH UTILS COSTS OPTS - - -
// - - - FETCH UTILS COSTS OPTS - - -

export const fetchUtilCostsOpts = (buildingIds, beginDate, endDate) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.costsOptsRequest());
  try {
    const { data } = await api.utility.costsChart(
      buildingIds,
      [],
      [],
      beginDate,
      endDate,
      0,
      false
    );
    return dispatch(actions.costsOptsResponse(data));
  } catch (err) {
    return dispatch(actions.costsOptsResponse(err));
  }
};

// - - - FETCH UTILS UNITS CHART - - -
// - - - FETCH UTILS UNITS CHART - - -
// - - - FETCH UTILS UNITS CHART - - -

export const fetchUtilUnitsChart = (
  buildingIds,
  billSourceIds,
  beginDate,
  endDate,
  isYearOverYear
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.unitsChartRequest());
  try {
    const { data } = await api.utility.unitsChart(
      buildingIds,
      billSourceIds,
      beginDate,
      endDate,
      isYearOverYear
    );
    data.chart.isValid = data.chart.data.datasets.length > 0;
    return dispatch(actions.unitsChartResponse(data));
  } catch (err) {
    return dispatch(actions.unitsChartResponse(err));
  }
};

// - - - FETCH UTILS UNITS OPTS - - -
// - - - FETCH UTILS UNITS OPTS - - -
// - - - FETCH UTILS UNITS OPTS - - -

export const fetchUtilUnitsOpts = (buildingIds, beginDate, endDate) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.unitsOptsRequest());
  try {
    const { data } = await api.utility.unitsChart(
      buildingIds,
      [],
      beginDate,
      endDate,
      false
    );
    return dispatch(actions.unitsOptsResponse(data));
  } catch (err) {
    return dispatch(actions.unitsOptsResponse(err));
  }
};

// - - - FETCH UTILS COSTS SUMMARY CHART - - -
// - - - FETCH UTILS COSTS SUMMARY CHART - - -
// - - - FETCH UTILS COSTS SUMMARY CHART - - -

export const fetchUtilCostsSummaryChart = (
  buildingIds,
  beginDate,
  endDate
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.costsSummaryChartRequest());
  try {
    const { data } = await api.utility.costSummaryChart(
      buildingIds,
      beginDate,
      endDate
    );
    data.chart.isValid = data.chart.data.datasets.length > 0;
    return dispatch(actions.costsSummaryChartResponse(data));
  } catch (err) {
    return dispatch(actions.costsSummaryChartResponse(err));
  }
};

// - - - FETCH SAVINGS TOTAL - - -
// - - - FETCH SAVINGS TOTAL - - -
// - - - FETCH SAVINGS TOTAL - - -

export const fetchSavingsTotal = (
  buildingIds,
  beginDate,
  endDate,
  aliasId = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.savingsTotalRequest());
  try {
    const { data } = await api.utility.savingsTotal(
      buildingIds,
      beginDate,
      endDate,
      aliasId
    );
    data.isValid = data.billSourceSavings.length > 0;
    return dispatch(actions.savingsTotalResponse(data));
  } catch (err) {
    return dispatch(actions.savingsTotalResponse(err));
  }
};

// - - - FETCH COSTS COMPARISON CHART - - -
// - - - FETCH COSTS COMPARISON CHART - - -
// - - - FETCH COSTS COMPARISON CHART - - -

export const fetchCostsComparisonChart = (
  buildingIds,
  billSourceIds,
  billSourceGroupIds,
  beginDate,
  endDate,
  costSourceType,
  aliasId = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.costsComparisonChartRequest());
  try {
    const { data } = await api.utility.costsComparison(
      buildingIds,
      billSourceIds.length ? billSourceIds : [],
      billSourceGroupIds.length ? billSourceGroupIds : [],
      beginDate,
      endDate,
      costSourceType,
      aliasId
    );
    data.chart.isValid = data.chart.data.datasets.length > 0;
    return dispatch(actions.costsComparisonChartResponse(data));
  } catch (err) {
    return dispatch(actions.costsComparisonChartResponse(err));
  }
};

// - - - FETCH UNITS COMPARISON CHART - - -
// - - - FETCH UNITS COMPARISON CHART - - -
// - - - FETCH UNITS COMPARISON CHART - - -

export const fetchUnitsComparisonChart = (
  buildingIds,
  billSourceIds,
  beginDate,
  endDate,
  aliasId = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.unitsComparisonChartRequest());
  try {
    const { data } = await api.utility.unitsComparison(
      buildingIds,
      billSourceIds.length ? billSourceIds : [],
      beginDate,
      endDate,
      aliasId
    );
    data.chart.isValid = data.chart.data.datasets.length > 0;
    return dispatch(actions.unitsComparisonChartResponse(data));
  } catch (err) {
    return dispatch(actions.unitsComparisonChartResponse(err));
  }
};

// - - - FETCH DEMAND CHART OPTS - - -
// - - - FETCH DEMAND CHART OPTS - - -
// - - - FETCH DEMAND CHART OPTS - - -

export const fetchDemandChartOpts = (buildingIds, beginDate, endDate) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.demandChartOptsRequest());
  try {
    const { data } = await api.utility.unitDemandDailyChart(
      buildingIds,
      [],
      [],
      beginDate,
      endDate
    );
    return dispatch(actions.demandChartOptsResponse(data));
  } catch (err) {
    return dispatch(actions.demandChartOptsResponse(err));
  }
};

// - - - FETCH DEMAND DAILY CHART - - -
// - - - FETCH DEMAND DAILY CHART - - -
// - - - FETCH DEMAND DAILY CHART - - -

export const fetchDemandDailyChart = (
  buildingIds,
  billSourceGroupIds,
  billSourceIds,
  beginDate,
  endDate
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.demandDailyChartRequest());
  try {
    const { data } = await api.utility.unitDemandDailyChart(
      buildingIds,
      billSourceGroupIds,
      billSourceIds,
      beginDate,
      endDate
    );
    data.chart.isValid = data.chart.data.length > 0;
    return dispatch(actions.demandDailyChartResponse(data));
  } catch (err) {
    return dispatch(actions.demandDailyChartResponse(err));
  }
};

// - - - FETCH DEMAND INTERVAL CHART - - -
// - - - FETCH DEMAND INTERVAL CHART - - -
// - - - FETCH DEMAND INTERVAL CHART - - -

export const fetchDemandIntervalChart = (
  buildingIds,
  billSourceGroupIds,
  billSourceIds,
  beginDate,
  endDate
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.demandIntervalChartRequest());
  try {
    const { data } = await api.utility.unitDemandIntervalChart(
      buildingIds,
      billSourceGroupIds,
      billSourceIds,
      beginDate,
      endDate
    );
    data.chart.isValid = data.chart.data.length > 0;
    return dispatch(actions.demandIntervalChartResponse(data));
  } catch (err) {
    return dispatch(actions.demandIntervalChartResponse(err));
  }
};

export const fetchServiceSummary = (buildingIds, beginDate, endDate) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.serviceSummaryRequest());
  try {
    const { data } = await api.utility.serviceSummary(
      buildingIds,
      beginDate,
      endDate
    );
    return dispatch(actions.serviceSummaryResponse(data));
  } catch (err) {
    return dispatch(actions.serviceSummaryResponse(err));
  }
};

export const fetchServiceDeterminants = serviceId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.serviceDeterminantsRequest());
  try {
    const { data } = await api.utility.serviceDeterminants(serviceId);

    // fill classname and id
    for (const o of data) {
      if (o.classId === null) {
        o.classId = -1;
        o.className = '(unclassified)';
      }
    }

    return dispatch(actions.serviceDeterminantsResponse(data));
  } catch (err) {
    return dispatch(actions.serviceDeterminantsResponse(err));
  }
};

export const fetchServiceBills = (serviceId, beginDate, endDate) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.serviceBillsRequest());
  try {
    const { data } = await api.utility.serviceBills(
      serviceId,
      beginDate,
      endDate
    );
    return dispatch(actions.serviceBillsResponse(data));
  } catch (err) {
    return dispatch(actions.serviceBillsResponse(err));
  }
};

export const createBill = (serviceId, billData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.createBillRequest());
  try {
    const { data } = await api.utility.postBill(serviceId, billData);
    return dispatch(actions.createBillResponse(data));
  } catch (err) {
    return dispatch(actions.createBillResponse(err));
  }
};

export const updateBill = (billId, billData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.updateBillRequest());
  try {
    const { data } = await api.utility.putBill(billId, billData);
    return dispatch(actions.updateBillResponse(data));
  } catch (err) {
    return dispatch(actions.updateBillResponse(err));
  }
};

export const deleteBill = billId => async (dispatch, _getState, { api }) => {
  dispatch(actions.deleteBillRequest());
  try {
    const { data } = await api.utility.deleteBill(billId);
    return dispatch(actions.deleteBillResponse(data));
  } catch (err) {
    return dispatch(actions.deleteBillResponse(err));
  }
};
// - - - ACTIONS - - -
// - - - ACTIONS - - -
// - - - ACTIONS - - -

export default handleActions(
  {
    [actions.costsChartRequest]: {
      next: state => ({
        ...state,
        costsChart: null,
        isRefreshing: true,
      }),
    },

    [actions.costsChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        costsChart: payload.chart,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.costsOptsRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.costsOptsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        costsOpts: payload.sourceMap,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    // - - - - - - -

    [actions.unitsChartRequest]: {
      next: state => ({
        ...state,
        unitsChart: null,
        isRefreshing: true,
      }),
    },

    [actions.unitsChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        unitsChart: payload.chart,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.unitsOptsRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.unitsOptsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        unitsOpts: payload.sourceMap,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.costsSummaryChartRequest]: {
      next: state => ({
        ...state,
        costsSummaryChart: null,
        isRefreshing: true,
      }),
    },

    [actions.costsSummaryChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        costsSummaryChart: payload.chart,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.savingsTotalRequest]: {
      next: state => ({
        ...state,
        totalSavings: null,
        isRefreshing: true,
      }),
    },

    [actions.savingsTotalResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        totalSavings: payload.totalSavings,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.costsComparisonChartRequest]: {
      next: state => ({
        ...state,
        costsCompareChart: null,
        isRefreshing: true,
      }),
    },

    [actions.costsComparisonChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        costsCompareChart: payload.chart,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.unitsComparisonChartRequest]: {
      next: state => ({
        ...state,
        unitsCompareChart: null,
        isRefreshing: true,
      }),
    },

    [actions.unitsComparisonChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        unitsCompareChart: payload.chart,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.demandChartOptsRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.demandChartOptsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        demandChartOpts: payload.demandMap,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.demandDailyChartRequest]: {
      next: state => ({
        ...state,
        demandDailyChart: null,
        isRefreshing: true,
      }),
    },

    [actions.demandDailyChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        demandDailyChart: payload.chart,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.demandIntervalChartRequest]: {
      next: state => ({
        ...state,
        demandIntervalChart: null,
        temperatureChart: null,
        isRefreshing: true,
      }),
    },

    [actions.demandIntervalChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        demandIntervalChart: payload.chart,
        temperatureChart: payload.outsideTemperatureChart,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.serviceSummaryRequest]: {
      next: state => ({
        ...state,
        serviceSummaryUpdating: true,
      }),
    },

    [actions.serviceSummaryResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        serviceSummaryUpdating: false,
        serviceSummary: payload,
      }),

      throw: (state, { payload }) => ({
        ...state,
        serviceSummaryUpdating: false,
        message: payload.response.data,
      }),
    },

    [actions.serviceDeterminantsRequest]: {
      next: state => ({
        ...state,
        serviceDeterminantsUpdating: true,
      }),
    },

    [actions.serviceDeterminantsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        serviceDeterminantsUpdating: false,
        serviceDeterminants: payload,
      }),

      throw: (state, { payload }) => ({
        ...state,
        serviceDeterminantsUpdating: false,
        message: payload.response.data,
      }),
    },

    [actions.serviceBillsRequest]: {
      next: state => ({
        ...state,
        serviceBillsUpdating: true,
      }),
    },

    [actions.serviceBillsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        serviceBillsUpdating: false,
        serviceBills: payload,
      }),

      throw: (state, { payload }) => ({
        ...state,
        serviceBillsUpdating: false,
        message: payload.response.data,
      }),
    },

    [actions.createBillRequest]: {
      next: state => ({
        ...state,
        billCreating: true,
      }),
    },

    [actions.createBillResponse]: {
      next: _state => ({
        ..._state,
        billCreating: false,
        billCreateError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        billCreating: false,
        billCreateError: payload.response.data,
      }),
    },

    [actions.updateBillRequest]: {
      next: state => ({
        ...state,
        billUpdating: true,
      }),
    },

    [actions.updateBillResponse]: {
      next: _state => ({
        ..._state,
        billUpdating: false,
        billUpdateError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        billUpdating: false,
        billUpdateError: payload.response.data,
      }),
    },

    [actions.deleteBillRequest]: {
      next: state => ({
        ...state,
        billDeleting: true,
      }),
    },

    [actions.deleteBillResponse]: {
      next: _state => ({
        ..._state,
        billDeleting: false,
        billDeleteError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        billDeleting: false,
        billDeleteError: payload.response.data,
      }),
    },
  },

  defaultState
);
