import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';

const defaultState = {
  isRefreshing: false,
  chart: null,
  treemap: null,
};

export const types = {
  OPTIONS_REQUEST: 'app/kpis/OPTIONS_REQUEST',
  OPTIONS_RESPONSE: 'app/kpis/OPTIONS_RESPONSE',

  TREEMAP_REQUEST: 'app/kpis/TREEMAP_REQUEST',
  TREEMAP_RESPONSE: 'app/kpis/TREEMAP_RESPONSE',

  CHART_REQUEST: 'app/kpis/CHART_REQUEST',
  CHART_RESPONSE: 'app/kpis/CHART_RESPONSE',

  ZOOMED_CHART_REQUEST: 'app/kpis/ZOOMED_CHART_REQUEST',
  ZOOMED_CHART_RESPONSE: 'app/kpis/ZOOMED_CHART_RESPONSE',

  SORT_TREEMAP: 'app/kpis/SORT_TREEMAP',
  CLEAR_TREEMAP: 'app/kpis/CLEAR_TREEMAP',
};

export const actions = {
  optionsRequest: createAction(types.OPTIONS_REQUEST),
  optionsResponse: createAction(types.OPTIONS_RESPONSE),

  treemapRequest: createAction(types.TREEMAP_REQUEST),
  treemapResponse: createAction(types.TREEMAP_RESPONSE),

  chartRequest: createAction(types.CHART_REQUEST),
  chartResponse: createAction(types.CHART_RESPONSE),

  zoomedChartRequest: createAction(types.ZOOMED_CHART_REQUEST),
  zoomedChartResponse: createAction(types.ZOOMED_CHART_RESPONSE),

  sortTreemap: createAction(types.SORT_TREEMAP),
  clearTreemap: createAction(types.CLEAR_TREEMAP),
};

// - - - FETCH OPTIONS - - -
export const fetchOptions = buildings => async (
  dispatch,
  getState,
  { api }
) => {
  dispatch(actions.optionsRequest());
  try {
    const { data } = await api.kpis.options(buildings);
    return dispatch(actions.optionsResponse(data));
  } catch (error) {
    return dispatch(actions.optionsRequest(error));
  }

};

// - - - FETCH TREEMAP - - -
export const fetchTreemap = (
  buildings,
  kpis,
  subsystems,
  beginDate,
  endDate,
  drillPath,
  isSortAscending,
  hierarchyType
) => async (dispatch, getState, { api }) => {
  dispatch(actions.treemapRequest());
  try {
    var childNodeType = hierarchyType === 1 ? 'building' : 'kpi';

    // Iterate over the drillPath to determine the next nodeType to request.
    // For each node on the drillPath, additionally reduce the query set.
    for (let node of drillPath) {
      switch (node.nodeType) {
        case 'kpi':
          kpis = [node.nodeId];
          childNodeType = 'building';
          break;
        case 'building':
          buildings = [node.nodeId];
          childNodeType = 'system';
          break;
        case 'system':
          subsystems = [node.nodeId];
          childNodeType = 'equipment';
          break;
      }
    }

    const { data } = await api.kpis.treemap(
      buildings,
      kpis,
      subsystems,
      beginDate,
      endDate,
      childNodeType,
      hierarchyType
    );

    const { treemap } = getState().kpis;
    if (!treemap || treemap.children.length === 0) {
      const newTreemap = recursiveSortTreemap(
        {
          nodeType: 'root',
          nodeId: -1,
          title: 'All Sites',
          color: '#FFFFFF',
          children: mapTreemapResponse(data),
        },
        isSortAscending
      );

      return dispatch(actions.treemapResponse(newTreemap));
    }

    // Merge and sort the treemap.
    const merged = recursiveSortTreemap(
      mergeTreemap(treemap, data, drillPath),
      isSortAscending
    );

    return dispatch(actions.treemapResponse(Object.assign({}, merged)));
  } catch (error) {
    return dispatch(actions.treemapResponse(error))
  }
};

function mergeTreemap(existingTreemap, newTreemap, drillPath) {
  var currNode = existingTreemap;
  for (let { nodeId } of drillPath) {
    currNode = currNode.children.find(x => x.nodeId === nodeId);
  }

  currNode.children = mapTreemapResponse(newTreemap);
  return existingTreemap;
}

function mapTreemapResponse(treemapResponse) {
  return treemapResponse.kpis.map(kpi => {
    var children;
    if (kpi.children) {
      // Some responses additionally contain children.
      children = kpi.children.map(x => ({
        title: x.name,
        nodeId: x.nodeId,
        color: x.hexColor,
        score: x.score,
        size: x.kpiCount ?? 1,
        nodeType: x.nodeType,
      }));
    }

    return {
      title: kpi.name,
      nodeId: kpi.nodeId,
      color: kpi.hexColor,
      score: kpi.score,
      size: kpi.kpiCount,
      nodeType: kpi.nodeType,
      children,
    };
  });
}

// - - - SORT TREEMAP - - -
export const sortTreemap = isSortAscending => async (dispatch, getState) => {
  const { treemap } = getState().kpis;
  const sorted = recursiveSortTreemap(treemap, isSortAscending);
  return dispatch(actions.sortTreemap(Object.assign({}, sorted)));
};

const recursiveSortTreemap = (node, isSortAscending) => {
  if (!node.children || !node.children.length) {
    return node;
  }

  node.children.sort(({ title: a }, { title: b }) => {
    if (a > b) {
      return isSortAscending ? 1 : -1;
    } else {
      return isSortAscending ? -1 : 1;
    }
  });

  node.children = node.children.map(x =>
    recursiveSortTreemap(x, isSortAscending)
  );

  return node;
};

// - - - FETCH CHART - - -
export const fetchChart = (id, beginDate, endDate) => async (
  dispatch,
  getState,
  { api }
) => {
  dispatch(actions.chartRequest());
  try {
    const { data } = await api.kpis.historyChart(id, beginDate, endDate);
    data.chart.data.forEach((_d, _idx) => {
      _d.label = _d.x;
      _d.date = _d.x;
      _d.x = _idx;
    });

    return dispatch(actions.chartResponse(data));
  } catch (error) {
    return dispatch(actions.chartResponse(error));
  }
};

// - - - FETCH ZOOMED CHART - - -
export const fetchZoomedChart = (id, beginDate, endDate) => async (
  dispatch,
  getState,
  { api }
) => {
  dispatch(actions.zoomedChartRequest());
  try {
    const { data } = await api.kpis.historyChart(id, beginDate, endDate);
    data.chart.data.forEach((_d, _idx) => {
      _d.label = _d.x;
      _d.date = moment(_d.x);
      _d.x = _idx;
    });
    return dispatch(actions.zoomedChartResponse(data));
  } catch (error) {
    return dispatch(actions.zoomedChartResponse(error));
  }
};

export const clearTreemap = () => async (dispatch, getState, { api }) => {
  return dispatch(actions.clearTreemap());
};

// - - - ACTIONS - - -
export default handleActions(
  {
    [actions.optionsRequest]: {
      next: state => ({
        ...state,
        options: null,
      }),
    },
    [actions.optionsResponse]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          options: payload,
        };
      },
      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
      }),
    },
    [actions.treemapResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        treemap: payload,
      }),
      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
      }),
    },
    [actions.chartRequest]: {
      next: state => ({
        ...state,
        chart: null,
        chartLoading: true,
      }),
    },
    [actions.chartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        chart: payload,
        chartLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        chartLoading: false,
      }),
    },
    [actions.zoomedChartRequest]: {
      next: state => ({
        ...state,
        chartLoading: true,
      }),
    },
    [actions.zoomedChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        zoomedChart: payload,
        chartLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        chartLoading: false,
      }),
    },
    [actions.sortTreemap]: {
      next: (_state, { payload }) => ({
        ..._state,
        treemap: payload,
      }),
      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
      }),
    },
    [actions.clearTreemap]: {
      next: (_state, { payload }) => ({
        ..._state,
        treemap: null,
      }),
      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
      }),
    },
  },
  defaultState
);
