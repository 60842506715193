import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { dbMP_getTasks } from './dbTools/dbPrevMaintenance';
import { updateMPTaskState } from './calculations/maintenancePlanningState';
import { mergeArrays } from '../utilities/arrayUtils';

import { getDistributionAssets } from '../routes/MED/helpers/MEDPrevMaintenance';

export const types = {
  ASSET_TASK_REQUEST: 'app/prevMaint/ASSET_TASK_REQUEST',
  ASSET_TASK_RESPONSE: 'app/prevMaint/ASSET_TASK_RESPONSE',
  FILTER_OPTS_REQUEST: 'app/prevMaint/FILTER_OPTS_REQUEST',
  FILTER_OPTS_RESPONSE: 'app/prevMaint/FILTER_OPTS_RESPONSE',
  EQUIPMENT_TYPES_REQUEST: 'app/prevMaint/EQUIPMENT_TYPES_REQUEST',
  EQUIPMENT_TYPES_RESPONSE: 'app/prevMaint/EQUIPMENT_TYPES_RESPONSE',
  SELECT_SUBSYSTEMS: 'app/prevMaint/SELECT_SUBSYSTEMS',
  SELECT_EQUIPMENT_TYPES: 'app/prevMaint/SELECT_EQUIPMENT_TYPES',
  UPDATE_COSTCALCS: 'app/prevMaint/UPDATE_COSTCALCS',
  UPDATE_METRIC: 'app/prevMaint/UPDATE_METRIC',
  UPDATE_REPLACEMENTS: 'app/prevMaint/UPDATE_REPLACEMENTS',
  SELECT_LIFE_INDICATOR: 'app/prevMaint/SELECT_LIFE_INDICATOR',
  ASSET_LIST_UPDATE: 'app/prevMaint/ASSET_LIST_UPDATE',
  SELECT_EQUIPMENT_DETAIL: 'app/prevMaint/SELECT_EQUIPMENT_DETAIL',
  GET_ASSET_DIST_CHART_DATA: 'app/prevMaint/GET_ASSET_DIST_CHART_DATA',

  // = = = SCENARIOS = = =
  SCENARIOS_REQUEST: 'app/prevMaint/SCENARIOS_REQUEST',
  SCENARIOS_RESPONSE: 'app/prevMaint/SCENARIOS_RESPONSE',
  SAVE_SCENARIO_REQUEST: 'app/prevMaint/SAVE_SCENARIO_REQUEST',
  SAVE_SCENARIO_RESPONSE: 'app/prevMaint/SAVE_SCENARIO_RESPONSE',
  UPDATE_SCENARIO_REQUEST: 'app/prevMaint/UPDATE_SCENARIO_REQUEST',
  UPDATE_SCENARIO_RESPONSE: 'app/prevMaint/UPDATE_SCENARIO_RESPONSE',
  SET_ACTIVE_SCENARIO_REQUEST: 'app/prevMaint/SET_ACTIVE_SCENARIO_REQUEST',
  SET_ACTIVE_SCENARIO_RESPONSE: 'app/prevMaint/SET_ACTIVE_SCENARIO_RESPONSE',
  DELETE_SCENARIO_REQUEST: 'app/prevMaint/DELETE_SCENARIO_REQUEST',
  DELETE_SCENARIO_RESPONSE: 'app/prevMaint/DELETE_SCENARIO_RESPONSE',
  SCENARIO_ACCESS_REQUEST: 'app/prevMaint/SCENARIO_ACCESS_REQUEST',
  SCENARIO_ACCESS_RESPONSE: 'app/prevMaint/SCENARIO_ACCESS_RESPONSE',
  SHARE_SCENARIO_REQUEST: 'app/prevMaint/SHARE_SCENARIO_REQUEST',
  SHARE_SCENARIO_RESPONSE: 'app/prevMaint/SHARE_SCENARIO_RESPONSE',
  UNSHARE_SCENARIO_REQUEST: 'app/prevMaint/UNSHARE_SCENARIO_REQUEST',
  UNSHARE_SCENARIO_RESPONSE: 'app/prevMaint/UNSHARE_SCENARIO_RESPONSE',
  UPDATE_SCENARIO_TASKS: 'app/prevMaint/UPDATE_SCENARIO_TASKS',
  UPDATE_TASK_SCHEDULE: 'app/prevMaint/UPDATE_TASK_SCHEDULE',
  UPDATE_TASKS: 'app/prevMaint/UPDATE_TASKS',
  CLEAR_ACTIVE_SCENARIO: 'app/prevMaint/CLEAR_ACTIVE_SCENARIO',

  // = = = = UI = = = =
  UI_OPEN_SAVE_SCENARIO_MODAL: 'app/prevMaint/UI_OPEN_SAVE_SCENARIO_MODAL',
  UI_CLOSE_SAVE_SCENARIO_MODAL: 'app/prevMaint/UI_CLOSE_SAVE_SCENARIO_MODAL',
};

export const actions = {
  assetTaskRequest: createAction(types.ASSET_TASK_REQUEST),
  assetTaskResponse: createAction(types.ASSET_TASK_RESPONSE),
  filterOptsRequest: createAction(types.FILTER_OPTS_REQUEST),
  filterOptsResponse: createAction(types.FILTER_OPTS_RESPONSE),
  equipmentTypesRequest: createAction(types.EQUIPMENT_TYPES_REQUEST),
  equipmentTypesResponse: createAction(types.EQUIPMENT_TYPES_RESPONSE),
  selectSubsystems: createAction(types.SELECT_SUBSYSTEMS),
  selectEquipmentTypes: createAction(types.SELECT_EQUIPMENT_TYPES),
  updateCostCalcs: createAction(types.UPDATE_COSTCALCS),
  updateMetric: createAction(types.UPDATE_METRIC),
  updateReplacements: createAction(types.UPDATE_REPLACEMENTS),
  selectLifeIndicator: createAction(types.SELECT_LIFE_INDICATOR),
  assetListUpdate: createAction(types.ASSET_LIST_UPDATE),
  selectEquipmentDetail: createAction(types.SELECT_EQUIPMENT_DETAIL),
  getAssetDistChartData: createAction(types.GET_ASSET_DIST_CHART_DATA),

  // = = = SCENARIOS = = =
  clearActiveScenario: createAction(types.CLEAR_ACTIVE_SCENARIO),
  scenariosRequest: createAction(types.SCENARIOS_REQUEST),
  scenariosResponse: createAction(types.SCENARIOS_RESPONSE),
  saveScenarioRequest: createAction(types.SAVE_SCENARIO_REQUEST),
  saveScenarioResponse: createAction(types.SAVE_SCENARIO_RESPONSE),
  updateScenarioRequest: createAction(types.UPDATE_SCENARIO_REQUEST),
  updateScenarioResponse: createAction(types.UPDATE_SCENARIO_RESPONSE),
  setActiveScenarioRequest: createAction(types.SET_ACTIVE_SCENARIO_REQUEST),
  setActiveScenarioResponse: createAction(types.SET_ACTIVE_SCENARIO_RESPONSE),
  deleteScenarioRequest: createAction(types.DELETE_SCENARIO_REQUEST),
  deleteScenarioResponse: createAction(types.DELETE_SCENARIO_RESPONSE),
  scenarioAccessRequest: createAction(types.SCENARIO_ACCESS_REQUEST),
  scenarioAccessResponse: createAction(types.SCENARIO_ACCESS_RESPONSE),
  shareScenarioRequest: createAction(types.SHARE_SCENARIO_REQUEST),
  shareScenarioResponse: createAction(types.SHARE_SCENARIO_RESPONSE),
  unshareScenarioRequest: createAction(types.UNSHARE_SCENARIO_REQUEST),
  unshareScenarioResponse: createAction(types.UNSHARE_SCENARIO_RESPONSE),
  updateScenarioTasks: createAction(types.UPDATE_SCENARIO_TASKS),
  updateTaskSchedule: createAction(types.UPDATE_TASK_SCHEDULE),
  updateTasks: createAction(types.UPDATE_TASKS),

  // = = = =  UI  = = = =
  uiOpenSaveScenarioModal: createAction(types.UI_OPEN_SAVE_SCENARIO_MODAL),
  uiCloseSaveScenarioModal: createAction(types.UI_CLOSE_SAVE_SCENARIO_MODAL),
};

const defaultState = {
  accessList: null,
  activeScenario: null,
  assets: [],
  assetKey: 0,
  assetDistChartData: null,
  bucketHexColors: [],
  costCalcs: null,
  equipmentDetail: null,
  filterOpts: null,
  isDeletingScenario: false,
  isLoadingScenario: false,
  isRefreshing: false,
  isRefreshingCashFlow: false,
  isRequestingAssets: false,
  isRequestingTasks: false,
  isSavingScenario: false,
  isScenarioRefreshing: false,
  isSummaryRefreshing: false,
  isUpdatingScenatio: false,
  metricsRaw: [],
  metricFilters: [],
  metricScores: [],
  replacements: [],
  scenarios: [],
  selectedEquipmentTypes: [],
  selectedSubsystems: [],
  taskKey: 0,
  updatedTasks: {},
  tasksRaw: [],
  useObserved: true,
  showSaveModal: false,
  saveModalType: undefined,
};

// MAINTENANCE PLANNING :: CRUD for Scenarios  ////////////////////////////////
export const updateTasks = newTasks => async (dispatch, _getState) => {
  const updatedTasks = updateMPTaskState(
    _getState().prevMaintenance.assets,
    _getState().prevMaintenance.updatedTasks,
    newTasks
  );
  return dispatch(actions.updateTasks(updatedTasks));
};

export const fetchScenarios = siteId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scenariosRequest());
  try {
    const { data } = await api.prevmaintenance.scenarios(siteId);
    return dispatch(actions.scenariosResponse(data));
  } catch (err) {
    return dispatch(actions.scenariosResponse(err));
  }
};

export const saveScenario = _scenario => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.saveScenarioRequest());
  try {
    const { data } = await api.prevmaintenance.saveScenario(_scenario);
    return dispatch(actions.saveScenarioResponse(data));
  } catch (err) {
    return dispatch(actions.saveScenarioResponse(err));
  }
};

export const updateScenario = _scenario => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.updateScenarioRequest());
  try {
    const { data } = await api.prevmaintenance.updateScenario(_scenario);
    return dispatch(actions.updateScenarioResponse(data));
  } catch (err) {
    return dispatch(actions.updateScenarioResponse(err));
  }
};

export const setActiveScenario = _id => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.setActiveScenarioRequest());
  try {
    const { data } = await api.prevmaintenance.getScenario(_id);
    return dispatch(actions.setActiveScenarioResponse(data));
  } catch (err) {
    return dispatch(actions.setActiveScenarioResponse(err));
  }
};

export const deleteScenario = _id => async (dispatch, _getState, { api }) => {
  dispatch(actions.deleteScenarioRequest());
  try {
    await api.prevmaintenance.deleteScenario(_id);
    return dispatch(actions.deleteScenarioResponse(_id));
  } catch (err) {
    return dispatch(actions.deleteScenarioResponse(err));
  }
};

export const scenarioAccessList = _id => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.scenarioAccessRequest());
  try {
    const { data } = await api.prevmaintenance.getScenarioAccessList(_id);
    return dispatch(actions.scenarioAccessResponse(data));
  } catch (err) {
    return dispatch(actions.scenarioAccessResponse(err));
  }
};

export const shareScenario = (_id, _userid) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.shareScenarioRequest());
  try {
    const { data } = await api.prevmaintenance.shareScenario(_id, {
      userId: _userid,
    });
    return dispatch(actions.shareScenarioResponse(data));
  } catch (err) {
    return dispatch(actions.shareScenarioResponse(err));
  }
};

export const unshareScenario = (_id, _userid) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.unshareScenarioRequest());
  try {
    const { data } = await api.prevmaintenance.unshareScenario(_id, [_userid]);
    return dispatch(actions.unshareScenarioResponse(data));
  } catch (err) {
    return dispatch(actions.unshareScenarioResponse(err));
  }
};

export const clearActiveScenario = () => async dispatch => {
  return dispatch(actions.clearActiveScenario());
};

// MAINTENANCE PLANNING :: POPULATE FILTERS, ETC //////////////////////////////
export const fetchOptions = (siteId, buildingIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.filterOptsRequest());
  try {
    const { data } = await api.prevmaintenance.filterOptions(
      siteId,
      buildingIds
    );
    return dispatch(actions.filterOptsResponse(data));
  } catch (err) {
    return dispatch(actions.filterOptsResponse(err));
  }
};

export const fetchEquipmentTypes = (buildingIds, subsystemIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.equipmentTypesRequest());
  try {
    const { data } = await api.prevmaintenance.equipmentTypes(
      buildingIds,
      subsystemIds
    );
    return dispatch(actions.equipmentTypesResponse(data));
  } catch (err) {
    return dispatch(actions.equipmentTypesResponse(err));
  }
};

export const fetchAssetTasks = (
  buildingIds,
  equipmentTypeIds,
  metricFilters,
  useObserved,
  assetScoreFilter,
  inHouseFtes,
  inHouseUtilization,
  inHouseFteLaborRate,
  outSourcedLaborRate,
  materialsMultiplier,
  updatedTasks,
  activeScenario
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.assetTaskRequest());
  try {
    const filters = {
      buildingIds,
      equipmentTypeIds,
      metricFilters,
      useObserved,
      assetScoreFilter,
      inHouseFtes,
      inHouseUtilization,
      inHouseFteLaborRate,
      outSourcedLaborRate,
      materialsMultiplier,
      updatedTasks,
    };
    const discountRate = _getState().capitalPlanning.discountRate;
    const data = await dbMP_getTasks(
      api,
      filters,
      240,
      activeScenario,
      discountRate
    );
    if (!data) {
      return dispatch(actions.assetTaskResponse(data));
    }
    return dispatch(actions.assetTaskResponse(data));
  } catch (err) {
    return dispatch(actions.assetTaskResponse(err));
  }
};

export const selectSubsystems = subsystemIds => async (dispatch, _getState) => {
  dispatch(actions.selectSubsystems({ subsystemIds: subsystemIds }));
};

export const selectEquipmentTypes = equipmentTypeIds => async (
  dispatch,
  _getState
) => {
  dispatch(
    actions.selectEquipmentTypes({ equipmentTypeIds: equipmentTypeIds })
  );
};

export const updateCostCalcs = costCalcs => async (dispatch, _getState) => {
  dispatch(actions.updateCostCalcs(costCalcs));
};

export const updateMetric = metric => async (dispatch, _getState) => {
  saveMetric(metric);
  dispatch(actions.updateMetric({ metric: metric }));
};

export const updateReplacements = replacements => async (
  dispatch,
  _getState,
  { api }
) => {
  saveFilter('replacements', replacements);
  dispatch(actions.updateReplacements({ replacements: replacements }));
};

export const selectLifeIndicator = useObserved => async (
  dispatch,
  _getState
) => {
  saveFilter('useObserved', useObserved);
  dispatch(actions.selectLifeIndicator({ useObserved: useObserved }));
};

export const updateAssetScore = metric => async (dispatch, _getState) => {
  saveFilter('assetScore', metric);
};

// MAINTENANCE PLANNING :: Update Who Does Maintenance Tasks //////////////////
export const convertByEquipmentType = (equipmentTypeId, bucketId) => async (
  dispatch,
  _getState
) => {
  /*
  const output = updateMPTaskState(
    _getState().prevMaintenance.assets,
    _getState().prevMaintenance.updatedTasks,
    {
      type: 'equipmentType',
      equipmentTypeId: equipmentTypeId,
      equipmentId: undefined,
      taskId: undefined,
      labor: bucketId,
      months: [],
    }
  );
  */
  let _assets = Object.assign(_getState().prevMaintenance.assets, {});
  const assetsLength = _assets.length;
  for (let x = 0; x < assetsLength; x++) {
    if (_assets[x].equipmentTypeId === equipmentTypeId) {
      _assets[x].touched = true;
      _assets[x].tasks.forEach(_t => (_t.performedBy = bucketId));
    }
  }
  return dispatch(actions.assetListUpdate(_assets));
};

export const convertByEquipment = (equipmentId, bucketId) => async (
  dispatch,
  _getState
) => {
  let _assets = Object.assign(_getState().prevMaintenance.assets, {});
  const assetsLength = _assets.length;
  for (let x = 0; x < assetsLength; x++) {
    if (_assets[x].equipmentId === equipmentId) {
      _assets[x].touched = true;
      _assets[x].tasks.forEach(_t => (_t.performedBy = bucketId));
      break;
    }
  }
  return dispatch(actions.assetListUpdate(_assets));
};

export const convertByTask = (equipmentId, taskId, bucketId) => async (
  dispatch,
  _getState
) => {
  let _assets = Object.assign(_getState().prevMaintenance.assets, {});
  const assetsLength = _assets.length;
  for (let x = 0; x < assetsLength; x++) {
    if (_assets[x].equipmentId === equipmentId) {
      _assets[x].touched = true;
      _assets[x].tasks.forEach(_t => {
        if (_t.taskId === taskId) _t.performedBy = bucketId;
      });
      break;
    }
  }
  return dispatch(actions.assetListUpdate(_assets));
};

// MAINTENANCE PLANNING :: Update Filters /////////////////////////////////////
export const selectEquipmentDetail = (equipmentId, taskId) => async (
  dispatch,
  _getState
) => {
  if (!equipmentId) return dispatch(actions.selectEquipmentDetail(null));
  const _asset = _getState().prevMaintenance.assets.find(
    _e => _e.equipmentId === equipmentId
  );
  _asset.taskId = taskId;
  return dispatch(actions.selectEquipmentDetail(_asset));
};

const saveMetric = metric => {
  let _localMetric;
  let _metrics = JSON.parse(localStorage.getItem('sideBarMetrics')) || [];
  if (_metrics && _metrics.length) {
    _localMetric = _metrics.find(_m => _m.metricId === metric.metricId);
    if (_localMetric)
      _metrics = _metrics.filter(_m => _m.metricId !== metric.metricId);
  }

  localStorage.setItem('sideBarMetrics', JSON.stringify([..._metrics, metric]));
};

const saveFilter = (title, filter) => {
  let _localFilter;
  let _filters = JSON.parse(localStorage.getItem('sideBarFilters')) || {};
  if (_filters && Object.keys(_filters).length) {
    _localFilter = _filters[title];
    if (_localFilter) delete _filters[title];
  }
  _filters[title] = filter;

  localStorage.setItem('sideBarFilters', JSON.stringify(_filters));
};

// MAINTENANCE PLANNING :: UI /////////////////////////////////////////////////
export const updateTaskSchedule = payload => dispatch => {
  dispatch(actions.updateTaskSchedule(payload));
};

export const openSaveScenarioModal = (type = undefined) => dispatch => {
  dispatch(actions.uiOpenSaveScenarioModal(type));
};

export const closeSaveScenarioModal = () => dispatch => {
  dispatch(actions.uiCloseSaveScenarioModal());
};

//--- Selector to cache asset distribution chart data call ---
export const getAssetDistChartData_cached = createSelector(
  assets => assets,
  updatedTasks => updatedTasks,
  (assets, updatedTasks) => getAssetDistChartData(assets, updatedTasks)
);

export const getAssetDistChartData = (assets, updatedTasks) => dispatch => {
  const chartData = getDistributionAssets(assets, updatedTasks);
  dispatch(actions.getAssetDistChartData(chartData));
};

// Action Handlers ////////////////////////////////////////////////////////////

export default handleActions(
  {
    [actions.filterOptsRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },
    [actions.filterOptsResponse]: {
      next: (_state, { payload }) => {
        const _subsystemIds = payload.subSystems.map(ss => {
          return ss.id;
        });
        const _equipmentTypeIds = payload.equipmentTypes.map(et => {
          return et.id;
        });
        const _metricFilters = payload.metricFilters.map(mf => {
          return {
            metricId: mf.metricId,
            maxValue: mf.maxValue,
            minValue: mf.minValue,
            weight: 100,
          };
        });
        const useObserved = payload.metricFilters.filter(mf => {
          return mf.name === 'Observed Remaining Life';
        }).length;

        return {
          ..._state,
          isRefreshing: false,
          filterOpts: payload,
          metricFilters: _metricFilters,
          selectedEquipmentTypes: _equipmentTypeIds,
          selectedSubsystems: _subsystemIds,
          costCalcs: payload.costCalculationDefaults,
          useObserved: useObserved,
          activeScenario: null,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.scenariosRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },
    [actions.scenariosResponse]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          scenarios: payload,
          isRefreshing: false,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.scenarioAccessRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
      }),
    },
    [actions.scenarioAccessResponse]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          accessList: payload,
          isScenarioRefreshing: false,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isScenarioRefreshing: false,
      }),
    },

    [actions.saveScenarioRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
        isSavingScenario: true,
      }),
    },
    [actions.saveScenarioResponse]: {
      next: (_state, { payload }) => {
        const _newState = _state.scenarios
          ? {
              owned: [..._state.scenarios.owned, payload],
              shared: _state.scenarios.shared,
            }
          : { owned: [payload], shared: [] };
        payload.noRefresh = true;

        return {
          ..._state,
          isScenarioRefreshing: false,
          isSavingScenario: false,
          scenarios: _newState,
          activeScenario: payload,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isScenarioRefreshing: false,
      }),
    },

    [actions.updateScenarioRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
        isUpdatingScenario: true,
      }),
    },
    [actions.updateScenarioResponse]: {
      next: (_state, { payload }) => {
        const _owned = mergeArrays(_state.scenarios.owned, payload, 'id');
        const _newState = {
          owned: _owned,
          shared: _state.scenarios.shared,
        };
        return {
          ..._state,
          isScenarioRefreshing: false,
          isUpdatingScenario: false,
          scenarios: _newState,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isScenarioRefreshing: false,
      }),
    },

    [actions.deleteScenarioRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
        isDeletingScenario: true,
      }),
    },
    [actions.deleteScenarioResponse]: {
      next: (_state, { payload }) => {
        const newOwnedState = _state.scenarios.owned.filter(
          val => val.id !== payload
        );
        const newSharedState = _state.scenarios.shared.filter(
          val => val.id !== payload
        );
        return {
          ..._state,
          isScenarioRefreshing: false,
          isDeletingScenario: false,
          scenarios: { owned: newOwnedState, shared: newSharedState },
          activeScenario:
            _state.activeScenario?.id === payload.id
              ? null
              : _state.activeScenario,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isScenarioRefreshing: false,
      }),
    },

    [actions.shareScenarioRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
      }),
    },
    [actions.shareScenarioResponse]: {
      next: (_state, { payload }) => {
        let newState = _state.scenarios.owned.filter(
          val => val.id !== payload.id
        );
        newState = newState.concat([payload]);
        return {
          ..._state,
          isScenarioRefreshing: false,
          scenarios: { owned: newState, shared: _state.scenarios.shared },
          activeScenario:
            _state.activeScenario.id === payload.id
              ? payload
              : _state.activeScenario,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isScenarioRefreshing: false,
      }),
    },

    [actions.unshareScenarioRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
      }),
    },
    [actions.unshareScenarioResponse]: {
      next: (_state, { payload }) => {
        // owned scenarios....
        const _ownedScenario = _state.scenarios.owned.find(
          _s => _s.id === payload.id
        );
        let newOwnedState = _state.scenarios.owned.filter(
          val => val.id !== payload.id
        );
        _ownedScenario && (newOwnedState = newOwnedState.concat([payload]));

        // shared scenarios....
        let newSharedState = _state.scenarios.shared.filter(
          val => val.id !== payload.id
        );

        return {
          ..._state,
          isScenarioRefreshing: false,
          scenarios: { owned: newOwnedState, shared: newSharedState },
          activeScenario:
            _state.activeScenario?.id === payload.id
              ? payload
              : _state.activeScenario,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.setActiveScenarioRequest]: {
      next: state => ({
        ...state,
        isScenarioRefreshing: true,
        isLoadingScenario: true,
      }),
    },
    [actions.setActiveScenarioResponse]: {
      next: (_state, { payload }) => {
        const updatedTasks = payload?.options?.updatedTasks || {};
        return {
          ..._state,
          activeScenario: payload,
          updatedTasks: updatedTasks,
          isScenarioRefreshing: false,
          isLoadingScenario: false,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isScenarioRefreshing: false,
      }),
    },

    [actions.clearActiveScenario]: {
      next: state => ({
        ...state,
        activeScenario: null,
        updatedTasks: {},
      }),
    },

    [actions.equipmentTypesRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },
    [actions.equipmentTypesResponse]: {
      next: (_state, { payload }) => {
        const _equipmentTypeIds = payload.map(et => {
          return et.id;
        });
        const _filterOpts = JSON.parse(JSON.stringify(_state.filterOpts));
        _filterOpts.equipmentTypes = payload;
        return {
          ..._state,
          filterOpts: _filterOpts,
          isRefreshing: false,
          selectedEquipmentTypes: _equipmentTypeIds,
        };
      },

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.selectSubsystems]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          selectedSubsystems: payload.subsystemIds,
        };
      },
    },
    [actions.selectEquipmentTypes]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          selectedEquipmentTypes: payload.equipmentTypeIds,
        };
      },
    },

    //--- Tasks ---
    [actions.assetTaskRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
        isRequestingTasks: true,
      }),
    },
    [actions.assetTaskResponse]: {
      next: (state, { payload }) => {
        if (payload === undefined) {
          return {
            ...state,
            isRefreshing: false,
            isRequestingTasks: false,
          };
        } else {
          const _assets = payload.assets;
          delete payload.assets; // remove list of assets from assetStats summary
          if (!_assets) {
            return { ...state };
          }
          return {
            ...state,
            assets: _assets,
            assetKey: state.assetKey + 1, // used by asset distribution chart
            assetStats: payload,
            isRefreshing: false,
            isRequestingTasks: false,
          };
        }
      },
    },

    [actions.assetListUpdate]: {
      next: (state, { payload }) => {
        return {
          ...state,
          assets: payload,
          assetKey: state.assetKey + 1,
          isRequestingAssets: false,
        };
      },
    },

    [actions.selectEquipmentDetail]: {
      next: (state, { payload }) => {
        return {
          ...state,
          equipmentDetail: payload,
        };
      },
    },

    [actions.updateCostCalcs]: {
      next: (state, { payload }) => {
        return {
          ...state,
          costCalcs: payload,
        };
      },
    },

    [actions.updateMetric]: {
      next: (_state, { payload }) => {
        let _metricFilters = JSON.parse(JSON.stringify(_state.metricFilters));
        _metricFilters.forEach((filter, index) => {
          if (filter.metricId === payload.metric.metricId) {
            _metricFilters[index] = payload.metric;
          }
        });
        return {
          ..._state,
          metricFilters: _metricFilters,
        };
      },
    },

    [actions.updateReplacements]: {
      next: (_state, { payload }) => {
        return {
          ..._state,
          replacements: payload.replacements,
        };
      },
    },

    [actions.selectLifeIndicator]: {
      next: (_state, { payload }) => ({
        ..._state,
        useObserved: payload.useObserved,
      }),
    },
    [actions.updateTaskSchedule]: {
      next: (_state, { payload }) => {
        // Loop through all assets to find the one whose task we are updating
        let newEquipmentDetail = _state.equipmentDetail;
        const assetsWithUpdatedTaskSchedule = _state.assets.map(asset => {
          if (asset.equipmentId === payload.equipmentId) {
            // if the asset matches, loop through all tasks to find the specific schedule we are updating
            const newTasks = asset.tasks.map(task => {
              if (task.taskId === payload.taskId) {
                return {
                  ...task,
                  months: payload.months,
                  monthsOverriden: true,
                };
              }
              return task;
            });
            newEquipmentDetail = {
              ...asset,
              tasks: newTasks,
            };
            return newEquipmentDetail;
          }
          return asset;
        });

        return {
          ..._state,
          assets: assetsWithUpdatedTaskSchedule,
          equipmentDetail: newEquipmentDetail,
          taskKey: _state.taskKey + 1,
        };
      },
    },

    [actions.updateTasks]: {
      next: (_state, { payload }) => ({
        ..._state,
        updatedTasks: payload,
      }),
    },

    [actions.uiOpenSaveScenarioModal]: {
      next: (_state, { payload }) => ({
        ..._state,
        showSaveModal: true,
        saveModalType: payload,
      }),
    },

    [actions.uiCloseSaveScenarioModal]: {
      next: _state => ({
        ..._state,
        showSaveModal: false,
        saveModalType: undefined,
      }),
    },
    [actions.getAssetDistChartData]: {
      next: (_state, { payload }) => ({
        ..._state,
        assetDistChartData: payload,
      }),
    },
  },
  defaultState
);
