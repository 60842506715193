export class BudgetPlanningService {
  constructor(http) {
    this.http = http;
  }

  async chart(
    siteId,
    buildingIds,
    equipmentTypeIds,
    metricFilters,
    assetScoreFilter,
    useObserved,
    budgetYears,
    excludedEquipmentIds
  ) {
    let data = {
      siteId: siteId,
      buildingIds: buildingIds,
      equipmentTypeIds: equipmentTypeIds,
      budgetYears: budgetYears,
      excludedEquipmentIds,
    };

    if (metricFilters) {
      data.metricFilters = metricFilters;
    }

    if (useObserved !== undefined) {
      data.useObserved = useObserved;
    }

    if (assetScoreFilter !== undefined) {
      data.assetScoreFilter = assetScoreFilter;
    }

    return this.http.post(`/budget-planning/chart`, data);
  }

  async scenarios() {
    return this.http.get(`/budget-planning/scenarios`);
  }

  async saveScenario(scenario) {
    return this.http.post(`/budget-planning/scenario`, scenario);
  }

  async getScenario(scenarioId) {
    return this.http.get(`/budget-planning/scenario/${scenarioId}`);
  }

  async deleteScenario(scenarioId) {
    return this.http.delete(`/budget-planning/scenario/${scenarioId}`);
  }

  async getScenarioAssets(scenarioId) {
    return this.http.get(`/budget-planning/scenario/${scenarioId}/assets`);
  }

  async getScenarioAccessList(scenarioId) {
    return this.http.get(`/budget-planning/scenario/${scenarioId}/access-list`);
  }

  async updateScenario(scenario) {
    const _id = scenario.id;
    delete scenario.id;
    return this.http.put(`/budget-planning/scenario/${_id}`, scenario);
  }

  async shareScenario(scenarioId, userId) {
    return this.http.post(`/budget-planning/scenario/${scenarioId}/share`, [
      userId,
    ]);
  }

  async unshareScenario(scenarioId, userIds) {
    return this.http.post(
      `/budget-planning/scenario/${scenarioId}/remove-access`,
      userIds
    );
  }
}
