import {
  DRILL_HIERARCHY_OPTIONS,
  FAULT_VIEWS,
  HEATMAP_VIEW_COLOR_PALETTES,
  LIST_VIEW_SORT_BY_OPTIONS,
  LIST_VIEW_SORT_ORDER_OPTIONS,
} from 'components/Tables/FaultViews/FaultViewCommon';
import { createAction, handleActions } from 'redux-actions';

export const types = {
  FILTER_OPTS_REQUEST: 'app/analytics/FILTER_OPTS_REQUEST',
  FILTER_OPTS_RESPONSE: 'app/analytics/FILTER_OPTS_RESPONSE',

  HOURS_SUMMARY_REQUEST: 'app/analytics/HOURS_SUMMARY_REQUEST',
  HOURS_SUMMARY_RESPONSE: 'app/analytics/HOURS_SUMMARY_RESPONSE',

  HOURS_BREAKDOWN_REQUEST: 'app/analytics/HOURS_BREAKDOWN_REQUEST',
  HOURS_BREAKDOWN_RESPONSE: 'app/analytics/HOURS_BREAKDOWN_RESPONSE',

  TASK_CHART_DETAIL_REQUEST: 'app/analytics/TASK_CHART_DETAIL_REQUEST',
  TASK_CHART_DETAIL_RESPONSE: 'app/analytics/TASK_CHART_DETAIL_RESPONSE',

  TASK_CHART_REQUEST: 'app/analytics/TASK_CHART_REQUEST',
  TASK_CHART_RESPONSE: 'app/analytics/TASK_CHART_RESPONSE',

  FAULT_TABLE_NODES_REQUEST: 'app/analytics/FAULT_TABLE_NODES_REQUEST',
  FAULT_TABLE_NODES_RESPONSE: 'app/analytics/FAULT_TABLE_NODES_RESPONSE',

  FAULT_HEATMAP_NODES_REQUEST: 'app/analytics/FAULT_HEATMAP_NODES_REQUEST',
  FAULT_HEATMAP_NODES_RESPONSE: 'app/analytics/FAULT_HEATMAP_NODES_RESPONSE',

  FAULT_NODE_CHART_REQUEST: 'app/analytics/FAULT_NODE_CHART_REQUEST',
  FAULT_NODE_CHART_RESPONSE: 'app/analytics/FAULT_NODE_CHART_RESPONSE',

  FAULT_LIST_REQUEST: 'app/analytics/FAULT_LIST_REQUEST',
  FAULT_LIST_RESPONSE: 'app/analytics/FAULT_LIST_RESPONSE',

  CLEAR_FAULT_NODE_CHART: 'app/analytics/CLEAR_FAULT_NODE_CHART',

  SET_FAULT_VIEW: 'app/analytics/SET_FAULT_VIEW',
  SET_FAULT_VIEW_DRILL_PATH: 'app/analytics/SET_FAULT_VIEW_DRILL_PATH',
  SET_FAULT_VIEW_DRILL_HIERARCHY:
    'app/analytics/SET_FAULT_VIEW_DRILL_HIERARCHY',

  SET_LIST_VIEW_SORT_BY: 'app/analytics/SET_LIST_VIEW_SORT_BY',
  SET_LIST_VIEW_SORT_ORDER: 'app/analytics/SET_LIST_VIEW_SORT_ORDER',

  SET_HEATMAP_VIEW_COLOR_PALETTE:
    'app/analytics/SET_HEATMAP_VIEW_COLOR_PALETTE',
};

export const actions = {
  filterOptsRequest: createAction(types.FILTER_OPTS_REQUEST),
  filterOptsResponse: createAction(types.FILTER_OPTS_RESPONSE),

  hoursSummaryRequest: createAction(types.HOURS_SUMMARY_REQUEST),
  hoursSummaryResponse: createAction(types.HOURS_SUMMARY_RESPONSE),

  hoursBreakdownRequest: createAction(types.HOURS_BREAKDOWN_REQUEST),
  hoursBreakdownResponse: createAction(types.HOURS_BREAKDOWN_RESPONSE),

  taskChartDetailRequest: createAction(types.TASK_CHART_DETAIL_REQUEST),
  taskChartDetailResponse: createAction(types.TASK_CHART_DETAIL_RESPONSE),

  taskChartRequest: createAction(types.TASK_CHART_REQUEST),
  taskChartResponse: createAction(types.TASK_CHART_RESPONSE),

  faultTableNodesRequest: createAction(types.FAULT_TABLE_NODES_REQUEST),
  faultTableNodesResponse: createAction(types.FAULT_TABLE_NODES_RESPONSE),

  faultHeatmapNodesRequest: createAction(types.FAULT_HEATMAP_NODES_REQUEST),
  faultHeatmapNodesResponse: createAction(types.FAULT_HEATMAP_NODES_RESPONSE),

  faultListRequest: createAction(types.FAULT_LIST_REQUEST),
  faultListResponse: createAction(types.FAULT_LIST_RESPONSE),

  faultNodeChartRequest: createAction(types.FAULT_NODE_CHART_REQUEST),
  faultNodeChartResponse: createAction(types.FAULT_NODE_CHART_RESPONSE),
  clearFaultNodeChart: createAction(types.CLEAR_FAULT_NODE_CHART),

  setFaultView: createAction(types.SET_FAULT_VIEW),
  setFaultViewDrillPath: createAction(types.SET_FAULT_VIEW_DRILL_PATH),
  setFaultViewDrillHierarchy: createAction(
    types.SET_FAULT_VIEW_DRILL_HIERARCHY
  ),

  setListViewSortBy: createAction(types.SET_LIST_VIEW_SORT_BY),
  setListViewSortOrder: createAction(types.SET_LIST_VIEW_SORT_ORDER),

  setHeatmapViewColorPalette: createAction(
    types.SET_HEATMAP_VIEW_COLOR_PALETTE
  ),
};

const DRILL_PATH_KEY = 'analytics-fault-drill-path';
const FAULT_VIEW_KEY = 'analytics-fault-view';
const DRILL_HIERARCHY_KEY = 'analytics-fault-drill-hierarchy';
const LIST_VIEW_SORT_BY_KEY = 'analytics-list-view-sort-by';
const LIST_VIEW_SORT_ORDER_KEY = 'analytics-list-view-sort-order';
const HEATMAP_COLOR_PALETTE_KEY = 'analytics-heatmap-view-color-palette';

const defaultState = {
  faultView: JSON.parse(localStorage.getItem(FAULT_VIEW_KEY)) ?? FAULT_VIEWS[0],
  faultViewDrillPath: JSON.parse(localStorage.getItem(DRILL_PATH_KEY)) ?? [],
  faultViewDrillHierarchy:
    JSON.parse(localStorage.getItem(DRILL_HIERARCHY_KEY)) ??
    DRILL_HIERARCHY_OPTIONS[0],
  listViewSortBy:
    JSON.parse(localStorage.getItem(LIST_VIEW_SORT_BY_KEY)) ??
    LIST_VIEW_SORT_BY_OPTIONS[0],
  listViewSortOrder:
    JSON.parse(localStorage.getItem(LIST_VIEW_SORT_ORDER_KEY)) ??
    LIST_VIEW_SORT_ORDER_OPTIONS[1],
  heatmapViewColorPalette:
    JSON.parse(localStorage.getItem(HEATMAP_COLOR_PALETTE_KEY)) ??
    HEATMAP_VIEW_COLOR_PALETTES[1],
  filterOpts: null,
  isRefreshing: false,
  isRefreshingTaskChart: false,
  isRefreshingTaskChartDetail: false,
  isRefreshingFaultTableNodes: false,
  isRefreshingFaultNodeChart: false,
  isRefreshingFaultList: false,
  taskChart: null,
  taskChartDetail: null,
  taskChartError: null,
  taskChartDetailError: null,
  faultTableNodes: null,
  faultHeatmapNodes: null,
  faultNodeChart: null,
  faultList: null,
};

// - - - FETCH FILTER OPTIONS - - -
// - - - FETCH FILTER OPTIONS - - -
// - - - FETCH FILTER OPTIONS - - -

export const fetchFilterOptions = (buildingIds, beginDate, endDate) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.filterOptsRequest());
  try {
    const { data } = await api.analytics.filterOptions(
      buildingIds,
      beginDate,
      endDate
    );
    return dispatch(actions.filterOptsResponse(data));
  } catch (err) {
    return dispatch(actions.filterOptsResponse(err));
  }
};

// - - - FETCH HOURS SUMMARY - - -
// - - - FETCH HOURS SUMMARY - - -
// - - - FETCH HOURS SUMMARY - - -

export const fetchHoursSummary = (
  buildingIds,
  analyticTypes,
  equipmentTypeIds,
  beginDate,
  endDate
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.hoursSummaryRequest());
  try {
    const { data } = await api.analytics.faultHoursSummary(
      buildingIds,
      analyticTypes,
      equipmentTypeIds,
      beginDate,
      endDate
    );
    let isValid = data.faultHoursChart.data.datasets.length > 0 ? true : false;
    data.isValid = isValid;
    data.faultHoursChart.isValid = isValid;
    return dispatch(actions.hoursSummaryResponse(data));
  } catch (err) {
    return dispatch(actions.hoursSummaryResponse(err));
  }
};

export const fetchTaskChart = (taskId, beginDate, endDate, timezone) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.taskChartRequest());
  try {
    const { data } = await api.analytics.taskChart(taskId, beginDate, endDate, timezone);
    return dispatch(actions.taskChartResponse(data));
  } catch (err) {
    return dispatch(actions.taskChartResponse(err));
  }
};

export const fetchTaskChartDetail = (taskId, beginDate, endDate, timezone) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.taskChartDetailRequest());
  try {
    const { data } = await api.analytics.taskChartDetail(
      taskId,
      beginDate,
      endDate,
      timezone
    );
    return dispatch(actions.taskChartDetailResponse(data));
  } catch (err) {
    return dispatch(actions.taskChartDetailResponse(err));
  }
};

export const fetchFaultTableNodes = (
  nodeType,
  buildingIds,
  analyticTypes,
  equipmentClassIds,
  beginDate,
  endDate
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.faultTableNodesRequest());
  try {
    const { data } = await api.analytics.faultTableNodes(
      nodeType,
      buildingIds,
      analyticTypes,
      equipmentClassIds,
      beginDate,
      endDate
    );

    return dispatch(actions.faultTableNodesResponse(data.faultNodes));
  } catch (err) {
    return dispatch(actions.faultTableNodesResponse(err));
  }
};

export const fetchFaultHeatmapNodes = (
  nodeType,
  aggregationInterval,
  buildingIds,
  analyticTypes,
  equipmentClassIds,
  beginDate,
  endDate
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.faultHeatmapNodesRequest());
  try {
    const { data } = await api.analytics.faultHeatmapNodes(
      nodeType,
      aggregationInterval,
      buildingIds,
      analyticTypes,
      equipmentClassIds,
      beginDate,
      endDate
    );

    return dispatch(actions.faultHeatmapNodesResponse(data.faultIntervalNodes));
  } catch (err) {
    return dispatch(actions.faultHeatmapNodesResponse(err));
  }
};

export const fetchFaultList = (
  buildingIds,
  analyticTypes,
  equipmentClassIds,
  beginDate,
  endDate,
  pageSize,
  pageNumber,
  sortSelection,
  orderDescending,
  searchTerm
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.faultListRequest());
  try {
    const { data } = await api.analytics.faultList(
      buildingIds,
      analyticTypes,
      equipmentClassIds,
      beginDate,
      endDate,
      pageSize,
      pageNumber,
      sortSelection,
      orderDescending,
      searchTerm
    );

    return dispatch(actions.faultListResponse(data));
  } catch (err) {
    return dispatch(actions.faultListResponse(err));
  }
};

export const fetchFaultNodeChart = (
  buildingIds,
  analyticTypes,
  equipmentClassIds,
  taskId,
  beginDate,
  endDate
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.faultNodeChartRequest());
  try {
    const { data } = await api.analytics.faultNodeChart(
      buildingIds,
      analyticTypes,
      equipmentClassIds,
      taskId,
      beginDate,
      endDate
    );

    return dispatch(actions.faultNodeChartResponse(data.chartData));
  } catch (err) {
    return dispatch(actions.faultNodeChartResponse(err));
  }
};

export const clearFaultNodeChart = () => async (
  dispatch,
  _getState,
  { api }
) => {
  return dispatch(actions.clearFaultNodeChart());
};

// Helper function to facilitate access to localstorage.
const setFaultViewDrillpath = (dispatch, drillPath) => {
  localStorage.setItem(DRILL_PATH_KEY, JSON.stringify(drillPath));
  return dispatch(actions.setFaultViewDrillPath(drillPath));
};

export const drill = node => async (dispatch, _getState, { api }) => {
  const drillPath = _getState().analytics.faultViewDrillPath;
  return setFaultViewDrillpath(dispatch, [...drillPath, node]);
};

export const unDrill = walkBackIndex => async (
  dispatch,
  _getState,
  { api }
) => {
  const drillPath = _getState().analytics.faultViewDrillPath;
  return setFaultViewDrillpath(dispatch, drillPath.slice(0, walkBackIndex));
};

export const clearDrill = () => async (dispatch, _getState, { api }) => {
  return setFaultViewDrillpath(dispatch, []);
};

export const setFaultViewDrillHierarchy = drillHierarchy => async (
  dispatch,
  _getState,
  { api }
) => {
  localStorage.setItem(DRILL_HIERARCHY_KEY, JSON.stringify(drillHierarchy));
  return dispatch(actions.setFaultViewDrillHierarchy(drillHierarchy));
};

export const setFaultView = faultView => async (
  dispatch,
  _getState,
  { api }
) => {
  localStorage.setItem(FAULT_VIEW_KEY, JSON.stringify(faultView));
  return dispatch(actions.setFaultView(faultView));
};

export const setListViewSortBy = sortByOption => async (
  dispatch,
  _getState,
  { api }
) => {
  localStorage.setItem(LIST_VIEW_SORT_BY_KEY, JSON.stringify(sortByOption));
  return dispatch(actions.setListViewSortBy(sortByOption));
};

export const setListViewSortOrder = sortOrderOption => async (
  dispatch,
  _getState,
  { api }
) => {
  localStorage.setItem(
    LIST_VIEW_SORT_ORDER_KEY,
    JSON.stringify(sortOrderOption)
  );
  return dispatch(actions.setListViewSortOrder(sortOrderOption));
};

export const setHeatmapViewColorPalette = colorPalette => async (
  dispatch,
  _getState,
  { api }
) => {
  localStorage.setItem(HEATMAP_COLOR_PALETTE_KEY, JSON.stringify(colorPalette));
  return dispatch(actions.setHeatmapViewColorPalette(colorPalette));
};

// - - - ACTIONS - - -
// - - - ACTIONS - - -
// - - - ACTIONS - - -

export default handleActions(
  {
    [actions.filterOptsRequest]: {
      next: state => ({
        ...state,
        isRefreshingOptions: true,
      }),
    },
    [actions.filterOptsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        filterOpts: payload,
        isRefreshingOptions: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshingOptions: false,
      }),
    },

    [actions.hoursSummaryRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },
    [actions.hoursSummaryResponse]: {
      next: (state, { payload }) => ({
        ...state,
        summaryInfo: payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.hoursBreakdownRequest]: {
      next: state => ({
        ...state,
        isRefreshingFaultTableNodes: true,
      }),
    },
    [actions.hoursBreakdownResponse]: {
      next: (state, { payload }) => ({
        ...state,
        faultTableNodes: payload,
        isRefreshingFaultTableNodes: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshingFaultTableNodes: false,
      }),
    },
    [actions.taskChartDetailRequest]: {
      next: state => ({
        ...state,
        taskChartDetailError: null,
        taskChartDetail: null,
        taskChart: null,
        isRefreshingTaskChartDetail: true,
      }),
    },
    [actions.taskChartDetailResponse]: {
      next: (state, { payload }) => ({
        ...state,
        taskChartDetailError: null,
        taskChartDetail: payload,
        isRefreshingTaskChartDetail: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        taskChartDetailError: payload.message,
        isRefreshing: false,
        isRefreshingTaskChartDetail: false,
      }),
    },

    [actions.taskChartRequest]: {
      next: state => ({
        ...state,
        taskChart: null,
        taskChartError: null,
        isRefreshingTaskChart: true,
      }),
    },
    [actions.taskChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        taskChart: payload,
        taskChartError: null,
        isRefreshingTaskChart: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        taskChartError: payload.message,
        isRefreshingTaskChart: false,
      }),
    },
    [actions.faultTableNodesRequest]: {
      next: state => ({
        ...state,
        isRefreshingFaultTableNodes: true,
      }),
    },
    [actions.faultTableNodesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        faultTableNodes: payload,
        isRefreshingFaultTableNodes: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshingFaultTableNodes: false,
      }),
    },
    [actions.faultHeatmapNodesRequest]: {
      next: state => ({
        ...state,
        isRefreshingFaultHeatmapNodes: true,
      }),
    },
    [actions.faultHeatmapNodesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        faultHeatmapNodes: payload,
        isRefreshingFaultHeatmapNodes: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshingFaultHeatmapNodes: false,
      }),
    },
    [actions.faultListRequest]: {
      next: state => ({
        ...state,
        isRefreshingFaultList: true,
      }),
    },
    [actions.faultListResponse]: {
      next: (state, { payload }) => ({
        ...state,
        faultList: payload,
        isRefreshingFaultList: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshingFaultList: false,
      }),
    },
    [actions.faultNodeChartRequest]: {
      next: state => ({
        ...state,
        isRefreshingFaultNodeChart: true,
        faultNodeChartErrorMessage: null,
      }),
    },
    [actions.faultNodeChartResponse]: {
      next: (state, { payload }) => ({
        ...state,
        faultNodeChart: payload,
        faultNodeChartErrorMessage: null,
        isRefreshingFaultNodeChart: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        faultNodeChartErrorMessage: payload?.response?.data,
        isRefreshingFaultNodeChart: false,
      }),
    },
    [actions.clearFaultNodeChart]: {
      next: (state, { payload }) => ({
        ...state,
        faultNodeChart: null,
      }),
    },
    [actions.setFaultViewDrillPath]: {
      next: (state, { payload }) => ({
        ...state,
        faultViewDrillPath: payload,
      }),
    },
    [actions.setFaultViewDrillHierarchy]: {
      next: (state, { payload }) => ({
        ...state,
        faultViewDrillHierarchy: payload,
      }),
    },
    [actions.setFaultView]: {
      next: (state, { payload }) => ({
        ...state,
        faultView: payload,
      }),
    },
    [actions.setListViewSortBy]: {
      next: (state, { payload }) => ({
        ...state,
        listViewSortBy: payload,
      }),
    },
    [actions.setListViewSortOrder]: {
      next: (state, { payload }) => ({
        ...state,
        listViewSortOrder: payload,
      }),
    },
    [actions.setHeatmapViewColorPalette]: {
      next: (state, { payload }) => ({
        ...state,
        heatmapViewColorPalette: payload,
      }),
    },
  },
  defaultState
);
