import db from './utilities/dbConnectUtilities';
import SparkMD5 from 'spark-md5';

/**
 * - - - - -   DATA MODEL :: Filters  - - - - -
 * store: 'filters'
 * {
 *    name: 'mp_tasks',
 *    cachedAt: 1603402330299,        // when the API was last read
 *    filterApi: {},                  // filters used in API call
 *    hashActiveFilters: 'hk...4',    // filters used to parse active data for app
 * }
 */

export const getFilters = async name => {
  return db.filters.where({ name: name }).first();
};

export const setFilters = async (name, filters, type = 'full') => {
  //-- Write All Info --
  if (type === 'full') {
    const newFilter = {
      name: name,
      cachedAt: Date.now(),
      filterApi: filters,
      hashActiveFilters: SparkMD5.hash(JSON.stringify(filters)),
    };
    db.filters.put(newFilter);
    return;
  }

  //-- Update Hash Only --
  if (type === 'hashOnly') {
    db.filters.where({ name: name }).modify({
      hashActiveFilters: SparkMD5.hash(JSON.stringify(filters)),
    });
  }
};

export const checkFilterHash = async (cachedHash, filters) => {
  const newFilterHash = SparkMD5.hash(JSON.stringify(filters));
  return newFilterHash === cachedHash;
};

export const checkScenarioHash = async (cachedHash, scenario) => {
  const newScenarioHash = SparkMD5.hash(JSON.stringify(scenario));
  return newScenarioHash === cachedHash;
};

export const clearExpiredFilters = async (expirationInMinutes = 120) => {
  const expirationTime = Date.now() - expirationInMinutes * 60 * 1000;
  return db.filters
    .where('cachedAt')
    .below(expirationTime)
    .delete();
};
